import { Checkbox, Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { searchBillingPCMPatients } from "../action/bliMonthyReportAction";
import { getlistClinicSites, getlistStaff } from "../action/registerAction";
import ReportHeader99426_7 from "./ReportHeader99426_7";
import ReportReviewedBase from "./ReportReviewedBase";
import _ from "lodash";


const { Option } = Select;

class ReportReviewed99426_7 extends ReportReviewedBase {

  cptCode = "99426-7"

  headerClass = ReportHeader99426_7

  renderTableProps() {
    const data = this.state.tableData && this.state.tableData.map(p => {
      const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
      const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
      const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
      const initializedDate = p.initializedDate
        ? p.initializedDate.split("T")[0]
        : "";
      const appInitialized = p.appInitialized ? p.appInitialized : "No";
      const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
      const billedAt = p.billedAt
        ? moment.tz(p.billedAt, p.userTimezone).format("YYYY-MM-DD")
        : "N/A";
      let billedBy = p.billedBy ? p.billedBy : "N/A";
      let staffInfo = _.filter(this.props.listStaff, {
        email: p.billedBy
      });
      if (staffInfo.length > 0) {
        billedBy = staffInfo[0].firstName + " " + staffInfo[0].lastName;
      }
      const diagnosisCodes = p.diagnosisCodes
        ? p.diagnosisCodes.replace(/_/g, ",")
        : "";
      const billingMonth = p.billingKey
        ? moment(p.billingKey).format("MM/YYYY")
        : "";
      return {
        ...p,
        createdAt,
        sirenId,
        initializedDate,
        dispensedDay,
        spentTime,
        billedAt,
        billedBy,
        diagnosisCodes,
        billingMonth,
        appInitialized
      };
    })

    const columns = [
      {
        Header: props => {
          return (
            <Checkbox
              onChange={this.handleCheckAll}
              indeterminate={
                this.state.selectedPatients.length > 0 &&
                this.state.selectedPatients.length <
                this.state.tableData.length
              }
              checked={
                this.state.selectedPatients.length > 0 &&
                this.state.selectedPatients.length ===
                this.state.tableData.length
              }
            />
          );
        },
        accessor: "",
        maxWidth: 60,
        sortable: false,
        filterable: false,
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <Checkbox
                onChange={(e, data) =>
                  this.handleCheck(e.target.checked, props.index)
                }
                checked={this.state.selectedPatients.includes(
                  props.index
                )}
              />
            </div>
          );
        }
      },
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60
      },
      {
        Header: "PatientID",
        accessor: "patientId"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "Date of Birth",
        accessor: "birthday"
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes"
      },
      {
        Header: "Account Created",
        accessor: "createdAt"
      },
      {
        Header: "App Activated",
        accessor: "appInitialized",
        maxWidth: 72,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "Yes") {
            return row[filter.id] === "Yes";
          }
          return row[filter.id] === "No";
        },
        Filter: ({ filter, onChange }) => (
          <Select
            className="select_common_style"
            onChange={onChange}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
            showSearch
            optionFilterProp="children"
          >
            <Option value="all">All</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        )
      },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth"
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime"
      },
      {
        Header: "Reviewed By",
        accessor: "billedBy",
        filterMethod: (filter, row) => {
          let rowfilterfullname = row._original.billedBy
            ? row[filter.id].toLowerCase()
            : "";
          let filterfullname = filter.value.toLowerCase();
          return rowfilterfullname.includes(filterfullname);
        }
      },
      {
        Header: "Reviewed Date",
        accessor: "billedAt"
      }
    ]

    return {
      data,
      columns
    }
  }

}

const mapStateToProp = state => ({
  authSites: state.authGroupStore.profile.siteId,
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff,
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  searchBillingPatients: (input) =>
    dispatch(searchBillingPCMPatients(input)),
  getlistStaff: () => dispatch(getlistStaff())
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportReviewed99426_7);
