import React, { Component } from "react";
import { Modal, Button, Form, Icon, Input, TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import ButtonSuccess from "../home/buttonSuccess";
import { Auth } from "aws-amplify";
import { createNote } from "../../action/DoctorNoteAction";
import "./AddText.css";
import queryString from "query-string";
import _ from "lodash";

class Add extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      confirmSuccess: false,
      inputTerm: {},
      doctorName: "",
      userId: "",
      id: ""
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const id = params.id.replace(" ", "+");
    const userId = params.userId;
    this.setState({ userId, id });
  }

  handleOpen = async () => {
    let user = await Auth.currentAuthenticatedUser();
    let doctorInfo = _.filter(this.props.listStaff, {
      email: user.attributes.email
    });
    let doctorName = user.attributes.email;
    if (doctorInfo.length > 0) {
      doctorName =
        doctorInfo[0].isPrescribing && !doctorInfo[0].firstName.includes("Dr.")
          ? "Dr. " + doctorInfo[0].firstName + " " + doctorInfo[0].lastName
          : doctorInfo[0].firstName + " " + doctorInfo[0].lastName;
    }
    this.setState({ open: true, confirmSuccess: false, doctorName });
  };
  close = () => this.setState({ open: false, confirmSuccess: false });
  handleInputChange(Input, value) {
    let inputTerm = Object.assign({}, this.state.inputTerm);
    inputTerm[Input] = value;
    this.setState({ inputTerm: inputTerm });
  }
  submit() {
    const { createNote } = this.props;
    this.setState({ open: false });
    const input = {
      id: this.state.id,
      author: this.state.doctorName,
      authorId: this.props.userName
    };
    if (this.state.inputTerm.note && this.state.inputTerm.note !== "") {
      Object.assign(input, { note: this.state.inputTerm.note });
    }
    if (this.state.inputTerm.title && this.state.inputTerm.title !== "") {
      Object.assign(input, { title: this.state.inputTerm.title });
    }
    createNote(input, this);
  }
  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <button className="note_button" onClick={this.handleOpen}>
            ADD
          </button>
        }
        open={open}
      >
        <Modal.Header>NEW NOTE</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <Input
                    onChange={(e, data) => {
                      this.handleInputChange("title", e.target.value);
                    }}
                    placeholder="Title"
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width="16">
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange("note", e.target.value);
                    }}
                    placeholder="Write your comments here .."
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.submit()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            Submit
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  listStaff: state.patientsListStore.listStaff,
  userName: state.patientsListStore.userName
});
const mapDispatchToProp = dispatch => ({
  createNote: (input, self) => dispatch(createNote(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(Add);
