import * as TYPES from "../types/types";
export function getCalenDate(calenDate) {
  return dispatch => {
    dispatch(changeCalenDate(calenDate));
  };
}
function changeCalenDate(calenDate) {
  return {
    type: TYPES.CALEN_DATE,
    text: calenDate
  };
}
