import { DatePicker, message, Modal as AntdModal, Select } from "antd";
import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import _ from "lodash";
import moment from "moment-timezone";
import PropTypes from 'prop-types';
import qs from "qs";
import queryString from "query-string";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button } from "semantic-ui-react";
import aws_exports from "../aws-exports-siren-medical";
import "../share/common.css";
import getPatchTrProps from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./report.css";
import "./selectComponent.css";

const { MonthPicker } = DatePicker;
const { Option } = Select;

export default class ReportReviewedBase extends Component {
  constructor(props) {
    super(props);

    // parse url param
    let selectSites = [], startDate = moment().format("YYYY-MM"), endDate = moment().add(1, "month").format("YYYY-MM")
    const queryParam = window.location.href.split("?")[1];
    if (queryParam) {
      let params = queryString.parse(queryParam);
      selectSites = params.siteId.split("_");
      startDate = params.startDate;
      endDate = params.endDate;
    }

    this.state = {
      modalIsOpen: false,
      tableData: [],
      selectedPatients: [],
      selectSites,
      startDate,
      endDate
    };
  }

  onChangeStartDate = (date, dateString) => {
    this.setState({ startDate: dateString });
  }

  onChangeEndDate = (date, dateString) => {
    this.setState({ endDate: dateString });
  }

  componentDidMount() {
    const { getlistStaff, getlistClinicSites } = this.props;
    if (this.props.listStaff.length === 0) {
      getlistStaff();
    }
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
    if (this.state.selectSites.length > 0) {
      this.searchPatients();
    } else {
      this.setState({
        selectSites: this.props.authSites
      }, this.searchPatients);
    }
  }

  makeRequestSearchPatient() {
    this.setState({
      selectedPatients: []
    });
    const fmtSiteId = this.state.selectSites.join("_");
    const billingInputValue = {
      siteId: fmtSiteId,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    this.setState({
      modalIsOpen: true
    })
    this.props.searchBillingPatients(billingInputValue).then((data) => {
      this.setState({
        tableData: data
      })

      let currentPath = Control.path;
      if (currentPath.includes('?')) {
        currentPath = currentPath.split('?')[0]
      }
      Control.replace(
        `${currentPath}?siteId=${fmtSiteId}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`
      );
    }).catch(e => {
      console.log("searchBillingPatients error: ", e);
    }).finally(() => {
      this.setState({
        modalIsOpen: false
      })
    });
  }

  handleCheckAll = (e) => {
    const checked = e.target.checked;
    let nextSelected = [...this.state.selectedPatients];
    const { page, pageSize, sortedData } = this.tableInstance.state;
    const currentPageData = sortedData.slice(page * pageSize, (page + 1) * pageSize);
    if (checked) {
      nextSelected = _.union(nextSelected, currentPageData.map(item => item._index))
    } else {
      nextSelected = _.difference(nextSelected, currentPageData.map(item => item._index))
    }
    this.setState({
      selectedPatients: nextSelected
    });
  }

  handleCheck = (checked, index) => {
    let selectedPatients = [...this.state.selectedPatients];
    if (checked) {
      selectedPatients.push(index);
    } else {
      selectedPatients = selectedPatients.filter(p => p !== index);
    }

    this.setState({
      selectedPatients
    });
  }

  searchPatients = () => {
    if (this.state.selectSites.length) {
      this.makeRequestSearchPatient();
    }
  };

  gotoDoctorDetailPage(userId, id, date) {
    let currentRoute = Control.path
    if (currentRoute.includes('?')) {
      currentRoute = currentRoute.split('?')[0]
    }
    // remove leading '/'
    currentRoute = currentRoute.substring(1)

    Control.go(`/StaffMedicalDetails?${currentRoute}/id=` + id +
      "&userId=" + userId +
      "&startDate=" + date +
      "&endDate=" + date
    );
  }

  handleGenerateReport = () => {
    if (this.state.selectedPatients.length === 0) {
      message.info(
        "Please select at least one patient record to download a report."
      );
      return;
    }

    if (this.state.selectedPatients.length > 25) {
      message.info("For best results, please reduce to 25 records or less.");
      return;
    }

    AntdModal.confirm({
      title: "Downloading Report",
      content:
        "To download the report, disable your pop-up blocker. It will take a few minutes for us to generate your report. You may continue using the Siren Dashboard and the download will start automatically when it is ready.",
      centered: true,
      onOk: async () => {
        await this.startDownloading();
      },
      onCancel: () => { }
    });
  }

  async startDownloading() {
    if (!this.cptCode) {
      throw "cptCode not set"
    }
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const cptCode = this.cptCode;
    const endpointInfo = _.filter(aws_exports.aws_cloud_logic_custom, {
      name: "dev-pdf-exporter"
    });
    const pdfEndpoint = endpointInfo[0].endpoint;
    let downloadUrl = null;
    const checkedAll =
      this.state.selectedPatients.length ===
      this.state.tableData.length;
    if (checkedAll) {
      const siteIds = this.state.selectSites.join("_");
      downloadUrl = `${pdfEndpoint}/medical/allBilledReport?siteIds=${siteIds}&cptCode=${cptCode}&token=${token}&startDate=${this.state.startDate
        }&endDate=${this.state.endDate}&generatedDate=${moment().format(
          "YYYY-MM-DD"
        )}`;
    } else {
      if (this.state.selectedPatients.length > 100) {
        AntdModal.warn({
          title: "Downloading Report",
          content: "Can not exceed 100 data.",
          centered: true
        });
        return;
      }
      const selectDownloadReport = this.state.selectedPatients.map(p => {
        const patient = this.state.tableData[p];
        if (this.cptCode === "99426-7") {
          return {
            sirenId: patient.sirenId,
            type: '99426',
            billingDate: patient.billingKey
          };
        } else {
          return {
            sirenId: patient.sirenId,
            type: patient.billedType,
            billingDate: patient.billingDate
          };
        }
      });
      downloadUrl = `${pdfEndpoint}/medical/billedReport?reportPatients=${encodeURIComponent(
        qs.stringify(selectDownloadReport)
      )}&cptCode=${cptCode}&token=${token}&startDate=${this.state.startDate
        }&endDate=${this.state.endDate}&generatedDate=${moment().format(
          "YYYY-MM-DD"
        )}`;
    }
    window.open(downloadUrl);
  }

  handleResetFilter = () => {
    this.tableInstance.setState({
      sorted: [],
      filtered: [],
      resized: []
    })
    this.searchPatients()
  }

  renderHeader() {
    return React.createElement(this.headerClass, {
      onResetFilter: this.handleResetFilter
    })
  }

  renderActionBar() {
    return (
      <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
        <span className="search_label">SiteID:</span>
        <Select
          mode="multiple"
          style={{ minWidth: 200 }}
          placeholder="Please select sites"
          value={this.state.selectSites}
          onChange={value => {
            this.setState({
              selectSites: value
            }, this.searchPatients);
          }}
          optionLabelProp="value"
          ptionFilterProp="children"
        >
          {this.props.authSites.map(id => {
            const site = this.props.clinicSites.find(site => site.siteId === id)
            if (!site) {
              return
            }
            return (
              <Option key={site.siteId} value={site.siteId}>
                {`${site.siteId} - ${site.name}`}
              </Option>
            )
          })}
        </Select>
        <span className="search_label search_time_label">StartDate:</span>
        <MonthPicker
          style={{ minWidth: 100 }}
          onChange={this.onChangeStartDate}
          value={moment(this.state.startDate, "YYYY-MM")}
          allowClear={false}
        />
        <span className="search_label search_time_label">EndDate:</span>
        <MonthPicker
          style={{ minWidth: 100 }}
          onChange={this.onChangeEndDate}
          value={moment(this.state.endDate, "YYYY-MM")}
          allowClear={false}
        />
        <Button
          size="small"
          onClick={this.searchPatients}
          style={{ marginLeft: 20 }}
          disabled={this.state.modalIsOpen}
          loading={this.state.modalIsOpen}
        >
          Search
        </Button>
        <Button
          size="small"
          onClick={this.handleGenerateReport}
          style={{ marginLeft: 20 }}
          disabled={this.state.modalIsOpen}
          loading={this.state.modalIsOpen}
        >
          Download
        </Button>
      </div>
    )
  }

  // subclass should override
  renderTableProps() {
    return { data: [], columns: [] }
  }

  renderTable() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.userId,
        rowInfo.original.id,
        moment().format("YYYY-MM-DD")
      );
    });

    const { data, columns } = this.renderTableProps()

    return (
      <ReactTable
        getTrProps={trProps}
        loading={this.state.modalIsOpen}
        ref={ref => (this.tableInstance = ref)}
        data={data}
        filterable
        defaultFilterMethod={(filter, row) => {
          if (!row[filter.id]) {
            return false;
          }
          return row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }}
        columns={columns}
        defaultSorted={[{ id: "sirenId", desc: true }]}
        defaultPageSize={20}
        className="-striped -highlight"
        minRows={0}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderActionBar()}
        {this.renderTable()}
      </div>
    );
  }
}

ReportReviewedBase.propTypes = {
  authSites: PropTypes.array.isRequired,
  clinicSites: PropTypes.array.isRequired,
  listStaff: PropTypes.array.isRequired,
  getlistClinicSites: PropTypes.func.isRequired,
  searchBillingPatients: PropTypes.func.isRequired,
  getlistStaff: PropTypes.func.isRequired,
}
