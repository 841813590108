import * as TYPES from "../types/types";

const initialState = {
  bliSirenIdReport: [],
  selectSirenIdReport: [],
  searchPatientsList: [],
  curMonthPatients99457: [],
  curMonthPatients99454: [],
  curMonthPatients99453: [],
  billing99454UnbilledPatients: [],
  billing99454BilledPatients: [],
  billing99453BilledPatients: [],
  billing99453UnbilledPatients: [],
  billing99457UnbilledPatients: [],
  billing99457BilledPatients: [],
  billingTableList: [],
  billingTableList_99454: [],
  generate99454Delay: "",
  showGenerate99454Delay: false,
  generate99457Delay: "",
  showGenerate99457Delay: false,
  count99453Unbilled: 0,
  count99454Unbilled: 0,
  count99457Unbilled: 0,
  billingPCMBilledPatients: [],
  billingPCMUnbilledPatients: [],
  countPCMUnbilled: 0,
  showGeneratePCMDelay: false,
  generatePCMDelay: "",
  curMonthPatientsPCM: []
};

export default function monthlyReportReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.COUNT_99453_UNBILLED:
      return {
        ...state,
        count99453Unbilled: action.text
      };
    case TYPES.COUNT_99454_UNBILLED:
      return {
        ...state,
        count99454Unbilled: action.text
      };
    case TYPES.COUNT_99457_UNBILLED:
      return {
        ...state,
        count99457Unbilled: action.text
      };
    case TYPES.GENERATE_99454_DELAY:
      return {
        ...state,
        generate99454Delay: action.text
      };
    case TYPES.SHOW_GENERATE_99454_DELAY:
      return {
        ...state,
        showGenerate99454Delay: action.text
      };
    case TYPES.GENERATE_99457_DELAY:
      return {
        ...state,
        generate99457Delay: action.text
      };
    case TYPES.SHOW_GENERATE_99457_DELAY:
      return {
        ...state,
        showGenerate99457Delay: action.text
      };
    case TYPES.BLI_SIRENID_REPORT:
      return {
        ...state,
        bliSirenIdReport: action.text
      };
    case TYPES.RESET_ALL_SIRENID:
      return {
        ...state,
        bliSirenIdReport: [],
        selectSirenIdReport: []
      };
    case TYPES.SEL_SIRENID_REPORT:
      return {
        ...state,
        selectSirenIdReport: action.text
      };
    case TYPES.SEARCH_PATIENTS_LIST:
      return {
        ...state,
        searchPatientsList: action.text
      };
    case TYPES.CURMONTH_PATIENTS_99457:
      return {
        ...state,
        curMonthPatients99457: action.text
      };
    case TYPES.CURMONTH_PATIENTS_99454:
      return {
        ...state,
        curMonthPatients99454: action.text
      };
    case TYPES.CURMONTH_PATIENTS_99453:
      return {
        ...state,
        curMonthPatients99453: action.text
      };
    case TYPES.BILLING_99454_Billed_PATIENTS:
      return {
        ...state,
        billing99454BilledPatients: action.text
      };
    case TYPES.BILLING_99454_Unbilled_PATIENTS:
      return {
        ...state,
        billing99454UnbilledPatients: action.text
      };
    case TYPES.BILLING_99453_Billed_PATIENTS:
      return {
        ...state,
        billing99453BilledPatients: action.text
      };
    case TYPES.BILLING_99453_Unbilled_PATIENTS:
      return {
        ...state,
        billing99453UnbilledPatients: action.text
      };
    case TYPES.BILLING_99457_Billed_PATIENTS:
      return {
        ...state,
        billing99457BilledPatients: action.text
      };
    case TYPES.BILLING_99457_Unbilled_PATIENTS:
      return {
        ...state,
        billing99457UnbilledPatients: action.text
      };
    case TYPES.BILLING_TABLE_LIST:
      return {
        ...state,
        billingTableList: action.text
      };
    case TYPES.BILLING_TABLE_LIST_99454:
      return {
        ...state,
        billingTableList_99454: action.text
      };
    case TYPES.BILLING_PCM_Billed_PATIENTS:
      return {
        ...state,
        billingPCMBilledPatients: action.text
      };
    case TYPES.BILLING_PCM_Unbilled_PATIENTS:
      return {
        ...state,
        billingPCMUnbilledPatients: action.text
      };
    case TYPES.COUNT_PCM_UNBILLED:
      return {
        ...state,
        countPCMUnbilled: action.text
      };
    case TYPES.SHOW_GENERATE_PCM_DELAY:
      return {
        ...state,
        showGeneratePCMDelay: action.text
      };
    case TYPES.GENERATE_PCM_DELAY:
      return {
        ...state,
        generatePCMDelay: action.text
      };
    case TYPES.CURMONTH_PATIENTS_PCM:
      return {
        ...state,
        curMonthPatientsPCM: action.text
      };
    default:
      return state;
  }
}
