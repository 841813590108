export const updatePhotoStatus = `mutation updatePhotoStatus($input: UpdatePhotoTaskInput!){
  updatePhotoStatus(input: $input)
}`;
export const setComplianceReviewed = `mutation setComplianceReviewed($input:SetCompliacneReviewedInput!) {
  setComplianceReviewed(input: $input) {
    complianceReviewed
  }
}
`;
export const setAppointment = `mutation setAppointmented($input: SetAppointmentedInput!) {
  setAppointmented(input: $input) {
    userId
  }
}`;
export const setSchduled = `mutation setScheduled($input: SetScheduledInput!) {
  setScheduled(input: $input) {
    userId
  }
}`;
export const setComplianceReviewReq = `mutation setComplianceReviewReq($input:SetCompliacneReviewReqInput!) {
  setComplianceReviewReq(input: $input) {
    complianceReviewReq 
    userId
  }
}
`;
export const registerAccount = `mutation createPatient($input: CreatePatientInput!) {
  createPatient(input: $input) {
    status
    message
  }
}
`;
export const updateNewPatient = `mutation updatePatient($input: UpdatePatientInput!) {
  updatePatient(input: $input) 
}
`;
export const updateSirenNote = `mutation updateSirenNote($input: UpdateSirenNoteInput!){
  updateSirenNote(input: $input){
     id
     timestamp
     author
     note
     title
  }
}`;

export const deleteSirenNote = `mutation deleteSirenNote($input: DeleteSirenNoteInput!){
  deleteSirenNote(input: $input){
     id
     timestamp
  }
}`;

export const createSirenNote = `mutation createSirenNote($input: CreateSirenNoteInput!){
  createSirenNote(input: $input){
     id
     timestamp
     author
     authorId
     note
     title
  }
}`;

export const createRpmTracker = `mutation createRpmTracker($input: CreateRpmTrackerInput!){
  createRpmTracker(input: $input){
     status
     message
  }
}`;

export const updateRpmTracker = `mutation updateRpmTracker($input: UpdateRpmTrackerInput!){
  updateRpmTracker(input: $input){
     status
     message
  }
}`;

export const setAppointmented = `mutation setAppointmented($input: SetAppointmentedInput!) {
  setAppointmented(input: $input) {
    userId
  }
}`;
export const deleteRegisteredPatient = `mutation deleteRegisteredPatient($input:DeleteRegisteredPatientInput!){
  deleteRegisteredPatient(input: $input){
    status
    message
  }
}
`;

export const deleteRpmTracker = `mutation deleteRpmTracker($input:DeleteRpmTrackerInput!){
  deleteRpmTracker(input: $input){
    status
    message
  }
}
`;

export const sendAppLink = `mutation sendAppLink($input: SendAppLinkInput!) {
  sendAppLink(input: $input)
}
`;
export const updateNewRegisterPatient = `mutation UpdateNewPatient($input: UpdateNewPatientInput!) {
  updateNewPatient(input: $input) 
}
`;
export const resetPatientPassword = `mutation resetPatientPassword($input: ResetPasswordInput!) {
  resetPatientPassword(input: $input) {
    status
    message
  }
}`;
export const updateAccountStatus = `mutation updateAccountStatus($input:UpdateAccountStatusInput!) {
  updateAccountStatus(input: $input)
}
`;
export const updateServiceStatus = `mutation updateAccountServiceStatus($input:UpdateAccountServiceStatusInput!) {
  updateAccountServiceStatus(input: $input)
}
`;
export const updateBilledPatient = `mutation updateBilledPatient($input: UpdateBilledPatientInput!) {
  updateBilledPatient(input: $input)
}`;
export const updateBilled99453Status = `mutation updateBilled99453Status($input: UpdateBilled99453StatusInput!) {
  updateBilled99453Status(input: $input)
}`;
export const updateBilled99454Status = `mutation updateBilled99454Status($input: UpdateBilledStatusInput!) {
  updateBilled99454Status(input: $input)
}`;
export const updateBilled99457Status = `mutation updateBilled99457Status($input: UpdateBilledStatusInput!) {
  updateBilled99457Status(input: $input)
}
`;
export const updateBillingStatus = `mutation updateBilledStatus($input: UpdateBilledStatusInput!) {
  updateBilledStatus(input: $input) 
}`;
export const UpdateNewPatient = `mutation UpdateNewPatient($input: UpdateNewPatientInput!) {
  updateNewPatient(input: $input) 
}`;
export const resetStaffPassword = `mutation resetStaffPassword($input: ResetPasswordInput!) {
  resetStaffPassword(input: $input) {
    status
    message
  }
}`;
export const registerDoctorAccount = `mutation createDoctor($input: CreateDoctorInput!) {
  createDoctor(input: $input) {
    status
    message
  }
}`;
export const deleteDoctor = `mutation deleteDoctor($input: DeleteDoctorInput!) {
  deleteDoctor(input: $input) {
   status
   message
  }
}
`;
export const updateDoctor = `mutation updateDoctor($input: UpdateDoctorInput!) {
  updateDoctor(input: $input) {
    status
    message
  }
}`;
export const generateBillingReport = `mutation generateBillingReport($input: GenerateBillingReportInput!) {
  generateBillingReport(input: $input)
}`;

export const updateDoctorProfile = `mutation updateDoctorProfile($input: UpdateDoctorProfileInput!) {
  updateDoctorProfile(input: $input) {
    status
    message
  }
}`;

export const updateDeviceStatus = `mutation updateDeviceStatus($input:UpdateDeviceStatusInput!) {
  updateDeviceStatus(input: $input)
}
`;

export const createCarePlan = `mutation createCarePlan($input: CarePlanInput!){
  createCarePlan(input: $input){
    createdAt
    userId
    name
    author
    authorId
    location
    month
  }
}`;

export const deleteCarePlan = `mutation deleteCarePlan($input: CarePlanInput!){
  deleteCarePlan(input: $input){
    createdAt
    userId
    name
    author
    authorId
    location
    month
  }
}`;

export const createFootSymptom = `mutation createFootSymptom($input: CreateFootSymptomInput!){
  createFootSymptom(input: $input){
     userId
     reportTime
     footSymptom
     foot
     footArea
  }
}`;
export const updateSpecialCaseStatus = `mutation updateSpecialCaseStatus($input:UpdateSpecialCaseStatusInput!) {
  updateSpecialCaseStatus(input: $input)
}`;
export const updateAddendum = `mutation updateAddendum($input: UpdateRpmTrackerInput!){
  updateAddendum(input: $input){
     status
     message
  }
}`;

export const updateBilledPCMStatus = `mutation updateBilledPCMStatus($input: UpdateBilledStatusInput!) {
  updateBilledPCMStatus(input: $input)
}
`;
