export const SOCK_STATUS = "sock_status";
export const SOCK_HOME_STATUS = "sock_home_status";
export const MEDICAL_STATUS = "medical_status";
export const SUPPORT_STATUS = "support_status";
export const PATIENTS_STATUS_DETAIL = "patients_status_detail";
export const AUTH_GROUP = "auth_group";
export const DELTA = "delta";
export const LEFT_FOOT_TEMP = "left_foot_temp";
export const RIGHT_FOOT_TEMP = "right_foot_temp";
export const STEPS = "steps";
export const LEFT_POSITION = "left_position";
export const RIGHT_POSITION = "right_position";
export const MIN1_OR_MIN30 = "min1_or_min30";
export const DELTA_AUTO = "delta_auto";
export const TEMP_UNIT = "temp_unit";
export const IS_ZERO = "is_zero";
export const CALENDAR_DATA = "calendar_data";
export const CALENDAR_DATA2 = "calendar_data2";
export const TIME_ZONE = "time_zone";
export const FOOT_STATUS = "foot_status";
export const FOOT_SYMTOMS = "foot_symtoms";
export const DOCTOR_DETAILS = "doctor_details";
export const RESOLVED_LIST = "resolved_list";
export const PATIENT_FIRST_NAME = "patient_first_name";
export const PATIENT_LAST_NAME = "patient_last_name";
export const BIR_MONTH = "bir_month";
export const BIR_DAY = "bir_day";
export const BIR_YEAR = "bir_year";
export const PATIENT_EMAIL = "patient_email";
export const PATIENT_MBI = "patient_mbi";
export const PATIENT_PATIENT_ID = "patient_patient_id";
export const PATIENT_SITE_ID = "patient_site_id";
export const PATIENT_SITE_NAME = "patient_site_name";
export const PATIENT_SEC_RELA = "patient_sec_rela";
export const PATIENT_GENDER = "patient_gender";
export const PATIENT_DOCTOR_ID = "patient_doctor_id";
export const PATIENT_LPN = "patient_lpn";
export const PATIENT_BIRTHDAY = "patient_birthday";
export const PATIENT_PHONE = "patient_phone";
export const PATIENT_CELL_PHONE = "patient_cell_phone";
export const PATIENT_HOME_PHONE = "patient_home_phone";
export const PATIENT_SEC_PHONE = "patient_sec_phone";
export const PATIENT_SEC_EMAIL = "patient_sec_email";
export const PATIENT_SEC_CONTACT = "patient_sec_contact";
export const RESET_ALL_PATIENT_INFO = "reset_all_patient_info";
export const CLINIC_SITES = "clinic_sites";
export const ALL_CLINIC_SITES = "all_clinic_sites";
export const LIST_STAFF = "list_staff";
export const PRESCRIBING_STAFF = "prescribing_staff";
export const APPOINTMENT_LIST = "appointment_list";
export const COMPLIANCE_LIST = "compliance_list";
export const FOOT_DATA = "foot_data";
export const RANGE_DATA = "range_data";
export const TRIAL_STAFF = "trial_staff";
export const PATIENT_WORN = "patient_worn";
export const LOADING_CHARTS = "loading_charts";
export const CALEN_DATE = "calendarDate";
export const EMPTY_FIRST_NAME = "empty_first_name";
export const EMPTY_LAST_NAME = "empty_last_name";
export const EMPTY_BIR = "empty_bir";
export const ERROR_BIR = "error_bir";
export const ERROR_MONTH_BIR = "error_month_bir";
export const ERROR_DAY_BIR = "error_day_bir";
export const EMPTY_PHONE = "empty_phone";
export const ERROR_PHONE = "error_phone";
export const ERROR_SEC_PHONE = "error_sec_phone";
export const EMPTY_SITE_ID = "empty_site_id";
export const EMPTY_DOCTOR_ID = "empty_doctor_id";
export const DELETE_PATIENTS = "del_patients";
export const FILTER_GROUP = "filter_group";
export const BILLING_TABLE_LIST = "billing_table_list";
export const BILLING_TABLE_LIST_99454 = "billing_table_list_99454";
export const BLI_SIRENID_REPORT = "bli_sirenid_report";
export const SEL_SIRENID_REPORT = "sel_sirenid_report";
export const RESET_ALL_SIRENID = "reset_all_sirenid";
export const SEARCH_PATIENTS_LIST = "search_patient_list";
export const CURMONTH_PATIENTS_99457 = "curmonth_patient_99457";
export const CURMONTH_PATIENTS_99454 = "curmonth_patient_99454";
export const CURMONTH_PATIENTS_99453 = "curmonth_patient_99453";
export const BILLING_99454_Billed_PATIENTS = "billing_99454_billed_patients";
export const BILLING_99454_Unbilled_PATIENTS =
  "billing_99454_unbilled_patients";
export const BILLING_99453_Billed_PATIENTS = "billing_99453_billed_patients";
export const BILLING_99453_Unbilled_PATIENTS =
  "billing_99453_unbilled_patients";
export const BILLING_99457_Billed_PATIENTS = "billing_99457_billed_patients";
export const BILLING_99457_Unbilled_PATIENTS =
  "billing_99457_unbilled_patients";
export const DIAGNOSIS_CODES = "diagnosis_codes";
export const MEDICAL_CODITIONS = "medical_conditions";
export const PATIENT_NOTES = "patient_notes";
export const DISPENSE_COMMENT = "dispense_comment";
export const DISPENSE_STYLE = "dispense_style";
export const SOCK_SIZE = "sock_size";
export const SOCK_COLOR = "sock_color";
export const SHIP_ADDRESS = "ship_address";
export const SHIP_CITY = "ship_city";
export const SHIP_STATE = "ship_state";
export const SHIP_ZIP = "ship_zip";
export const AUTH_SECONDARY = "auth_secondary";
export const REMOTE_MONITOR = "remote_monitor";
export const REMOTE_MONITOR_CHECK = "remote_monitor_check";
export const DISPENSE_CRITERIA = "dispense_criteria";
export const DISPENSE_CRITERIA_CHECK = "dispense_criteria_check";
export const SEL_RIGHT_TOE = "sel_right_toe";
export const SEL_RIGHT_MTS = "sel_right_mts";
export const SEL_RIGHT_ARCH = "sel_right_arch";
export const SEL_RIGHT_HEEL = "sel_right_heel";
export const SEL_LEFT_TOE = "sel_left_toe";
export const SEL_LEFT_MTS = "sel_left_mts";
export const SEL_LEFT_ARCH = "sel_left_arch";
export const SEL_LEFT_HEEL = "sel_left_heel";
export const DISPENSE_STYLE_CHECK = "dispense_style_check";
export const SOCK_COLOR_CHECK = "sock_color_check";
export const SOCK_SIZE_CHECK = "sock_size_check";
export const INITIALIZED_NOTE = "initialized_note";
export const INTAKE_PATIENT = "intake_patients";
export const NPM_SERVICE = "npm_service";
export const MODAL_STATE = "modal_state";
export const FILTER_NPM_SERVICE = "filter_npm_service";
export const SUMMARY_LIST = "summary_list";
export const SUMMARY_LIST_FILTER = "summary_list_filter";
export const GENERATE_99454_DELAY = "generate_99454_delay";
export const GENERATE_99457_DELAY = "generate_99457_delay";
export const SHOW_GENERATE_99454_DELAY = "show_generate_99454_delay";
export const SHOW_GENERATE_99457_DELAY = "show_generate_99457_delay";
export const COUNT_99453_UNBILLED = "count_99453_unbilled";
export const COUNT_99454_UNBILLED = "count_99454_unbilled";
export const COUNT_99457_UNBILLED = "count_99457_unbilled";
export const EMPTY_DISPENSED_METHOD = "empty_dispensed_method";
export const HAVE_PHONE = "have_phone";
export const HAVE_WIFI = "have_wifi";
export const BIR_DATE = "bir_date";
export const SOCK_HUB_TYPE = "sock_hub_type";
export const CONTACT_PHONE = "contact_phone";
export const CONTACT_EMAIL = "contact_email";
export const CONTACT_MESSAGE = "contact_message";
export const UPDATE_SERIAL_NUMBER = "update_serial_number";
export const UPDATE_HUB_ID = "update_hub_id";
export const REGISTER_BOX_TYPE = "register_box_type";
export const ALLERGIC_MATERIALS = "allergic_materials";
export const AGREE_UPLOAD_PHOTO = "agree_upload_photo";
export const UPDATE_DOCTORID = "update_doctorid";
export const CLINIC_IRB = "clinic_irb";
export const LIST_ADMIN_STAFF = "list_admin_staff";
export const USER_LOGIN_NAME = "user_login_name";
export const DOCTOR_PROFILE = "doctor_profile";
export const LIST_ICD_10_CODES = "list_ICD_10_codes";
export const LIST_CARE_PLAN = "list_care_plan";
export const LIST_MEDICAL_CONDITIONS = "list_medical_conditions";
//PCM
export const BILLING_PCM_Billed_PATIENTS = "billing_pcm_billed_patients";
export const BILLING_PCM_Unbilled_PATIENTS = "billing_pcm_unbilled_patients";
export const COUNT_PCM_UNBILLED = "count_pcm_unbilled";
export const SHOW_GENERATE_PCM_DELAY = "show_generate_pcm_delay";
export const GENERATE_PCM_DELAY = "generate_pcm_delay";
export const CURMONTH_PATIENTS_PCM = "curmonth_patient_pcm";
