import { Checkbox, Select, Tabs } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Button, Icon, Modal } from "semantic-ui-react";
import { changeNewPatient } from "../../action/addAction";
import { getGroups } from "../../action/patientAction";
import { getPrescribingStaff } from "../../action/registerAction";
import { phoneWithCountryFormat } from "../../tools/phoneFormat";
import ButtonSuccess from "../home/buttonSuccess";
import "./editpatient.css";
import PatientMedicalHistory from "../../report/patientMedicalHistory";

const { TabPane } = Tabs;

class PatientIntakeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthday: "",
      open: false,
      siteId: "",
      firstName: "",
      lastName: "",
      email: "",
      contactName: "",
      secondaryEmail: "",
      contactPhone: "",
      relation: "",
      confirmSuccess: false,
      patientNote: "",
      medicalCondition: [],
      footSpecific: [],
      footAmputation: [],
      diagnosisCodesStr: [],
      birYear: "",
      birMonth: "",
      birDay: "",
      phone: "",
      homePhone: "",
      patientId: "",
      doctorId: "",
      authSecondary: false,
      dispenseType: "",
      dispenseAddress: "",
      dispenseCity: "",
      dispenseState: "",
      dispenseZip: "",
      dispenseSize: "",
      dispenseColor: "",
      dispenseComment: "",
      viewAll: true
    };
  }

  handleOpen = () => {
    if (_.isEmpty(this.props.userInfo)) {
      return;
    }
    // const { getPrescribingStaff } = this.props;
    // getPrescribingStaff(this.props.userInfo.siteId);
    this.setState({
      open: true,
      confirmSuccess: false,
      siteId:
        this.props.userInfo && this.props.userInfo.siteId
          ? this.props.userInfo.siteId
          : "",
      lastName:
        this.props.userInfo && this.props.userInfo.lastName
          ? this.props.userInfo.lastName
          : "",
      firstName:
        this.props.userInfo && this.props.userInfo.firstName
          ? this.props.userInfo.firstName
          : "",
      email:
        this.props.userInfo && this.props.userInfo.email
          ? this.props.userInfo.email
          : "",
      contactName:
        this.props.userInfo && this.props.userInfo.contactName
          ? this.props.userInfo.contactName
          : "",
      secondaryEmail:
        this.props.userInfo && this.props.userInfo.secondaryEmail
          ? this.props.userInfo.secondaryEmail
          : "",
      contactPhone:
        this.props.userInfo && this.props.userInfo.contactPhone
          ? phoneWithCountryFormat(this.props.userInfo.contactPhone)
          : "",
      relation:
        this.props.userInfo && this.props.userInfo.relation
          ? this.props.userInfo.relation
          : "",
      patientNote:
        this.props.userInfo && this.props.userInfo.patientNotes
          ? this.props.userInfo.patientNotes
          : "",
      medicalCondition:
        this.props.userInfo && this.props.userInfo.medicalCondition
          ? this.props.userInfo.medicalCondition.split(",")
          : [],
      footSpecific:
        this.props.userInfo && this.props.userInfo.footSpecific
          ? this.props.userInfo.footSpecific.split(",")
          : [],
      footAmputation:
        this.props.userInfo && this.props.userInfo.footAmputation
          ? this.props.userInfo.footAmputation.split(",")
          : [],
      diagnosisCodesStr:
        this.props.userInfo && this.props.userInfo.diagnosisCodes
          ? this.props.userInfo.diagnosisCodes.split("_")
          : "",
      birYear:
        this.props.userInfo &&
        this.props.userInfo.birthday &&
        this.props.userInfo.birthday.split("-")[0],
      birMonth:
        this.props.userInfo &&
        this.props.userInfo.birthday &&
        this.props.userInfo.birthday.split("-")[1],
      birDay:
        this.props.userInfo &&
        this.props.userInfo.birthday &&
        this.props.userInfo.birthday.split("-")[2],
      doctorId:
        this.props.userInfo && this.props.userInfo.doctorId
          ? this.props.userInfo.doctorId
          : "",
      patientId:
        this.props.userInfo && this.props.userInfo.patientId
          ? this.props.userInfo.patientId
          : "",
      phone:
        this.props.userInfo && this.props.userInfo.phone
          ? phoneWithCountryFormat(this.props.userInfo.phone)
          : "",
      homePhone:
        this.props.userInfo && this.props.userInfo.homePhone
          ? phoneWithCountryFormat(this.props.userInfo.homePhone)
          : "",
      authSecondary:
        this.props.userInfo && this.props.userInfo.authSecondary
          ? this.props.userInfo.authSecondary
          : "",
      dispenseType:
        this.props.userInfo && this.props.userInfo.dispenseType
          ? this.props.userInfo.dispenseType
          : "",
      dispenseAddress:
        this.props.userInfo && this.props.userInfo.dispenseAddress
          ? this.props.userInfo.dispenseAddress
          : "",
      dispenseCity:
        this.props.userInfo && this.props.userInfo.dispenseCity
          ? this.props.userInfo.dispenseCity
          : "",
      dispenseState:
        this.props.userInfo && this.props.userInfo.dispenseState
          ? this.props.userInfo.dispenseState
          : "",
      dispenseZip:
        this.props.userInfo && this.props.userInfo.dispenseZip
          ? this.props.userInfo.dispenseZip
          : "",
      dispenseSize:
        this.props.userInfo && this.props.userInfo.dispenseSize
          ? this.props.userInfo.dispenseSize
          : "",
      dispenseColor:
        this.props.userInfo && this.props.userInfo.dispenseColor
          ? this.props.userInfo.dispenseColor
          : "",
      dispenseComment:
        this.props.userInfo && this.props.userInfo.dispenseComments
          ? this.props.userInfo.dispenseComments
          : ""
    });
  };

  close = () =>
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      confirmSuccess: false
    });

  showTitle() {
    // if there is a title, show 'title'. if no title, show 'note'
    let text = this.props.title;
    if (!text) {
      text = this.props.note;
    }
    if (!text) {
      text = "";
    }

    return text;
  }

  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <div className="note_title_table" onClick={this.handleOpen}>
            {this.showTitle()}
          </div>
        }
        open={open}
      >
        <Modal.Header>Patient Intake Details</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Tabs
              style={{ height: 600, overflowY: "auto" }}
              defaultActiveKey={"1"}
            >
              <TabPane tab="Enroll" key="1">
                <div className="newSite_box">
                  <div className="Patient_group">
                    <div className="inputTotal step3InputTotal">
                      <p
                        className="step3_section_title"
                        style={{ textAlign: "left", fontSize: 20 }}
                      >
                        Enrollment Criteria
                      </p>
                      <p
                        className="step3_section_title"
                        style={{ textAlign: "left", marginTop: 30 }}
                      >
                        Confirm that patient meets ALL of the following
                        requirements:
                      </p>
                      <Checkbox checked={true}>
                        Patient agrees to upload photos for their doctor to
                        review
                      </Checkbox>
                      <div className="chekboxBottom"></div>
                      <Checkbox checked={true}>
                        Patient is not allergic to the following materials:
                      </Checkbox>
                      <div
                        style={{ marginTop: 15, fontSize: 14, marginLeft: 40 }}
                      >
                        ●&nbsp;&nbsp;Polyester
                      </div>
                      <div
                        style={{ marginTop: 15, fontSize: 14, marginLeft: 40 }}
                      >
                        ●&nbsp;&nbsp;Elastane (Spandex)
                      </div>
                      <div
                        style={{ marginTop: 15, fontSize: 14, marginLeft: 40 }}
                      >
                        ●&nbsp;&nbsp;Elastodiene (Elastic fiber)
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Medical" key="2">
                <div className="Patient_group">
                  <div style={{ margin: '0 40px' }}>
                    <PatientMedicalHistory
                      readOnly
                      medicalCondition={this.state.medicalCondition}
                      footSpecific={this.state.footSpecific}
                      footAmputation={this.state.footAmputation}
                    />
                  </div>
                  <div style={{ margin: '30px 40px' }}>
                    <span className="patient_note_label">ICD-10 Codes</span>
                    <Select
                      mode="tags"
                      style={{ marginTop: 10, width: '100%' }}
                      placeholder="Click to add ICD-10 codes"
                      optionLabelProp="value"
                      value={
                        this.state.diagnosisCodesStr.length === 0
                          ? undefined
                          : this.state.diagnosisCodesStr
                      }
                      disabled
                    >
                      {this.props.ICD10Codes.map(code => (
                        <Option value={code} key={code}>
                          {code}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.close()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  prescribingStaff: state.patientsListStore.prescribingStaff,
  filterGroup: state.patientsListStore.filterGroup,
  ICD10Codes: state.authGroupStore.ICD10Codes.map(item => item.code)
});
const mapDispatchToProp = dispatch => ({
  changeNewPatient: (editInput, userInfo, self) =>
    dispatch(changeNewPatient(editInput, userInfo, self)),
  getGroups: () => dispatch(getGroups()),
  getPrescribingStaff: staffSiteId => dispatch(getPrescribingStaff(staffSiteId))
});
export default connect(mapStateToProp, mapDispatchToProp)(PatientIntakeDetails);
