import moment from "moment";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { API, graphqlOperation } from "aws-amplify";

/**
 *  Created by shengli.zhang on 2020-06-15 14:13
 */

export function getSockStatus(id, userid, self) {
  return async dispatch => {
    try {
      self.setState({ open: true });
      const patient = await API.graphql(
        graphqlOperation(_api.getSock(), { id, userid })
      );
      if (patient) {
        self.setState({
          open: false,
          sirenId: patient.data.getPatient.sirenId
        });
        patient.data.getPatient.socks = patient.data.getPatient.socks.sort(
          compareRegistered
        );
        if (
          patient.data.getPatient.hasOwnProperty("appInfo") &&
          patient.data.getPatient.appInfo.length > 0
        ) {
          patient.data.getPatient.appInfo = patient.data.getPatient.appInfo.sort(
            function(a, b) {
              return new moment(b.createdAt) - new moment(a.createdAt);
            }
          );
          dispatch(changeSockStatus(patient.data.getPatient));
        } else {
          dispatch(changeSockStatus(patient.data.getPatient));
        }
      }
    } catch (err) {
      self.setState({ open: false });
      console.log("getSockStatus error: ", err);
    }
  };
}

export function clearSockStatus() {
  return dispatch => {
    dispatch(changeSockStatus({}));
  };
}

function changeSockStatus(sockStatus) {
  return {
    type: TYPES.SOCK_STATUS,
    text: sockStatus
  };
}

function compareRegistered(a, b) {
  const akey = a.registered + a.boxId;
  const bkey = b.registered + b.boxId;
  if (akey > bkey) return -1;
  if (akey > bkey) return 1;
  return 0;
}
