import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Select, Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const DOWN = require("../img/down_blue.png");
const UP = require("../img/up_blue.png");
const { Option } = Select;

class PatientIcdCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      diagnosisCodes: [],
      viewAll: false
    };
  }

  componentDidMount() {
    const diagnosisCodes = this.props.doctorDetails.diagnosisCodes
      ? this.props.doctorDetails.diagnosisCodes.split("_")
      : [];
    this.setState({
      diagnosisCodes: this.props.canEdit
        ? diagnosisCodes
        : this.props.diagnosisCodes
    });
  }

  handleCheckDiagnosisCode = value => {
    const filterValue = value.map(v => v.trim()).filter(v => v.length);
    this.setState({ diagnosisCodes: filterValue });
    this.props.saveDiagnosisCodes(filterValue);
  };

  render() {
    const disableCheck = !this.props.canEdit;
    return (
      <div>
        <div style={{ display: "flex", alignItems: "baseline", marginTop: 20 }}>
          <div className="patient_note_label" style={{ fontSize: 18 }}>
            ICD-10 Codes
            <Tooltip title="To add a new code to the list, press enter after typing the code into the search bar.">
							<InfoCircleFilled
								style={{
									color: "#D3D6E1",
									marginLeft: 5
								}}
							/>
						</Tooltip>
          </div>{" "}
          <Button
            type="link"
            size="small"
            onClick={() => {
              this.setState({ viewAll: !this.state.viewAll });
            }}
          >
            View all
            <img
              src={this.state.viewAll ? UP : DOWN}
              style={{ width: 12, height: 16, marginLeft: 5 }}
            />
          </Button>
        </div>
        <div style={{ fontSize: 14, marginTop: 10, display: this.state.viewAll ? 'block' : 'none' }}>
          <Select
            mode="tags"
            style={{ width: "100%" }}
            tokenSeparators={['_', ',']}
            onChange={this.handleCheckDiagnosisCode}
            optionLabelProp="value"
            value={
              this.state.diagnosisCodes.length === 0
                ? undefined
                : this.state.diagnosisCodes
            }
            disabled={disableCheck}
          >
            {this.props.ICD10Codes.map(code => (
              <Option value={code} key={code}>
                {code}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }
}

const mapStateToProp = state => ({
  ICD10Codes: state.authGroupStore.ICD10Codes.map(item => item.code),
  doctorDetails: state.patientsListStore.doctorDetails
});
export default connect(mapStateToProp, null)(PatientIcdCode);
