import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, Select, Tabs } from "antd";
import { Auth } from "aws-amplify";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Input, Modal } from "semantic-ui-react";
import { changeTempUnit } from "../../action/chartAction";
import {
  getAllListClinicSites,
  getlistStaffSetting
} from "../../action/registerAction";
import { updateStaffProfile } from "../../action/staffProfileAction";
import "../../registerSteps/step.css";
import Emitter from "../../tools/Emitter";
import modalInfo from "../../tools/module-info";
/**
 *  Created by shengli.zhang on 12/18/20 3:55 PM
 */
const { TabPane } = Tabs;

class ProfileSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fullName: "",
      email: "",
      notificationEmail: "",
      weeklyNotificationStatus: "OFF",
      weeklyReportSend: false,
      userSiteId: [],
      inputValue: "",
      showCheckGroup: false,
      allSiteIdSelect: [],
      indeterminate: false,
      checkAll: false,
      selectValues: [],
      selectValuesBK: []
    };
    this.onSelectChange = this.onSelectChange.bind(this);
    this.changeWeeklyNotificationStatus = this.changeWeeklyNotificationStatus.bind(
      this
    );
    this.changeTemperatureUnit = this.changeTemperatureUnit.bind(this);
  }

  async logout() {
    try {
      Emitter.emit("changeNewPassword", "change password");
      await Auth.signOut();
      // window.location.reload();
    } catch (e) {
      alert("SignOut error: " + e);
    }
  }

  handleInputChange(data) {
    this.setState({ inputValue: data });
  }

  handleOpen = () => {
    const { getlistClinicSites } = this.props;
    this.setState({ open: true });
    this.getSiteId();
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
  };

  getSiteId = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let email = user.attributes.email;
      let allSiteId = this.props.siteId;
      let allSiteIdSelect = _.clone(allSiteId);
      this.setState({
        userSiteId: allSiteId,
        allSiteIdSelect: allSiteIdSelect
      });
      // }
      if (this.props.listStaff.length > 0) {
        let staffInfo = _.filter(this.props.listStaff, { email });
        if (staffInfo.length > 0) {
          this.setState({
            fullName: `${staffInfo[0].firstName} ${staffInfo[0].lastName}`,
            email: staffInfo[0].email,
            notificationEmail: staffInfo[0].notificationEmail,
            weeklyNotificationStatus: staffInfo[0].weeklyNotificationStatus
              ? staffInfo[0].weeklyNotificationStatus
              : staffInfo[0].autoReportSites &&
                JSON.parse(staffInfo[0].autoReportSites).length > 0
              ? JSON.parse(staffInfo[0].autoReportSites).length ===
                allSiteIdSelect.length
                ? "ON"
                : "CUSTOM"
              : "OFF",
            selectValues: staffInfo[0].autoReportSites
              ? JSON.parse(staffInfo[0].autoReportSites)
              : [],
            weeklyReportSend: !!(
              staffInfo[0].weeklyReportSend &&
              staffInfo[0].weeklyReportSend === "Yes"
            )
          });
        }
      } else {
        const { getlistStaffSetting } = this.props;
        getlistStaffSetting(email, this);
      }
    } catch (e) {
      console.log("App.js get user siteId error: ", e);
    }
  };

  close = () => {
    this.setState({ open: false });
  };

  renderValueItem({ value, key }) {
    return (
      <div
        key={key}
        style={{
          display: "inline-block",
          marginLeft: "5px"
        }}
        className="selectBox"
      >
        <span className="selectValue">{value}</span>{" "}
        <CloseOutlined
          className="CloseOutlined"
          onClick={() => this.deleteSelectSiteId(key)}
        />
      </div>
    );
  }

  changeTemperatureUnit = async event => {
    const { changeTempUnit } = this.props;
    changeTempUnit(event);
    let user = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(user, {
      "custom:temp_unit": event
    })
      .then(data => {
        console.log("updateUserAttributes: ", data);
      })
      .catch(error => {
        console.log("updateUserAttributes error: ", error);
      });
  };

  changeWeeklyNotificationStatus = event => {
    if (event === "CUSTOM") {
      this.setState({
        weeklyNotificationStatus: event,
        selectValues: this.state.selectValuesBK,
        selectValuesBK: [],
        weeklyReportSend: false
      });
    } else if (event === "ON") {
      this.setState({
        weeklyNotificationStatus: event,
        selectValuesBK:
          this.state.selectValues.length > 0
            ? this.state.selectValues
            : this.state.selectValuesBK,
        selectValues: [],
        weeklyReportSend: true
      });
    } else {
      this.setState({
        weeklyNotificationStatus: event,
        selectValuesBK:
          this.state.selectValues.length > 0
            ? this.state.selectValues
            : this.state.selectValuesBK,
        selectValues: []
      });
    }
  };

  onCheckWeeklyReportChange = event => {
    this.setState({ weeklyReportSend: event.target.checked });
  };

  onSelectChange(value) {
    this.setState({
      selectValues: value,
      indeterminate:
        !!value.length && value.length < this.state.allSiteIdSelect.length,
      checkAll: value.length === this.state.allSiteIdSelect.length
    });
  }
  deleteSelectSiteId(key) {
    const newSelectValues = _.clone(this.state.selectValues);
    newSelectValues.splice(key, 1);
    this.setState({
      selectValues: newSelectValues,
      inputValue: "",
      indeterminate:
        !!newSelectValues.length &&
        newSelectValues.length < this.state.allSiteIdSelect.length,
      checkAll: newSelectValues.length === this.state.allSiteIdSelect.length
    });
  }

  onNotificationEmailChange = event => {
    this.setState({ notificationEmail: event.target.value });
  };

  onCheckAllChange = e => {
    this.setState({
      inputValue: "",
      indeterminate: false,
      checkAll: e.target.checked
    });
    if (e.target.checked) {
      this.setState({
        siteId: this.state.userSiteId,
        selectValues: this.state.userSiteId
      });
    } else {
      this.setState({ siteId: [], selectValues: [] });
    }
  };

  render() {
    const { open } = this.state;
    const { Option } = Select;
    return (
      <Modal
        trigger={<p onClick={this.handleOpen}>Profile Settings</p>}
        open={open}
      >
        <Modal.Header>
          Profile Settings{" "}
          <CloseOutlined
            style={{ float: "right" }}
            onClick={e => this.close()}
          />
        </Modal.Header>
        <Modal.Content>
          <Tabs style={{ height: 600, overflowY: "auto" }}>
            <TabPane tab="Profile" key="1">
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 15,
                  marginTop: 10,
                  fontWeight: "bold"
                }}
              >
                Full Name
              </div>
              <Input
                style={{
                  marginLeft: 30,
                  marginTop: 5,
                  fontSize: 15,
                  width: 300
                }}
                value={this.state.fullName}
                disabled
              />
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 15,
                  marginTop: 20,
                  fontWeight: "bold"
                }}
              >
                Email
              </div>
              <Input
                style={{
                  marginLeft: 30,
                  marginTop: 5,
                  fontSize: 15,
                  width: 300
                }}
                value={this.state.email}
                disabled
              />
              <div style={{ marginLeft: 30, marginTop: 20 }}>
                <span
                  className="spanonclick"
                  onClick={this.logout.bind(this)}
                  style={{ color: "#0f8cff" }}
                >
                  Change password
                </span>
              </div>
            </TabPane>
            <TabPane tab="Notifications" key="2">
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 24,
                  marginTop: 10,
                  fontWeight: "bold"
                }}
              >
                Email Notifications
              </div>
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 15,
                  marginTop: 20,
                  fontWeight: "bold"
                }}
              >
                Preferred Email
              </div>
              <Input
                style={{
                  marginLeft: 30,
                  marginTop: 5,
                  fontSize: 15,
                  width: 300
                }}
                value={this.state.notificationEmail}
                onChange={this.onNotificationEmailChange}
              />
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 15,
                  marginTop: 20,
                  fontWeight: "bold"
                }}
              >
                Settings
              </div>
              <Select
                className="selectStyle"
                style={{ marginLeft: 30, marginTop: 5 }}
                onChange={this.changeWeeklyNotificationStatus}
                value={this.state.weeklyNotificationStatus}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                optionFilterProp="children"
              >
                <Option value={"ON"} key={"si" + 1}>
                  Notifications on
                </Option>
                <Option value={"CUSTOM"} key={"si" + 1}>
                  Custom Notifications
                </Option>
                <Option value={"OFF"} key={"si" + 2}>
                  Notifications off
                </Option>
              </Select>
              {this.state.weeklyNotificationStatus === "CUSTOM" ? (
                <div>
                  <div
                    style={{
                      marginLeft: 30,
                      fontSize: 15,
                      marginTop: 20
                    }}
                  >
                    Enter site IDs or names to received notifications:
                  </div>
                  <div
                    className="selectContainer"
                    style={{
                      marginLeft: 30,
                      marginTop: 5,
                      width: "96%",
                      height: "auto",
                      paddingBottom: 3
                    }}
                    onClick={() => {
                      this.setState({ showCheckGroup: true });
                    }}
                  >
                    <div className="selectValuesBox" style={{ width: "100%" }}>
                      {this.state.selectValues.length > 0 ? (
                        this.state.selectValues.map((value, key) => {
                          return this.renderValueItem({ value, key });
                        })
                      ) : (
                        <div className="blanck_SelectBox"></div>
                      )}
                    </div>
                    {this.state.showCheckGroup && (
                      <div
                        className="CheckboxGroupBox"
                        style={{
                          position: "absolute",
                          border: "1px solid #d9d9d9",
                          top: "100%",
                          borderRadius: 4,
                          width: 600
                        }}
                        onMouseLeave={e => {
                          this.setState({
                            showCheckGroup: false,
                            inputValue: ""
                          });
                        }}
                      >
                        <Input
                          className="selectInputSearch"
                          placeholder="Type to Search"
                          onChange={(e, data) => {
                            this.handleInputChange(e.target.value);
                          }}
                        />
                        <br />
                        <Checkbox
                          indeterminate={this.state.indeterminate}
                          onChange={this.onCheckAllChange}
                          checked={this.state.checkAll}
                          style={{ marginLeft: 8 }}
                        >
                          All Sites
                        </Checkbox>
                        <br />
                        <Checkbox.Group
                          className="CheckboxGroup"
                          onChange={this.onSelectChange}
                          value={this.state.selectValues}
                        >
                          {this.state.allSiteIdSelect &&
                            this.state.allSiteIdSelect.map((group, i) => {
                              const siteInfo = this.props.clinicSites.filter(
                                t => t.siteId === group
                              );
                              let siteName = "";
                              if (siteInfo.length > 0) {
                                siteName = ` - ${siteInfo[0].name}`;
                              }
                              const groupValue = `${group}${siteName}`;
                              return (
                                <Checkbox
                                  className="Checkbox"
                                  value={group}
                                  key={"select" + i}
                                  style={{
                                    display: _.isEmpty(this.state.inputValue)
                                      ? "block"
                                      : groupValue
                                          .toLowerCase()
                                          .indexOf(
                                            this.state.inputValue.toLowerCase()
                                          ) === -1
                                      ? "none"
                                      : "block"
                                  }}
                                >
                                  {groupValue}
                                </Checkbox>
                              );
                            })}
                        </Checkbox.Group>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {this.state.weeklyNotificationStatus !== "OFF" ? (
                <div>
                  <div
                    style={{
                      marginLeft: 30,
                      fontSize: 14,
                      marginTop: 20,
                      fontWeight: "bold"
                    }}
                  >
                    Send me email notification for:
                  </div>
                  <div
                    style={{ display: "flex", marginLeft: 30, marginTop: 10 }}
                  >
                    <Checkbox
                      onChange={this.onCheckWeeklyReportChange}
                      checked={this.state.weeklyReportSend}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: "bold"
                        }}
                      >
                        Weekly Patient Updates
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: 5
                        }}
                      >
                        Overview of patients in compliance or medical review.
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </TabPane>
            <TabPane tab="Sites" key="3">
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 14,
                  marginTop: 10
                }}
              >
                Contact your clinic's Siren administrator to update your site
                access.
              </div>
              <div style={{ display: "flex", marginTop: 10 }}>
                <div
                  style={{
                    marginLeft: 30,
                    fontSize: 15,
                    marginTop: 10,
                    fontWeight: "bold",
                    width: 200
                  }}
                >
                  Site ID
                </div>
                <div
                  style={{
                    fontSize: 15,
                    marginTop: 10,
                    fontWeight: "bold"
                  }}
                >
                  Site Name
                </div>
              </div>
              <div
                style={{
                  marginLeft: 30,
                  width: "96%",
                  backgroundColor: "#e8e8fe",
                  marginTop: 10,
                  height: 1
                }}
              />
              {this.props.clinicSites.length > 0 &&
                this.state.userSiteId.map((p, i) => {
                  const siteInfo = _(this.props.clinicSites).find({
                    siteId: p
                  });
                  return (
                    <div style={{ display: "flex" }} key={`site${i}`}>
                      <div
                        style={{
                          marginLeft: 30,
                          fontSize: 15,
                          marginTop: 10,
                          width: 200
                        }}
                      >
                        {siteInfo.siteId}
                      </div>
                      <div
                        style={{
                          // marginLeft: 100,
                          fontSize: 15,
                          marginTop: 10
                        }}
                      >
                        {siteInfo.name}
                      </div>
                    </div>
                  );
                })}
            </TabPane>
            <TabPane tab="Temperature Units" key="4">
              <div
                style={{
                  marginLeft: 30,
                  fontSize: 20,
                  marginTop: 10,
                  fontWeight: "bold"
                }}
              >
                Temperature
              </div>
              <Select
                className="selectStyle"
                style={{ marginLeft: 30, marginTop: 5 }}
                onChange={this.changeTemperatureUnit}
                value={this.props.tempUnit}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                optionFilterProp="children"
              >
                <Option value={"Fahrenheit"} key={"si" + 0}>
                  Fahrenheit
                </Option>
                <Option value={"Celsius"} key={"si" + 1}>
                  Celsius
                </Option>
              </Select>
            </TabPane>
          </Tabs>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              let input = {
                email: this.state.email,
                weeklyNotificationStatus: this.state.weeklyNotificationStatus
              };
              if (this.state.notificationEmail) {
                Object.assign(input, {
                  notificationEmail: this.state.notificationEmail
                });
              }
              if (this.state.weeklyNotificationStatus === "ON") {
                Object.assign(input, {
                  autoReportSites: JSON.stringify(this.state.allSiteIdSelect)
                });
              } else if (this.state.weeklyNotificationStatus === "CUSTOM") {
                if (this.state.selectValues.length > 0) {
                  Object.assign(input, {
                    autoReportSites: JSON.stringify(this.state.selectValues)
                  });
                } else {
                  modalInfo.warning(
                    "Incomplete Fields",
                    "Add sites to enable notifications or adjust your Settings selection."
                  );
                  return;
                }
              } else {
                Object.assign(input, {
                  autoReportSites: JSON.stringify([])
                });
              }
              Object.assign(input, {
                weeklyReportSend:
                  this.state.weeklyNotificationStatus === "OFF"
                    ? "No"
                    : this.state.weeklyReportSend
                    ? "Yes"
                    : "No"
              });

              this.props.updateStaffProfile(input, this);
            }}
            primary
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  siteId: state.authGroupStore.profile.siteId,
  clinicSites: state.patientsListStore.allClinicSites,
  listStaff: state.patientsListStore.listStaff,
  tempUnit: state.patientsListStore.tempUnit
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getAllListClinicSites()),
  getlistStaffSetting: (email, self) =>
    dispatch(getlistStaffSetting(email, self)),
  changeTempUnit: tempUnit => dispatch(changeTempUnit(tempUnit)),
  updateStaffProfile: (input, self) => dispatch(updateStaffProfile(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(ProfileSetting);
