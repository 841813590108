import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
// import createHistory from "history";
import { createStore, combineReducers, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import reducers from "./reducers/reducers";
import { CookiesProvider } from "react-cookie";
import { HashRouter } from "react-keeper";
// import createHistory from "history/createBrowserHistory";

let createHistory = require("history").createBrowserHistory;
// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

// const debugSwitch = true;
// if (!debugSwitch) {
//   console.log = () => {};
// }

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
  combineReducers({
    ...reducers,
    router: routerReducer
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk, middleware)
);

const systemDebugSwitch = false;
//env: development、staging、production
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
} else {
  if (systemDebugSwitch) {
    window.LOG_LEVEL = "DEBUG";
  }
}

document.addEventListener("wheel", function(event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      {/* ConnectedRouter will use the store from Provider automatically */}
      <HashRouter>
        <div id="root-div">
          {/* <HashRouter exact path="/" component={App} /> */}
          {/* <Route exact path="/" component={App} /> */}
          <App />
        </div>
      </HashRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById("root")
);

// ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
