import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Form, Icon, Modal, TextArea } from "semantic-ui-react";
import { changeDeviceStatus } from "../../action/addAction";
import "../../share/button.css";
import "./AccountStatus.css";
import ButtonSuccess from "./buttonSuccess";
import { Select, Tag } from "antd";
import { ACCOUNT_PAUSED_REASON } from "../../tools/patient-medical";
import _ from "lodash";

const OnService = "ON_SERVICE";
const COLOR = {
  PENDING: "#F1BE0A",
  Pending: "#F1BE0A",
  ON_SERVICE: "#73D1AE",
  NOT_ON_SERVICE: "#6F7381",
  ACTIVE: "#73D1AE",
  IDLE: "#AEC3BB",
  PAUSED: "#F1BE0A"
};

const DEVICE_STATUS = [
  { name: "Active", value: "ACTIVE" },
  { name: "Idle", value: "IDLE" },
  { name: "Paused", value: "PAUSED" },
  { name: "Canceled", value: "NOT_ON_SERVICE" }
];

const value2label = {
  ACTIVE: "Active",
  IDLE: "Idle",
  PAUSED: "Paused",
  NOT_ON_SERVICE: "Canceled"
};

class AccountStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      confirmSuccess: false,
      note: "",
      pausedReason: "",
      deviceStatus: ""
    };
    this.handleConfirm.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  show = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      deviceStatus: this.props.deviceStatus
    });
  };
  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ confirmSuccess: true });
    const { changeDeviceStatus } = this.props;
    const id = this.props.id;
    const newStatus = {
      program: "RPM",
      id: id,
      userId: this.props.userId,
      status: this.state.deviceStatus,
      author: `${user.attributes.given_name} ${user.attributes.family_name}`,
      note:
        (!_.isEmpty(this.state.pausedReason)
          ? this.state.pausedReason + ", "
          : "") + this.state.note,
      title: `Siren Socks Status: ${value2label[this.state.deviceStatus]}`
    };
    changeDeviceStatus(newStatus, this.props.userInfo, this);
  };
  handleCancel = () =>
    this.setState({
      open: false,
      confirmSuccess: false,
      note: "",
      pausedReason: "",
      deviceStatus: this.props.deviceStatus
    });

  handleInputChange(value) {
    this.setState({ note: value });
  }
  handleReasonChange = value => {
    this.setState({ pausedReason: value });
  };
  handleDeviceStatus = value => {
    this.setState({ deviceStatus: value });
  };
  render() {
    const { open } = this.state;
    const { Option } = Select;
    return (
      <Modal
        trigger={
          <Tag
            color={COLOR[this.props.deviceStatus]}
            key={"RPM"}
            style={{ cursor: "pointer" }}
          >
            {"Siren Socks"}
          </Tag>
        }
        onClose={this.handleCancel}
        onOpen={this.show}
        open={open}
        className="add-box-form-container"
        style={{ cursor: "default" }}
      >
        <Modal.Header>Update RPM Device Status</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <div>
                    <label style={{ fontWeight: "bold", color: "#000" }}>
                      Device Status
                    </label>
                    <Select
                      value={this.state.deviceStatus}
                      onChange={this.handleDeviceStatus}
                      style={{ width: "100%", marginTop: 5 }}
                      placeholder="Please Select"
                      showSearch
                      optionFilterProp="children"
                    >
                      {DEVICE_STATUS.map((p, index) => {
                        return (
                          <Option value={p.value} key={"reason" + index}>
                            {p.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  {this.state.deviceStatus === "PAUSED" &&
                    this.props.userInfo.rpmProgramStatus === OnService && (
                      <div>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            marginTop: 10
                          }}
                        >
                          Reason
                        </label>
                        <Select
                          value={this.state.pausedReason}
                          onChange={this.handleReasonChange}
                          style={{ width: "100%", marginTop: 5 }}
                          placeholder="Please Select"
                          showSearch
                          optionFilterProp="children"
                        >
                          <Option value={""} />
                          {ACCOUNT_PAUSED_REASON.map((p, index) => {
                            return (
                              <Option value={p.value} key={"reason" + index}>
                                {p.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    )}
                  <label
                    style={{
                      marginBottom: 5,
                      marginTop: 10
                    }}
                  >
                    Comment
                  </label>
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleInputChange(e.target.value);
                    }}
                    placeholder="Reason for change to patient's RPM Device Status"
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  doctorRoleList: state.patientsListStore.doctorRoleList
});
const mapDispatchToProp = dispatch => ({
  changeDeviceStatus: (newStatus, userInfo, self) =>
    dispatch(changeDeviceStatus(newStatus, userInfo, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(AccountStatus);
