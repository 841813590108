import { Select, Checkbox, Modal as AntdModal, Popover, Divider } from "antd";
import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import _, { filter, isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import "react-dropdown/style.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import qs from "qs";
import { Button, Icon } from "semantic-ui-react";
import { listAdminUsers } from "../action/registerAction";
import { listRpmTracker, updateNpmList } from "../action/ServiceAction";
import aws_exports from "../aws-exports-siren-medical";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import "../share/common.css";
import Add99457Service from "./Add99457Service";
import "./biMonthly.css";
import DeleteRpmTracker from "./deleteRpmTracker";
import Edit99457Service from "./Edit99457Service";
import Show99457Service from "./Show99457Service";
import "./Tracker99457.css";
import Addendum99457Service from "./Addendum99457Service";
import getPatchTrProps from "../tools/reactTablePatch";

Modal.setAppElement("div");
const UNCHECKED = require("../img/unchecked.svg");
const CHECKED = require("../img/checked.svg");
const INDETERMINATE = require("../img/indeterminate.svg");
const defaultCheckedList = ["CCM", "PCM", "RPM", "RTM"];
const { Option } = Select;
class Tracker99457 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rpmYear: new Date().getFullYear(),
      rpmMonth: new Date().getMonth() + 1,
      startDate: moment().format("YYYY-MM"),
      programList: defaultCheckedList,
      selectRpmList: []
    };
    this.edit99457 = false;
    this.tableInstance = null;
    this.hasReqRpmNoteTmpls = false;
  }

  componentDidMount() {
    this.searchServices(this.state.rpmYear, this.state.rpmMonth);
    this.refreshEventHandler = () => {
      this.props.updateNpmList(
        this.props.npmService,
        this.state.programList,
        this.state.rpmYear +
          "-" +
          (this.state.rpmMonth < 10
            ? "0" + this.state.rpmMonth
            : this.state.rpmMonth)
      );
    };
    document.addEventListener("refreshRpmList", this.refreshEventHandler);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.edit99457;
  }

  handleProgramChange = programList => {
    if (programList.includes("all")) {
      if (programList.length === defaultCheckedList.length + 1) {
        programList = [];
      } else {
        programList = defaultCheckedList;
      }
    }
    this.setState({
      programList
    });
    this.props.updateNpmList(
      this.props.npmService,
      programList,
      this.state.rpmYear +
        "-" +
        (this.state.rpmMonth < 10
          ? "0" + this.state.rpmMonth
          : this.state.rpmMonth)
    );
  };

  handleMonthChange = value => {
    this.setState({
      rpmMonth: parseInt(value),
      startDate: `${this.state.rpmYear}-${value < 10 ? "0" : ""}${value}`
    });
    this.searchServices(this.state.rpmYear, parseInt(value));
  };

  handleYearChange = value => {
    this.setState({
      rpmYear: value,
      startDate: `${value}-${this.state.rpmMonth}`
    });
    this.searchServices(value, this.state.rpmMonth);
  };

  searchServices = (rpmYear, rpmMonth) => {
    const { listRpmTracker } = this.props;
    const start =
      rpmYear + "-" + (rpmMonth < 10 ? "0" + rpmMonth : rpmMonth) + "-01";
    let startDate = moment(start).format("YYYY-MM-DD");
    let endDate = moment(start)
      .add(1, "month")
      .format("YYYY-MM-DD");
    listRpmTracker(this.props.patientDetails.userId, startDate, endDate);
  };

  checkProgramNeedDownloadOrNot = program => {
    let checkNpmService = [];
    const npm = this.state.selectRpmList.filter(item =>
      item.programNotes.find(p => p.program === program)
    );
    checkNpmService = _.uniqBy([...checkNpmService, ...npm], "programTime");
    return checkNpmService;
  };

  downloadServices = async () => {
    if (this.state.selectRpmList.length === 0) {
      AntdModal.warning({
        title: "Download Service Report",
        content: "Please select DMT items to download.",
        centered: true
      });
      return;
    }
    const programTimes = this.state.selectRpmList.map(p => {
      return p.programTime;
    });
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (this.props.intakePatient.length === 0) {
      return;
    }
    let userId = this.props.patientDetails.userId;
    let startDate = moment(
      this.state.rpmYear + "-" + this.state.rpmMonth + "-1"
    ).format("YYYY-MM-DD");
    let endDate = moment(this.state.rpmYear + "-" + this.state.rpmMonth + "-1")
      .add(1, "month")
      .format("YYYY-MM-DD");
    let id = encodeURIComponent(this.props.intakePatient[0].id);
    const endpointInfo = filter(aws_exports.aws_cloud_logic_custom, {
      name: "dev-pdf-exporter"
    });
    const pdfEndpoint = endpointInfo[0].endpoint;
    for (const program of this.state.programList) {
      if (this.checkProgramNeedDownloadOrNot(program).length > 0) {
        const downloadUrl = `${pdfEndpoint}/medical/rpm?userId=${userId}&startDate=${startDate}&endDate=${endDate}&id=${id}&&token=${token}&&programTimes=${encodeURIComponent(
          qs.stringify(programTimes)
        )}&program=${program}`;
        window.open(downloadUrl);
      }
    }
  };

  handleCheckAll(checked) {
    let selectRpmList = [];
    if (checked) {
      selectRpmList = this.props.filterNpmService;
    }
    console.log("all: ", selectRpmList);
    this.setState({
      selectRpmList
    });
  }

  handleCheck(checked, service) {
    let selectRpmList = [...this.state.selectRpmList];
    if (checked) {
      selectRpmList.push(service);
    } else {
      selectRpmList = selectRpmList.filter(
        p => p.programTime !== service.programTime
      );
    }

    console.log(selectRpmList);
    this.setState({
      selectRpmList
    });
  }

  showPopupNote = programService => {
    return (
      <div
        style={{
          maxWidth: 500,
          maxHeight: 600,
          overflowY: "auto",
          paddingLeft: 10,
          paddingRight: 10
        }}
      >
        <p style={{ fontSize: 16, fontWeight: "600" }}>Preview for DMT Notes</p>
        <p style={{ fontSize: 12, marginTop: -10, fontWeight: "600" }}>
          Service Date -{" "}
          {moment(programService.serviceDate).format("MM/DD/YYYY")}
        </p>
        <div style={{ backgroundColor: "#C0BCDA", height: 2, width: "100%" }} />
        {programService.programNotes.map((item, index) => {
          return (
            <div style={{ marginTop: 10 }} key={index}>
              <div style={{ fontSize: 14, display: "flex" }}>
                Program:&nbsp;&nbsp;
                <div style={{ fontWeight: "800" }}>{item.program}</div>
              </div>
              <p style={{ marginTop: 15, fontSize: 12, fontWeight: "800" }}>
                Note details
              </p>
              <div
                style={{
                  fontSize: 12,
                  wordWrap: "break-word",
                  whiteSpace: "pre-line"
                }}
              >
                {item.description}
              </div>
              <div style={{ marginTop: 10 }} />
              {!isEmpty(item.addendums) &&
                item.addendums.map((p, i) => {
                  return (
                    <div style={{ marginTop: 10 }} key={i}>
                      <p style={{ fontSize: 12, fontWeight: "800" }}>
                        Addendum - {moment(p.addendumTime).format("MM/DD/YYYY")}
                      </p>
                      <div
                        style={{
                          fontSize: 12,
                          wordWrap: "break-word",
                          whiteSpace: "pre-line"
                        }}
                      >
                        {p.addendum}
                      </div>
                    </div>
                  );
                })}
              {index !== programService.programNotes.length - 1 && (
                <div
                  style={{
                    backgroundColor: "#C0BCDA",
                    height: 1,
                    width: "100%",
                    marginTop: !isEmpty(item.addendums) ? 10 : 0
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const trProps = getPatchTrProps(({ rowInfo, event: e }) => {
      const buttons = e.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (
          moment(rowInfo.original.serviceDate).format("YYYY-MM") ===
            moment().format("YYYY-MM") &&
          maxServiceInfo.programTime === rowInfo.original.programTime &&
          this.props.userName === rowInfo.original.doctorId
        ) {
          if (button.innerHTML === "Edit") {
            button.click();
            return;
          }
        } else {
          if (
            moment(rowInfo.original.serviceDate).format("YYYY-MM") ===
              moment().format("YYYY-MM") ||
            (moment().format("YYYY-MM-DD") < moment().format("YYYY-MM-02") &&
              moment(rowInfo.original.serviceDate).format("YYYY-MM") ===
                moment()
                  .add(-1, "month")
                  .format("YYYY-MM")) ||
            this.props.patientDetails.siteId.startsWith("0203")
          ) {
            if (button.innerHTML === "Addendum") {
              button.click();
              return;
            }
          } else {
            if (button.innerHTML === "Preview") {
              button.click();
              return;
            }
          }
        }
      }
    });
    const allServiceInfo = _(this.props.npmService)
      .sortBy(["programTime"])
      .value();
    const maxServiceInfo =
      allServiceInfo.length > 0 && allServiceInfo[allServiceInfo.length - 1];
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.props.modalState}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="search_patient_container" style={{ display: "flex" }}>
          <span
            className="search_label search_time_label"
            style={{ marginLeft: 70, marginTop: 5 }}
          >
            Month:
          </span>
          <Select
            onChange={this.handleMonthChange}
            showSearch
            value={this.state.rpmMonth.toString()}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ marginLeft: 10, width: 150, height: "32px" }}
          >
            <Option value="1">1 - January</Option>
            <Option value="2">2 - February</Option>
            <Option value="3">3 - March</Option>
            <Option value="4">4 - April</Option>
            <Option value="5">5 - May</Option>
            <Option value="6">6 - June</Option>
            <Option value="7">7 - July</Option>
            <Option value="8">8 - August</Option>
            <Option value="9">9 - September</Option>
            <Option value="10">10 - October</Option>
            <Option value="11">11 - November</Option>
            <Option value="12">12 - December</Option>
          </Select>
          <span
            className="search_label search_time_label"
            style={{ marginLeft: 20, marginTop: 5 }}
          >
            Year:
          </span>
          <Select
            onChange={this.handleYearChange}
            className="bli_Select_half_month"
            showSearch
            value={this.state.rpmYear.toString()}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ marginLeft: 10 }}
          >
            <Option
              value={moment()
                .add(-1, "year")
                .format("YYYY")}
            >
              {moment()
                .add(-1, "year")
                .format("YYYY")}
            </Option>
            <Option value={moment().format("YYYY")}>
              {moment().format("YYYY")}
            </Option>
            <Option
              value={moment()
                .add(1, "year")
                .format("YYYY")}
            >
              {moment()
                .add(1, "year")
                .format("YYYY")}
            </Option>
          </Select>
          <span
            className="search_label search_time_label"
            style={{ marginTop: 5, marginLeft: 20 }}
          >
            Program:
          </span>
          <div>
            <Select
              mode="multiple"
              onChange={this.handleProgramChange}
              value={this.state.programList}
              optionFilterProp="children"
              optionLabelProp="value"
              filterOption={(input, option) =>
                option.props.value.indexOf(input.toUpperCase()) >= 0
              }
              style={{ marginLeft: 10, height: "32px", width: 305 }}
              menuItemSelectedIcon={<Icon name="" />}
              showArrow={true}
            >
              <Option
                value="all"
                style={{ marginTop: 10, marginBottom: 10, width: 305 }}
              >
                <img
                  src={
                    this.state.programList.length === 0
                      ? UNCHECKED
                      : this.state.programList.length ===
                        defaultCheckedList.length
                      ? CHECKED
                      : INDETERMINATE
                  }
                  alt={""}
                  style={{ marginTop: -3, marginRight: 10 }}
                />
                <strong>All</strong>
              </Option>
              {_.sortBy(defaultCheckedList).map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    <img
                      src={
                        this.state.programList.includes(p) ? CHECKED : UNCHECKED
                      }
                      alt={""}
                      style={{ marginTop: -3, marginRight: 10 }}
                    />
                    {p}
                  </Option>
                );
              })}
            </Select>
          </div>
          <Divider
            type={"vertical"}
            style={{
              height: 30,
              marginTop: 3,
              marginLeft: 20,
              marginRight: 20
            }}
          />
          <Button
            type="link"
            onClick={this.downloadServices}
            style={
              {
                // marginLeft: 20
                // color: "#7C35AF",
                // position: "absolute",
                // top: 5,
                // right: 150
              }
            }
          >
            <Icon name="download" />
            Download Report
          </Button>
          {((!_.isEmpty(this.props.patientDetails) &&
            this.props.patientDetails.siteId.startsWith("0203")) ||
            moment(
              this.state.rpmYear +
                "-" +
                (this.state.rpmMonth < 10
                  ? "0" + this.state.rpmMonth
                  : this.state.rpmMonth)
            ).format("YYYY-MM") === moment().format("YYYY-MM")) && (
            <Add99457Service startDate={this.state.startDate} />
          )}
        </div>

        <ReactTable
          ref={ref => {
            if (ref) {
              this.tableInstance = ref;
            }
          }}
          getTrProps={trProps}
          data={
            this.props.filterNpmService &&
            this.props.filterNpmService.map(p => {
              let serviceDate = isEmpty(p.serviceDate)
                ? ""
                : moment(p.serviceDate).format("MM/DD/YYYY");
              let staffNameTemp = isEmpty(p.doctorName)
                ? ""
                : p.doctorName.includes("Siren -")
                ? p.doctorName.replace("Siren - ", "")
                : p.doctorName;
              let medicalStaffInfo = _(this.props.listStaff).find({
                email: p.doctorId
              });
              let staffName = medicalStaffInfo
                ? `${medicalStaffInfo.firstName} ${medicalStaffInfo.lastName}`
                : staffNameTemp;
              let doctorId = isEmpty(p.doctorId) ? "" : p.doctorId;
              let rpmService = isEmpty(p.rpmService) ? "" : p.rpmService;
              let description = `Preview for DMT Notes Service Date: ${moment(
                p.serviceDate
              ).format("MM/DD/YYYY")}`;
              let spentTime = p.spendTime + " mins";
              let programNotes = _(
                p.programNotes.map(item => {
                  if (item.program === "CCM") {
                    return { ccmTime: item.nurseTime + item.doctorTime };
                  } else if (item.program === "PCM") {
                    return { pcmTime: item.nurseTime + item.doctorTime };
                  } else if (item.program === "RTM") {
                    return { rtmTime: item.nurseTime + item.doctorTime };
                  }
                  return { rpmTime: item.nurseTime + item.doctorTime };
                })
              ).reduce((total, time) => {
                if (
                  Object.keys(total)
                    .map(p => {
                      return p;
                    })
                    .join(",")
                    .includes(Object.keys(time)[0])
                ) {
                  return {
                    ...total,
                    ...{
                      [Object.keys(time)[0]]:
                        total[Object.keys(time)[0]] + time[Object.keys(time)[0]]
                    }
                  };
                }
                return { ...total, ...time };
              });
              return {
                ...p,
                serviceDate,
                staffName,
                spentTime,
                rpmService,
                description,
                doctorId,
                ccmTime: 0,
                pcmTime: 0,
                rtmTime: 0,
                rpmTime: 0,
                ...programNotes
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              columns: [
                {
                  Header: props => {
                    return (
                      <Checkbox
                        onChange={e => this.handleCheckAll(e.target.checked)}
                        indeterminate={
                          this.state.selectRpmList.length > 0 &&
                          this.state.selectRpmList.length <
                            this.props.filterNpmService.length
                        }
                        checked={
                          this.state.selectRpmList.length > 0 &&
                          this.state.selectRpmList.length ===
                            this.props.filterNpmService.length
                        }
                        name="selectAll"
                        className="check_all"
                      />
                    );
                  },
                  accessor: "",
                  maxWidth: 60,
                  sortable: false,
                  style: {
                    padding: 0,
                    border: 0,
                    paddingTop: "15px",
                    paddingBottom: "15px"
                  },
                  filterable: false,
                  Cell: props => {
                    return (
                      <div onClick={e => e.stopPropagation()}>
                        <Checkbox
                          onChange={e => {
                            this.handleCheck(e.target.checked, props.original);
                          }}
                          checked={
                            !!this.state.selectRpmList.find(
                              item =>
                                item.programTime === props.original.programTime
                            )
                          }
                          name="selectSite"
                        />
                      </div>
                    );
                  },
                  Footer: (
                    <span
                      className="tableFooterRow"
                      style={{ color: "rgb(45, 1, 114)" }}
                    >
                      -
                    </span>
                  )
                }
              ]
            },
            {
              Header: "Date",
              accessor: "serviceDate",
              style: {
                padding: 0,
                border: 0,
                paddingTop: "15px",
                paddingBottom: "15px"
              },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{ color: "rgb(45, 1, 114)" }}
                >
                  -
                </span>
              )
            },
            {
              Header: "Author",
              accessor: "staffName",
              style: {
                padding: 0,
                border: 0,
                paddingTop: "15px"
              },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{ color: "rgb(45, 1, 114)" }}
                >
                  -
                </span>
              )
            },
            {
              Header: "Service(s)",
              accessor: "rpmService",
              style: {
                padding: 0,
                border: 0,
                paddingTop: "15px"
              },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{ color: "rgb(45, 1, 114)" }}
                >
                  -
                </span>
              )
            },
            {
              Header: "Notes",
              accessor: "description",
              style: {
                padding: 0,
                border: 0,
                // whiteSpace: "break-spaces",
                whiteSpace: "nowrap",
                overflow: "hidden",
                wordbreak: "normal",
                paddingTop: "15px"
              },
              Cell: props => {
                if (!props.value) {
                  return "";
                }
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <Popover content={this.showPopupNote(props.original)}>
                      {props.original.description}
                    </Popover>
                  </div>
                );
              },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{
                    color:
                      this.props.filterNpmService.length > 0
                        ? "rgb(255,255,255)"
                        : "rgb(45,1,114)"
                  }}
                >
                  TOTAL TIME
                </span>
              )
            },
            {
              Header: "CCM",
              accessor: "ccmTime",
              style: { padding: 0, border: 0, paddingTop: "15px" },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{
                    color:
                      this.props.filterNpmService.length > 0
                        ? "rgb(255,255,255)"
                        : "rgb(45,1,114)"
                  }}
                >
                  {this.props.totalTime_ccm}
                </span>
              )
            },
            {
              Header: "PCM",
              accessor: "pcmTime",
              style: { padding: 0, border: 0, paddingTop: "15px" },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{
                    color:
                      this.props.filterNpmService.length > 0
                        ? "rgb(255,255,255)"
                        : "rgb(45,1,114)"
                  }}
                >
                  {this.props.totalTime_pcm}
                </span>
              )
            },
            {
              Header: "RPM",
              accessor: "rpmTime",
              style: { padding: 0, border: 0, paddingTop: "15px" },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{
                    color:
                      this.props.filterNpmService.length > 0
                        ? "rgb(255,255,255)"
                        : "rgb(45,1,114)"
                  }}
                >
                  {this.props.totalTime_rpm}
                </span>
              )
            },
            {
              Header: "RTM",
              accessor: "rtmTime",
              style: { padding: 0, border: 0, paddingTop: "15px" },
              Footer: (
                <span
                  className="tableFooterRow"
                  style={{
                    color:
                      this.props.filterNpmService.length > 0
                        ? "rgb(255,255,255)"
                        : "rgb(45,1,114)"
                  }}
                >
                  {this.props.totalTime_rtm}
                </span>
              ),
              Cell: props => {
                return (
                  <div>
                    {props.original.rtmTime}
                    <Show99457Service
                      serviceInfo={props.original}
                      isEdit={isEdit => {
                        this.edit99457 = isEdit;
                      }}
                    />
                    <Edit99457Service
                      staffId={this.props.profile.email}
                      serviceInfo={props.original}
                      isEdit={isEdit => {
                        this.edit99457 = isEdit;
                      }}
                      loadNewEditValue={() => {
                        this.props.loadNewEditValue();
                      }}
                    />
                    <Addendum99457Service
                      serviceInfo={props.original}
                      isEdit={isEdit => {
                        this.edit99457 = isEdit;
                      }}
                      staffId={this.props.profile.email}
                      loadNewEditValue={() => {
                        this.props.loadNewEditValue();
                      }}
                    />
                    <DeleteRpmTracker
                      staffId={this.props.profile.email}
                      serviceInfo={props.original}
                      loadNewEditValue={() => {
                        this.props.loadNewEditValue();
                      }}
                    />
                  </div>
                );
              }
            },
            {
              Header: "Action",
              accessor: "edit",
              sortable: false,
              filterable: false,
              width: 0,
              show: false,
              Cell: props => {
                return (
                  <div>
                    <Show99457Service serviceInfo={props.original} />
                    <Edit99457Service
                      staffId={this.props.profile.email}
                      serviceInfo={props.original}
                      isEdit={isEdit => {
                        this.edit99457 = isEdit;
                      }}
                      loadNewEditValue={() => {
                        this.props.loadNewEditValue();
                      }}
                    />
                    <Addendum99457Service serviceInfo={props.original} />
                    <DeleteRpmTracker
                      staffId={this.props.profile.email}
                      serviceInfo={props.original}
                      loadNewEditValue={() => {
                        this.props.loadNewEditValue();
                      }}
                    />
                  </div>
                );
              },
              Footer: <span className="tableFooterRow"></span>
            }
          ]}
          defaultSorted={[{ id: "serviceDate", desc: false }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
          showPaginationTop={false}
          style={{ textAlign: "center" }}
        />
      </div>
    );
  }
}

const mapStateToProp = state => {
  let programServices = state.patientsListStore.filterNpmService.map(p => {
    return _(
      p.programNotes.map(item => {
        if (item.program === "CCM") {
          return { ccmTime: item.nurseTime + item.doctorTime };
        } else if (item.program === "PCM") {
          return { pcmTime: item.nurseTime + item.doctorTime };
        } else if (item.program === "RTM") {
          return { rtmTime: item.nurseTime + item.doctorTime };
        }
        return { rpmTime: item.nurseTime + item.doctorTime };
      })
    ).reduce((total, time) => {
      if (
        Object.keys(total)
          .map(p => {
            return p;
          })
          .join(",")
          .includes(Object.keys(time)[0])
      ) {
        return {
          ...total,
          ...{
            [Object.keys(time)[0]]:
              total[Object.keys(time)[0]] + time[Object.keys(time)[0]]
          }
        };
      }
      return { ...total, ...time };
    });
  });
  const totalTime_rpm = _(programServices)
    .map("rpmTime")
    .sum();
  const totalTime_rtm = _(programServices)
    .map("rtmTime")
    .sum();
  const totalTime_pcm = _(programServices)
    .map("pcmTime")
    .sum();
  const totalTime_ccm = _(programServices)
    .map("ccmTime")
    .sum();
  return {
    listStaff: state.patientsListStore.listStaff,
    npmService: state.patientsListStore.npmService,
    totalTime_ccm: totalTime_ccm === undefined ? 0 : totalTime_ccm,
    totalTime_pcm: totalTime_pcm === undefined ? 0 : totalTime_pcm,
    totalTime_rpm: totalTime_rpm === undefined ? 0 : totalTime_rpm,
    totalTime_rtm: totalTime_rtm === undefined ? 0 : totalTime_rtm,
    modalState: state.patientsListStore.modalState,
    intakePatient: state.patientsListStore.intakePatient,
    filterNpmService: state.patientsListStore.filterNpmService,
    patientDetails: state.patientsListStore.doctorDetails,
    userName: state.patientsListStore.userName,
    profile: state.authGroupStore.profile
  };
};

const mapDispatchToProp = dispatch => ({
  listRpmTracker: (userId, startDate, endDate) =>
    dispatch(listRpmTracker(userId, startDate, endDate)),
  listAdminUsers: () => dispatch(listAdminUsers()),
  updateNpmList: (npmService, programList, currentMonth) =>
    dispatch(updateNpmList(npmService, programList, currentMonth))
});
export default connect(mapStateToProp, mapDispatchToProp)(Tracker99457);
