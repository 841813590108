import echarts from "echarts";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  getChartDeltaOptions,
  getChartLeftPositionOptions,
  getChartLeftTempOptions,
  getChartRightPositionOptions,
  getChartRightTempOptions,
  getChartStepsOptions
} from "../constant/chartOptions";
import "./patientCharts.css";

class patientCharts extends Component {
  componentDidMount() {
    this.getUserData();
  }

  componentDidUpdate() {
    this.getUserData();
  }

  getUserData() {
    let myChart1 = echarts.init(document.getElementById("main1"));
    let myChart2 = echarts.init(document.getElementById("main2"));
    let myChart3 = echarts.init(document.getElementById("main3"));
    let myChart4 = echarts.init(document.getElementById("main4"));
    let myChart5 = echarts.init(document.getElementById("main5"));
    let myChart6 = echarts.init(document.getElementById("main6"));
    let deltaData = this.props.delta;
    let stepsData = this.props.steps;
    let leftFootTempData = this.props.leftFootTemp;
    let rightFootTempData = this.props.rightFootTemp;
    let leftPositionData = this.props.leftPosition;
    let rightPositionData = this.props.rightPosition;
    let option1 = getChartDeltaOptions(
      deltaData,
      this.props.min1_or_min30,
      this.props.tempUnit,
      this.props.delta_auto
    );
    let option2 = getChartStepsOptions(
      deltaData,
      stepsData,
      leftFootTempData,
      rightFootTempData,
      leftPositionData,
      rightPositionData,
      this.props.min1_or_min30
    );
    let option3 = getChartLeftTempOptions(
      deltaData,
      stepsData,
      leftFootTempData,
      rightFootTempData,
      leftPositionData,
      rightPositionData,
      this.props.min1_or_min30,
      this.props.tempUnit
    );
    let option4 = getChartRightTempOptions(
      deltaData,
      stepsData,
      leftFootTempData,
      rightFootTempData,
      leftPositionData,
      rightPositionData,
      this.props.min1_or_min30,
      this.props.tempUnit
    );
    let option5 = getChartLeftPositionOptions(
      deltaData,
      stepsData,
      leftFootTempData,
      rightFootTempData,
      leftPositionData,
      rightPositionData,
      this.props.min1_or_min30
    );
    let option6 = getChartRightPositionOptions(
      deltaData,
      stepsData,
      leftFootTempData,
      rightFootTempData,
      leftPositionData,
      rightPositionData,
      this.props.min1_or_min30
    );
    myChart1.setOption(option1);
    myChart2.setOption(option2);
    myChart3.setOption(option3);
    myChart4.setOption(option4);
    myChart5.setOption(option5);
    myChart6.setOption(option6);

    //link all charts
    echarts.connect([
      myChart1,
      myChart2,
      myChart3,
      myChart4,
      myChart5,
      myChart6
    ]);

    window.onresize = function() {
      myChart1.resize();
      myChart2.resize();
      myChart3.resize();
      myChart4.resize();
      myChart5.resize();
      myChart6.resize();
    };
  }

  render() {
    return (
      <div id="charts_container_id" className="charts_container">
        <Dimmer active={this.props.loading_charts} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>
        <div className="delta_left_label">Left</div>
        <div className="delta_right_label">Right</div>
        <div id="main1" style={{ width: "100%", height: 2000 / 6 }} />
        <div
          id="main2"
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        <div
          id="main3"
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        <div
          id="main4"
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        <div
          id="main5"
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
        <div
          id="main6"
          style={{ width: "100%", height: 2000 / 6, marginTop: -50 }}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  loading_charts: state.patientsListStore.loading_charts,
  delta: state.patientsListStore.delta,
  steps: state.patientsListStore.steps,
  min1_or_min30: state.patientsListStore.min1_or_min30,
  leftFootTemp: state.patientsListStore.leftFootTemp,
  rightFootTemp: state.patientsListStore.rightFootTemp,
  leftPosition: state.patientsListStore.leftPosition,
  rightPosition: state.patientsListStore.rightPosition,
  tempUnit: state.patientsListStore.tempUnit,
  delta_auto: state.patientsListStore.delta_auto
});
export default connect(
  mapStateToProp,
  null
)(patientCharts);
