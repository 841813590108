import { Modal as AntdModal } from "antd";
import { API, graphqlOperation } from "aws-amplify/lib/index";
import _ from "lodash";
import _api from "../graphql/_api";
import message from "../tools/message-info";
import * as TYPES from "../types/types";
export function changePatientFirstName(firstName) {
  return dispatch => {
    dispatch(changePatientFirstNameState(firstName));
  };
}

export function changePatientLastName(lastName) {
  return dispatch => {
    dispatch(changePatientLastNameState(lastName));
  };
}

export function changePatientBirMonth(birMonth) {
  return dispatch => {
    dispatch(changePatientBirMonthState(birMonth));
    if (birMonth.length === 2) {
      document.getElementById("filedBirMonth").blur();
      document.getElementById("filedBirDay").focus();
    }
  };
}

export function changePatientBirDay(birDay) {
  return dispatch => {
    dispatch(changePatientBirDayState(birDay));
    if (birDay.length === 2) {
      document.getElementById("filedBirDay").blur();
      document.getElementById("filedBirYear").focus();
    }
  };
}

export function changePatientBirYear(birYear) {
  return dispatch => {
    dispatch(changePatientBirYearState(birYear));
    if (birYear.length === 4) {
      document.getElementById("filedBirYear").blur();
    }
  };
}

export function changePatientEmail(email) {
  return dispatch => {
    dispatch(changePatientEmailState(email));
  };
}

export function changePatientMBI(patientMBI) {
  return dispatch => {
    dispatch(changePatientMBIState(patientMBI));
  };
}

export function changePatientPhone(phone) {
  return dispatch => {
    dispatch(changePatientPhoneState(phone));
  };
}

export function changePatientCellPhone(phone) {
  return dispatch => {
    dispatch(changePatientCellPhoneState(phone));
  };
}

export function changePatientHomePhone(phone) {
  return dispatch => {
    dispatch(changePatientHomePhoneState(phone));
  };
}

function changePatientHomePhoneState(phone) {
  return {
    type: TYPES.PATIENT_HOME_PHONE,
    text: phone
  };
}

export function changePatientSecPhone(secPhone) {
  return dispatch => {
    dispatch(changePatientSecPhoneState(secPhone));
  };
}

export function changePatientSecEmail(secEmail) {
  return dispatch => {
    dispatch(changePatientSecEmailState(secEmail));
  };
}

export function changePatientSecContact(secContact) {
  return dispatch => {
    dispatch(changePatientSecContactState(secContact));
  };
}

export function changePatientPatientID(patientID) {
  return dispatch => {
    dispatch(changePatientPatientIDState(patientID));
  };
}

export function changePatientSiteId(siteId) {
  return dispatch => {
    dispatch(changePatientSiteIDState(siteId));
  };
}

export function changePatientSecRel(secRel) {
  return dispatch => {
    dispatch(changePatientSecRelaState(secRel));
  };
}
export function changePatientGender(gender) {
  return dispatch => {
    dispatch(changePatientGenderState(gender));
  };
}
export function changePatientDoctorId(doctorId) {
  return dispatch => {
    dispatch(changePatientDoctorIDState(doctorId));
  };
}

export function changePatientBirthday(birthday) {
  return dispatch => {
    dispatch(changePatientBirthdayState(birthday));
  };
}

export function resetAllPatientInfo() {
  return dispatch => {
    dispatch(resetAllPatientInfoState());
  };
}

export function registerPatientAccount(self, CreatePatientInput) {
  return async dispatch => {
    try {
      self.setState({ openModal: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.registerAccount(), CreatePatientInput)
      );
      if (newEvent.data.createPatient.status) {
        self.setState({
          activeStep: 5,
          openModal: false
        });
      } else {
        let message = newEvent.data.createPatient.message;
        self.setState({
          activeStep: 7,
          failedMess: message,
          openModal: false
        });
      }
    } catch (err) {
      console.log("registerPatientAccount error: ", err);
      self.setState({
        activeStep: 7,
        failedMess: err,
        openModal: false
      });
    }
  };
}
function changePatientFirstNameState(firstName) {
  return {
    type: TYPES.PATIENT_FIRST_NAME,
    text: firstName
  };
}

function changePatientLastNameState(lastName) {
  return {
    type: TYPES.PATIENT_LAST_NAME,
    text: lastName
  };
}

function changePatientBirMonthState(birMonth) {
  return {
    type: TYPES.BIR_MONTH,
    text: birMonth
  };
}

function changePatientBirDayState(birDay) {
  return {
    type: TYPES.BIR_DAY,
    text: birDay
  };
}

function changePatientBirYearState(birYear) {
  return {
    type: TYPES.BIR_YEAR,
    text: birYear
  };
}

function changePatientEmailState(email) {
  return {
    type: TYPES.PATIENT_EMAIL,
    text: email
  };
}

function changePatientMBIState(patientMBI) {
  return {
    type: TYPES.PATIENT_MBI,
    text: patientMBI
  };
}

function changePatientPhoneState(phone) {
  return {
    type: TYPES.PATIENT_PHONE,
    text: phone
  };
}

function changePatientCellPhoneState(phone) {
  return {
    type: TYPES.PATIENT_CELL_PHONE,
    text: phone
  };
}

function changePatientSecPhoneState(secPhone) {
  return {
    type: TYPES.PATIENT_SEC_PHONE,
    text: secPhone
  };
}

function changePatientSecEmailState(secEmail) {
  return {
    type: TYPES.PATIENT_SEC_EMAIL,
    text: secEmail
  };
}

function changePatientSecContactState(secContact) {
  return {
    type: TYPES.PATIENT_SEC_CONTACT,
    text: secContact
  };
}

function changePatientPatientIDState(patientID) {
  return {
    type: TYPES.PATIENT_PATIENT_ID,
    text: patientID
  };
}

function changePatientSiteIDState(siteId) {
  return {
    type: TYPES.PATIENT_SITE_ID,
    text: siteId
  };
}

function changePatientSecRelaState(secRel) {
  return {
    type: TYPES.PATIENT_SEC_RELA,
    text: secRel
  };
}
function changePatientGenderState(gender) {
  return {
    type: TYPES.PATIENT_GENDER,
    text: gender
  };
}
function changePatientDoctorIDState(doctorId) {
  return {
    type: TYPES.PATIENT_DOCTOR_ID,
    text: doctorId
  };
}

function changePatientBirthdayState(birthday) {
  return {
    type: TYPES.PATIENT_BIRTHDAY,
    text: birthday
  };
}

function resetAllPatientInfoState() {
  return {
    type: TYPES.RESET_ALL_PATIENT_INFO
  };
}
export function compareSiteId(a, b) {
  if (a.siteId < b.siteId) return -1;
  if (a.siteId > b.siteId) return 1;
  return 0;
}
export function compareFirstName(a, b) {
  if (a.firstName < b.firstName) return -1;
  if (a.firstName > b.firstName) return 1;
  return 0;
}
export function compareGroup(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function getlistClinicSitesPatient(self, siteId, doctorId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        let allSites = newEvent.data.listClinicSitesNew.items.sort(
          compareSiteId
        );
        let currentSites = [];
        _.forEach(allSites, function(item) {
          // if (self.state.staffSiteIds.indexOf(item.siteId) >= 0) {
          //   currentSites.push(item);
          // }
          _.forEach(self.state.staffSiteIds, function(siteItem) {
            if (item.siteId.startsWith(siteItem)) {
              if (
                _.filter(currentSites, { siteId: item.siteId }).length === 0
              ) {
                currentSites.push(item);
              }
            }
          });
        });
        dispatch(changelistClinicSites(currentSites));
        if (currentSites.length > 0) {
          // const localSiteIdList = newEvent.data.listClinicSitesNew.items
          //   .filter(
          //     item => self.state.localSiteIdList.indexOf(item.siteId) >= 0
          //   )
          //   .sort(compareSiteId);
          let IRBConsent = "Not Approved";
          if (!siteId && IRBConsent) {
            IRBConsent = currentSites.sort(compareSiteId)[0].IRBConsent;
          }
          dispatch(changeIRBConsentStatus(IRBConsent));
          dispatch(
            changePatientSiteIDState(
              siteId ? siteId : currentSites.sort(compareSiteId)[0].siteId
            )
          );
          self.setState({
            hubContract:
              currentSites.sort(compareSiteId)[0].hubContract || "Wifi"
          });
          self.handleSiteIdChange(
            siteId ? siteId : currentSites.sort(compareSiteId)[0].siteId,
            doctorId
          );
        }
      }
    } catch (e) {
      console.log("getlistClinicSitesPatient error: ", e);
    }
  };
}

export function getlistClinicSites() {
  return async (dispatch, getState) => {
    try {
      const siteIds = getState().authGroupStore.profile.siteId;

      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        let allSites = newEvent.data.listClinicSitesNew.items.sort(
          compareSiteId
        );
        let currentSites = [];
        _.forEach(allSites, function(item) {
          _.forEach(siteIds, function(siteItem) {
            if (item.siteId.startsWith(siteItem)) {
              if (
                _.filter(currentSites, { siteId: item.siteId }).length === 0
              ) {
                currentSites.push(item);
              }
            }
          });
        });
        dispatch(changelistClinicSites(currentSites));
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function getAllListClinicSites() {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listClinicSites())
      );
      if (newEvent) {
        let allSites = newEvent.data.listClinicSitesNew.items.sort(
          compareSiteId
        );
        dispatch(changeAllListClinicSites(allSites));
      }
    } catch (e) {
      console.log("getlistClinicSites error: ", e);
    }
  };
}

export function changelistClinicSites(clinicSites) {
  return {
    type: TYPES.CLINIC_SITES,
    text: clinicSites
  };
}

export function changeAllListClinicSites(clinicSites) {
  return {
    type: TYPES.ALL_CLINIC_SITES,
    text: clinicSites
  };
}

export function changelistStaff(listStaff) {
  return {
    type: TYPES.LIST_STAFF,
    text: listStaff
  };
}
export function changeTriallistStaff(trialStaff) {
  return {
    type: TYPES.TRIAL_STAFF,
    text: trialStaff
  };
}

function getCurrentSiteDoctor(staffSiteId, siteId) {
  let siteIds = siteId.split("-");
  return siteIds.includes(staffSiteId);
}

export function getPrescribingStaff(staffSiteId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.patientStaff()));
      if (newEvent) {
        const newStaffList = newEvent.data.listStaff.items
          .filter(item => {
            return (
              getCurrentSiteDoctor(staffSiteId, item.siteId) &&
              item.isPrescribing === true
            );
          })
          .sort(compareSiteId);
        dispatch(changePrescribingStaf(newStaffList));
        // dispatch(changePatientDoctorId(newStaffList[0].email));
      }
    } catch (e) {
      console.log("getPrescribingStaff error: ", e);
    }
  };
}

export function getPresNewStaff(staffSiteId, self, doctorId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.patientStaff()));

      if (newEvent) {
        const newStaffList = newEvent.data.listStaff.items
          .filter(
            item =>
              getCurrentSiteDoctor(staffSiteId, item.siteId) &&
              item.isPrescribing === true
          )
          .sort(compareSiteId);
        if (newStaffList.length > 0) {
          self.setState({
            staffDoctorId: doctorId ? doctorId : newStaffList[0].email
          });
          dispatch(changePrescribingStaf(newStaffList));
          dispatch(
            changePatientDoctorId(doctorId ? doctorId : newStaffList[0].email)
          );
        } else {
          self.setState({ staffDoctorId: "" });
          dispatch(changePrescribingStaf(newStaffList));
          dispatch(changePatientDoctorId(""));
        }
      }
    } catch (e) {
      console.log("getPresNewStaff error: ", e);
    }
  };
}

export function getPrescribingStaffChanged(staffSiteId, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(graphqlOperation(_api.patientStaff()));
      if (newEvent) {
        const newStaffList = newEvent.data.listStaff.items
          .filter(item => {
            return (
              getCurrentSiteDoctor(staffSiteId, item.siteId) &&
              item.isPrescribing === true
            );
          })
          .sort(compareSiteId);
        dispatch(changePrescribingStaf(newStaffList));
        self.setState({ doctorId: newStaffList[0].email });
      }
    } catch (e) {
      console.log("getPrescribingStaff error: ", e);
    }
  };
}

export function changePrescribingStaf(prescribingStaff) {
  return {
    type: TYPES.PRESCRIBING_STAFF,
    text: prescribingStaff
  };
}

export async function getlistSummaryStaff(dispatch, getState) {
  let siteIds = getState().authGroupStore.profile.siteId;
  let filter = {};
  let or = siteIds.map(p => {
    return { siteId: { contains: p } };
  });
  Object.assign(filter, { or });
  if (_.isEmpty(filter)) {
    filter = null;
  }
  const newEvent = await API.graphql(
    graphqlOperation(_api.listStaff(), {
      filter
    })
  );
  if (newEvent) {
    const allDoctors = _.filter(newEvent.data.listStaff.items, {
      isPrescribing: true
    });
    dispatch(changelistStaff(allDoctors.sort(compareFirstName)));
  }
}

export function getlistStaff() {
  return async (dispatch, getState) => {
    try {
      let siteIds = getState().authGroupStore.profile.siteId;
      let filter = {};
      let or = siteIds.map(p => {
        return { siteId: { contains: p } };
      });
      Object.assign(filter, { or });
      if (_.isEmpty(filter)) {
        filter = null;
      }
      const newEvent = await API.graphql(
        graphqlOperation(_api.listStaff(), {
          filter
        })
      );
      if (newEvent) {
        dispatch(
          changelistStaff(newEvent.data.listStaff.items.sort(compareFirstName))
        );
      }
    } catch (e) {
      console.log("getlistStaff error: ", e);
    }
  };
}

export function getlistStaffSetting(email, self) {
  return async (dispatch, getState) => {
    try {
      let siteIds = getState().authGroupStore.profile.siteId;
      let filter = {};
      let or = siteIds.map(p => {
        return { siteId: { contains: p } };
      });
      Object.assign(filter, { or });
      if (_.isEmpty(filter)) {
        filter = null;
      }
      const newEvent = await API.graphql(
        graphqlOperation(_api.listStaff(), {
          filter
        })
      );
      if (newEvent) {
        let staffInfo = _.filter(newEvent.data.listStaff.items, { email });
        if (staffInfo.length > 0) {
          self.setState({
            fullName: `${staffInfo[0].firstName} ${staffInfo[0].lastName}`,
            email: staffInfo[0].email,
            notificationEmail: staffInfo[0].notificationEmail,
            weeklyNotificationStatus: staffInfo[0].weeklyNotificationStatus
              ? staffInfo[0].weeklyNotificationStatus
              : staffInfo[0].autoReportSites &&
                JSON.parse(staffInfo[0].autoReportSites).length > 0
              ? JSON.parse(staffInfo[0].autoReportSites).length ===
                self.state.allSiteIdSelect.length
                ? "ON"
                : "CUSTOM"
              : "OFF",
            selectValues: staffInfo[0].autoReportSites
              ? JSON.parse(staffInfo[0].autoReportSites)
              : [],
            weeklyReportSend: !!(
              staffInfo[0].weeklyReportSend &&
              staffInfo[0].weeklyReportSend === "Yes"
            )
          });
        }
        dispatch(
          changelistStaff(newEvent.data.listStaff.items.sort(compareFirstName))
        );
      }
    } catch (e) {
      console.log("getlistStaff error: ", e);
    }
  };
}

export function ifEmptyFirstName(emptyFirstName) {
  return dispatch => {
    dispatch(changeEmptyFirstName(emptyFirstName));
  };
}
function changeEmptyFirstName(emptyFirstName) {
  return {
    type: TYPES.EMPTY_FIRST_NAME,
    text: emptyFirstName
  };
}
export function ifEmptyLastName(emptyLastName) {
  return dispatch => {
    dispatch(changeEmptyLastName(emptyLastName));
  };
}
function changeEmptyLastName(emptyLastName) {
  return {
    type: TYPES.EMPTY_LAST_NAME,
    text: emptyLastName
  };
}
export function ifEmptyBir(emptyBir) {
  return dispatch => {
    dispatch(changeEmptyBir(emptyBir));
  };
}
function changeEmptyBir(emptyBir) {
  return {
    type: TYPES.EMPTY_BIR,
    text: emptyBir
  };
}
export function ifMonthErrorBir(birMonthErrorFormat) {
  return dispatch => {
    dispatch(changeMonthErrorBir(birMonthErrorFormat));
  };
}
function changeMonthErrorBir(birMonthErrorFormat) {
  return {
    type: TYPES.ERROR_MONTH_BIR,
    text: birMonthErrorFormat
  };
}
export function ifDayErrorBir(birDayErrorFormat) {
  return dispatch => {
    dispatch(changeDayErrorBir(birDayErrorFormat));
  };
}
function changeDayErrorBir(birDayErrorFormat) {
  return {
    type: TYPES.ERROR_DAY_BIR,
    text: birDayErrorFormat
  };
}
export function ifErrorBir(birErrorFormat) {
  return dispatch => {
    dispatch(changeErrorBir(birErrorFormat));
  };
}
function changeErrorBir(birErrorFormat) {
  return {
    type: TYPES.ERROR_BIR,
    text: birErrorFormat
  };
}
export function ifEmptyPhone(emptyPhone) {
  return dispatch => {
    dispatch(changeEmptyPhone(emptyPhone));
  };
}
function changeEmptyPhone(emptyPhone) {
  return {
    type: TYPES.EMPTY_PHONE,
    text: emptyPhone
  };
}
export function ifErrorPhone(phoneErrorFormat) {
  return dispatch => {
    dispatch(changeErrorPhone(phoneErrorFormat));
  };
}
function changeErrorPhone(phoneErrorFormat) {
  return {
    type: TYPES.ERROR_PHONE,
    text: phoneErrorFormat
  };
}
export function ifErrorSecPhone(secPhoneErrorFormat) {
  return dispatch => {
    dispatch(changeErrorSecPhone(secPhoneErrorFormat));
  };
}
function changeErrorSecPhone(secPhoneErrorFormat) {
  return {
    type: TYPES.ERROR_SEC_PHONE,
    text: secPhoneErrorFormat
  };
}
export function ifEmptySiteId(emptySiteId) {
  return dispatch => {
    dispatch(changeEmptySiteId(emptySiteId));
  };
}
function changeEmptySiteId(emptySiteId) {
  return {
    type: TYPES.EMPTY_SITE_ID,
    text: emptySiteId
  };
}
export function ifEmptyDoctorId(emptyDoctorId) {
  return dispatch => {
    dispatch(changeEmptyDoctorId(emptyDoctorId));
  };
}
function changeEmptyDoctorId(emptyDoctorId) {
  return {
    type: TYPES.EMPTY_DOCTOR_ID,
    text: emptyDoctorId
  };
}

export function saveDiagnosisCodes(diagnosisCodes) {
  return dispatch => {
    dispatch(changeDiagnosisCodeState(diagnosisCodes));
  };
}

function changeDiagnosisCodeState(diagnosisCodes) {
  return {
    type: TYPES.DIAGNOSIS_CODES,
    text: diagnosisCodes
  };
}

export function saveMedicalConditions(medicalConditions) {
  return dispatch => {
    dispatch(changeMedicalConditionState(medicalConditions));
  };
}

function changeMedicalConditionState(medicalConditions) {
  return {
    type: TYPES.MEDICAL_CODITIONS,
    text: medicalConditions
  };
}

export function savePatientNotes(patientNotes) {
  return dispatch => {
    dispatch(changePatientNoteState(patientNotes));
  };
}

function changePatientNoteState(patientNotes) {
  return {
    type: TYPES.PATIENT_NOTES,
    text: patientNotes
  };
}

export function saveDispenseComment(comment) {
  return dispatch => {
    dispatch(changeDispenseComment(comment));
  };
}

function changeDispenseComment(comment) {
  return {
    type: TYPES.DISPENSE_COMMENT,
    text: comment
  };
}

export function saveDispenseStyle(style) {
  return dispatch => {
    dispatch(changeDispenseStyle(style));
  };
}

function changeDispenseStyle(style) {
  return {
    type: TYPES.DISPENSE_STYLE,
    text: style
  };
}

export function saveSockSize(sockSize) {
  return dispatch => {
    dispatch(changeSockSize(sockSize));
  };
}

function changeSockSize(sockSize) {
  return {
    type: TYPES.SOCK_SIZE,
    text: sockSize
  };
}

export function saveSockColor(sockColor) {
  return dispatch => {
    dispatch(changeSockColor(sockColor));
  };
}

function changeSockColor(sockColor) {
  return {
    type: TYPES.SOCK_COLOR,
    text: sockColor
  };
}

export function saveShipAddress(address) {
  return dispatch => {
    dispatch(changeShipAddress(address));
  };
}

function changeShipAddress(address) {
  return {
    type: TYPES.SHIP_ADDRESS,
    text: address
  };
}

export function saveShipCity(city) {
  return dispatch => {
    dispatch(changeShipCity(city));
  };
}

function changeShipCity(city) {
  return {
    type: TYPES.SHIP_CITY,
    text: city
  };
}

export function saveShipState(state) {
  return dispatch => {
    dispatch(changeShipState(state));
  };
}

function changeShipState(state) {
  return {
    type: TYPES.SHIP_STATE,
    text: state
  };
}

export function saveShipZip(zip) {
  return dispatch => {
    dispatch(changeShipZip(zip));
  };
}

function changeShipZip(zip) {
  return {
    type: TYPES.SHIP_ZIP,
    text: zip
  };
}

export function authSecondary(checked) {
  return dispatch => {
    dispatch(changeAuthSecondary(checked));
  };
}

function changeAuthSecondary(checked) {
  return {
    type: TYPES.AUTH_SECONDARY,
    text: checked
  };
}
export function enableApp(checked) {
  return dispatch => {
    dispatch(changeEnableApp(checked));
  };
}

function changeEnableApp(checked) {
  return {
    type: TYPES.CONTACT_PHONE,
    text: checked
  };
}
export function enableEmail(checked) {
  return dispatch => {
    dispatch(changeEnableEmail(checked));
  };
}

function changeEnableEmail(checked) {
  return {
    type: TYPES.CONTACT_EMAIL,
    text: checked
  };
}
export function enableMessage(checked) {
  return dispatch => {
    dispatch(changeEnableMessage(checked));
  };
}

function changeEnableMessage(checked) {
  return {
    type: TYPES.CONTACT_MESSAGE,
    text: checked
  };
}
export function changeRemoteMonitor(checked) {
  return dispatch => {
    dispatch(changeRemoteMonitorState(checked));
  };
}

function changeRemoteMonitorState(checked) {
  return {
    type: TYPES.REMOTE_MONITOR,
    text: checked
  };
}

export function changeAllergicMaterials(checked) {
  return dispatch => {
    dispatch(changeAllergicMaterialsState(checked));
  };
}

function changeAllergicMaterialsState(checked) {
  return {
    type: TYPES.ALLERGIC_MATERIALS,
    text: checked
  };
}

export function changeAgreeUploadPhoto(checked) {
  return dispatch => {
    dispatch(changeAgreeUploadPhotoState(checked));
  };
}

function changeAgreeUploadPhotoState(checked) {
  return {
    type: TYPES.AGREE_UPLOAD_PHOTO,
    text: checked
  };
}

export function changeRemoteMonitorCheck(checked) {
  return dispatch => {
    dispatch(changeRemoteMonitorCheckState(checked));
  };
}

function changeRemoteMonitorCheckState(checked) {
  return {
    type: TYPES.REMOTE_MONITOR_CHECK,
    text: checked
  };
}

export function changeDispenseCriteria(checked) {
  return dispatch => {
    dispatch(changeDispenseCriteriaState(checked));
  };
}

function changeDispenseCriteriaState(checked) {
  return {
    type: TYPES.DISPENSE_CRITERIA,
    text: checked
  };
}

export function changeDispenseCriteriaCheck(checked) {
  return dispatch => {
    dispatch(changeDispenseCriteriaCheckState(checked));
  };
}

function changeDispenseCriteriaCheckState(checked) {
  return {
    type: TYPES.DISPENSE_CRITERIA_CHECK,
    text: checked
  };
}

export function changeRightToe() {
  return dispatch => {
    dispatch(changeRightToeState());
  };
}

function changeRightToeState() {
  return {
    type: TYPES.SEL_RIGHT_TOE
  };
}

export function changeRightMts() {
  return dispatch => {
    dispatch(changeRightMtsState());
  };
}

function changeRightMtsState() {
  return {
    type: TYPES.SEL_RIGHT_MTS
  };
}

export function changeRightArch() {
  return dispatch => {
    dispatch(changeRightArchState());
  };
}

function changeRightArchState() {
  return {
    type: TYPES.SEL_RIGHT_ARCH
  };
}

export function changeRightHeel() {
  return dispatch => {
    dispatch(changeRightHeelState());
  };
}

function changeRightHeelState() {
  return {
    type: TYPES.SEL_RIGHT_HEEL
  };
}

export function changeLeftToe() {
  return dispatch => {
    dispatch(changeLeftToeState());
  };
}

function changeLeftToeState() {
  return {
    type: TYPES.SEL_LEFT_TOE
  };
}

export function changeLeftMts() {
  return dispatch => {
    dispatch(changeLeftMtsState());
  };
}

function changeLeftMtsState() {
  return {
    type: TYPES.SEL_LEFT_MTS
  };
}

export function changeLeftArch() {
  return dispatch => {
    dispatch(changeLeftArchState());
  };
}

function changeLeftArchState() {
  return {
    type: TYPES.SEL_LEFT_ARCH
  };
}

export function changeLeftHeel() {
  return dispatch => {
    dispatch(changeLeftHeelState());
  };
}

function changeLeftHeelState() {
  return {
    type: TYPES.SEL_LEFT_HEEL
  };
}

export function changeDispenseStyleCheck(checked) {
  return dispatch => {
    dispatch(changeDispenseStyleCheckState(checked));
  };
}
function changeDispenseStyleCheckState(checked) {
  return {
    type: TYPES.DISPENSE_STYLE_CHECK,
    text: checked
  };
}

export function changeSockSizeCheck(checked) {
  return dispatch => {
    dispatch(changeSockSizeCheckState(checked));
  };
}
function changeSockSizeCheckState(checked) {
  return {
    type: TYPES.SOCK_SIZE_CHECK,
    text: checked
  };
}

export function changeSockColorCheck(checked) {
  return dispatch => {
    dispatch(changeSockColorCheckState(checked));
  };
}
function changeSockColorCheckState(checked) {
  return {
    type: TYPES.SOCK_COLOR_CHECK,
    text: checked
  };
}

export function createDoctorAccount(CreateDoctorInput) {
  return async (dispatch, getState) => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.registerDoctorAccount(), CreateDoctorInput)
      );
      if (newEvent.data.createDoctor.status) {
        let staffList = getState().patientsListStore.listStaff;
        const newStaff = _.clone(staffList);
        newStaff.push(CreateDoctorInput.input);
        dispatch(changelistStaff(newStaff));
      } else {
        message.info(newEvent.data.createDoctor.message);
      }
    } catch (err) {
      console.log("createDoctorAccount error: ", err);
    }
  };
}
export function updateStaff(newStaffInput) {
  return async (dispatch, getState) => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDoctor(), newStaffInput)
      );
      if (newEvent.data.updateDoctor.status) {
        const StaffInputValue = _.clone(newStaffInput);
        StaffInputValue.input.doctorRole = "STAFF_CLINICAL";
        let StaffList = getState().patientsListStore.listStaff;
        const newStaffInfo = _.clone(StaffList);
        const doc = _.filter(newStaffInfo, function(t) {
          return t.email === StaffInputValue.input.email;
        });
        if (doc.length > 0 && doc[0].lastLogin) {
          StaffInputValue.input.lastLogin = doc[0].lastLogin;
        }
        _.remove(newStaffInfo, {
          email: StaffInputValue.input.email
        });
        newStaffInfo.push(StaffInputValue.input);
        const staffInfoAfterDel = newStaffInfo;
        dispatch(changelistStaff(staffInfoAfterDel));
      } else {
        message.info(newEvent.data.updateDoctor.message);
      }
    } catch (err) {
      console.log("updateStaff error: ", err);
    }
  };
}

export function updateDoctorLastLogin(newStaffInput) {
  return async dispatch => {
    try {
      await API.graphql(graphqlOperation(_api.updateDoctor(), newStaffInput));
    } catch (err) {
      console.log("updateDoctorLastLogin error: ", err);
    }
  };
}

export function getDeleteStaff(email, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteDoctor(), { input: { email } })
      );
      if (newEvent) {
        if (newEvent.data.deleteDoctor.status) {
          self.setState({ open: false });
          const tmpSites = _.clone(self.props.listStaff);
          _.remove(tmpSites, {
            email: email
          });
          const itemsAfterDel = tmpSites;
          dispatch(changelistStaff(itemsAfterDel));
        } else {
          AntdModal.warn({
            title: "Delete Doctor Failed",
            content: newEvent.data.deleteDoctor.message,
            centered: true
          });
        }
      }
    } catch (err) {
      AntdModal.warn({
        title: "Delete Doctor Exception",
        content: err,
        centered: true
      });
      console.log("getDeleteStaff exception: ", err);
    }
  };
}

export function resetPwd(newPwdInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.resetStaffPassword(), newPwdInput)
      );
      if (newEvent.data.resetStaffPassword.status) {
        self.setState({ newPwd: "", open: false });
      } else {
        message.info(newEvent.data.resetStaffPassword.message);
      }
    } catch (err) {
      console.log("resetPwd error: ", err);
    }
  };
}
export function ifEmptyDispensedMethod(emptyDispensedMethod) {
  return dispatch => {
    dispatch(EmptyDispensedMethod(emptyDispensedMethod));
  };
}
function EmptyDispensedMethod(emptyDispensedMethod) {
  return {
    type: TYPES.EMPTY_DISPENSED_METHOD,
    text: emptyDispensedMethod
  };
}
export function saveIfHavePhone(ifHavePhone) {
  return dispatch => {
    dispatch(changeIfHavePhone(ifHavePhone));
  };
}

function changeIfHavePhone(ifHavePhone) {
  return {
    type: TYPES.HAVE_PHONE,
    text: ifHavePhone
  };
}
export function saveIfHaveWifi(ifHaveWifi) {
  return dispatch => {
    dispatch(changeIfHaveWifi(ifHaveWifi));
  };
}

function changeIfHaveWifi(ifHaveWifi) {
  return {
    type: TYPES.HAVE_WIFI,
    text: ifHaveWifi
  };
}

export function changePatientBirDate(birDate) {
  return dispatch => {
    dispatch(changePatientBirDateState(birDate));
  };
}
function changePatientBirDateState(patientBirDate) {
  return {
    type: TYPES.BIR_DATE,
    text: patientBirDate
  };
}

export function saveSockHubType(sockHubType) {
  return dispatch => {
    dispatch(changeSockHubType(sockHubType));
  };
}

function changeSockHubType(sockHubType) {
  return {
    type: TYPES.SOCK_HUB_TYPE,
    text: sockHubType
  };
}

export function updateDoctorId(doctorId) {
  return dispatch => {
    dispatch(changeDoctorId(doctorId));
  };
}

function changeDoctorId(doctorId) {
  return {
    type: TYPES.UPDATE_DOCTORID,
    text: doctorId
  };
}

export function changeIRBConsent(IRBConsent) {
  return dispatch => {
    dispatch(changeIRBConsentStatus(IRBConsent));
  };
}

function changeIRBConsentStatus(IRBConsent) {
  return {
    type: TYPES.CLINIC_IRB,
    text: IRBConsent
  };
}

export function listAdminUsers() {
  return async dispatch => {
    const newEvent = await API.graphql(graphqlOperation(_api.listAdminUsers()));

    dispatch(changeListAdminUsersStatus(newEvent.data.listAdminStaff.items));
  };
}

export function listLPNs(self, siteId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listAdminUsers())
      );
      if (newEvent) {
        if (siteId) {
          const sirenStaff = _.compact(
            _.filter(newEvent.data.listAdminStaff.items, function(t) {
              return (
                t.group === "staff" && t.patientCount < parseInt(t.patientLoad)
              );
            }).map(p => {
              if (
                p.primarySites &&
                JSON.parse(p.primarySites).includes(siteId)
              ) {
                const rate = parseFloat(
                  p.patientCount / parseInt(p.patientLoad)
                );
                return { ...p, rate };
              }
              return "";
            })
          );
          const preStaff = _.minBy(sirenStaff, "rate");
          if (preStaff) {
            dispatch(changePatientLPNState(preStaff.id));
          }
        }
        dispatch(
          changeListAdminUsersStatus(newEvent.data.listAdminStaff.items)
        );
      }
    } catch (e) {
      console.log(`get list admin users error: ${JSON.stringify(e)}`);
    }
  };
}

function changeListAdminUsersStatus(listUsers) {
  return {
    type: TYPES.LIST_ADMIN_STAFF,
    text: listUsers
  };
}

export function changePatientLPN(lpn) {
  return dispatch => {
    dispatch(changePatientLPNState(lpn));
  };
}

function changePatientLPNState(lpn) {
  return {
    type: TYPES.PATIENT_LPN,
    text: lpn
  };
}

export function changePatientSiteName(siteName) {
  return dispatch => {
    dispatch(changePatientSiteNameState(siteName));
  };
}

function changePatientSiteNameState(siteName) {
  return {
    type: TYPES.PATIENT_SITE_NAME,
    text: siteName
  };
}
