import React, { Component } from "react";
import { connect } from "react-redux";
import "./FAQ.css";
class PurchaseOrder extends Component {
  componentDidMount() {
    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.height = "100%";
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    document.getElementById("root").style.height = "100%";
    document.getElementById("root-div").style.height = "100%";
    document.getElementsByClassName("App")[0].style.height = "100%";
  }
  componentWillUnmount() {
    document.documentElement.style.overflow = "auto";
    document.documentElement.style.height = "auto";
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    document.getElementById("root").style.height = "auto";
    document.getElementById("root-div").style.height = "auto";
    document.getElementsByClassName("App")[0].style.height = "auto";
  }
  render() {
    return (
      <div className="iframe-container" style={{ marginTop: 50, fontSize: 18 }}>
        {/*<iframe*/}
        {/*  id="iframeFaq"*/}
        {/*  title="study"*/}
        {/*  src="https://docs.google.com/forms/d/e/1FAIpQLSdQ91UvFW1pES-oPEfhxqLFXV9Axpovq1NkAsHhwpXU-yGpbQ/viewform?embedded=true"*/}
        {/*  width="100%"*/}
        {/*  height="100%"*/}
        {/*></iframe>*/}
        To order Siren Boxes, please email{" "}
        <a href="mailto:Clinic@Siren.care?subject=Order Siren Boxes">
          Clinic@Siren.care
        </a>{" "}
        or email your{" "}
        <a href="mailto:?subject=Order Siren Boxes">
          Area Sales Manager
        </a>
        . <br />
        Please include the <p1 style={{ fontWeight: "bold" }}>
          Quantity
        </p1>, <p1 style={{ fontWeight: "bold" }}>Size</p1> and{" "}
        <p1 style={{ fontWeight: "bold" }}>Color</p1> of your Purchase Order
        Request.
      </div>
    );
  }
}
const mapStateToProp = state => ({});

const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(PurchaseOrder);
