import _ from "lodash";
import moment from "moment-timezone";

export function compareValue(a, b) {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
}

export function getSockRegisterDay(socks) {
  socks.sort(function(a, b) {
    return (a.lastSeen === null) - (b.lastSeen === null);
  });
  socks.reverse();
  return _.isEmpty(socks[0].createdAt)
    ? ""
    : moment(socks[0].createdAt).format("YYYY-MM-DD");
}
