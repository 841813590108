import Amplify, { Auth } from "aws-amplify";
import { Layout } from "antd";
import { withAuthenticator } from "aws-amplify-react";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-keeper";
import { connect } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Divider, Dropdown, Image, Menu } from "semantic-ui-react";
import packageJson from "../../package.json";
import { changeTempUnit, updateUserNameState } from "../action/chartAction";
import { updateDoctorLastLogin } from "../action/registerAction";
import {
  getDoctorProfile,
  listICD10Codes,
  listMedicalConditions
} from "../action/userGroupAction";
import aws_exports from "../aws-exports-siren-medical";
import logo from "../logo.png";
import AllPatients from "../MedicalDashboard/account/AllPatients";
import deletePatients from "../MedicalDashboard/account/deletePatients";
// import NewAccount from "../MedicalDashboard/account/NewAccount";
import ProfileSetting from "../MedicalDashboard/account/ProfileSetting";
import FAQ from "../MedicalDashboard/home/FAQ";
import PatientsHome from "../MedicalDashboard/home/Patients";
import PurchaseOrder from "../MedicalDashboard/home/PurchaseOrder";
import StaffMedicalDetails from "../MedicalDashboard/medicaldetails/StaffMedicalDetails";
import Billed99453 from "../report/Billed99453";
import ManagementSummary from "../report/ManagementSummary";
import MonitoringNeedsReview from "../report/ReportNeedsReview99457_8";
import MonitoringReport from "../report/ReportCurrentMonth99457_8";
import MonitoringReviewed from "../report/ReportReviewed99457_8";
import RecordingNeedsReview from "../report/ReportNeedsReview99453_4";
import RecordingReport from "../report/ReportCurrentMonth99453_4";
import RecordingReviewed from "../report/ReportReviewed99453_4";
import RPM99453Report from "../report/Unbilled99453";
import UserReportTable from "../report/userReportTable";
import sockstatus from "../sock-status/sockstatus";
import StaffList from "../staff/StaffList";
import PCMReport from "../report/ReportCurrentMonth99426_7";
import PCMReviewed from "../report/ReportReviewed99426_7";
import PCMNeedsReview from "../report/ReportNeedsReview99426_7";
import "./App.css";

const { Header, Content, Footer } = Layout;

const version = packageJson.version;
const versionDate = packageJson.version_date;
Amplify.configure(aws_exports);
Amplify.configure({
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken()
      };
    }
  }
});

class App extends Component {
  async onLogoutClick() {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (e) {
      alert("SignOut error: " + e);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      userGroup: [],
      isAdministrator: false
    };
  }

  componentDidMount() {
    this.props.initialICD10Codes();
    this.props.initialMedicalConditions();

    const { authData } = this.props;

    const sessionPayload = authData.signInUserSession.idToken.payload;
    const groups = sessionPayload["cognito:groups"];
    const userName = sessionPayload["cognito:username"];
    this.props.updateUserName(userName);
    this.setState({ userGroup: groups });

    if (authData.attributes["custom:temp_unit"]) {
      this.props.changeTempUnit(authData.attributes["custom:temp_unit"]);
    }
    if (authData.attributes["custom:medical_admin"]) {
      this.setState({
        isAdministrator: authData.attributes["custom:medical_admin"] === "Yes"
      });
    }

    let staffInput = {
      input: {
        email: authData.username,
        lastLogin: moment().format("YYYY-MM-DDTHH:mm:ss")
      }
    };
    this.props.updateDoctorLastLogin(staffInput);

    this.props.getDoctorProfile();
  }

  renderNavigationBar() {
    return (
      <Menu fixed="top">
        <Menu.Item as="a" header href="/#/">
          <Image size="mini" src={logo} style={{ marginRight: "1.5em" }} />
          SirenCare
        </Menu.Item>
        <Menu.Item as="a" href="/#/">
          Patients
        </Menu.Item>
        <Dropdown item simple text="New Patient">
          <Dropdown.Menu>
            {/*<Dropdown.Item as="a" href="/#/NewAccount">*/}
            {/*  Create New Patient*/}
            {/*</Dropdown.Item>*/}
            <Dropdown.Item as="a" href="/#/AllPatients">
              Account Activation Status
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/deletePatients">
              Deleted Patients
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {this.state.isAdministrator ? (
          <Menu.Item as="a" href="/#/StaffList">
            Staff Management
          </Menu.Item>
        ) : null}
        <Menu.Item as="a" href="/#/PurchaseOrder" style={{ display: "none" }}>
          Purchase Order
        </Menu.Item>
        <Dropdown item simple text="Reports">
          <Dropdown.Menu>
            <Dropdown.Item as="a" href="/#/ManagementSummary">
              Management Summary
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/RecordingNeedsReview">
              RPM: 99453 & 99454
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/MonitoringNeedsReview">
              RPM: 99457 & 99458
            </Dropdown.Item>
            <Dropdown.Item as="a" href="/#/PCMNeedsReview">
              PCM: 99426 & 99427
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item as="a" href="/#/FAQ" style={{ display: "none" }}>
          FAQ
        </Menu.Item>
        {/* <Menu.Item as="div"> */}
        {/*<div>Site : {this.props.profile.siteId}</div>*/}
        {/*<div>*/}
        {/*  <ProfileSetting />*/}
        {/*</div>*/}
        {/*</Menu.Item>*/}
        <Menu.Item as="div" style={{ display: "block" }} position="right">
          <div>Version : {version}</div>
          <div>Build Date : {versionDate}</div>
        </Menu.Item>
        <Menu.Menu position="right" id="deleteLeft">
          <Dropdown
            text={this.props.authData.username}
            pointing
            className="link item"
          >
            <Dropdown.Menu>
              {/*<Dropdown*/}
              {/*  direction="left"*/}
              {/*  text="Setting"*/}
              {/*  pointing="left"*/}
              {/*  className="link item"*/}
              {/*>*/}
              {/*  <Dropdown.Menu>*/}
              {/*    <Dropdown.Item onClick={this.fahrenheitChanged.bind(this)}>*/}
              {/*      Fahrenheit*/}
              {/*    </Dropdown.Item>*/}
              {/*    <Divider />*/}
              {/*    <Dropdown.Item onClick={this.celsiusChanged.bind(this)}>*/}
              {/*      Celsius*/}
              {/*    </Dropdown.Item>*/}
              {/*  </Dropdown.Menu>*/}
              {/*</Dropdown>*/}
              <Dropdown.Item>
                <ProfileSetting />
              </Dropdown.Item>
              <Dropdown.Item onClick={this.onLogoutClick.bind(this)}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }

  render() {
    if (!this.props.profile.siteId) {
      return <div></div>;
    }
    return (
      <Layout style={{ backgroundColor: "white" }}>
        <Header
          style={{
            position: "fixed",
            width: "100%",
            height: 54,
            zIndex: 100,
            background: "#fff",
            padding: 0
          }}
        >
          {this.renderNavigationBar()}
        </Header>
        <Content style={{ backgroundColor: "white", marginTop: 64 }}>
          <div
            style={{
              background: "#fff",
              padding: 24,
              paddingTop: 0,
              minHeight: 280
            }}
            className="App"
          >
            <Route
              miss
              index
              cache="parent"
              path="/"
              component={PatientsHome}
            />
            <Route
              path="/StaffMedicalDetails"
              component={StaffMedicalDetails}
            />
            {/* <Route path="/NewAccount" component={NewAccount} /> */}
            <Route path="/StaffList" component={StaffList} />
            <Route path="/FAQ" component={FAQ} />
            <Route path="/PurchaseOrder" component={PurchaseOrder} />
            <Route cache="parent" path="/AllPatients" component={AllPatients} />
            <Route
              cache="parent"
              path="/deletePatients"
              component={deletePatients}
            />
            <Route path="/ManagementSummary" component={ManagementSummary} />
            <Route cache path="/RPM99453Report" component={RPM99453Report} />
            <Route path="/Billed99453" component={Billed99453} />
            <Route cache path="/RecordingNeedsReview" component={RecordingNeedsReview} />
            <Route cache path="/RecordingReviewed" component={RecordingReviewed} />
            <Route cache path="/RecordingReport" component={RecordingReport} />
            <Route cache path="/MonitoringNeedsReview" component={MonitoringNeedsReview} />
            <Route cache path="/MonitoringReviewed" component={MonitoringReviewed} />
            <Route cache path="/MonitoringReport" component={MonitoringReport} />
            <Route cache path="/PCMReport" component={PCMReport} />
            <Route cache path="/PCMNeedsReview" component={PCMNeedsReview} />
            <Route cache path="/PCMReviewed" component={PCMReviewed} />
            <Route path="/UserReportTable" component={UserReportTable} />
            <Route path="/sockstatus" component={sockstatus} />
          </div>
        </Content>
        <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
          <img src={logo} style={{ height: 40 }} /> © {moment().format("YYYY")}{" "}
          Siren Care | All rights reserved
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProp = state => ({
  profile: state.authGroupStore.profile
});
const mapDispatchToProp = dispatch => ({
  updateDoctorLastLogin: staffInput =>
    dispatch(updateDoctorLastLogin(staffInput)),
  changeTempUnit: tempUnit => dispatch(changeTempUnit(tempUnit)),
  updateUserName: userName => dispatch(updateUserNameState(userName)),
  getDoctorProfile: () => dispatch(getDoctorProfile()),
  initialICD10Codes: () => dispatch(listICD10Codes),
  initialMedicalConditions: () => dispatch(listMedicalConditions)
});

export default withAuthenticator(
  connect(mapStateToProp, mapDispatchToProp)(App)
);
