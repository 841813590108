import { Select } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import "react-dropdown/style.css";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { getDeletePatientsList } from "../../action/patientAction";
import { getAllListClinicSites } from "../../action/registerAction";
import "../../home/DeviceList.css";
import Progress from "../../home/progress";
import { formatNumber } from "../../tools/phoneFormat";
import RecoveryPatient from "./recoveryPatient";
Modal.setAppElement("div");
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
class deletePatients extends Component {
  constructor(props) {
    super(props);
    this.needs = [];
    this.state = {
      modalIsOpen: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount() {
    const { getDeletePatientsList, getlistClinicSites } = this.props;
    getDeletePatientsList();
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }
  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  render() {
    const { Option } = Select;
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <Progress />
        </Modal>
        <ReactTable
          id="deletedPatientsTable"
          data={this.props.delPatients.map(p => {
            const phone = !_.isEmpty(p.phone)
              ? formatNumber(p.phone).trim()
              : "";
            const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
            const initializedDate = p.initializedDate
              ? p.initializedDate.split("T")[0]
              : "";
            const initialized =
              p.initialized === true
                ? "Yes"
                : p.initialized === false
                ? "No"
                : p.initialized === null
                ? "No"
                : "";
            const initializedStr = p.appInitialized ? p.appInitialized : "No";
            const contactName = p.contactName === null ? "" : p.contactName;
            const contactPhone = _.isEmpty(p.contactPhone)
              ? ""
              : formatNumber(p.contactPhone).trim();
            const email = p.email && p.email.includes("@") ? p.email : "";
            const primaryKey = p.id;
            const dispensed =
              p.sockStatus ||
              p.socks.length > 0 ||
              (p.dispenseType && p.dispenseType === "CLINIC_DISPENSED") ||
              (p.dispenseType &&
                p.dispenseType === "SIREN_SHIPPED" &&
                p.shipmentStatus &&
                (p.shipmentStatus === "In Transit" ||
                  p.shipmentStatus === "Delivered"))
                ? "Yes"
                : "No";
            const sirenId = p.sirenId ? p.sirenId : "";
            const siteInfo = _.find(this.props.clinicSites, {
              siteId: p.siteId
            });
            const siteName = siteInfo === undefined ? "" : siteInfo.name;
            const deletedAt = p.deletedAt
              ? moment.tz(p.deletedAt, p.userTimezone).format("YYYY-MM-DD")
              : "";
            return {
              ...p,
              phone,
              createdAt,
              initializedDate,
              initialized,
              deletedAt,
              initializedStr,
              contactName,
              contactPhone,
              email,
              primaryKey,
              dispensed,
              sirenId,
              siteName
            };
          })}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "SiteId",
              accessor: "siteId",
              maxWidth: 70,
              filterMethod: (filter, row) => {
                return row[filter.id] && row[filter.id].includes(filter.value);
              }
            },
            {
              Header: "SiteName",
              accessor: "siteName",
              filterMethod: (filter, row) => {
                return (
                  row[filter.id] &&
                  row[filter.id]
                    .toLowerCase()
                    .includes(filter.value.toLowerCase())
                );
              }
            },
            {
              Header: "SirenId",
              accessor: "sirenId",
              maxWidth: 70,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "First Name",
              accessor: "firstName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.firstName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.lastName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Phone",
              accessor: "phone",
              filterMethod: (filter, row) => {
                let noSym1 = row[filter.id].replace("(", "");
                let noSym2 = noSym1.replace(")", "");
                let noSym3 = noSym2.replace("-", "");
                let noBlank = noSym3.replace(/\s+/g, "");
                return String(noBlank).includes(filter.value);
              }
            },
            {
              Header: "Email",
              accessor: "email",
              minWidth: 120,
              filterMethod: (filter, row) =>
                row._original.email && row[filter.id].includes(filter.value)
            },
            {
              Header: "Account Created",
              accessor: "createdAt",
              filterMethod: (filter, row) => {
                return row[filter.id] && row[filter.id].includes(filter.value);
              }
            },
            {
              Header: "Socks Dispensed",
              accessor: "dispensed",
              sortable: false,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id] === "Yes";
                }
                return row[filter.id] === "No";
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "App Activated",
              accessor: "initializedStr",
              maxWidth: 72,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id] === "Yes";
                }
                return row[filter.id] === "No";
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Deleted On",
              accessor: "deletedAt",
              filterMethod: (filter, row) => {
                return row[filter.id] && row[filter.id].includes(filter.value);
              }
            },
            {
              Header: "Deleted By",
              accessor: "deletedBy",
              minWidth: 180,
              filterMethod: (filter, row) =>
                row._original.deletedBy && row[filter.id].includes(filter.value)
            },
            {
              Header: "Actions",
              sortable: false,
              filterable: false,
              minWidth: 100,
              Cell: props => {
                return (
                  <div>
                    <RecoveryPatient
                      id={props.original.primaryKey}
                      userInfo={props.original}
                    />
                  </div>
                );
              }
            }
          ]}
          defaultSorted={[{ id: "createdAt", desc: true }]}
          defaultFiltered={[
            {
              id: "initialized",
              value: "No"
            }
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
          showPaginationTop={true}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  delPatients: state.patientsListStore.delPatients,
  clinicSites: state.patientsListStore.allClinicSites
});
const mapDispatchToProp = dispatch => ({
  getDeletePatientsList: () => dispatch(getDeletePatientsList),
  getlistClinicSites: () => dispatch(getAllListClinicSites())
});
export default connect(mapStateToProp, mapDispatchToProp)(deletePatients);
