import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";

/**
 *  Created by shengli.zhang on 2020-07-08 10:19
 */

export function onSummaryFilterChange(summaryFilter, self) {
  return dispatch => {
    let summaryFilterPatients = _.clone(self.props.summaryList);
    if (summaryFilter.indexOf("New Patients") < 0 && summaryFilter.length > 0) {
      if (summaryFilter.length === 1) {
        if (summaryFilter.indexOf("Accounts Activated") >= 0) {
          summaryFilterPatients = _.filter(summaryFilterPatients, {
            initializedStr: "Yes"
          });
        }
        if (summaryFilter.indexOf("Socks Dispensed") >= 0) {
          summaryFilterPatients = _.filter(summaryFilterPatients, {
            dispensed: "Yes"
          });
        }
        if (summaryFilter.indexOf("Registered") >= 0) {
          summaryFilterPatients = _.filter(summaryFilterPatients, function(t) {
            return t.socks.length > 0;
          });
        }
      } else if (summaryFilter.length === 3) {
        summaryFilterPatients = _.filter(summaryFilterPatients, function(t) {
          return (
            t.initializedStr === "Yes" ||
            t.dispensed === "Yes" ||
            t.socks.length > 0
          );
        });
      } else {
        if (
          summaryFilter.indexOf("Accounts Activated") >= 0 &&
          summaryFilter.indexOf("Socks Dispensed") >= 0
        ) {
          summaryFilterPatients = _.filter(summaryFilterPatients, function(t) {
            return t.initializedStr === "Yes" || t.dispensed === "Yes";
          });
        } else if (
          summaryFilter.indexOf("Accounts Activated") >= 0 &&
          summaryFilter.indexOf("Registered") >= 0
        ) {
          summaryFilterPatients = _.filter(summaryFilterPatients, function(t) {
            return t.initializedStr === "Yes" || t.socks.length > 0;
          });
        } else {
          summaryFilterPatients = _.filter(summaryFilterPatients, function(t) {
            return t.dispensed === "Yes" || t.socks.length > 0;
          });
        }
      }
    }
    dispatch(changeSummaryFilterState(summaryFilterPatients));
  };
}

export function getManagementSummary(filters, self, summaryFilter) {
  self.setState({ ifOpen: false, modalIsOpen: true });
  return async dispatch => {
    try {
      let filter = {
        group: {
          ne: "TRIAL_SILENCE"
        },
        accountStatus: {
          ne: "DELETED"
        }
      };
      if (!_.isEmpty(filters)) {
        if (
          filters.hasOwnProperty("startDate") &&
          filters.hasOwnProperty("endDate")
        ) {
          Object.assign(filter, {
            createdAt: { range: [filters.startDate, filters.endDate] }
          });
        } else if (filters.hasOwnProperty("startDate")) {
          Object.assign(filter, {
            createdAt: { gte: filters.startDate }
          });
        } else if (filters.hasOwnProperty("endDate")) {
          Object.assign(filter, {
            createdAt: { lte: filters.endDate }
          });
        }
        if (filters.hasOwnProperty("sites")) {
          const siteIds = filters.sites.map(t => {
            return { siteId: { eq: t } };
          });
          Object.assign(filter, { or: siteIds });
        }
        if (filters.hasOwnProperty("doctorIds")) {
          const doctors = filters.doctorIds.map(t => {
            return { doctorId: { eq: t } };
          });
          if (filter.hasOwnProperty("or")) {
            Object.assign(filter, { and: [{ or: doctors }] });
          } else {
            Object.assign(filter, { or: doctors });
          }
        }
      }
      const newEvent = await API.graphql(
        graphqlOperation(_api.listManagementSummary(), {
          // searchStr: "",
          filter,
          limit: 1000
        })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        const patients = _.clone(newEvent.data.lambdaSearchPatients.items);
        const realpatients = _.filter(patients, function(item) {
          return (
            !_.isEmpty(item.userId) &&
            !_.isEmpty(String(item.sirenId)) &&
            item.sirenId !== undefined &&
            item.sirenId !== null &&
            item.userId !== undefined &&
            item.userId !== null &&
            !item.siteId.startsWith("US") &&
            !item.siteId.startsWith("C") &&
            item.siteId !== "INACTIVE" &&
            item.siteId !== "TRIAL_SILENCE"
          );
        });
        const registeredCount = _.filter(realpatients, function(t) {
          return t.socks.length > 0;
        }).length;
        const overallCount = realpatients.length;
        const newPatientCount = realpatients.length;
        const initializedCount = _.filter(realpatients, {
          initializedStr: "Yes"
        }).length;
        const dispensedCount = _.filter(realpatients, { dispensed: "Yes" })
          .length;
        self.setState({
          ids: [],
          newPatientCount,
          registeredCount,
          initializedCount,
          dispensedCount
        });
        if (self.state.selectedSites.length === self.props.siteId.length) {
          self.setState({
            overallCount
          });
        }

        dispatch(changeSummaryState(realpatients));
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("getManagementSummary error: ", e);
    }
  };
}

export function resetSummaryList() {
  return dispatch => {
    dispatch(changeSummaryState([]));
  };
}

function changeSummaryState(summaryList) {
  return {
    type: TYPES.SUMMARY_LIST,
    text: summaryList
  };
}

function changeSummaryFilterState(summaryFilterList) {
  return {
    type: TYPES.SUMMARY_LIST_FILTER,
    text: summaryFilterList
  };
}
