import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import AWS from "aws-sdk";
import _ from "lodash";
import moment from "moment";
import _api from "../graphql/_api";
import { compareValue } from "../tools/compare";
import * as TYPES from "../types/types";
export function getGroups() {
  return async dispatch => {
    const newEvent = await API.graphql(graphqlOperation(_api.listUserGroups()));
    if (newEvent) {
      let groupArray = _.clone(newEvent.data.listUserGroups);
      let groupArraySort = [];
      _.forEach(groupArray, function(items, i) {
        if (items === "TRIAL_SILENCE") {
          groupArray.splice(i, 1);
        }
      });
      groupArraySort = groupArray.sort(compareValue);
      dispatch(changeGroups(groupArraySort));
    }
  };
}
export function changeGroups(filterGroup) {
  return {
    type: TYPES.FILTER_GROUP,
    text: filterGroup
  };
}

export async function getPatientList(filter, sort, pagination = {
  page: 0,
  size: 20
}) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      initializedStr: {
        eq: "Yes"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );

  return await API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export async function getResolvedList(dispatch) {
  let sort = {
    field: "sirenId",
    direction: "asc"
  };
  let newEvent = await API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      // searchStr: "",
      filter: {
        category: {
          eq: "Medical Review"
        },
        group: {
          ne: "TRIAL_SILENCE"
        },
        initializedStr: {
          eq: "Yes"
        },
        accountStatus: {
          ne: "DELETED"
        }
        // and: [
        //   {
        //     accountStatus: {
        //       ne: "PAUSED"
        //     }
        //   }
        // ]
      },
      sort,
      limit: 1000
    })
  );

  if (newEvent.data.lambdaSearchPatients.items.length === 0) {
    dispatch(changeIsZeroState(true));
  }
  dispatch(changeResolvedList(newEvent.data.lambdaSearchPatients.items));
}

export function changeResolvedList(resolvedList) {
  return {
    type: TYPES.RESOLVED_LIST,
    text: resolvedList
  };
}
export async function getAppointmentList(dispatch) {
  let newEvent = await API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      // searchStr: "",
      filter: {
        appointmented: {
          eq: true
        },
        and: [
          {
            scheduled: {
              ne: true
            },
            group: {
              ne: "TRIAL_SILENCE"
            },
            initializedStr: {
              eq: "Yes"
            },
            accountStatus: {
              ne: "DELETED"
            }
          }
        ]
      },
      limit: 1000
    })
  );

  if (newEvent.data.lambdaSearchPatients.items.length === 0) {
    dispatch(changeIsZeroState(true));
  }
  dispatch(changeAppointmentList(newEvent.data.lambdaSearchPatients.items));
}
export function changeAppointmentList(appointmentList) {
  return {
    type: TYPES.APPOINTMENT_LIST,
    text: appointmentList
  };
}
export async function getComplianceList(dispatch) {
  let newEvent = await API.graphql(
    graphqlOperation(_api.searchPatientsConnection(), {
      // searchStr: "",
      filter: {
        category: {
          eq: "Compliance"
        },
        group: {
          ne: "TRIAL_SILENCE"
        },
        initializedStr: {
          eq: "Yes"
        },
        accountStatus: {
          ne: "DELETED"
        },
        and: [
          {
            rpmDeviceStatus: {
              ne: "PAUSED"
            }
          }
        ]
      },
      limit: 1000
    })
  );

  if (newEvent.data.lambdaSearchPatients.items.length === 0) {
    dispatch(changeIsZeroState(true));
  }
  dispatch(changeComplianceList(newEvent.data.lambdaSearchPatients.items));
}
export function changeComplianceList(complianceList) {
  return {
    type: TYPES.COMPLIANCE_LIST,
    text: complianceList
  };
}
function changeIsZeroState(isZero) {
  return {
    type: TYPES.IS_ZERO,
    text: isZero
  };
}

function titleCase(str) {
  if (str === null) {
    return "";
  } else {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
}
export function getDoctorDetails(id, userid, start, end) {
  return async dispatch => {
    dispatch(changeDoctorDetails({}));
    dispatch(changeSymtoms({}));
    // dispatch(changeFootStatus({}));
    let patientSymptoms = [];
    let patientSymptomsApp = [];
    const patient = await API.graphql(
      graphqlOperation(_api.docDetail(), { id, userid, start, end })
    );

    let obj = {};
    obj.userId = patient.data.getPatient.userId;
    obj.timestamp = moment(patient.data.getPatient.createdAt).format(
      "YYYY-MM-DD"
    );
    obj.author = patient.data.getPatient.doctorId;
    obj.title = "Patient Intake Details";
    obj.note = "Patient Intake Details";
    dispatch(changeInitializedNote([obj]));
    const newPatientInfo = _.clone(patient.data.getPatient);
    newPatientInfo.sex = titleCase(newPatientInfo.sex);
    const afterReplace = newPatientInfo;
    dispatch(changeDoctorDetails(afterReplace));
    //symptoms
    let footSymptomByDMT = _.filter(
      patient.data.getPatient.footSymptoms,
      function(item) {
        return item.source === "DMT";
      }
    );
    let footSymptomByApp = _.filter(
      patient.data.getPatient.footSymptoms,
      function(item) {
        return item.source !== "DMT";
      }
    );
    _(footSymptomByDMT)
      .groupBy(item => {
        return _.split(item.reportTime, "T")[0];
      })
      .flatMap((value, key) => {
        let symptomType = undefined;
        const symptomTypes = value.map(itemSub => {
          if (!itemSub.symptomType) {
            symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
            return symptomType;
          } else {
            symptomType = caseConversion(itemSub.symptomType);
            return symptomType;
          }
        });
        patientSymptoms.push({
          reportTime: key,
          symptomType: symptomTypes
            .map(p => {
              return p;
            })
            .join(", "),
          source: "DMT"
        });
      })
      .uniq()
      .value();

    _(footSymptomByApp)
      .groupBy(item => {
        return _.split(item.reportTime, "T")[0];
      })
      .flatMap((value, key) => {
        let symptomType = undefined;
        const symptomTypes = value.map(itemSub => {
          if (!itemSub.symptomType) {
            symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
            return symptomType;
          } else {
            symptomType = caseConversion(itemSub.symptomType);
            return symptomType;
          }
        });
        patientSymptomsApp.push({
          reportTime: key,
          symptomType: symptomTypes
            .map(p => {
              return p;
            })
            .join(", "),
          source: "APP"
        });
      })
      .uniq()
      .value();
    const symptoms = _.orderBy(
      [...patientSymptoms, ...patientSymptomsApp],
      ["reportTime"],
      ["desc"]
    );
    dispatch(changeSymtoms({ footSymptoms: symptoms }));
  };
}

export function changeDoctorDetails(doctorDetails) {
  return {
    type: TYPES.DOCTOR_DETAILS,
    text: doctorDetails
  };
}
export function changeInitializedNote(initializedNote) {
  return {
    type: TYPES.INITIALIZED_NOTE,
    text: initializedNote
  };
}
export function getSymtoms(userid, start, end) {
  return async dispatch => {
    try {
      dispatch(changeSymtoms({}));
      let patientSymptoms = [];
      dispatch(changeSymtoms({ footSymptoms: patientSymptoms }));
      const patient = await API.graphql(
        graphqlOperation(_api.getSyms(), { userid, start, end })
      );
      if (patient) {
        _(patient.data.getPatient.footSymptoms)
          .groupBy(item => {
            return _.split(item.reportTime, "T")[0];
            // return item.reportTime;
          })
          .flatMap((value, key) => {
            let symptomType = "";
            value.map(itemSub => {
              if (symptomType === "") {
                symptomType = caseConversion(itemSub.symptomType);
                return symptomType;
              } else {
                symptomType =
                  symptomType + ", " + caseConversion(itemSub.symptomType);
                return symptomType;
              }
            });
            patientSymptoms.push({ reportTime: key, symptomType });
          })
          .uniq()
          .value();
        dispatch(changeSymtoms({ footSymptoms: patientSymptoms }));
      }
    } catch (err) {
      console.log("getSymtoms error: ", err);
    }
  };
}

export function caseConversion(patient) {
  if (patient === "BURN") {
    return "Burn";
  } else if (patient === "BLISTER") {
    return "Blister";
  } else if (patient === "BRUISING") {
    return "Bruising";
  } else if (patient === "BUG_BITE") {
    return "Bug bite";
  } else if (patient === "CALLOUS") {
    return "Callous";
  } else if (patient === "LOSS_OF_SENSATION") {
    return "Loss of sensation";
  } else if (patient === "NUMBNESS") {
    return "Numbness";
  } else if (patient === "OPEN_WOUND") {
    return "Open wound";
  } else if (patient === "REDNESS") {
    return "Redness";
  } else if (patient === "SWELLING") {
    return "Swelling";
  } else if (patient === "INCREASED_PAIN") {
    return "Increased Pain";
  } else if (patient === "INGROWN_TOENAILS") {
    return "Ingrown toenails";
  } else if (patient === "COLOR_TOENAILS") {
    return "Color changes with toenails";
  }
}

export function changeSymtoms(symtomDatas) {
  return {
    type: TYPES.FOOT_SYMTOMS,
    text: symtomDatas
  };
}

//getFootStatus: change foot status
export function getFootStatus(id, userid, start, end) {
  return async dispatch => {
    dispatch(changeFootStatus({}));
    const patient = await API.graphql(
      graphqlOperation(_api.getFoot(), { id, userid, start, end })
    );
      dispatch(changeFootStatus(patient.data.getPatient));
      let footStatus = {}, footOverview = {};
      if (
        patient.data.getPatient.footStatuses &&
        patient.data.getPatient.footStatuses.length > 0
      ) {
        footStatus =
          patient.data.getPatient.footStatuses[
            patient.data.getPatient.footStatuses.length - 1
          ];
      }
      if (patient.data.getPatient.footOverview) {
        footOverview = patient.data.getPatient.footOverview
      }
      dispatch(changeFootStatus(footStatus, footOverview));
  };
}

function changeFootStatus(footStatus, footOverview) {
  return {
    type: TYPES.FOOT_STATUS,
    text: footStatus,
    footOverview
  };
}

export function getImage(userid, self) {
  return async dispatch => {
    AWS.config.credentials = Auth.essentialCredentials(
      await Auth.currentCredentials()
    );
    try {
      Storage.list("photoFoot/", {
        identityId: userid,
        level: "private"
      })
        .then(result => {
          // let currentLeft = null;
          // let currentRight = null;
          let historyLeft = null;
          let historyRight = null;
          let history = [];
          let historyDate = [];
          result
            .reverse()
            .slice(0, 10)
            .map(item => {
              Storage.get(item.key, {
                identityId: userid,
                level: "private"
              }).then(result => {
                const key = item.key.split("/");
                const footdire = item.key.split("/")[2].split(".jpg")[0];
                let obj = {
                  date: key[1],
                  uri: result,
                  foot: footdire
                };
                if (historyDate.indexOf(key[1]) < 0) {
                  if (item.key.includes("left")) {
                    historyLeft = obj;
                    history.push({ left: historyLeft });
                    historyDate.push(key[1]);
                    self.setState({ images: history });
                  } else {
                    historyRight = obj;
                    history.push({ right: historyRight });
                    historyDate.push(key[1]);
                    self.setState({ images: history });
                  }
                } else {
                  if (item.key.includes("left")) {
                    historyLeft = obj;
                    history[history.length - 1].left = historyLeft;
                    self.setState({ images: history });
                  } else {
                    historyRight = obj;
                    history[history.length - 1].right = historyRight;
                    self.setState({ images: history });
                  }
                }
              });
              return true;
            });
        })
        .catch(err => console.log(err));
    } catch (err) {
      console.log("getImage error: ", err);
    }
  };
}

export function comFootHealth(footHealth, dispatch) {
  var pastSymp = [];
  var past1Symp = [];
  var currentSymp = [];
  var footSymptoms = [];
  var foot1Symptoms = [];
  var foot2Symptoms = [];
  var foot3Symptoms = [];
  var maxLength = 0;
  if (footHealth.hasOwnProperty("pastSymptoms")) {
    var pastSymptomsObj = JSON.parse(footHealth.pastSymptoms);
    pastSymp = pastSymptomsSelect(pastSymptomsObj);
  }
  if (footHealth.hasOwnProperty("past1yearSymptoms")) {
    var past1yearSymptomsObj = JSON.parse(footHealth.past1yearSymptoms);
    past1Symp = past1yearSymptomsSelect(past1yearSymptomsObj);
  }
  if (footHealth.hasOwnProperty("currentSymptoms")) {
    var currentSymptomsObj = JSON.parse(footHealth.currentSymptoms);
    currentSymp = currentSymptomsSelect(currentSymptomsObj);
  }
  if (pastSymp.length > maxLength) {
    maxLength = pastSymp.length;
  }
  if (past1Symp.length > maxLength) {
    maxLength = past1Symp.length;
  }
  if (currentSymp.length > maxLength) {
    maxLength = currentSymp.length;
  }
  for (var i = 0; i < maxLength; i++) {
    if (pastSymp.length > i) {
      foot1Symptoms.push({ pastSymptoms: pastSymp[i] });
    } else {
      foot1Symptoms.push({ pastSymptoms: "" });
    }
    if (past1Symp.length > i) {
      foot2Symptoms.push({ past1Symptoms: past1Symp[i] });
    } else {
      foot2Symptoms.push({ past1Symptoms: "" });
    }
    if (currentSymp.length > i) {
      foot3Symptoms.push({ currentSymp: currentSymp[i] });
    } else {
      foot3Symptoms.push({ currentSymp: "" });
    }
  }

  for (var k = 0; k < maxLength; k++) {
    let obj = {};
    obj.pastSymptoms = foot1Symptoms[k];
    obj.past1Symptoms = foot2Symptoms[k];
    obj.currentSymp = foot3Symptoms[k];
    footSymptoms.push(obj);
  }
  if (maxLength === 0) {
    if (footHealth !== null) {
      let obj = {};
      obj.pastSymptoms = {};
      obj.past1Symptoms = {};
      obj.currentSymp = {};
      footSymptoms.push(obj);
    }
  }
  dispatch(changePatientStatusDetail(footSymptoms));
}

function changePatientStatusDetail(footSymptoms) {
  return {
    type: TYPES.PATIENTS_STATUS_DETAIL,
    text: footSymptoms
  };
}

function pastSymptomsSelect(pastSymptoms) {
  var pastSymptomsArr = [];
  if (pastSymptoms != null) {
    if (pastSymptoms.hasOwnProperty("openWound")) {
      pastSymptomsArr.push("open wound");
    }
    if (pastSymptoms.hasOwnProperty("charcot")) {
      pastSymptomsArr.push("charcot");
    }
    if (pastSymptoms.hasOwnProperty("toeAmputation")) {
      pastSymptomsArr.push("toe amputation");
    }
    if (pastSymptoms.hasOwnProperty("partialAmputation")) {
      pastSymptomsArr.push("partial amputation");
    }
    if (pastSymptoms.hasOwnProperty("fullAmputation")) {
      pastSymptomsArr.push("full amputation");
    }
  }
  return pastSymptomsArr;
}

function past1yearSymptomsSelect(past1yearSymptoms) {
  var past1yearSymptomsArr = [];
  if (past1yearSymptoms != null) {
    if (past1yearSymptoms.hasOwnProperty("hammerToe")) {
      past1yearSymptomsArr.push("hammer toe");
    }
    if (past1yearSymptoms.hasOwnProperty("bunion")) {
      past1yearSymptomsArr.push("bunion");
    }
    if (past1yearSymptoms.hasOwnProperty("footOrToeFracture")) {
      past1yearSymptomsArr.push("foot or toe fracture");
    }
  }
  return past1yearSymptomsArr;
}

function currentSymptomsSelect(currentSymptoms) {
  var currentSymptomsArr = [];
  if (currentSymptoms != null) {
    if (currentSymptoms.hasOwnProperty("burn")) {
      currentSymptomsArr.push("burn");
    }
    if (currentSymptoms.hasOwnProperty("blister")) {
      currentSymptomsArr.push("blister");
    }
    if (currentSymptoms.hasOwnProperty("bugBite")) {
      currentSymptomsArr.push("bug bite");
    }
    if (currentSymptoms.hasOwnProperty("callous")) {
      currentSymptomsArr.push("callous");
    }
    if (currentSymptoms.hasOwnProperty("sprain/Strain")) {
      currentSymptomsArr.push("sprain/strain");
    }
  }
  return currentSymptomsArr;
}

export function convertAppointmentType(appointType) {
  if (appointType === "HOSPITAL_VISIT") {
    return "Hospital visit";
  }
  if (appointType === "FOLLOW_UP_APPOINTMENT") {
    return "Follow-up appointment";
  }
  if (appointType === "REGULAR_CHECK_UP") {
    return "Regular check-up";
  }
  if (appointType === "URGENT_CARE_VISIT") {
    return "Urgent care";
  }
  if (appointType === "OTHER") {
    return "Other - open field";
  }
}
//changeCalendarData:Change the background color of the second calendar
export function changeCalendarData(id, userId, startDate, endDate) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.getCalendarData(), {
          id,
          userId,
          startDate,
          endDate,
          limit: 100
        })
      );
      if (newEvent) {
        dispatch(updateCalendarData(newEvent.data.getPatient));
      }
    } catch (err) {
      console.log("changeCalendarData error: ", err);
    }
  };
}

function updateCalendarData(calendardata) {
  return {
    type: TYPES.CALENDAR_DATA,
    text: calendardata
  };
}

export function changeLoadingChartsState(value) {
  return {
    type: TYPES.LOADING_CHARTS,
    text: value
  };
}

export function getPhotoReviews(inputData, self) {
  return async dispatch => {
    try {
      const photoReview = await API.graphql(
        graphqlOperation(_api.getPhotoReview(), inputData)
      );
      if (photoReview) {
        if (
          photoReview.data.getPatient.hasOwnProperty("photoTask")
          // && photoReview.data.getPatient.photoTask.imageUrls !== null
          // &&
          // photoReview.data.getPatient.photoTask.status !== "RESOLVED"
        ) {
          self.setState({
            open: true,
            imageUrls: photoReview.data.getPatient.photoTask.imageUrls,
            taskTime: photoReview.data.getPatient.photoTask.taskTime
          });
        }
      }
    } catch (err) {
      console.log("getPhotoReview error: ", err);
    }
  };
}

export function updatePhotoState(input, self) {
  return async dispatch => {
    try {
      const updatePhotoState = await API.graphql(
        graphqlOperation(_api.updatePhotoStatus(), { input })
      );
      if (updatePhotoState) {
        self.setState({
          open: false,
          photoReviewStatus: self.state.retake ? "RETAKE" : "RESOLVED"
        });
      }
    } catch (err) {
      console.log("updatePhotoState error: ", err);
    }
  };
}

export function getImageFromS3(self, userId) {
  return dispatch => {
    let yDate = new Date();
    yDate.setDate(yDate.getDate() - 1);
    Storage.list("photoFoot/" + moment().format("YYYY-MM-DD"), {
      level: "private",
      identityId: userId
    }).then(res => {
      if (res.length === 0) {
        getYesterdayPhoto(yDate, userId, self);
      } else {
        res.map(item => {
          if (item.key.indexOf("left") >= 0) {
            return getLeftPhoto(item.key, self, userId, new Date());
          } else {
            return getRightPhoto(item.key, self, userId, new Date());
          }
        });
      }
    });
  };
}

function getYesterdayPhoto(dateTime, userId, self) {
  Storage.list("photoFoot/" + moment(dateTime).format("YYYY-MM-DD"), {
    level: "private",
    identityId: userId
  }).then(res => {
    if (res.length > 0) {
      res.map(item => {
        if (item.key.indexOf("left") >= 0) {
          return getLeftPhoto(item.key, self, userId, dateTime);
        } else {
          return getRightPhoto(item.key, self, userId, dateTime);
        }
      });
    }
  });
}

function getLeftPhoto(key, self, userId, dateTime) {
  Storage.get(key, {
    level: "private",
    identityId: userId
  })
    .then(res => {
      self.setState({ leftImageUrl: res });
    })
    .catch(err => {
      console.log("errLeft: ", err);
    });
}

function getRightPhoto(key, self, userId, dateTime) {
  Storage.get(key, {
    level: "private",
    identityId: userId
  }).then(res => {
    self.setState({ rightImageUrl: res });
  });
}

export function changelistClinicSites(clinicSites) {
  return {
    type: TYPES.CLINIC_SITES,
    text: clinicSites
  };
}
//fetchCalendarDate:change foot status time
export function fetchCalendarDate(changeFootDate) {
  return async dispatch => {
    try {
      if (changeFootDate) {
        dispatch(updatechangeFootDate(changeFootDate));
      }
    } catch (err) {
      console.log("fetchCalendarDate error: ", err);
    }
  };
}

function updatechangeFootDate(changeFootDate) {
  return {
    type: TYPES.FOOT_DATA,
    text: changeFootDate
  };
}

export function fetchRangeDate(changeRangeDate) {
  return async dispatch => {
    try {
      if (changeRangeDate) {
        dispatch(updateRangeDate(changeRangeDate));
      }
    } catch (err) {
      console.log("fetchRangeDate error: ", err);
    }
  };
}

function updateRangeDate(changeRangeDate) {
  return {
    type: TYPES.RANGE_DATA,
    text: changeRangeDate
  };
}
//getMinuteWorn:Change the background color of the first calendar
export function getMinuteWorn(id, userid, startDate, endDate) {
  return async dispatch => {
    dispatch(changeMinuteWorn([]));
    const patient = await API.graphql(
      graphqlOperation(_api.PatientMinuteWorn(), {
        id,
        userid,
        startDate,
        endDate
      })
    );
    if (patient) {
      dispatch(changeMinuteWorn(patient.data.getPatient.timeWornByDay));
    }
  };
}
function changeMinuteWorn(patientWorn) {
  return {
    type: TYPES.PATIENT_WORN,
    text: patientWorn
  };
}
export async function getNewPatientsList(filter, sort, pagination = {
  page: 0,
  size: 20
}) {
  let combineFilter = Object.assign(
    {
      group: {
        ne: "TRIAL_SILENCE"
      },
      accountStatus: {
        ne: "DELETED"
      }
    },
    filter
  );
  return await API.graphql(
    graphqlOperation(_api.listRegisteredPatients(), {
      filter: combineFilter,
      sort,
      limit: pagination.size,
      nextToken: pagination.page * pagination.size
    })
  );
}

export async function getDeletePatientsList(dispatch) {
  const newEvent = await API.graphql(
    graphqlOperation(_api.listRegisteredPatients(), {
      // searchStr: "",
      filter: {
        group: {
          ne: "TRIAL_SILENCE"
        },
        accountStatus: {
          eq: "DELETED"
        }
      },
      limit: 1000
    })
  );
  if (newEvent) {
    dispatch(
      changeDeletePatientslListState(newEvent.data.lambdaSearchPatients.items)
    );
  }
}

export function getIntakePatientDetail(self, sirenId) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listRegisteredPatients(), {
          // searchStr: "",
          filter: {
            group: {
              ne: "TRIAL_SILENCE"
            },
            sirenId: { eq: sirenId }
          }
        })
      );
      if (newEvent) {
        dispatch(
          changeIntakePatientDetailState(
            newEvent.data.lambdaSearchPatients.items
          )
        );
      }
    } catch (e) {
      console.log("getIntakePatientDetail error: ", e);
    }
  };
}
export function changeIntakePatientDetailState(intakePatient) {
  return {
    type: TYPES.INTAKE_PATIENT,
    text: intakePatient
  };
}

export function changeDeletePatientslListState(delPatients) {
  return {
    type: TYPES.DELETE_PATIENTS,
    text: delPatients
  };
}

export function getDeleteNewPatients(id, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteRegisteredPatient(), { input: { id } })
      );
      if (newEvent) {
        self.setState({ open: false });
        const tmpSites = _.clone(self.props.delPatients);
        _.remove(tmpSites, {
          id: id
        });
        const itemsAfterDel = tmpSites;
        dispatch(changeDeletePatientslListState(itemsAfterDel));
      }
    } catch (err) {
      console.log("getDeleteNewPatients exception: ", err);
    }
  };
}
export function resetNewPatientPwd(newPwdInput, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.resetPatientPassword(), newPwdInput)
      );
      if (newEvent.data.resetPatientPassword.status) {
        self.setState({ newPwd: "", open: false });
      } else {
        alert(newEvent.data.resetPatientPassword.message);
      }
    } catch (err) {
      console.log("resetNewPatientPwd error: ", err);
    }
  };
}

export async function getFootPhotos(userid) {
  const credentials = await Auth.currentCredentials();
  AWS.config.credentials = Auth.essentialCredentials(credentials);
  // console.log("AWS.config.credentials: ", AWS.config.credentials)

  const photosList = await Storage.list("photoFoot/", {
    identityId: userid,
    level: "private"
  });

  const showPhotosProp = photosList.reverse().slice(0, 10);
  const promises = showPhotosProp.map(p =>
    Storage.get(p.key, {
      identityId: userid,
      level: "private"
    })
  );

  const photos = await Promise.all(promises);

  return photos.map((result, index) => {
    const item = showPhotosProp[index];
    const key = item.key.split("/");
    const footdire = key[key.length - 1].split(".jpg")[0];
    let obj = {
      date:
        footdire === "left" || footdire === "right"
          ? key[1]
          : moment(
              parseInt(footdire.split("-")[footdire.split("-").length - 1])
            ).format("YYYY-MM-DD HH:mm:ss"),
      uri: result,
      foot: footdire
    };

    return obj;
  });
}

export function updateSerialNumber(serialNumber) {
  return dispatch => {
    dispatch(updateSerialNumberStatus(serialNumber));
  };
}

function updateSerialNumberStatus(serialNumber) {
  return {
    type: TYPES.UPDATE_SERIAL_NUMBER,
    text: serialNumber
  };
}

export function updateHubId(hubId) {
  return dispatch => {
    dispatch(updateHubIdStatus(hubId));
  };
}

function updateHubIdStatus(hubId) {
  return {
    type: TYPES.UPDATE_HUB_ID,
    text: hubId
  };
}

export function changeRegisterBoxType(clinicType) {
  return dispatch => {
    dispatch(changeRegisterBoxTypeStatus(clinicType));
  };
}

function changeRegisterBoxTypeStatus(clinicType) {
  return {
    type: TYPES.REGISTER_BOX_TYPE,
    text: clinicType
  };
}

function compareRegistered(a, b) {
  const akey = a.registered + a.boxId;
  const bkey = b.registered + b.boxId;
  if (akey > bkey) return -1;
  if (akey > bkey) return 1;
  return 0;
}

export function getSockHomeStatus(sirenId, self) {
  return async dispatch => {
    try {
      self.setState({ open: true });
      const patient = await API.graphql(
        graphqlOperation(_api.getSockBySirenId(), { sirenId })
      );
      if (patient) {
        self.setState({
          open: false
        });
        patient.data.getPatientBySirenId.socks = patient.data.getPatientBySirenId.socks.sort(
          compareRegistered
        );
        if (
          patient.data.getPatientBySirenId.hasOwnProperty("appInfo") &&
          patient.data.getPatientBySirenId.appInfo.length > 0
        ) {
          patient.data.getPatientBySirenId.appInfo = patient.data.getPatientBySirenId.appInfo.sort(
            function(a, b) {
              return new moment(b.createdAt) - new moment(a.createdAt);
            }
          );
          dispatch(changeSockHomeStatus(patient.data.getPatientBySirenId));
        } else {
          dispatch(changeSockHomeStatus(patient.data.getPatientBySirenId));
        }
      }
    } catch (err) {
      self.setState({ open: false });
      console.log("getSockHomeStatus error: ", err);
    }
  };
}
function changeSockHomeStatus(sockHomeStatus) {
  return {
    type: TYPES.SOCK_HOME_STATUS,
    text: sockHomeStatus
  };
}

export function clearSockHomeStatus() {
  return dispatch => {
    dispatch(changeSockHomeStatus({}));
  };
}
