import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import { changeRenderDate } from "../action/chartAction";
import { getCalenDate } from "../action/passAction";
import {
  changeCalendarData,
  fetchCalendarDate,
  fetchRangeDate,
  getFootStatus,
  getMinuteWorn
} from "../action/patientAction";
import PatientCharts from "../chart/patientCharts";
import "./calendarRange.css";
import "./MedicalDetailsHome.css";
class calendarRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      DateRangeDate: [],
      defaultRange: [],
      ifOpen: this.props.complianceId ? true : false,
      activeYear: "",
      startDate: "",
      activeMonth: "",
      userId: "",
      id: ""
    };
    this.DELAY = 24 * 60 * 60 * 1000;
    this.goPreDay = this.goPreDay.bind(this);
    this.goNextDay = this.goNextDay.bind(this);
  }
  goPreDay() {
    const preDay = [
      new Date(moment(this.state.DateRangeDate[0]).add(-1, "days")),
      new Date(moment(this.state.DateRangeDate[0]).add(-1, "days"))
    ];
    this.setState({ DateRangeDate: preDay });
    const {
      changeRenderDate,
      getCalenDate,
      fetchCalendarDate,
      fetchRangeDate,
      getFootStatus,
      getMinuteWorn
    } = this.props;
    changeRenderDate(this, this.state.id, this.state.userId, preDay);
    const calenDate = [
      moment(this.state.DateRangeDate[0])
        .add(-1, "days")
        .format("YYYY-MM-DD"),
      moment(this.state.DateRangeDate[0])
        .add(-1, "days")
        .format("YYYY-MM-DD")
    ];
    let StartDate = moment(calenDate[0])
      .add(-35, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(calenDate[0])
      .add(35, "days")
      .format("YYYY-MM-DD");
    getMinuteWorn(this.state.id, this.state.userId, StartDate, EndDate);
    getCalenDate(calenDate);
    fetchRangeDate(preDay[1]);
    fetchCalendarDate(preDay[1]);
    getFootStatus(this.state.id, this.state.userId, calenDate[0], calenDate[1]);
    window.location.href.includes("Patients")
      ? Control.go(
          "/StaffMedicalDetails?Patients/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : window.location.href.includes("Appointments")
      ? Control.go(
          "/StaffMedicalDetails?Appointments/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : window.location.href.includes("Compliance")
      ? Control.go(
          "/StaffMedicalDetails?Compliance/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : Control.go(
          "/StaffMedicalDetails?id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        );
  }
  goNextDay() {
    const NextDay = [
      new Date(moment(this.state.DateRangeDate[1]).add(1, "days")),
      new Date(moment(this.state.DateRangeDate[1]).add(1, "days"))
    ];
    this.setState({ DateRangeDate: NextDay });
    const {
      changeRenderDate,
      getCalenDate,
      fetchCalendarDate,
      fetchRangeDate,
      getFootStatus,
      getMinuteWorn
    } = this.props;
    changeRenderDate(this, this.state.id, this.state.userId, NextDay);
    const calenDate = [
      moment(this.state.DateRangeDate[1])
        .add(1, "days")
        .format("YYYY-MM-DD"),
      moment(this.state.DateRangeDate[1])
        .add(1, "days")
        .format("YYYY-MM-DD")
    ];
    let StartDate = moment(calenDate[0])
      .add(-35, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(calenDate[0])
      .add(35, "days")
      .format("YYYY-MM-DD");
    getMinuteWorn(this.state.id, this.state.userId, StartDate, EndDate);
    getCalenDate(calenDate);
    fetchRangeDate(NextDay[1]);
    fetchCalendarDate(NextDay[1]);
    getFootStatus(this.state.id, this.state.userId, calenDate[0], calenDate[1]);
    window.location.href.includes("Patients")
      ? Control.go(
          "/StaffMedicalDetails?Patients/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : window.location.href.includes("Appointments")
      ? Control.go(
          "/StaffMedicalDetails?Appointments/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : window.location.href.includes("Compliance")
      ? Control.go(
          "/StaffMedicalDetails?Compliance/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : Control.go(
          "/StaffMedicalDetails?id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        );
  }
  onChangeDateRange = DateRangeDate => {
    const {
      changeRenderDate,
      getCalenDate,
      fetchCalendarDate,
      fetchRangeDate,
      getFootStatus,
      getMinuteWorn
    } = this.props;
    this.setState({ DateRangeDate });
    this.setState({ ifOpen: true });
    const calenDate = [
      moment(DateRangeDate[0]).format("YYYY-MM-DD"),
      moment(DateRangeDate[1]).format("YYYY-MM-DD")
    ];
    let StartDate = moment(calenDate[0])
      .add(-35, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(calenDate[0])
      .add(35, "days")
      .format("YYYY-MM-DD");
    getMinuteWorn(this.state.id, this.state.userId, StartDate, EndDate);
    changeRenderDate(this, this.state.id, this.state.userId, DateRangeDate);
    getCalenDate(calenDate);
    fetchRangeDate(new Date(DateRangeDate[1]));
    fetchCalendarDate(new Date(DateRangeDate[1]));
    getFootStatus(this.state.id, this.state.userId, calenDate[1], calenDate[1]);
    window.location.href.includes("Patients")
      ? Control.go(
          "/StaffMedicalDetails?Patients/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : window.location.href.includes("Appointments")
      ? Control.go(
          "/StaffMedicalDetails?Appointments/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : window.location.href.includes("Compliance")
      ? Control.go(
          "/StaffMedicalDetails?Compliance/id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        )
      : Control.go(
          "/StaffMedicalDetails?id=" +
            this.state.id +
            "&userId=" +
            this.state.userId +
            "&startDate=" +
            calenDate[0] +
            "&endDate=" +
            calenDate[1]
        );
  };
  async componentDidMount() {
    const {
      changeRenderDate,
      fetchRangeDate,
      fetchCalendarDate,
      changeCalendarData
    } = this.props;
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const userId = this.props.complianceId
      ? this.props.complianceId
      : params.userId;
    const id = params.id.replace(" ", "+");
    const DateRangeDate_startDate = window.location.href.includes("startDate")
      ? params.startDate
      : moment().format("YYYY-MM-DD");
    const DateRangeDate_endDate = window.location.href.includes("endDate")
      ? params.endDate
      : moment().format("YYYY-MM-DD");
    let StartDate = moment(DateRangeDate_startDate)
      .add(-35, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(DateRangeDate_startDate)
      .add(35, "days")
      .format("YYYY-MM-DD");
    changeCalendarData(id, userId, StartDate, EndDate);
    this.setState({
      DateRangeDate: [
        new Date(moment(DateRangeDate_startDate).format("MM/DD/YYYY")),
        new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
      ],
      userId,
      id
    });
    fetchRangeDate(
      new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
    );
    fetchCalendarDate(
      new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
    );
    changeRenderDate(this, id, userId, [
      new Date(moment(DateRangeDate_startDate).format("MM/DD/YYYY")),
      new Date(moment(DateRangeDate_endDate).format("MM/DD/YYYY"))
    ]);
  }
  turnChange = ({ activeStartDate, view }) => {
    const { changeCalendarData } = this.props;
    let StartDate = moment(activeStartDate)
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment(activeStartDate)
      .add(35, "days")
      .format("YYYY-MM-DD");
    changeCalendarData(this.state.id, this.state.userId, StartDate, EndDate);
  };
  render() {
    const daterangeClassName = ({ date, view }) => {
      let getDate = moment(date).format("YYYY-MM-DD");
      const dispensedDay = this.props.doctorDetails.dispensedDay
        ? moment(this.props.doctorDetails.dispensedDay).format("YYYY-MM-DD")
        : moment(this.props.doctorDetails.createdAt).format("YYYY-MM-DD");
      if (getDate < dispensedDay) {
        return "";
      }
      let activemonth = new Date().getMonth() + 1;
      if (
        this.props.calendardata &&
        this.props.calendardata.hasOwnProperty("footStatuses")
      ) {
        let markStatus = "";
        let markTimeWorn = "";
        _.forEach(this.props.calendardata.footStatuses, function(footStatus) {
          if (footStatus.date === getDate) {
            if (footStatus.overall === "NORMAL") {
              if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
                markStatus = "normalmetabase2";
              } else {
                markStatus = "normalmetabase";
              }
            } else if (footStatus.overall === "YELLOW") {
              if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
                markStatus = "yellowmetabase2";
              } else {
                markStatus = "yellowmetabase";
              }
            } else if (footStatus.overall === "RED") {
              if (parseInt(footStatus.date.split("-")[1]) === activemonth) {
                markStatus = "redmetabase2";
              } else {
                markStatus = "redmetabase";
              }
            }
          }
        });
        if (markStatus !== "") {
          return markStatus;
        }

        _.forEach(this.props.calendardata.footStatuses, function(item) {
          if (
            item.date.split("T")[0] === getDate &&
            Number(item.minuteWorn) >= 30
          ) {
            return (markTimeWorn = getDate);
          }
        });
        if (markTimeWorn !== "") {
          return "timeMore";
        }
      }
    };
    return (
      <div id="user-info-container3">
        <div className="urlbox">
          <Button
            basic
            className="change_calenDay_button"
            onClick={this.goPreDay}
          >
            <Icon name="angle left" />
            Previous day
          </Button>
          <DateRangePicker
            onChange={this.onChangeDateRange}
            value={[this.state.DateRangeDate[0], this.state.DateRangeDate[1]]}
            tileClassName={daterangeClassName}
            clearIcon=""
            minDetail="month"
            locale="en"
            isOpen={this.state.ifOpen}
            onActiveDateChange={this.turnChange}
          />
          <Button
            basic
            className="change_calenDay_button"
            onClick={this.goNextDay}
          >
            Next day
            <Icon name="angle right" />
          </Button>
        </div>
        {this.props.complianceId ? (
          <div className="complianceBlank" />
        ) : (
          <PatientCharts />
        )}
      </div>
    );
  }
}
const mapStateToProp = state => ({
  medicalStatus: state.patientsListStore.medicalStatus,
  calendardata: state.patientsListStore.calendardata,
  delta: state.patientsListStore.delta,
  doctorDetails: state.patientsListStore.doctorDetails
});
const mapDispatchToProp = dispatch => ({
  changeCalendarData: (id, userId, startDate, endDate) =>
    dispatch(changeCalendarData(id, userId, startDate, endDate)),
  changeRenderDate: (self, id, userId, dateRender) =>
    dispatch(changeRenderDate(self, id, userId, dateRender)),
  fetchRangeDate: changeRangeDate => dispatch(fetchRangeDate(changeRangeDate)),
  getFootStatus: (id, userId, start, end) =>
    dispatch(getFootStatus(id, userId, start, end)),
  fetchCalendarDate: changeFootDate =>
    dispatch(fetchCalendarDate(changeFootDate)),
  getMinuteWorn: (id, userId, startDate, endDate) =>
    dispatch(getMinuteWorn(id, userId, startDate, endDate)),
  getCalenDate: calenDate => dispatch(getCalenDate(calenDate))
});
export default connect(mapStateToProp, mapDispatchToProp)(calendarRange);
