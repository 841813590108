import _ from "lodash";
import * as TYPES from "../types/types";
import _api from "../graphql/_api";
import { graphqlOperation, API, Auth } from "aws-amplify";
import moment from "moment";
import { Modal as AntdModal } from "antd";
import aws_exports from "../aws-exports-siren-medical";

let generate99454Timer = null;
let generate99457Timer = null;
let generatePCMTimer = null;

export function searchPatientGroup(billingInputValue, self) {
  return async dispatch => {
    try {
      const siteId = billingInputValue.siteId;
      const year = billingInputValue.year;
      const month = billingInputValue.month;
      const reportPeriod = billingInputValue.reportPeriod;
      self.setState({ modalIsOpen: true });
      let biMonthReport = [];
      const newEvent = await API.graphql(
        graphqlOperation(_api.listBillingPatients(), { siteId, year, month })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        const filteredPatients = newEvent.data.listBillingPatient.items.filter(
          p => {
            const billDay = !_.isEmpty(p.billDay) ? p.billDay : "";
            const day99454 = p.period99454End;
            const day99457 = p.periodStdEnd;
            if (reportPeriod === "allMonth") {
              return true;
            }
            if (
              !_.isEmpty(billDay) &&
              moment(billDay).year() === parseInt(year) &&
              moment(billDay).month() === month &&
              ((reportPeriod === "firstHalf" && moment(billDay).date() <= 15) ||
                (reportPeriod === "SecondHalf" && moment(billDay).date() > 15))
            ) {
              return true;
            }
            if (
              moment(day99454).year() === parseInt(year) &&
              moment(day99454).month() === month &&
              ((reportPeriod === "firstHalf" &&
                moment(day99454).date() <= 15) ||
                (reportPeriod === "SecondHalf" && moment(day99454).date() > 15))
            ) {
              return true;
            }
            if (
              moment(day99457).year() === parseInt(year) &&
              moment(day99457).month() === month &&
              ((reportPeriod === "firstHalf" &&
                moment(day99457).date() <= 15) ||
                (reportPeriod === "SecondHalf" && moment(day99457).date() > 15))
            ) {
              return true;
            }
            return false;
          }
        );
        dispatch(changeSearchPatientState(filteredPatients));
        _.forEach(filteredPatients, function(item) {
          biMonthReport.push(item.sirenId);
        });
        dispatch(changeMonthlyReportStatus(biMonthReport));
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("searchPatientGroup error: ", e);
    }
  };
}
export function changeSearchPatientState(searchPatientsList) {
  return {
    type: TYPES.SEARCH_PATIENTS_LIST,
    text: searchPatientsList
  };
}

export function changeMonthlyReportList(self, patient) {
  return dispatch => {
    let bliList = _.clone(self.props.bliSirenIdReport);
    if (bliList.indexOf(patient.sirenId) === -1) {
      bliList.push(patient.sirenId);
    } else {
      bliList.splice(bliList.indexOf(patient.sirenId), 1);
      self.setState({ checkedAll: false });
    }
    dispatch(changeMonthlyReportStatus(bliList));
  };
}

function changeMonthlyReportStatus(bliSirenIdReport) {
  return {
    type: TYPES.BLI_SIRENID_REPORT,
    text: bliSirenIdReport
  };
}

export function checkAll99457ReportList(value, CheckAllValue) {
  return dispatch => {
    let bliList = [];
    if (CheckAllValue.checkedAll) {
      _.forEach(value, function(item) {
        let obj = {};
        obj.sirenId = item.sirenId;
        obj.userId = item.userId;
        obj.type = item.billedType;
        obj.billingDate = item.billingDate;
        bliList.push(obj);
      });
    }
    dispatch(changeCheckReportStatus(bliList));
  };
}

export function changeCheckReportList(self, patient) {
  return dispatch => {
    let bliList = _.clone(self.props.selectSirenIdReport);
    const checkUser =
      bliList.length > 0
        ? _.filter(bliList, function(item) {
            return (
              item.sirenId === patient.sirenId &&
              item.billingDate === patient.billingDate
            );
          })
        : [];
    if (checkUser.length > 0) {
      bliList.splice(
        bliList.findIndex(
          t =>
            t.sirenId === patient.sirenId &&
            t.billingDate === patient.billingDate
        ),
        1
      );
      self.setState({ checkedAll: false });
    } else {
      bliList.push(patient);
    }
    dispatch(changeCheckReportStatus(bliList));
  };
}
function changeCheckReportStatus(selectSirenIdReport) {
  return {
    type: TYPES.SEL_SIRENID_REPORT,
    text: selectSirenIdReport
  };
}
export function resetAllSirenIdReport() {
  return dispatch => {
    dispatch(resetAllSirenIdState());
  };
}
function resetAllSirenIdState() {
  return {
    type: TYPES.RESET_ALL_SIRENID
  };
}

export function checkAllReportList(value, CheckAllValue) {
  return dispatch => {
    let newPatientsList = _.clone(value);
    let list = [];
    if (CheckAllValue.checkedAll === true) {
      _.forEach(newPatientsList, function(item) {
        list.push(item.sirenId);
      });
    } else {
      _.forEach(newPatientsList, function(item) {
        delete list[item.sirenId];
      });
    }
    dispatch(changeMonthlyReportStatus(list));
  };
}

export function changeBilledPatient(dispensedInput, from, self) {
  return async dispatch => {
    const newEvent = await API.graphql(
      graphqlOperation(_api.updateBilledPatient(), { input: dispensedInput })
    );
    if (newEvent) {
      self.setState({ open: false });
      let newPatientsList = {};
      if (from === "HomePage") {
      } else {
        newPatientsList = _.clone(self.props.searchPatientsList);
      }
      _.forEach(newPatientsList, function(item) {
        if (item.userId === dispensedInput.userId) {
          if (dispensedInput.hasOwnProperty("dispensedDay")) {
            item.dispensedDay = dispensedInput.dispensedDay;
          }
        }
      });
      if (from === "HomePage") {
      } else {
        dispatch(changeSearchPatientState(newPatientsList));
      }
    }
  };
}
export function change99454Period(periodInput, self) {
  return async dispatch => {
    const newEvent = await API.graphql(
      graphqlOperation(_api.updateBilledPatient(), { input: periodInput })
    );
    if (newEvent) {
      self.setState({ open: false });
      let newPatientsList = _.clone(self.props.searchPatientsList);
      _.forEach(newPatientsList, function(item) {
        if (item.userId === periodInput.userId) {
          if (periodInput.hasOwnProperty("billingStartDay")) {
            item.period99454Start = periodInput.billingStartDay;
          }
        }
      });
      dispatch(changeSearchPatientState(newPatientsList));
    }
  };
}
export function getBillingList(listBillingValue, self) {
  const siteId = listBillingValue.siteId;
  const sirenIds = listBillingValue.sirenIds;
  const year = listBillingValue.year;
  const month = listBillingValue.month;
  const reportPeriod = listBillingValue.reportPeriod;
  let report99454 = [];
  let all99454list = [];
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.listBillingSummary(), {
          siteId,
          sirenIds,
          year,
          month
        })
      );
      if (newEvent) {
        const filteredPatients = newEvent.data.listBillingSummary.items.filter(
          p => {
            const billDay = !_.isEmpty(p.billDay) ? p.billDay : "";
            const day99454 = p.period99454End;
            const day99457 = p.periodStdEnd;
            if (reportPeriod === "allMonth") {
              return true;
            }
            if (
              !_.isEmpty(billDay) &&
              moment(billDay).year() === parseInt(year) &&
              moment(billDay).month() === month &&
              ((reportPeriod === "firstHalf" && moment(billDay).date() <= 15) ||
                (reportPeriod === "SecondHalf" && moment(billDay).date() > 15))
            ) {
              return true;
            }
            if (
              p.cptCode === "99454" &&
              moment(day99454).year() === parseInt(year) &&
              moment(day99454).month() === month &&
              ((reportPeriod === "firstHalf" &&
                moment(day99454).date() <= 15) ||
                (reportPeriod === "SecondHalf" && moment(day99454).date() > 15))
            ) {
              return true;
            }
            if (
              p.cptCode === "99457" &&
              moment(day99457).year() === parseInt(year) &&
              moment(day99457).month() === month &&
              ((reportPeriod === "firstHalf" &&
                moment(day99457).date() <= 15) ||
                (reportPeriod === "SecondHalf" && moment(day99457).date() > 15))
            ) {
              return true;
            }
            return false;
          }
        );
        dispatch(getBillingListState(filteredPatients));
        _.forEach(filteredPatients, function(item) {
          if (item.cptCode === "99454") {
            report99454.push(item.sirenId);
          }
          if (item.cptCode === "99457") {
            if (
              _.filter(filteredPatients, {
                sirenId: item.sirenId,
                cptCode: "99454"
              }).length === 0
            ) {
              let itemTemp = _.clone(item);
              itemTemp.cptCode = "99454";
              all99454list.push(itemTemp);
              all99454list.push(item);
            } else {
              all99454list.push(item);
            }
          } else {
            all99454list.push(item);
          }
        });
        dispatch(getBillingList99454State(all99454list));
        self.setState({ report99454 });
      }
    } catch (e) {
      console.log("getBillingList error: ", e);
    }
  };
}
export function getBillingListState(billingTableList) {
  return {
    type: TYPES.BILLING_TABLE_LIST,
    text: billingTableList
  };
}

export function getBillingList99454State(billingTableList) {
  return {
    type: TYPES.BILLING_TABLE_LIST_99454,
    text: billingTableList
  };
}
export function updateBilledStatus(inputData, self) {
  return async dispatch => {
    try {
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateBillingStatus(), inputData)
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        let billingPatient = _.clone(self.props.searchPatientsList);
        let updatePatient = _.filter(billingPatient, {
          userId: inputData.input.userId
        });
        if (inputData.input.hasOwnProperty("billed99453")) {
          updatePatient[0].billed99453 = inputData.input.billed99453;
        }
        if (inputData.input.hasOwnProperty("billed99454")) {
          updatePatient[0].billed99454 = inputData.input.billed99454;
        }
        if (inputData.input.hasOwnProperty("billed99457")) {
          updatePatient[0].billed99457 = inputData.input.billed99457;
        }
        dispatch(changeSearchPatientState(billingPatient));
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("updateBilledStatus failed: ", e);
    }
  };
}
export function updateBilled99453Status(inputData) {
  return API.graphql(
    graphqlOperation(_api.updateBilled99453Status(), inputData)
  );
}
export function updateBilled99454Status(inputData) {
  return API.graphql(
    graphqlOperation(_api.updateBilled99454Status(), inputData)
  );
}
export function updateBilled99457Status(inputData) {
  return API.graphql(
    graphqlOperation(_api.updateBilled99457Status(), inputData)
  );
}
export function curMonthPatient99457(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    const endDate = billingInputValue.endDate;
    const data99457Table = await API.graphql(
      graphqlOperation(_api.listBilling99457Patients(), {
        siteId,
        startDate,
        endDate
      })
    );

    const newEvent = await API.graphql(
      graphqlOperation(_api.listBilling99453Patients(), {
        siteId,
        startDate,
        endDate
      })
    );
    const real99457 = _.filter(
      newEvent.data.listBilling99453Patient.items,
      function(t) {
        return (
          !_.isEmpty(t.userId) &&
          t.rpmDeviceStatus !== "PAUSED" &&
          t.rpmDeviceStatus !== "NOT_ON_SERVICE" &&
          t.accountStatus !== "DELETED" &&
          t.accountBillingStatus !== "Not active" &&
          t.rpmProgramStatus === "ON_SERVICE"
        );
      }
    );
    //totalMinutesSpent
    let checkReal99457 = _.clone(real99457);
    if (data99457Table.data.listBilling99457Patient.items.length > 0) {
      _.forEach(data99457Table.data.listBilling99457Patient.items, function(
        item
      ) {
        let tempData = _.filter(checkReal99457, { userId: item.userId });
        if (tempData.length > 0) {
          if (item.totalMinutesSpent !== undefined) {
            tempData[0].totalMinutesSpent = item.totalMinutesSpent;
            tempData[0].serviceE = item.serviceE;
          }
        }
      });
    }
    dispatch(changeCurMonthPatient99457(checkReal99457));

    return checkReal99457;
  };
}
export function changeCurMonthPatient99457(curMonthPatients99457) {
  return {
    type: TYPES.CURMONTH_PATIENTS_99457,
    text: curMonthPatients99457
  };
}
export function curMonthPatient99454(billingInputValue, self) {
  return async dispatch => {
    try {
      const siteId = billingInputValue.siteId;
      const startDate = billingInputValue.startDate;
      const endDate = billingInputValue.endDate;
      self.setState({ modalIsOpen: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.listBilling99454Patients(), {
          siteId,
          startDate,
          endDate
        })
      );
      self.setState({ modalIsOpen: false });
      if (newEvent) {
        dispatch(
          changeCurMonth99454State(newEvent.data.listBilling99454Patient.items)
        );
      }
    } catch (e) {
      self.setState({ modalIsOpen: false });
      console.log("curMonthPatient99454 error: ", e);
    }
  };
}
export function changeCurMonth99454State(curMonthPatients99454) {
  return {
    type: TYPES.CURMONTH_PATIENTS_99454,
    text: curMonthPatients99454
  };
}
export function curMonthPatient99453(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    const endDate = billingInputValue.endDate;
    const newEvent = await API.graphql(
      graphqlOperation(_api.listBilling99453Patients(), {
        siteId,
        startDate,
        endDate
      })
    );
    const real99453 = newEvent.data.listBilling99453Patient.items.filter(
      t =>
        !_.isEmpty(t.userId) &&
        t.rpmDeviceStatus !== "PAUSED" &&
        t.rpmDeviceStatus !== "NOT_ON_SERVICE" &&
        t.accountStatus !== "DELETED" &&
        t.accountBillingStatus !== "Not active"
    );
    if (newEvent) {
      dispatch(changeCurMonth99453State(real99453));
    }

    return real99453;
  };
}
export function changeCurMonth99453State(curMonthPatients99453) {
  return {
    type: TYPES.CURMONTH_PATIENTS_99453,
    text: curMonthPatients99453
  };
}
export function searchBilling99454Patients(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    let endDate = billingInputValue.endDate;
    if (endDate) {
      endDate = moment(endDate)
        .add("month", 1)
        .format("YYYY-MM");
    }
    const newEvent = await API.graphql(
      graphqlOperation(_api.listBilling99454Patients(), {
        siteId,
        startDate,
        endDate
      })
    );
    if (newEvent) {
      if (startDate) {
        const billed99454 = newEvent.data.listBilling99454Patient.items.filter(
          t =>
            !_.isEmpty(t.userId) &&
            t.billedStatus === "BILLED" &&
            t.accountBillingStatus !== "Not active"
        )
        dispatch(changeBilling99454BilledPatients(billed99454));

        return billed99454
      } else {
        const unbilled99454 = newEvent.data.listBilling99454Patient.items.filter(
          t => !_.isEmpty(t.userId) && t.accountBillingStatus !== "Not active"
        );
        dispatch(changeBilling99454UnbilledPatients(unbilled99454));
        dispatch(changeCount99454UnbilledStatus(unbilled99454.length));

        return unbilled99454
      }
    }
  };
}
export function changeBilling99454UnbilledPatients(billing99454Patients) {
  return {
    type: TYPES.BILLING_99454_Unbilled_PATIENTS,
    text: billing99454Patients
  };
}
export function changeBilling99454BilledPatients(billing99454Patients) {
  return {
    type: TYPES.BILLING_99454_Billed_PATIENTS,
    text: billing99454Patients
  };
}
export function searchBilling99457Patients(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    let endDate = billingInputValue.endDate;
    if (endDate) {
      endDate = moment(endDate)
        .add("month", 1)
        .format("YYYY-MM");
    }
    const newEvent = await API.graphql(
      graphqlOperation(_api.listBilling99457Patients(), {
        siteId,
        startDate,
        endDate
      })
    );
    if (newEvent) {
      if (startDate) {
        const billed99457 = newEvent.data.listBilling99457Patient.items.filter(
          t =>
            !_.isEmpty(t.userId) &&
            t.billedStatus === "BILLED" &&
            t.accountBillingStatus !== "Not active"
        )
        dispatch(changeBilling99457BilledPatients(billed99457));

        return billed99457;
      } else {
        const unbilled99457 = newEvent.data.listBilling99457Patient.items.filter(
          t => !_.isEmpty(t.userId) && t.accountBillingStatus !== "Not active"
        );
        dispatch(changeBilling99457UnbilledPatients(unbilled99457));
        dispatch(changeCount99457UnbilledStatus(unbilled99457.length));

        return unbilled99457;
      }
    }
  };
}
export function changeBilling99457UnbilledPatients(billing99457Patients) {
  return {
    type: TYPES.BILLING_99457_Unbilled_PATIENTS,
    text: billing99457Patients
  };
}
export function changeBilling99457BilledPatients(billing99457Patients) {
  return {
    type: TYPES.BILLING_99457_Billed_PATIENTS,
    text: billing99457Patients
  };
}
export function searchBilling99453Patients(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    let endDate = billingInputValue.endDate;
    if (endDate) {
      endDate = moment(endDate)
        .add("month", 1)
        .format("YYYY-MM");
    }
    const newEvent = await API.graphql(
      graphqlOperation(_api.listBilling99453Patients(), {
        siteId,
        startDate,
        endDate
      })
    );
    if (newEvent) {
      if (startDate) {
        const billed99453 = newEvent.data.listBilling99453Patient.items.filter(
          t =>
            !_.isEmpty(t.userId) &&
            t.rpmDeviceStatus !== "PAUSED" &&
            t.rpmDeviceStatus !== "NOT_ON_SERVICE" &&
            t.accountStatus !== "DELETED" &&
            t.createdAt >= startDate &&
            t.createdAt <= endDate &&
            t.billed99453 === "Yes" &&
            !_.isEmpty(t.billDay) &&
            t.accountBillingStatus !== "Not active"
        )
        dispatch(changeBilling99453BilledPatients(billed99453));

        return billed99453
      } else {
        const unbilled99453 = newEvent.data.listBilling99453Patient.items.filter(
          t =>
            !_.isEmpty(t.userId) &&
            t.billed99453 !== "Yes" &&
            !_.isEmpty(t.billDay) &&
            t.accountBillingStatus !== "Not active"
        );
        dispatch(changeBilling99453UnbilledPatients(unbilled99453));
        dispatch(changeCount99453UnbilledStatus(unbilled99453.length));

        return unbilled99453
      }
    }
  };
}

export function changeCount99453UnbilledStatus(count) {
  return {
    type: TYPES.COUNT_99453_UNBILLED,
    text: count
  };
}

export function changeCount99454UnbilledStatus(count) {
  return {
    type: TYPES.COUNT_99454_UNBILLED,
    text: count
  };
}

export function changeCount99457UnbilledStatus(count) {
  return {
    type: TYPES.COUNT_99457_UNBILLED,
    text: count
  };
}

export function changeBilling99453UnbilledPatients(billing99453Patients) {
  return {
    type: TYPES.BILLING_99453_Unbilled_PATIENTS,
    text: billing99453Patients
  };
}

export function changeBilling99453BilledPatients(billing99453Patients) {
  return {
    type: TYPES.BILLING_99453_Billed_PATIENTS,
    text: billing99453Patients
  };
}

export function generate99457BillingReport(input) {
  return async dispatch => {
    try {
      dispatch(showGenerate99457DelayStatus());
      let reportLength = input.reportPatients.length;
      let waitAbout = reportLength * 12;
      start99457Timer(waitAbout, dispatch);

      const newEvent = await API.graphql(
        graphqlOperation(_api.generateBillingReport(), { input })
      );
      if (newEvent) {
        const subscription = API.graphql(
          graphqlOperation(_api.subscribeBillingReport())
        ).subscribe({
          next: async todoData => {
            if (todoData.value.data.onReportGenerated.token === input.token) {
              clear99457Timer(dispatch);

              //unsubscribe
              subscription.unsubscribe();
              //auto download
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              const cptCode = "99457-8";
              const downloadKey = todoData.value.data.onReportGenerated.path;
              const endpointInfo = _.filter(
                aws_exports.aws_cloud_logic_custom,
                {
                  name: "dev-pdf-exporter"
                }
              );
              const pdfEndpoint = endpointInfo[0].endpoint;
              const downloadUrl = `${pdfEndpoint}/medical/unBilledReport?key=${downloadKey}&token=${token}&cptCode=${cptCode}&generatedDate=${input.generatedDate}`;
              window.open(downloadUrl);
            }
          }
        });
      }
    } catch (e) {
      // self.setState({
      //   disableGenerateBtn: false,
      //   showDownloadButton: false,
      //   // modalIsOpen: false,
      //   showGenerateDelay: false,
      //   generateDelay: ""
      // });
      clear99457Timer(dispatch);
      console.log("generateBillingReport error: ", e);
      error(e);
    }
  };
}

export function generateBillingReport(input) {
  return async dispatch => {
    try {
      dispatch(showGenerate99454DelayStatus());
      let reportLength = input.reportPatients.length;
      let waitAbout = reportLength * 12;
      start99454Timer(waitAbout, dispatch);

      const newEvent = await API.graphql(
        graphqlOperation(_api.generateBillingReport(), { input })
      );
      if (newEvent) {
        const subscription = API.graphql(
          graphqlOperation(_api.subscribeBillingReport())
        ).subscribe({
          next: async todoData => {
            if (todoData.value.data.onReportGenerated.token === input.token) {
              clear99454Timer(dispatch);

              //unsubscribe
              subscription.unsubscribe();
              //auto download
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              const cptCode = "99453-4";
              const downloadKey = todoData.value.data.onReportGenerated.path;
              const endpointInfo = _.filter(
                aws_exports.aws_cloud_logic_custom,
                {
                  name: "dev-pdf-exporter"
                }
              );
              const pdfEndpoint = endpointInfo[0].endpoint;
              const downloadUrl = `${pdfEndpoint}/medical/unBilledReport?key=${downloadKey}&token=${token}&cptCode=${cptCode}&generatedDate=${input.generatedDate}`;
              window.open(downloadUrl);
            }
          }
        });
      }
    } catch (e) {
      clear99454Timer(dispatch);
      console.log("generateBillingReport error: ", e);
      error(e);
    }
  };
}
function error(e) {
  AntdModal.info({
    title: "Generate Report Failed",
    content: e,
    centered: true
  });
}

export function reportGenerate(self) {
  AntdModal.warn({
    title: "Downloading Report",
    content:
      "To download the report, disable your pop-up blocker. It will take a few minutes for us to generate your report. You may continue using the Siren Dashboard and the download will start automatically when it is ready.",
    onOk: async () => {
      if (self) {
        await self.startDownloading();
      }
    },
    onCancel: () => {},
    centered: true
  });
}

function showGenerate99454DelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_99454_DELAY,
    text: true
  };
}

function showGenerate99457DelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_99457_DELAY,
    text: true
  };
}

function hiddenGenerate99457DelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_99457_DELAY,
    text: false
  };
}

function hiddenGenerate99454DelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_99454_DELAY,
    text: false
  };
}

function startGenerate99454DelayStatus(generate99454Delay) {
  return {
    type: TYPES.GENERATE_99454_DELAY,
    text: generate99454Delay
  };
}

function startGenerate99457DelayStatus(generate99457Delay) {
  return {
    type: TYPES.GENERATE_99457_DELAY,
    text: generate99457Delay
  };
}

function start99457Timer(time, dispatch) {
  let sys_second = time * 1000;
  generate99457Timer = setInterval(() => {
    if (sys_second > 1000) {
      sys_second -= 1000;
      let minute = Math.floor((sys_second / 1000 / 60) % 60);
      let second = Math.floor((sys_second / 1000) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      let generateDelay = minute + "m" + second + "s";
      dispatch(startGenerate99457DelayStatus(generateDelay));
    } else {
      clear99457Timer(dispatch);
    }
  }, 1000);
}

function clear99457Timer(dispatch) {
  clearInterval(generate99457Timer);
  dispatch(hiddenGenerate99457DelayStatus());
  dispatch(startGenerate99457DelayStatus(""));
}

function start99454Timer(time, dispatch) {
  let sys_second = time * 1000;
  generate99454Timer = setInterval(() => {
    if (sys_second > 1000) {
      sys_second -= 1000;
      let minute = Math.floor((sys_second / 1000 / 60) % 60);
      let second = Math.floor((sys_second / 1000) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      let generateDelay = minute + "m" + second + "s";
      dispatch(startGenerate99454DelayStatus(generateDelay));
    } else {
      clear99454Timer(dispatch);
    }
  }, 1000);
}

function clear99454Timer(dispatch) {
  clearInterval(generate99454Timer);
  dispatch(hiddenGenerate99454DelayStatus());
  dispatch(startGenerate99454DelayStatus(""));
}

export function updateBilledPCMStatus(inputData) {
  return API.graphql(graphqlOperation(_api.updateBilledPCMStatus(), inputData));
}

export function curMonthPatientPCM(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    const endDate = billingInputValue.endDate;
    const dataPCMTable = await API.graphql(
      graphqlOperation(_api.listBillingPCMPatients(), {
        siteId,
        startDate,
        endDate
      })
    );

    const newEvent = await API.graphql(
      graphqlOperation(_api.listBilling99453Patients(), {
        siteId,
        startDate,
        endDate
      })
    );
    const realPCM = _.filter(
      newEvent.data.listBilling99453Patient.items,
      function(t) {
        return (
          !_.isEmpty(t.userId) &&
          t.accountStatus !== "DELETED" &&
          t.accountBillingStatus !== "Not active" &&
          t.pcmProgramStatus === "ON_SERVICE"
        );
      }
    );
    //totalMinutesSpent
    let checkRealPCM = _.clone(realPCM);
    if (dataPCMTable.data.listBillingPCMPatient.items.length > 0) {
      _.forEach(dataPCMTable.data.listBillingPCMPatient.items, function(
        item
      ) {
        let tempData = _.filter(checkRealPCM, { userId: item.userId });
        if (tempData.length > 0) {
          if (item.totalMinutesSpent !== undefined) {
            tempData[0].totalMinutesSpent = item.totalMinutesSpent;
          }
        }
      });
    }
    dispatch(changeCurMonthPatientPCM(checkRealPCM));

    return checkRealPCM;
  };
}
export function changeCurMonthPatientPCM(curMonthPatientsPCM) {
  return {
    type: TYPES.CURMONTH_PATIENTS_PCM,
    text: curMonthPatientsPCM
  };
}

export function searchBillingPCMPatients(billingInputValue) {
  return async dispatch => {
    const siteId = billingInputValue.siteId;
    if (_.isEmpty(siteId)) {
      return;
    }
    const startDate = billingInputValue.startDate;
    const endDate = billingInputValue.endDate;
    const newEvent = await API.graphql(
      graphqlOperation(_api.listBillingPCMPatients(), {
        siteId,
        startDate,
        endDate
      })
    );
    if (newEvent) {
      if (startDate) {
        const billedPCM = newEvent.data.listBillingPCMPatient.items.filter(
          t =>
            !_.isEmpty(t.userId) &&
            t.billedStatus === "BILLED" &&
            t.accountBillingStatus !== "Not active"
        )
        dispatch(changeBillingPCMBilledPatients(billedPCM));

        return billedPCM;
      } else {
        const unbilledPCM = newEvent.data.listBillingPCMPatient.items.filter(
          t => !_.isEmpty(t.userId) && t.accountBillingStatus !== "Not active"
        );
        dispatch(changeBillingPCMUnbilledPatients(unbilledPCM));
        dispatch(changeCountPCMUnbilledStatus(unbilledPCM.length));

        return unbilledPCM;
      }
    }
  };
}
export function changeBillingPCMBilledPatients(billingPCMPatients) {
  return {
    type: TYPES.BILLING_PCM_Billed_PATIENTS,
    text: billingPCMPatients
  };
}
export function changeBillingPCMUnbilledPatients(billingPCMPatients) {
  return {
    type: TYPES.BILLING_PCM_Unbilled_PATIENTS,
    text: billingPCMPatients
  };
}
export function changeCountPCMUnbilledStatus(count) {
  return {
    type: TYPES.COUNT_PCM_UNBILLED,
    text: count
  };
}

export function generatePCMBillingReport(input) {
  return async dispatch => {
    try {
      console.log("generate pcm unbilled input: ", input);
      dispatch(showGeneratePCMDelayStatus());
      let reportLength = input.reportPatients.length;
      let waitAbout = reportLength * 12;
      startPCMTimer(waitAbout, dispatch);
      const newEvent = await API.graphql(
        graphqlOperation(_api.generateBillingReport(), { input })
      );
      if (newEvent) {
        console.log("before subscription");
        const subscription = API.graphql(
          graphqlOperation(_api.subscribeBillingReport())
        ).subscribe({
          next: async todoData => {
            if (todoData.value.data.onReportGenerated.token === input.token) {
              clearPCMTimer(dispatch);
              //unsubscribe
              subscription.unsubscribe();
              //auto download
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              const cptCode = "99426-7";
              const downloadKey = todoData.value.data.onReportGenerated.path;
              const endpointInfo = _.filter(
                aws_exports.aws_cloud_logic_custom,
                {
                  name: "dev-pdf-exporter"
                }
              );
              const pdfEndpoint = endpointInfo[0].endpoint;
              const downloadUrl = `${pdfEndpoint}/medical/unBilledReport?key=${downloadKey}&token=${token}&cptCode=${cptCode}&generatedDate=${input.generatedDate}`;
              window.open(downloadUrl);
            }
          }
        });
      }
    } catch (e) {
      clearPCMTimer(dispatch);
      console.log("generateBillingReport error: ", e);
      error(e);
    }
  };
}
function showGeneratePCMDelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_PCM_DELAY,
    text: true
  };
}

function startPCMTimer(time, dispatch) {
  let sys_second = time * 1000;
  generatePCMTimer = setInterval(() => {
    if (sys_second > 1000) {
      sys_second -= 1000;
      let minute = Math.floor((sys_second / 1000 / 60) % 60);
      let second = Math.floor((sys_second / 1000) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      let generateDelay = minute + "m" + second + "s";
      dispatch(startGeneratePCMDelayStatus(generateDelay));
    } else {
      clearPCMTimer(dispatch);
    }
  }, 1000);
}

function startGeneratePCMDelayStatus(generatePCMDelay) {
  return {
    type: TYPES.GENERATE_PCM_DELAY,
    text: generatePCMDelay
  };
}
function clearPCMTimer(dispatch) {
  clearInterval(generatePCMTimer);
  dispatch(hiddenGeneratePCMDelayStatus());
  dispatch(startGeneratePCMDelayStatus(""));
}
function hiddenGeneratePCMDelayStatus() {
  return {
    type: TYPES.SHOW_GENERATE_PCM_DELAY,
    text: false
  };
}
