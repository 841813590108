import { Select } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import PropTypes from 'prop-types';
import queryString from "query-string";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button } from "semantic-ui-react";
import "../share/common.css";
import getPatchTrProps from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./report.css";
import "./selectComponent.css";

const { Option } = Select;

export default class ReportCurrentMonthBase extends Component {

  constructor(props) {
    super(props);

    this.startDate = moment().startOf("month").format("YYYY-MM-DD");
    this.endDate = moment().endOf("month").format("YYYY-MM-DD");
    // parse url param
    let siteId = []
    const queryParam = window.location.href.split("?")[1];
    if (queryParam) {
      let params = queryString.parse(queryParam);
      siteId = params.siteId.split("_");
      if (params.startDate) {
        this.startDate = params.startDate;
      }
      if (params.endDate) {
        this.endDate = params.endDate;
      }
    }

    this.state = {
      modalIsOpen: false,
      tableData: [],
      selectSites: siteId,
    };
  }

  componentDidMount() {
    const { getlistClinicSites } = this.props;
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
    if (this.state.selectSites.length > 0) {
      this.searchPatients();
    } else {
      this.setState({
        selectSites: this.props.authSites
      }, this.searchPatients);
    }
  }

  searchPatients = () => {
    if (this.state.selectSites.length === 0) {
      return;
    }
    this.setState({
      selectedPatients: []
    });
    const { searchCurrentMonthPatients } = this.props;
    const sites = this.state.selectSites.join("_");
    const billingInputValue = {
      siteId: sites,
      startDate: this.startDate,
      endDate: this.endDate
    };

    this.setState({
      modalIsOpen: true
    });
    searchCurrentMonthPatients(billingInputValue).then((data) => {
      this.setState({
        tableData: data
      })

      let currentPath = Control.path;
      if (currentPath.includes('?')) {
        currentPath = currentPath.split('?')[0]
      }
      Control.replace(currentPath + "?siteId=" + sites + "&startDate=" + this.startDate + "&endDate=" + this.endDate);
    }).catch(e => {
      console.log("searchCurrentMonthPatients error: ", e);
    }).finally(() => {
      this.setState({
        modalIsOpen: false
      })
    });
  }

  gotoDoctorDetailPage(userId, id, date) {
    let currentRoute = Control.path
    if (currentRoute.includes('?')) {
      currentRoute = currentRoute.split('?')[0]
    }
    // remove leading '/'
    currentRoute = currentRoute.substring(1)

    Control.go(
      `/StaffMedicalDetails?${currentRoute}/id=` + id +
      "&userId=" + userId +
      "&startDate=" + date +
      "&endDate=" + date
    );
  }

  handleResetFilter = () => {
    this.tableInstance.setState({
      sorted: [],
      filtered: [],
      resized: []
    })
    this.searchPatients()
  }

  renderHeader() {
    return React.createElement(this.headerClass, {
      onResetFilter: this.handleResetFilter
    })
  }

  renderActionBar() {
    return (
      <div
        className="search_patient_container"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className="search_label">SiteID:</span>
        <Select
          mode="multiple"
          style={{ minWidth: 200 }}
          placeholder="Please select sites"
          value={this.state.selectSites}
          onChange={value => {
            this.setState({
              selectSites: value
            }, this.searchPatients)
          }}
          optionLabelProp="value"
          optionFilterProp="children"
        >
          {this.props.authSites.map(id => {
            const site = this.props.clinicSites.find(site => site.siteId === id)
            if (!site) {
              return
            }
            return (
              <Option key={site.siteId} value={site.siteId}>
                {`${site.siteId} - ${site.name}`}
              </Option>
            )
          })}
        </Select>
        <Button
          size="small"
          onClick={this.searchPatients}
          style={{ marginLeft: 20 }}
          disabled={this.state.modalIsOpen}
          loading={this.state.modalIsOpen}
        >
          Search
        </Button>
      </div>
    )
  }

  // subclass should override
  renderTableProps() {
    return { data: [], columns: [] }
  }

  renderTable() {
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.userId,
        rowInfo.original.id,
        moment().format("YYYY-MM-DD")
      );
    });

    const { data, columns } = this.renderTableProps()

    return (
      <ReactTable
        ref={ref => this.tableInstance = ref}
        getTrProps={trProps}
        loading={this.state.modalIsOpen}
        data={data}
        filterable
        defaultFilterMethod={(filter, row) => {
          if (!row[filter.id]) {
            return false;
          }
          return row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }}
        columns={columns}
        defaultSorted={[{ id: "sirenId", desc: false }]}
        defaultPageSize={20}
        className="-striped -highlight"
        minRows={0}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderActionBar()}
        {this.renderTable()}
      </div>
    );
  }
}

ReportCurrentMonthBase.propTypes = {
  authSites: PropTypes.array.isRequired,
  clinicSites: PropTypes.array.isRequired,
  getlistClinicSites: PropTypes.func.isRequired,
  searchCurrentMonthPatients: PropTypes.func.isRequired,
}
