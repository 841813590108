import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Input } from 'antd';
import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button } from "semantic-ui-react";
import {
  changeMonthlyReportList,
  checkAllReportList,
  resetAllSirenIdReport,
  searchBilling99453Patients
} from "../action/bliMonthyReportAction";
import aws_exports from "../aws-exports-siren-medical";
import Progress from "../home/progress";
import "../share/common.css";
import "./biMonthly.css";
import Report99453 from "./report99453";
import "./selectComponent.css";
Modal.setAppElement("div");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
class RecordingReviewed extends Component {
  constructor(props) {
    super(props);
    this.needs = [];
    this.state = {
      userGroup: [],
      modalIsOpen: false,
      billingYear: new Date().getFullYear(),
      billingMonth: new Date().getMonth() + 1,
      siteId: "",
      userSiteId: [],
      checkedAll: true,
      reportPeriod: "allMonth",
      selectValues: [],
      inputValue: "",
      showCheckGroup: false,
      allSiteIdSelect: [],
      indeterminate: true,
      checkAll: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.selectHalfMonth = this.selectHalfMonth.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.handleCheckAll = this.handleCheckAll.bind(this);
    this.deleteSelectSiteId = this.deleteSelectSiteId.bind(this);
  }
  getSiteId = async () => {        
    let allSiteIdSelect = _.clone(this.props.siteId)
    this.setState({
      userSiteId: this.props.siteId,
      siteId: this.props.siteId,
      allSiteIdSelect: allSiteIdSelect
    });
  };

  async componentDidMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({ userGroup: groups });
    this.getSiteId();
    const { searchBilling99453Patients } = this.props;
    if (window.location.href.includes("siteId")) {
      const url = window.location.href.split("?")[1];
      let params = queryString.parse(url);
      const siteId = params.siteId.split("_");
      this.setState({
        siteId: siteId,
        selectValues: siteId
      });
      if (this.props.searchBilling99453Patients.length === 0) {
        const billingInputValue = { siteId: params.siteId };
        searchBilling99453Patients(billingInputValue, this);
      }
    }
  }
  handleCheckAll() {
    this.setState({ checkedAll: !this.state.checkedAll });
    const { checkAllReportList } = this.props;
    const CheckAllValue = {
      checkedAll: !this.state.checkedAll,
      siteId: this.state.siteId
    };
    checkAllReportList(this, CheckAllValue);
  }
  handleCheck(patient) {
    const { changeMonthlyReportList } = this.props;
    changeMonthlyReportList(this, patient);
  }
  handleClear() {
    const { resetAllSirenIdReport } = this.props;
    resetAllSirenIdReport();
  }
  onSelectChange(value) {
    this.setState({
      selectValues: value,
      indeterminate: !!value.length && value.length < this.state.allSiteIdSelect.length,
      checkAll: value.length === this.state.allSiteIdSelect.length,
    });
    let reqSite = "";
    if (value.length > 0) {
      reqSite = value
        .map(p => {
          return p;
        })
        .join("_");
    }
    const { searchBilling99453Patients } = this.props;
    const billingInputValue = { siteId: reqSite };
    searchBilling99453Patients(billingInputValue, this);
    Control.go( "/Billed99453?siteId=" + reqSite );
  }
  deleteSelectSiteId(key) {
    const newSelectValues = this.state.selectValues;
    newSelectValues.splice(key, 1);
    this.setState({ selectValues: newSelectValues, inputValue: "" });
    const reqSite = newSelectValues
      .map(p => {
        return p;
      })
      .join("_");
    const { searchBilling99453Patients } = this.props;
    const billingInputValue = { siteId: reqSite };
    searchBilling99453Patients(billingInputValue, this);
    Control.go( "/Billed99453?siteId=" + reqSite );
  }
  selectHalfMonth(value) {
    const { searchBilling99453Patients } = this.props;
    this.setState({ reportPeriod: value });
    if (!_.isEmpty(this.state.siteId)) {
      const billingInputValue = { siteId: this.state.siteId};
      searchBilling99453Patients(billingInputValue, this);
      Control.go( "/Billed99453?siteId=" + this.state.siteId );
    }
  }
  searchUsers() {
    const { searchBilling99453Patients } = this.props;
    const sites = this.state.siteId
      .map(p => {
        return p;
      })
      .join("_");
    if (!_.isEmpty(sites)) {
      const billingInputValue = { siteId: sites };
      searchBilling99453Patients(billingInputValue, this);
      Control.go( "/Billed99453?siteId=" + sites );
    }
  }

  sendReport(list) {
    let selectSirenID = "";
    selectSirenID = list.join("_");
    Control.go(
      "/UserReportTable?siteId=" +
      this.state.siteId +
      "&sirenIds=" +
      selectSirenID +
      "&year=" +
      this.state.billingYear +
      "&month=" +
      this.state.billingMonth +
      "&reportPeriod=" +
      this.state.reportPeriod
    );
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  gotoDoctorDetailPage(userId, id, date) {
    Control.go(
      "/StaffMedicalDetails?Billed99453/id=" +
      id +
      "&userId=" +
      userId +
      "&startDate=" +
      date +
      "&endDate=" +
      date
    );
  }

  async downloadReport() {
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const siteId = this.state.siteId;
      const year = this.state.billingYear;
      const month = this.state.billingMonth - 1;
      const endpointInfo = _.filter(aws_exports.aws_cloud_logic_custom, {
        name: "dev-pdf-exporter"
      });
      const pdfEndpoint = endpointInfo[0].endpoint;
      const downloadUrl = `${pdfEndpoint}/medical/pdf?siteId=${siteId}&month=${month}&year=${year}&token=${token}`;
      window.open(downloadUrl);
    } catch (e) {
      console.log("download pdf error: ", e);
    }
  }
  handleInputChange(data) {
    this.setState({ inputValue: data })
  }
  renderValueItem({ value, key }) {
    return (
      <div
        key={key}
        style={{
          display: 'inline-block',
          marginLeft: '5px'
        }}
        className="selectBox"
      >
        <span className="selectValue">{value}</span>{' '}
        <CloseOutlined
          className="CloseOutlined"
          onClick={() => this.deleteSelectSiteId(key)}
        />
      </div>
    );
  }
  onCheckAllChange = e => {
    this.setState({
      inputValue: "",
      indeterminate: false,
      checkAll: e.target.checked,
    });
    let reqSite = "";
    if (e.target.checked) {
      reqSite = this.state.userSiteId
        .map(p => {
          return p;
        })
        .join("_");
      this.setState({ siteId: this.state.userSiteId, selectValues: this.state.userSiteId });
    } else {
      this.setState({ siteId: [], selectValues: [] });
    }
    const { searchBilling99453Patients } = this.props;
    const billingInputValue = { siteId: reqSite };
    searchBilling99453Patients(billingInputValue, this);
    Control.go("/Billed99453?siteId=" + reqSite);
  };
  render() {
    return (
      <div className="device-list-container">
        <Report99453 />
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="search_patient_container">
          <span className="search_label">SiteID:</span>
          <div
            className="selectContainer"
            onClick={() => { this.setState({ showCheckGroup: true }) }}

          >
            <div className="selectValuesBox">
              {
                this.state.selectValues.length > 0 ?
                  this.state.selectValues.map((value, key) => {
                    return this.renderValueItem({ value, key });
                  })
                  : <div className="blanck_SelectBox"></div>
              }
            </div>
            {
              this.state.showCheckGroup &&
              <div
                className="CheckboxGroupBox"
                style={{
                  position: 'absolute',
                  border: "1px solid #d9d9d9",
                  top: '100%',
                  borderRadius: 4,
                  width: 240
                }}
                onMouseLeave={(e) => {
                  this.setState({ showCheckGroup: false })
                }}
              >
                <Input className="selectInputSearch" placeholder="Type to Search" onChange={(e, data) => { this.handleInputChange(e.target.value) }} />
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={this.state.checkAll}
                  style={{ marginLeft: 8 }}
                >
                  All Sites
                </Checkbox>
                <br />
                <Checkbox.Group
                  className="CheckboxGroup"
                  onChange={this.onSelectChange}
                  value={this.state.selectValues}
                >
                  {this.state.allSiteIdSelect && this.state.allSiteIdSelect.map((group, i) => {
                    return (
                      <Checkbox className="Checkbox" value={group} key={"select" + i} style={{
                        display: _.isEmpty(this.state.inputValue) ? "block"
                          : group.indexOf(this.state.inputValue) === -1 ? "none"
                            : "block"
                      }
                      }>
                        Site {group}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </div>
            }
          </div>
          <Button
            size="small"
            onClick={this.searchUsers}
            style={{ marginLeft: 20 }}
            className="fixedButtonHeight"
          >
            Search
          </Button>
          <Button
            onClick={() => this.downloadReport()}
            style={{ marginLeft: 20 }}
            className="fixedButtonHeight"
          >
            Download
          </Button>
        </div>
        <ReactTable
          data={
            this.props.filterSearchPatientsList &&
            this.props.filterSearchPatientsList.map(p => {
              const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
              const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
              const initializedDate = p.initializedDate ? p.initializedDate.split("T")[0] : "";
              let spentTime = 0;
              if (p.rpmInfo && p.rpmInfo.length > 0) {
                for (var i in p.rpmInfo) {
                  spentTime = spentTime + parseInt(p.rpmInfo[i].spentTime.split(" ")[0])
                }
              }
              let period;
              if(p.period99454Start){
                if(p.period99454End){
                  period = p.period99454Start + "~" + p.period99454End
                }else{
                  period = p.period99454Start
                }
              }else{
                if(p.period99454End){
                  period =  "~" + p.period99454End
                }else{
                  period =  ""
                }
              }
              return {
                ...p,
                createdAt,
                initializedDate,
                dispensedDay,
                spentTime,
                period
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              columns: [
                {
                  Header: props => {
                    return (
                      <Checkbox
                        onChange={this.handleCheckAll}
                        checked={this.state.checkedAll}
                        name="selectAll"
                        className="billCheck_all"
                      />
                    );
                  },
                  accessor: "",
                  maxWidth: 60,
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <Checkbox
                        onChange={(e, data) => this.handleCheck(props.original)}
                        checked={
                          this.props.bliSirenIdReport &&
                          this.props.bliSirenIdReport.indexOf(
                            props.original.sirenId
                          ) >= 0
                        }
                        name="selectSite"
                      />
                    );
                  }
                }
              ]
            },
            {
              Header: "SiteID",
              accessor: "siteId",
              maxWidth: 60,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "SiteName",
              accessor: "siteName",
              minWidth: 100,
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.siteName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "SirenID",
              accessor: "sirenId",
              maxWidth: 60,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "First Name",
              accessor: "firstName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.firstName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.lastName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Date of Birth",
              accessor: "birthday",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Account Created",
              accessor: "createdAt",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Account Activation Date",
              accessor: "initializedDate",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Dispensed Date",
              accessor: "dispensedDay",
              minWidth: 120,
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "16th Day of Wear",
              accessor: "billingDate",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            },
            {
              Header: "Medical Details",
              sortable: false,
              filterable: false,
              minWidth: 114,
              Cell: props => {
                return (
                  <div>
                    <Button
                      size="mini"
                      onClick={() =>
                        this.gotoDoctorDetailPage(
                          props.original.userId,
                          props.original.id,
                          moment().format("YYYY-MM-DD")
                        )
                      }
                    >
                      View Details
                    </Button>
                  </div>
                );
              }
            },
            {
              columns: [
                {
                  Header: "Billed By",
                  accessor: "billedBy",
                  filterMethod: (filter, row) => {
                    let rowfilterfullname = row._original.siteName
                      ? row[filter.id].toLowerCase()
                      : "";
                    let filterfullname = filter.value.toLowerCase();
                    return rowfilterfullname.includes(filterfullname);
                  }
                }
              ]
            },
            {
              Header: "Billed Date",
              accessor: "billedAt",
              filterMethod: (filter, row) => {
                return String(row[filter.id]).includes(filter.value);
              }
            }
          ]}
          defaultSorted={[{ id: "sirenId", desc: false }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
        <div className="add_import_box" style={{ textAlign: "center" }}>
          <Button
            size="small"
            onClick={() => this.sendReport(this.props.bliSirenIdReport)}
          >
            Next
          </Button>
          <Button size="small" onClick={this.handleClear}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProp = state => {
  const bliSirenIdReport = state.monthlyReportStore.bliSirenIdReport;
  const filterGroup = state.patientsListStore.filterGroup;
  const billing99453Patients = state.monthlyReportStore.billing99453Patients;
  const filterSearchPatientsList = billing99453Patients.filter(p => p.billedStatus === "BILLED");
  return {
    siteId: state.authGroupStore.profile.siteId,
    billing99453Patients:billing99453Patients,
    filterSearchPatientsList:filterSearchPatientsList,
    bliSirenIdReport:bliSirenIdReport,
    filterGroup:filterGroup
  };
};
const mapDispatchToProp = dispatch => ({
  searchBilling99453Patients: (billingInputValue, self) =>
    dispatch(searchBilling99453Patients(billingInputValue, self)),
  changeMonthlyReportList: (self, patient) =>
    dispatch(changeMonthlyReportList(self, patient)),
  resetAllSirenIdReport: () => dispatch(resetAllSirenIdReport()),
  checkAllReportList: (self, CheckAllValue) =>
    dispatch(checkAllReportList(self, CheckAllValue)),
});
export default connect(mapStateToProp, mapDispatchToProp)(RecordingReviewed);
