import { MinusOutlined, PlusOutlined, QuestionCircleFilled } from "@ant-design/icons";
import {
  Collapse,
  Divider,
  Tabs,
  Result,
  Affix,
  Dropdown,
  Menu,
  message,
  Popover
} from "antd";
import { Auth } from "aws-amplify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Button, Form, Icon, Radio } from "semantic-ui-react";
import { getAppointmented } from "../../action/addAction";
import { getCalenDate } from "../../action/passAction";
import { getDoctorDetails, getPhotoReviews } from "../../action/patientAction";
import { getlistStaff, listLPNs } from "../../action/registerAction";
import commonStyles from "../../common/CommonStyles";
import Progress from "../../home/progress";
import Calendar2 from "../../medicaldetails/calendarRange";
import Status from "../../medicaldetails/status";
import Tracker99457 from "../../report/Tracker99457";
import Equipment from "../../sock-status/Equipment";
import { formatNumber } from "../../tools/phoneFormat";
import EditProfileInfo from "../account/EditProfileInfo";
import Calendar1 from "./calendar";
import "./DocMedicalDetails.css";
import PatientSym from "./PatientSym";
import Photo from "./Photo";
import "./reviewSuccess.css";
import SirenNotes from "./SirenNotes";
import ActivityLog from "./ActivityLog";
import DocumentsList from "../../documents/documents-list";

const { TabPane } = Tabs;
class UserStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      date: new Date(),
      url: "",
      Height: 356,
      calendarDate: new Date(),
      comment: "",
      value: "",
      taskTime: "",
      showSuccessAlert: false,
      showFailedAlert: false,
      modalIsOpen: false
    };
    this.returnHome = this.returnHome.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.closeReviewFail = this.closeReviewFail.bind(this);
    this.closeReviewSuccess = this.closeReviewSuccess.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.queryParams = {};
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  handleRadio = (e, { value }) => {
    this.setState({ value: value });
  };
  handleCommentsChange(value) {
    this.setState({ comment: value });
  }
  closeReviewFail() {
    this.setState({
      showFailedAlert: false
    });
  }
  closeReviewSuccess() {
    this.setState({
      showSuccessAlert: false
    });
  }
  async saveInfo() {
    let user = await Auth.currentAuthenticatedUser();
    const { getAppointmented } = this.props;
    let inputData = {
      id: this.queryParams.id,
      userId: this.queryParams.userId,
      doctor: user.username
    };
    if (this.state.value === "") {
      alert("Must be selected by submitting a button");
      return;
    }
    if (this.state.value && this.state.value === "radio_one") {
      inputData.appointmented = true;
    }
    if (this.state.value && this.state.value === "radio_two") {
      inputData.appointmented = false;
    }
    if (this.state.value && this.state.value === "radio_three") {
      inputData.retakPhoto = true;
    }
    if (this.state.comment && this.state.comment !== "") {
      inputData.comment = this.state.comment;
    }
    if (this.props.doctorDetails.category.includes("Photo Review")) {
      Object.assign(inputData, { authorTitle: "Photo Review" });
    } else if (this.props.doctorDetails.category.includes("Symptoms")) {
      Object.assign(inputData, { authorTitle: "Symptom Review" });
    } else if (this.props.doctorDetails.category.includes("Medical Review")) {
      Object.assign(inputData, { authorTitle: "Medical Review" });
    } else if (this.props.doctorDetails.category.includes("Data Update")) {
      Object.assign(inputData, { authorTitle: "Data Update" });
    }
    if (
      this.props.doctorDetails &&
      this.props.doctorDetails.photoTask &&
      this.props.doctorDetails.photoTask.taskTime !== ""
    ) {
      Object.assign(inputData, {
        taskTime: this.props.doctorDetails.photoTask.taskTime
      });
    }
    getAppointmented(inputData, this).then(() => {
      this.getPatientDetails();
    });
  }
  onChangeDateRange = DateRangeDate => {
    this.setState({ DateRangeDate });
    this.setState({ ifOpen: true });
  };
  returnHome() {
    const { getCalenDate } = this.props;
    const calenDate = [
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    ];
    getCalenDate(calenDate);
    Control.go(
      this.state.url.includes("Patients")
        ? "/PatientsHome"
        : this.state.url.includes("Appointments")
        ? "/AppointmentsHome"
        : this.state.url.includes("Compliance")
        ? "/Compliance"
        : this.state.url.includes("MonitoringReport")
        ? "/MonitoringReport"
        : this.state.url.includes("MonitoringNeedsReview")
        ? "/MonitoringNeedsReview"
        : this.state.url.includes("MonitoringReviewed")
        ? "/MonitoringReviewed"
        : this.state.url.includes("RecordingReport")
        ? "/RecordingReport"
        : this.state.url.includes("RecordingNeedsReview")
        ? "/RecordingNeedsReview"
        : this.state.url.includes("RecordingReviewed")
        ? "/RecordingReviewed"
        : this.state.url.includes("ManagementSummary")
        ? "/ManagementSummary"
        : "/"
    );
  }

  handleTopMenuClick = e => {
    const patientList = Control.state && Control.state.patientList;

    if (!patientList) {
      message.info("Did not found patient list");
      return;
    }

    const index = patientList.findIndex(
      p => p.userId === this.queryParams.userId
    );
    if (index === -1) {
      console.warn(
        "inconsistant state, patient list does not include current patient"
      );
      return;
    }
    const { key } = e;
    let nextPatient;
    if (key === "next") {
      if (index === patientList.length - 1) {
        message.info("This is the last patient, please go to the next page");
        return;
      }
      nextPatient = patientList[index + 1];
    } else {
      if (index === 0) {
        message.info("This is the first patient, please go to the next page");
        return;
      }
      nextPatient = patientList[index - 1];
    }

    let [path, paramStr] = Control.path.split("?");
    // there is some bug in Control, which may not get query params
    if (!paramStr) {
      paramStr = window.location.href.split("?")[1];
    }
    if (paramStr.includes("/")) {
      path = `${path}?${paramStr.split("/")[0]}/`;
    } else {
      path = `${path}?`;
    }
    // date must be today. if not today, the patient may have no data that date
    const today = moment().format("YYYY-MM-DD");

    Control.replace(
      `${path}id=${nextPatient.id}&userId=${nextPatient.userId}&startDate=${today}&endDate=${today}&tab=${this.state.activeTab}`,
      { patientList }
    );
    this.fetchInitData();
  };

  async componentDidMount() {
    const { getlistStaff, listLPNs } = this.props;
    getlistStaff();
    listLPNs();

    this.fetchInitData();

    document.addEventListener("refreshMedicalDetail", this.fetchInitData);
  }

  componentWillUnmount() {
    document.removeEventListener("refreshMedicalDetail", this.fetchInitData);
  }

  fetchInitData = () => {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const id = params.id.replace(" ", "+");
    const userId = params.userId;
    this.setState({ url: window.location.href });
    const DateRangeDate_endDate = window.location.href.includes("endDate")
      ? params.endDate
      : moment().format("YYYY-MM-DD");
    let currentYear = DateRangeDate_endDate.split("-")[0];
    let currentMonth = DateRangeDate_endDate.split("-")[1];
    let StartDate = moment(currentYear + "-" + currentMonth + "-01")
      .add(-6, "days")
      .format("YYYY-MM-DD");
    let EndDate = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
    this.queryParams = { userId, id, StartDate, EndDate };
    this.getPatientDetails();
  };

  getPatientDetails = () => {
    this.setState({
      patientDetailError: null
    });
    const { id, userId, StartDate, EndDate } = this.queryParams;
    this.props.getDoctorDetails(id, userId, StartDate, EndDate).catch(err => {
      // we only show the first error. usually there is only one error
      this.setState({
        patientDetailError: err.errors[0]
      });
    });
  };
  download = () => {
    this.setState({ modalIsOpen: true });

    html2canvas(document.getElementById("download_patient_detail"), {
      useCORS: true,
      scale: 2
    }).then(canvas => {
      const pdf = new jsPDF("p", "mm", "a4"); //A4纸，纵向
      const ctx = canvas.getContext("2d");
      const a4w = 190,
        a4h = 277; //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
      const canvasA4H = (a4h / a4w) * canvas.width;

      const tmpCanvas = document.createElement("canvas");
      for (
        let page = 0, totalPage = Math.ceil(canvas.height / canvasA4H);
        page < totalPage;
        page++
      ) {
        pdf.setFontSize(10);
        pdf.page = page + 1;
        pdf.text(204, 294, String(pdf.page)); //print number bottom right
        let height =
          page + 1 === totalPage ? canvas.height - page * canvasA4H : canvasA4H;
        let imageData = ctx.getImageData(
          0,
          page * canvasA4H,
          canvas.width,
          height
        );
        tmpCanvas.width = imageData.width;
        tmpCanvas.height = imageData.height;
        tmpCanvas.getContext("2d").putImageData(imageData, 0, 0);
        pdf.addImage(tmpCanvas, "JPEG", 10, 10, a4w, a4h); //添加图像到页面，保留10mm边距
        if (page + 1 < totalPage) {
          pdf.addPage(); //如果后面还有内容，添加一个空页
        }
      }

      pdf.save("medical-details.pdf");
      this.setState({ modalIsOpen: false });
    });
  };

  getDoctorName = doctorId => {
    let doctorInfo = _.filter(this.props.listStaff, { email: doctorId });
    if (doctorInfo.length > 0) {
      if (
        doctorInfo[0].isPrescribing &&
        !doctorInfo[0].firstName.includes("Dr.")
      ) {
        return (
          "Dr. " +
          (doctorInfo[0].firstName ? doctorInfo[0].firstName : "") +
          " " +
          (doctorInfo[0].lastName ? doctorInfo[0].lastName : "")
        );
      }
      return (
        (doctorInfo[0].firstName ? doctorInfo[0].firstName : "") +
        " " +
        (doctorInfo[0].lastName ? doctorInfo[0].lastName : "")
      );
    }
  };

  renderProfileBar = () => {
    const userName = !_.isEmpty(this.props.doctorDetails)
      ? this.props.doctorDetails.firstName +
        " " +
        this.props.doctorDetails.lastName
      : "";
    const items = [
      {
        label: "Site ID",
        value: "siteId"
      },
      {
        label: "Siren ID",
        value: "sirenId"
      },
      {
        label: "DOB",
        value: profile => {
          return (
            profile.birthday && moment(profile.birthday).format("MM/DD/YYYY")
          );
        }
      },
      {
        label: (
          <>
            {this.props.doctorDetails.phone &&
            formatNumber(this.props.doctorDetails.phone)
              ? "Cell"
              : "Home"}
          </>
        ),
        value: profile => {
          return (
            (profile.phone && formatNumber(profile.phone)) ||
            (profile.homePhone && formatNumber(profile.homePhone)) ||
            (profile.id && formatNumber(profile.id))
          );
        }
      },
      {
        label: "Smartphone",
        value: profile => {
          return profile.patientHasSmartPhone
            ? profile.patientHasSmartPhone
            : "No";
        }
      },
      {
        label: "Doctor",
        value: profile => {
          return profile.doctorId && this.getDoctorName(profile.doctorId);
        }
      }
    ];
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100vw",
          margin: "0 -24px 0 -24px",
          height: 60,
          backgroundColor: "#2D0172",
          textAlign: "center",
          fontSize: 14,
          color: "white"
        }}
      >
        <div
          style={{
            flex: "auto",
            display: "flex",
            alignItems: "center",
            marginLeft: 24,
            marginRight: 24
          }}
        >
          <strong
            style={{
              fontSize: 16,
              marginLeft: 40,
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            title={userName}
          >
            {userName}
          </strong>
          <Divider type="vertical" style={{ margin: "0 20px" }} />
          {items.map(item => (
            <React.Fragment key={item.label}>
              <div>
                <label>{item.label}: </label>
                <span style={{ whiteSpace: "nowrap" }}>
                  {this.props.doctorDetails &&
                    (typeof item.value === "function"
                      ? item.value(this.props.doctorDetails)
                      : this.props.doctorDetails[item.value])}
                </span>
              </div>
              <Divider type="vertical" style={{ margin: "0 20px" }} />
            </React.Fragment>
          ))}
          <EditProfileInfo
            userInfo={this.props.doctorDetails}
            loadNewEditValue={this.getPatientDetails}
          />
        </div>
      </div>
    );
  };

  render() {
    if (this.state.patientDetailError) {
      if (this.state.patientDetailError.errorType === "Unauthorized") {
        return (
          <Result
            style={{ paddingTop: 70 }}
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" href="/#/">
                Back Home
              </Button>
            }
          />
        );
      } else {
        return (
          <Result
            style={{ paddingTop: 70 }}
            status="warning"
            title={this.state.patientDetailError.message}
            extra={[
              <Button type="primary" href="/#/" key="backHome">
                Back Home
              </Button>,
              <Button
                type="primary"
                key="refresh"
                onClick={() => window.location.reload()}
              >
                Reload page
              </Button>
            ]}
          />
        );
      }
    }
    return (
      <div>
        <Affix offsetTop={54} style={{ marginTop: -10 }}>
          {this.renderProfileBar()}
        </Affix>
        <Affix
          offsetTop={120}
          style={{ position: "absolute", top: 120, right: 24, zIndex: 10 }}
        >
          <Dropdown.Button
            onClick={this.returnHome}
            overlay={
              <Menu onClick={this.handleTopMenuClick}>
                <Menu.Item key="next">Next patient</Menu.Item>
                <Menu.Item key="prev">Previous patient</Menu.Item>
              </Menu>
            }
          >
            Return to list
          </Dropdown.Button>
        </Affix>
        <Tabs
          defaultActiveKey="1"
          style={{
            textAlign: "left"
          }}
          tabBarStyle={{ marginLeft: 65 }}
        >
          <TabPane tab="Medical" key="1">
            <div
              className="device-detail-container"
              style={{ textAlign: "center" }}
            >
              <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={commonStyles}
                transitionEnterTimeout={30000}
                transitionLeaveTimeout={30000}
              >
                <Progress />
              </Modal>
              <div style={{ textAlign: "right" }}>
                <Button className="dowButton" basic onClick={this.download}>
                  <Icon name="download" />
                  Download Profile
                </Button>
              </div>
              <div className="medical_recommendation_container">
                <div
                  className="reviewSuccess_container"
                  style={{
                    display: this.state.showSuccessAlert ? "block" : "none"
                  }}
                >
                  <div className="reviewSuccess_left">
                    <Icon
                      name="check circle outline"
                      size="mini"
                      className="review_icon_circle"
                    />
                    <span>Your message was sent successfully.</span>
                  </div>
                  <div className="reviewSuccess_right">
                    <Icon
                      name="close"
                      className="reviewSuccess_right_close"
                      onClick={this.closeReviewSuccess}
                    />
                  </div>
                  <div className="clear_both" />
                </div>
                <div
                  className="reviewFail_container"
                  style={{
                    display: this.state.showFailedAlert ? "block" : "none"
                  }}
                >
                  <div className="reviewSuccess_left">
                    <Icon
                      name="check circle outline"
                      size="mini"
                      className="review_icon_circle"
                    />
                    <span>Your message failed to send.</span>
                  </div>
                  <div className="reviewSuccess_right">
                    <Icon
                      name="close"
                      className="reviewSuccess_right_close"
                      onClick={this.closeReviewFail}
                    />
                  </div>
                  <div className="clear_both" />
                </div>
                <div
                  style={{
                    width: "100%",
                    height:
                      this.props.doctorDetails &&
                      this.props.doctorDetails.category &&
                      this.props.doctorDetails.category.length > 0 &&
                      (this.props.doctorDetails.category.includes(
                        "Medical Review"
                      ) ||
                        this.props.doctorDetails.category.includes(
                          "Photo Review"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Symptoms"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Data Update"
                        ))
                        ? 270
                        : 0,
                    marginBottom:
                      this.props.doctorDetails &&
                      this.props.doctorDetails.category &&
                      this.props.doctorDetails.category.length > 0 &&
                      (this.props.doctorDetails.category.includes(
                        "Medical Review"
                      ) ||
                        this.props.doctorDetails.category.includes(
                          "Photo Review"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Symptoms"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Data Update"
                        ))
                        ? "2%"
                        : 0,
                    display:
                      this.props.doctorDetails &&
                      this.props.doctorDetails.category &&
                      this.props.doctorDetails.category.length > 0 &&
                      (this.props.doctorDetails.category.includes(
                        "Medical Review"
                      ) ||
                        this.props.doctorDetails.category.includes(
                          "Photo Review"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Symptoms"
                        ) ||
                        this.props.doctorDetails.category.includes(
                          "Data Update"
                        ))
                        ? "block"
                        : "none"
                  }}
                >
                  <div className="title">Doctor Recommendation</div>
                  <div className="topRecommend">
                    <div className="choice_box">
                      <div className="radio_box">
                        <Form>
                          <Form.Field>
                            <div className="radio_group_title">
                              Medical Recommendation
                            </div>
                            <Radio
                              label="I recommend the patient come in for an office visit"
                              name="radioGroup"
                              value="radio_one"
                              checked={this.state.value === "radio_one"}
                              onChange={this.handleRadio}
                            />
                          </Form.Field>
                          <Form.Field>
                            <Radio
                              label="The patient does not need to come in for an office visit"
                              name="radioGroup"
                              value="radio_two"
                              checked={this.state.value === "radio_two"}
                              onChange={this.handleRadio}
                            />
                          </Form.Field>
                        </Form>
                      </div>
                      <div className="comment_box">
                        <textarea
                          placeholder="Message for the patient"
                          value={this.state.comment}
                          onChange={(e, data) => {
                            this.handleCommentsChange(e.target.value);
                          }}
                          className="radio_textarea"
                        />
                        <div className="replace_button_box">
                          <Button
                            color="green"
                            className="replace_button_save"
                            onClick={this.saveInfo.bind(this)}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                      <div className="clear_both" />
                    </div>
                  </div>
                </div>
              </div>
              <div id="download_patient_detail">
                <div style={{ display: "flex", gap: 12 }}>
                  <div style={{ flex: "3 3" }}>
                    {this.props.doctorDetails.sirenId && (
                      <SirenNotes sirenId={this.props.doctorDetails.sirenId} />
                    )}
                    <PatientSym />
                  </div>
                  <div style={{ flex: "3 3" }}>
                    <div className="title">
                      COMPLIANCE CALENDAR
                      <Popover
                        content={(
                          <div>
                            <p style={{margin: 10}}><b>Calendar Key</b></p>
                            <div style={{padding: 10, backgroundColor: '#f3f3f5'}}>
                              <p><b>Purple:</b> 30+ mins of wear </p>
                            </div>
                          </div>
                        )}
                      >
                        <QuestionCircleFilled style={{fontSize: 20, float: 'right', margin: 4}} />
                      </Popover>
                    </div>
                    <Calendar1 staff={true} />
                  </div>
                  <div style={{ flex: "2 2" }}>
                    <div className="title">FOOT WARNING STATUS</div>
                    <Status staff={true} />
                  </div>
                </div>
                <Collapse
                  bordered={true}
                  expandIconPosition={"right"}
                  expandIcon={({ isActive }) => (
                    <div style={{ color: "white", fontSize: 18 }}>
                      {isActive ? <MinusOutlined /> : <PlusOutlined />}
                    </div>
                  )}
                  className="title"
                  style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}
                >
                  <Collapse.Panel
                    header={
                      <div
                        className="title"
                        style={{
                          width: "100%"
                        }}
                      >
                        PHOTO
                      </div>
                    }
                    key="1"
                    className="site-collapse-custom-panel"
                    showArrow={true}
                  >
                    <Photo key={this.queryParams.userId} />
                  </Collapse.Panel>
                </Collapse>
                <Collapse
                  bordered={true}
                  expandIconPosition={"right"}
                  expandIcon={({ isActive }) => (
                    <div style={{ color: "white", fontSize: 18 }}>
                      {isActive ? <MinusOutlined /> : <PlusOutlined />}
                    </div>
                  )}
                  className="title"
                  style={{ marginTop: 12, height: "auto", boarderRadius: 0 }}
                >
                  <Collapse.Panel
                    header={
                      <div
                        className="title"
                        style={{
                          width: "100%"
                        }}
                      >
                        PATIENT DATA
                      </div>
                    }
                    key="1"
                    className="site-collapse-custom-panel site-collapse-custom-panel-overflow-visible"
                    showArrow={true}
                  >
                    <Calendar2 />
                  </Collapse.Panel>
                </Collapse>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Activity" key="4">
            <ActivityLog patientId={this.queryParams.id} />
          </TabPane>
          <TabPane tab="Data Monitoring Tracker" key="2">
            <Tracker99457 loadNewEditValue={this.getPatientDetails} />
          </TabPane>
          <TabPane tab="Equipment" key="3">
            <Equipment />
          </TabPane>
          <TabPane tab="Documents" key="5">
            <DocumentsList />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  listStaff: state.patientsListStore.listStaff,
  intakePatient: state.patientsListStore.intakePatient,
  listAdminStaff: state.patientsListStore.listAdminStaff
});
const mapDispatchToProp = dispatch => ({
  getPhotoReview: (inputDate, self) =>
    dispatch(getPhotoReviews(inputDate, self)),
  getDoctorDetails: (id, userId, start, end) =>
    dispatch(getDoctorDetails(id, userId, start, end)),
  getAppointmented: (inputData, self) =>
    dispatch(getAppointmented(inputData, self)),
  getCalenDate: calenDate => dispatch(getCalenDate(calenDate)),
  getlistStaff: () => dispatch(getlistStaff()),
  listLPNs: self => dispatch(listLPNs(self))
});
export default connect(mapStateToProp, mapDispatchToProp)(UserStatus);
