import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Button, Grid, Icon, Label, Segment, Table } from "semantic-ui-react";
import commonStyles from "../common/CommonStyles";
import Progress from "../home/progress";
import "./sockstatus.css";
import { clearSockHomeStatus, getSockHomeStatus } from "../action/patientAction";

class Equipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      id: "",
      userId: "",
      timeZone: "",
      sirenId: "",
      open: false
    };
  }
  componentWillMount() {
    const { clearSockStatus } = this.props;
    clearSockStatus();
    this.setState({ url: window.location.href, timeZone: null });
  }

  async componentDidMount() {
    const { getSockHomeStatus } = this.props;
    const id = this.props.doctorDetails.id;
    const userId = this.props.doctorDetails.userId;
    const sirenId = this.props.doctorDetails.sirenId;
    this.setState({ id, userId, sirenId });
    getSockHomeStatus(sirenId, this);
  }

  statusDetailsKeys = statusDetails => {
    let parts = "";
    Object.keys(statusDetails).forEach(key => {
      if (parts === "") {
        parts = parts + key + ": " + statusDetails[key];
      } else {
        parts = parts + "," + key + ": " + statusDetails[key];
      }
    });
    return parts;
  };

  render() {
    return (
      <div className="Statistics-container">
        <Modal
          isOpen={this.state.open}
          onRequestClose={this.closeModal}
          style={commonStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="label_sirenId_box">
          <Label as="a" ribbon className="label_sirenId">
            SirenId: {this.state.sirenId}
          </Label>
        </div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <div className="seg-title">
                  <Label as="a" color="red" ribbon>
                    Sock status:
                  </Label>
                </div>
                <Table celled className="result-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>BoxId</Table.HeaderCell>
                      <Table.HeaderCell>SerialNo</Table.HeaderCell>
                      <Table.HeaderCell>Foot</Table.HeaderCell>
                      <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                      <Table.HeaderCell>LastSeen</Table.HeaderCell>
                      <Table.HeaderCell>DataLastSeen</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.sockHomeStatus &&
                      this.props.sockHomeStatus.hasOwnProperty("socks") &&
                      this.props.sockHomeStatus.socks.map((sock, i) => {
                        let tableRow = (
                          <Table.Row
                            key={"sock-list-" + i}
                            className={sock.registered ? "reSock" : "noResock"}
                          >
                            <Table.Cell>{sock.boxId}</Table.Cell>
                            <Table.Cell>{sock.serialNo}</Table.Cell>
                            <Table.Cell>{sock.foot}</Table.Cell>
                            <Table.Cell>
                              {sock.fwVersion === "0.1" ? "-" : sock.fwVersion}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.lastSeen
                                ? moment
                                    .tz(sock.lastSeen, this.state.timeZone)
                                    .format("MM/DD/YYYY HH:mm:ss")
                                : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {sock.dataLastSeen
                                ? moment(
                                    sock.dataLastSeen.replace("Z", "")
                                  ).format("MM/DD/YYYY HH:mm:ss")
                                : ""}
                            </Table.Cell>
                          </Table.Row>
                        );
                        return tableRow;
                      })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <div className="seg-title">
                  <Label as="a" color="red" ribbon>
                    HubList:
                  </Label>
                </div>
                <Table celled className="result-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>HubId</Table.HeaderCell>
                      <Table.HeaderCell>Authcode</Table.HeaderCell>
                      <Table.HeaderCell>Firmware Version</Table.HeaderCell>
                      <Table.HeaderCell>DataLastSeen</Table.HeaderCell>
                      <Table.HeaderCell>HubLastSeen</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.sockHomeStatus &&
                      this.props.sockHomeStatus.hasOwnProperty("hubs") &&
                      this.props.sockHomeStatus.hubs.map((hub, i) => {
                        let tableRow = (
                          <Table.Row key={"hub-list-" + i}>
                            <Table.Cell>{hub.hubId}</Table.Cell>
                            <Table.Cell>{hub.authcode}</Table.Cell>
                            <Table.Cell>
                              {hub.fwVersion ? hub.fwVersion : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {!_.isEmpty(hub.lastSeen)
                                ? moment(hub.lastSeen.replace("Z", "")).format(
                                    "MM/DD/YYYY HH:mm:ss"
                                  )
                                : ""}
                            </Table.Cell>
                            <Table.Cell>
                              {hub.hubLastSeen
                                ? moment
                                    .tz(hub.hubLastSeen, this.state.timeZone)
                                    .format("MM/DD/YYYY HH:mm:ss")
                                : ""}
                            </Table.Cell>
                          </Table.Row>
                        );
                        return tableRow;
                      })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Segment>
                <div className="seg-title">
                  <Label as="a" color="red" ribbon>
                    App Information:
                  </Label>
                </div>
                <Table celled className="result-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>AppVersion</Table.HeaderCell>
                      <Table.HeaderCell>DeviceName</Table.HeaderCell>
                      <Table.HeaderCell>OS</Table.HeaderCell>
                      <Table.HeaderCell>OS Version</Table.HeaderCell>
                      <Table.HeaderCell>Last Login</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.sockHomeStatus &&
                      this.props.sockHomeStatus.hasOwnProperty("appInfo") &&
                      this.props.sockHomeStatus.appInfo.map((appInfo, i) => {
                        let tableRow = (
                          <Table.Row key={"hub-list-" + i}>
                            <Table.Cell>{appInfo.appVersion}</Table.Cell>
                            <Table.Cell>{appInfo.deviceName}</Table.Cell>
                            <Table.Cell>{appInfo.deviceOS}</Table.Cell>
                            <Table.Cell>{appInfo.devcieOSVersion}</Table.Cell>
                            {/*<Table.Cell>{appInfo.deviceId}</Table.Cell>*/}
                            <Table.Cell>
                              {appInfo.updatedAt
                                ? moment
                                    .tz(appInfo.updatedAt, this.state.timeZone)
                                    .format("MM/DD/YYYY HH:mm:ss")
                                : moment
                                    .tz(appInfo.createdAt, this.state.timeZone)
                                    .format("MM/DD/YYYY HH:mm:ss")}
                            </Table.Cell>
                          </Table.Row>
                        );
                        return tableRow;
                      })}
                  </Table.Body>
                </Table>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  sockHomeStatus: state.patientsListStore.sockHomeStatus
});

const mapDispatchToProp = dispatch => ({
  getSockHomeStatus: (sirenId, self) =>
    dispatch(getSockHomeStatus(sirenId, self)),
  clearSockStatus: () => dispatch(clearSockHomeStatus())
});
export default connect(mapStateToProp, mapDispatchToProp)(Equipment);
