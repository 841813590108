export const getPhotoReview = `query getPatient($id: String!, $userid: String!, $start: String, $end: String) {
  getPatient(id: $id, userId: $userid) {
    userId
    photoTask{
      taskId
      taskTime
      status
      type
      imageUrls
    }
    footReports(startDate: $start, endDate: $end) {
      date
      steps
      timeWorn
    }
  }
}
`;

export const listRpmTracker = `query listRpmTracker($userId: String!, $startDate: String, $endDate: String){
  listRpmTracker(userId: $userId, startDate: $startDate, endDate: $endDate) {
     items {
       userId
       rpmKey
       serviceDate
       spendTime
       doctorName
       doctorId
       rpmService
       description
       doctorTime
       nurseTime
       medicalCondition
       footSpecific
       footAmputation
       createdAt
       diagnosisCodes
       program
       programTime
       symptoms {
         reportTime
         footSymptom
         detail
         foot
         footArea
       }
       footConditions{
         reportTime
         footCondition
         foot
         footArea
         startTime
         endTime
         followUpTime
         status
       }
       footTreatments{
         reportTime
         footTreatment
         foot
         startTime
         endTime
         followUpTime
         status
       }
       addendums{
         userName
         addendum
         addendumTime
         nurseTime
         doctorTime
       }
     }
  }
}`;

export const docDetail = `query getPatient($id: String!, $userid: String!, $start: String, $end: String) {
  getPatient(id: $id, userId: $userid) {
    id
    accountStatus
    appLastSeen
    appInitialized
    appInitializedDate
    authSecondaryContact
    userId
    sirenId
    siteId
    userTimezone
    birthday
    contactName
    contactPhone
    createdAt
    category
    dataLastSeen
    deletedAt
    deletedBy
    dispensed
    dispensedDay
    dispenseComments
    dispenseType
    dispenseSize
    dispenseColor
    dispenseAddress
    dispenseCity
    dispenseState
    dispenseZip
    diagnosisCodes
    doctorId
    email
    enableApp
    enableEmail
    enableSms
    firstName
    footSpecific
    footAmputation
    group
    gender
    homePhone
    hubType
    initialized
    initializedStr
    initializedDate
    lastName
    LPN
    medicalCondition
    overallStatus
    phone
    photoSource
    patientId
    patientHasSmartPhone
    patientMBI
    patientResidence
    patientHasSmartPhone
    patientHasWifi
    patientNotes
    relation
    reviewStatus
    reviewPhotoStatus
    secondaryEmail
    shipmentStatus
    sirenId
    siteId
    sex
    sockStatus
    symptomDetail
    timeWorn
    userId
    userTimezone
    specialCase
    specialCaseReason
    diagnosisCodes
    category
    program
    rpmProgramStatus
    rtmProgramStatus
    pcmProgramStatus
    ccmProgramStatus
    ccmDeviceStatus
    pcmDeviceStatus
    rtmDeviceStatus
    rpmDeviceStatus
    doctor{
      firstName
      lastName
    }
    socks{
      createdAt
    }
    hubs{
      hubId
      lastSeen
      hubLastSeen
    }
    notes{
      id
      timestamp
      author
      note
      title
      authorId
    }
    photoTask{
      taskId
      taskTime
      status
      type
      imageUrls
    }
    footStatuses(startDate: $start, endDate: $end){
      overall
      date
      ltoe
      lmts1
      lmts3
      lmts5
      larch
      lheel
      rtoe
      rmts1
      rmts3
      rmts5
      rarch
      rheel
    }
    footSymptoms(startDate: $start, endDate: $end){
      reportTime
      symptomType
      footSymptom
      source
    }
    patientMedicalInfo{
      footConditions{
        reportTime
        footCondition
        foot
        footArea
        startTime
        endTime
        followUpTime
        status
      }
      footTreatments{
        reportTime
        footTreatment
        foot
        startTime
        endTime
        followUpTime
        status
      }
      footSpecific
      footAmputation
      medicalCondition
    }
  }
}
`;
export const getSyms = `query getPatient($id: String!, $userid: String!, $start: String, $end: String) {
  getPatient(id: $id, userId: $userid) {
    userId
    sirenId
    footSymptoms(startDate: $start, endDate: $end){
      reportTime
      symptomType
    }
  }
}
`;
export const getFoot = `query getPatient($id: String!, $userid: String!, $start: String, $end: String) {
  getPatient(id: $id, userId: $userid) {
    userId
    footStatuses(startDate: $start, endDate: $end){
      overall
      date
      ltoe
      lmts1
      lmts3
      lmts5
      larch
      lheel
      rtoe
      rmts1
      rmts3
      rmts5
      rarch
      rheel
    }
    footOverview(userId: $userid, date: $end){
      minuteWorn
      steps
      leftAvg
      rightAvg
      leftAvg7
      rightAvg7
      leftPrevAvg
      rightPrevAvg
      leftPrevAvg7
      rightPrevAvg7
    }
  }
}
`;
// export const listPatients = `query listPatient($filter: ModelPatientFilterInput) {
//   listPatients(filter: $filter) {
//       items {
//         sirenId
//         userTimezone
//         complianceReviewed
//         complianceReviewReq
//         group
//         userId
//         email
//         phone
//         givenName
//         lastSeen
//         dataLastSeen
//         timeWorn
//         overallStatus
//         sockStatus
//       }
//   }
// }
// `;
// export const doctorRole = `query listPatient($filter: ModelPatientFilterInput) {
//   listPatients(filter: $filter) {
//       items {
//         siteId
//         phone
//         sirenId
//         email
//         patientId
//         doctor{
//           doctorId
//           firstName
//           lastName
//         }
//         userInfo{
//           firstName
//           lastName
//           birthday
//           phone
//         }
//         birthday
//         userTimezone
//         userId
//         givenName
//         timeWorn
//         dataLastSeen
//         overallStatus
//         reviewStatus
//         appointmented
//         scheduled
//         complianceReviewed
//         group
//       }
//   }
// }
// `;

// export const appointmentRole = `query listPatient($filter: ModelPatientFilterInput) {
//   listPatients(filter: $filter) {
//       items {
//         siteId
//         sirenId
//         email
//         phone
//         userTimezone
//         patientId
//         birthday
//         userInfo{
//           firstName
//           lastName
//           birthday
//           phone
//         }
//         userId
//         givenName
//         appointmented
//         scheduled
//       }
//   }
// }
// `;
export const getCalendarData = `query getPatient($id: String!, $userId: String!, $startDate: String, $endDate: String,$limit:Int) {
  getPatient(id: $id, userId: $userId) {
    userId
     footStatuses(startDate: $startDate, endDate: $endDate,limit:$limit) {
        date
        overall
        minuteWorn
      }
  }
}
`;

export const listPatientMin1 = `query listPatientMin1($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min1(userId: $userId, startDate: $startDate, endDate: $endDate)
    min1raw(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;

export const listPatientMin30 = `query listPatientMin30($id: String!, $userId: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userId){
    min30(userId: $userId, startDate: $startDate, endDate: $endDate)
    min30raw(userId: $userId, startDate: $startDate, endDate: $endDate)
  }
}`;
export const listClinicSites = `query listClinicSitesNew {
  listClinicSitesNew {
    items {
      siteId
      name
      address
      fax
      contact
      phone
      hubContract
      IRBConsent
      onStaffing
    }
  }
}
`;
export const patientStaff = `query listStaff($filter: ModelStaffFilterInput) {
  listStaff(filter: $filter) {
    items {
      siteId
      email
      isPrescribing
      doctorId
      firstName
      lastName
    }
  }
}
`;
export const PatientMinuteWorn = `query getPatient($id: String!, $userid: String!, $startDate: String, $endDate: String) {
  getPatient(id: $id, userId: $userid) {
    timeWornByDay(userId: $userid, startDate: $startDate, endDate: $endDate) {
      date
      minuteWorn
    }
  }
}
`;
export const listStaff = `query listStaff($filter: ModelStaffFilterInput) {
  listStaff(filter: $filter) {
    items {
      siteId
      firstName
      lastName
      email
      isPrescribing
      doctorId
      doctorRole
      doctorNPI
      lastLogin
      autoReportSites
      notificationEmail
      weeklyReportSend
      weeklyNotificationStatus
    }
  }
}
`;
// export const searchPatientsConnection = `query searchPatients($filter: SearchablePatientFilterInput, $sort: SearchablePatientSortInput, $limit: Int, $nextToken: Int) {
//   searchPatients(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
//       items {
//         siteId
//         patientId
//         sirenId
//         birthday
//         doctorId
//         reviewStatus
//         appointmented
//         scheduled
//         userTimezone
//         complianceReviewed
//         complianceReviewReq
//         group
//         userId
//         email
//         doctor{
//           doctorId
//           lastName
//           firstName
//         }
//         phone
//         givenName
//         dataLastSeen
//         timeWorn
//         overallStatus
//         sockStatus
//         userInfo{
//           firstName
//           lastName
//           birthday
//           phone
//         }
//         createdAt
//         accountStatus
//       }
//       total
//       nextToken
//   }
// }`;

export const lambdaSearchPatients = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
   items {
        id
        siteId
        siteName
        patientId
        sirenId
        birthday
        doctorId
        doctorName
        reviewStatus
        appointmented
        scheduled
        userTimezone
        complianceReviewed
        complianceReviewReq
        group
        userId
        patientResidence
        email
        patientMBI
        doctor{
          doctorId
          lastName
          firstName
        }
        phone
        dataLastSeen
        appLastSeen
        timeWorn
        overallStatus
        sockStatus
        firstName
        lastName
        birthday
        createdAt
        accountStatus
        accountServiceStatus
        hubs{
          hubId
          lastSeen
          hubLastSeen
        }
        hubType
        estimated99454StartDay
        rpmProgramStatus
        rtmProgramStatus
        pcmProgramStatus
        ccmProgramStatus
        ccmDeviceStatus
        pcmDeviceStatus
        rtmDeviceStatus
        rpmDeviceStatus
      }
    total
    nextToken
  }
}`;

export const lambdaSearchAllPatients = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
   items {
        sirenId
        userId
        siteId
        siteName
        email
        userTimezone
        deletedAt
        deletedBy
        patientMBI
        phone
        gender
        createdAt
        dispensedDay
        dispensed
        sockStatus
        shipmentStatus
        id
        firstName
        lastName
        patientId
        birthday
        doctorId
        contactName
        contactPhone
        initialized
        createdAt
        secondaryEmail
        relation
        medicalCondition
        footSpecific
        footAmputation
        patientNotes
        diagnosisCodes
        initializedStr
        appInitialized
        appInitializedDate
        homePhone
        authSecondaryContact
        dispenseComments
        dispenseType
        dispenseSize
        dispenseColor
        dispenseAddress
        dispenseCity
        dispenseState
        dispenseZip
        symptomDetail
        initializedDate
        accountStatus
        patientResidence
        socks{
          createdAt
        }
        hubs{
          hubId
          lastSeen
          hubLastSeen
        }
        hubType
        patientHasSmartPhone
        patientHasWifi
        enableApp
        enableEmail
        enableSms
      }
    total
    nextToken
  }
}`;

export const listBillingPatients = `query listBillingPatients($siteId: String! $year: Int $month:Int) {
  listBillingPatient(siteId:$siteId, year: $year, month: $month ) {
    items {
      userId
      siteId
      id
      siteName
      birthday
      firstName
      lastName
      sirenId
      billDay
      givenName
      createdAt
      dispensedDay
      period99454Start
      period99454End
      periodStdStart
      periodStdEnd
      daysWorn
      billed99453
      billed99454
      billed99457
      billingKey
    },
    nextToken
  }
}
`;
export const listBilling99454Patients = `query listBilling99454Patients($siteId: String! $startDate: String $endDate:String) {
  listBilling99454Patient(siteId:$siteId, startDate: $startDate, endDate: $endDate ) {
    items {
      userId
      id
      siteId
      siteName
      billedType
      sirenId
      birthday
      firstName
      lastName
      billDay
      sirenId
      billingDate
      billedAt
      patientId
      initializedDate
      appInitialized
      billedBy
      doctorName
      billedStatus
      givenName
      createdAt
      dispensedDay
      period99454Start
      period99454End
      estimated99454DaysWorn
      estimated99454StartDay
      daysWorn
      diagnosisCodes
      accountBillingStatus
    },
    nextToken
  }
}
`;
export const listBilling99453Patients = `query listBilling99453Patients($siteId: String!) {
  listBilling99453Patient(siteId: $siteId) {
    items {
      userId
      siteId
      siteName
      billedType
      sirenId
      birthday
      firstName
      patientId
      id
      lastName
      diagnosisCodes
      sirenId
      billDay
      billed99453
      billed99453By
      billed99453At
      initializedDate
      appInitialized
      givenName
      doctorName
      createdAt
      dispensedDay
      daysWorn
      estimated99454StartDay
      estimated99454DaysWorn
      accountStatus
      firstStatusDay
      accountBillingStatus
      rpmDeviceStatus
      rtmDeviceStatus
      rpmProgramStatus
      rtmProgramStatus
      pcmProgramStatus
      ccmProgramStatus
    },
    nextToken
  }
}
`;
export const listBilling99457Patients = `query listBilling99457Patients($siteId: String! $startDate: String $endDate:String) {
  listBilling99457Patient(siteId:$siteId, startDate: $startDate, endDate: $endDate ) {
    items {
      id
      userId
      siteId
      siteName
      sirenId
      birthday
      billedType
      firstName
      lastName
      sirenId
      patientId
      billingDate
      diagnosisCodes
      billedAt
      initializedDate
      appInitialized
      billedBy
      billedStatus
      givenName
      doctorName
      createdAt
      dispensedDay
      period99454Start
      period99454End
      periodStdStart
      periodStdEnd
      daysWorn
      totalMinutesSpent
      serviceE,
      firstStatusDay
      accountBillingStatus
    },
    nextToken
  }
}
`;
export const listUserGroups = `query listUserGroups{
  listUserGroups
}`;
export const listBillingSummary = `query listBillingSummary($siteId: String! $sirenIds: String $year: Int $month:Int) {
  listBillingSummary(siteId:$siteId, sirenIds: $sirenIds, year: $year, month: $month ) {
    items {
      userId
      patientShortName
      sirenId
      givenName
      patientId
      cptCode
      serviceDate
      billDay
      createdAt
      dispensedDay
      period99454Start
      period99454End
      periodStdStart
      periodStdEnd
      daysWorn
      siteName
      yellowDays
      redDays
      birthday
      doctorId
      min30
      doctorName
    },
    nextToken
  }
}
`;

export const getDoctorNpi = `query getDoctorNpi($firstName: String!,$lastName: String!){
  getDoctorNpi(firstName:$firstName, lastName:$lastName)
}`;

export const getSock = `query getPatient($id: String!, $userid: String!) {
  getPatient(id: $id, userId: $userid) {
    userId
    sirenId
    socks{
      boxId
      registered
      serialNo
      foot
      battery
      lastSeen
      sensorStatus
      statusDetails
      status
      fwVersion
      brokenTime
      dataLastSeen
    }
    hubs{
      hubId
      authcode
      lastSeen
      hubLastSeen
      fwVersion
      csq
    }
    appInfo{
      appVersion
      deviceName
      deviceId
      deviceOS
      devcieOSVersion
      createdAt
      updatedAt
    }
  }
}
`;

export const listManagementSummary = `query searchPatients($searchStr: String, $sort: SearchablePatientSortInput, $filter: SearchablePatientFilterInput, $limit: Int, $nextToken: Int) {
  lambdaSearchPatients(searchStr: $searchStr, filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      sirenId
      email
      firstName
      lastName
      patientId
      siteId
      birthday
      dataLastSeen
      doctorId
      phone
      createdAt
      dispensed
      dispensedDay
      shipmentStatus
      initializedDate
      initializedStr
      appInitialized
      appInitializedDate
      sockStatus
      overallStatus
      userTimezone
      doctor{
         doctorId
         lastName
         firstName
      }
      hubs{
        hubId
        lastSeen
        hubLastSeen
      }
      appInfo{
        createdAt
      }
      socks{
        boxId
        createdAt
      }
    }
    total
    nextToken
  }
}
`;

export const verifyHubInfo = `query verifyHubInfo($hubId:String! $siteId:String!){
  verifyHubInfo(hubId: $hubId, siteId:$siteId){
    hubId,
    authcode,
    registered
  }
}`;

export const verifyBoxInfo = `query verifyBoxInfo($boxId:String! $siteId:String!){
  verifyBoxInfo(boxId: $boxId, siteId:$siteId){
    id,
    authCode,
    registered
  }
}`;

export const checkPatientSocksAndHub = `query getPatientBySirenId($sirenId:Int!){
  getPatientBySirenId(sirenId: $sirenId){
    socks{
      serialNo
    }
    hubs{
      hubId
    }
  }
}
`;

export const listAdminStaff = `query listAdminStaff{
  listAdminStaff{
   items{
     id
     sub
     email
     firstName
     lastName
     phone
     status
     createdAt
     updatedAt
     emailVerified
     phoneVerified
     group
     primarySites
     EMRSystem
     backupFor
     patientLoad
     filterColumns
     patientCount
   }
  }
}`;

export const getSockBySirenId = `query getPatientBySirenId($sirenId:Int!){
  getPatientBySirenId(sirenId: $sirenId){
    id
    userId
    userTimezone
    socks{
      serialNo
      boxId
      sku
      fwVersion
      lastSeen
      dataLastSeen
      battery
      brokenTime
      sensorStatus
      statusDetails
      status
      registered
      foot
    }
    hubs{
      hubId
      authcode
      lastSeen
      fwVersion
      hubLastSeen
      csq
      ccid
      lifecycle
      lifecycleEndTime
      syncTime
      totalFlow
      useageFlow
    }
    appInfo{
      appVersion
      deviceOS
      deviceId
      deviceName
      devcieOSVersion
      createdAt
    }
  }
}
`;

export const getDoctorProfile = `query getDoctorProfile {
  doctorProfile {
    siteId
    firstName
    lastName
    email
    doctorId
    doctorRole
    isPrescribing
  }
}`;

export const getPatientActivity = `query getPatientActivity($patientId: String!, $limit: Int, $nextToken: Int, $yearMonth: String, $category: [PatientActivityCategory]) {
  getPatientActivity(patientId: $patientId, limit: $limit, nextToken: $nextToken, yearMonth: $yearMonth, category: $category) {
    items {
      status
      subject
      description
      loggingType
      callType
      createdDate
      createdBy
    }
    nextToken
  }
}`;

export const listICD10Codes = `query listICD10Codes {
  listICD10Codes {
    code
  }
}`;

export const listCarePlans = `query getCarePlans($userId: String!) {
  getCarePlans(userId: $userId) {
    createdAt
    userId
    name
    author
    authorId
    location
    month
  }
}`;

export const listMedicalConditions = `query listMedicalConditions {
  listMedicalConditions {
    condition
    value
    createdBy
    createdAt
  }
}`;

export const listBillingPCMPatients = `query listBillingPCMPatients($siteId: String! $startDate: String $endDate:String) {
  listBillingPCMPatient(siteId:$siteId, startDate: $startDate, endDate: $endDate ) {
    items {
      id
      userId
      siteId
      siteName
      sirenId
      birthday
      billedType
      firstName
      lastName
      sirenId
      patientId
      billingKey
      diagnosisCodes
      billedAt
      initializedDate
      appInitialized
      billedBy
      billedStatus
      givenName
      doctorName
      createdAt
      dispensedDay
      periodStdStart
      periodStdEnd
      totalMinutesSpent
      firstStatusDay
      accountBillingStatus
    },
    nextToken
  }
}
`;
