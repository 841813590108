import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, Divider, Input } from "antd";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Popup } from "semantic-ui-react";
import {
  FOOT_AMPUTATION,
  FOOT_SPECIFIC,
  MEDICAL_GENERAL
} from "../tools/patient-medical";

/**
 *  Created by shengli.zhang on 4/30/21 3:17 PM
 */
const DOWN = require("../img/down_blue.png");
const UP = require("../img/up_blue.png");
const AMPUTATION_ON_FOOT = require("../img/Amputation_on_Foot.png");

const { TextArea } = Input;

class PatientMedicalHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: this.props.size || 'default',
      medicalCondition: this.props.medicalCondition || [],
      footSpecific: this.props.footSpecific || [],
      footAmputation: this.props.footAmputation || [],
      viewAll: !props.collapse,
      otherDisease: ''
    };
  }

  componentDidMount() {
    const otherMedical = this.state.medicalCondition.find(item => item.startsWith('Other'));
    if (otherMedical) {
      const otherDisease = otherMedical.substring(6, otherMedical.length - 1);
      const medicalCondition = this.state.medicalCondition.filter(item => item !== otherMedical);
      medicalCondition.push('Other');
      this.setState({
        medicalCondition,
        otherDisease
      })
    }
  }

  onChangeMedicalGeneral = (event, medicalInfo) => {
    let medicalCondition = [...this.state.medicalCondition];
    if (event.target.checked) {
      medicalCondition.push(medicalInfo);
    } else {
      medicalCondition = _.filter(medicalCondition, function(p) {
        return p !== medicalInfo;
      });
    }
    this.setState({ medicalCondition });
    this.props.updateMedicalCondition(medicalCondition);
  };

  onChangeFootSpecificLeft = (event, footSpecificInfo, index) => {
    let footSpecific = this.state.footSpecific;
    if (event.target.checked) {
      if (!footSpecific.includes(footSpecificInfo.value_L)) {
        footSpecific.push(footSpecificInfo.value_L);
      }
    } else {
      if (footSpecific.includes(footSpecificInfo.value_L)) {
        footSpecific = _.filter(footSpecific, function(p) {
          return p !== footSpecificInfo.value_L;
        });
      }
    }
    this.setState({ footSpecific });
    this.props.updateFootSpecific(footSpecific);
  };

  onChangeFootSpecificRight = (event, footSpecificInfo, index) => {
    let footSpecific = this.state.footSpecific;
    if (event.target.checked) {
      if (!footSpecific.includes(footSpecificInfo.value_R)) {
        footSpecific.push(footSpecificInfo.value_R);
      }
    } else {
      if (footSpecific.includes(footSpecificInfo.value_R)) {
        footSpecific = _.filter(footSpecific, function(p) {
          return p !== footSpecificInfo.value_R;
        });
      }
    }
    this.setState({ footSpecific });
    this.props.updateFootSpecific(footSpecific);
  };

  onChangeFootAmputationLeft = (event, footAmputationInfo, index) => {
    let footAmputation = this.state.footAmputation;
    if (event.target.checked) {
      if (!footAmputation.includes(footAmputationInfo.value_L)) {
        footAmputation.push(footAmputationInfo.value_L);
      }
    } else {
      if (footAmputation.includes(footAmputationInfo.value_L)) {
        footAmputation = _.filter(footAmputation, function(p) {
          return p !== footAmputationInfo.value_L;
        });
      }
    }
    this.setState({ footAmputation });
    this.props.updateFootAmputation(footAmputation);
  };

  onChangeFootAmputationRight = (event, footAmputationInfo, index) => {
    let footAmputation = this.state.footAmputation;
    if (event.target.checked) {
      if (!footAmputation.includes(footAmputationInfo.value_R)) {
        footAmputation.push(footAmputationInfo.value_R);
      }
    } else {
      if (footAmputation.includes(footAmputationInfo.value_R)) {
        footAmputation = _.filter(footAmputation, function(p) {
          return p !== footAmputationInfo.value_R;
        });
      }
    }
    this.setState({ footAmputation });
    this.props.updateFootAmputation(footAmputation);
  };

  render() {
    const disableCheck = this.props.readOnly && !this.props.canEdit;
    return (
      <div>
        <div style={{ display: "flex", alignItems: "baseline", marginTop: 20 }}>
          <div className="patient_note_label" style={{ fontSize: 18 }}>
            Patient Medical History
          </div>{" "}
          <Button
            type="link"
            size="small"
            onClick={() => {
              this.setState({ viewAll: !this.state.viewAll });
            }}
          >
            View all
            <img
              src={this.state.viewAll ? UP : DOWN}
              style={{ width: 12, height: 16, marginLeft: 5 }}
            />
          </Button>
        </div>
        {this.props.readOnlyHint && (
          <div style={{ marginTop: 10 }}>
            For view only. To change any medical history, go to{" "}
            <Button
              type="link"
              style={{ marginLeft: -15 }}
              onClick={() => {
                this.props.editFootMedical("2");
              }}
            >
              DMT
            </Button>
          </div>
        )}
        {this.state.viewAll ? (
          <div>
            <div style={{ display: "flex" }}>
              <div
                className="diagnosis_codes_new"
                style={{ width: 200, marginLeft: 0 }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    height: 20
                  }}
                >
                  General
                </div>
                {MEDICAL_GENERAL.map((p, index) => {
                  return (
                    <div style={{ marginTop: 10 }} key={index}>
                      <div style={{ display: 'inline-block', width: "80%" }}>{p.name}</div>
                      <Checkbox
                        checked={this.state.medicalCondition.includes(p.value)}
                        onChange={e => this.onChangeMedicalGeneral(e, p.value)}
                        disabled={disableCheck}
                        style={{ float: 'right', marginRight: 10 }}
                      />
                    </div>
                  );
                })}
                <div style={{ marginTop: 10 }}>
                  <div style={{ display: 'inline-block', width: "80%" }}>Other (optional)</div>
                  <Checkbox
                    checked={this.state.medicalCondition.includes('Other')}
                    onChange={e => this.onChangeMedicalGeneral(e, 'Other')}
                    disabled={disableCheck}
                    style={{ float: 'right', marginRight: 10 }}
                  />
                  <div style={{marginTop: 8, display: this.state.medicalCondition.includes('Other') ? 'block' : 'none'}}>
										<TextArea
                      disabled={disableCheck}
											style={this.state.otherDisease.length === 0 ? {borderColor: 'red'} : {}}
											autoSize={{minRows: 2, maxRows: 4}}
											value={this.state.otherDisease}
											onChange={e => {
												if (e.target.value.length <= 50) {
                          const fOther = e.target.value.replaceAll(',', ';');
													this.setState({
														otherDisease: fOther
													})
                          const medicalCondition = this.state.medicalCondition.map(item => {
                            if (item === 'Other') {
                              return `Other(${fOther})`;
                            }
                            return item;
                          });
                          this.props.updateMedicalCondition(medicalCondition);
												}
											}} />
                    <div style={{float: 'left', display: disableCheck ? 'none' : 'block'}}>50 characters limit</div>
										<div style={{float: 'right', display: disableCheck ? 'none' : 'block', color: this.state.otherDisease.length === 50 ? 'red' : '#00000073'}}>{this.state.otherDisease.length} / 50</div>
									</div>
                </div>
              </div>
              <Divider type="vertical" style={{ marginTop: 20, height: 340 }} />
              <div
                className="diagnosis_codes_new"
                style={{ marginLeft: 40, width: 250 }}
              >
                <div style={{ display: "flex", height: 20 }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      width: "70%"
                    }}
                  >
                    Foot Specific
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      paddingTop: 10,
                      marginLeft: -3
                    }}
                  >
                    Left
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                      fontSize: 12,
                      paddingTop: 10
                    }}
                  >
                    Right
                  </div>
                </div>
                {FOOT_SPECIFIC.map((p, index) => {
                  return (
                    <div
                      style={{ display: "flex", marginTop: 10 }}
                      key={`${index}`}
                    >
                      <div style={{ width: "70%" }}>{p.name}</div>
                      <Checkbox
                        checked={this.state.footSpecific.includes(p.value_L)}
                        onChange={e =>
                          this.onChangeFootSpecificLeft(e, p, index)
                        }
                        disabled={disableCheck}
                      />
                      <Checkbox
                        checked={this.state.footSpecific.includes(p.value_R)}
                        onChange={e =>
                          this.onChangeFootSpecificRight(e, p, index)
                        }
                        style={{ marginLeft: 20 }}
                        disabled={disableCheck}
                      />
                    </div>
                  );
                })}
              </div>
              <Divider type="vertical" style={{ marginTop: 20, height: 280 }} />
              <div
                className="diagnosis_codes_new"
                style={{ marginLeft: 40, width: 200 }}
              >
                <div style={{ display: "flex", height: 20 }}>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      width: "60%"
                    }}
                  >
                    Amputation
                    <Popup
                      trigger={
                        <InfoCircleFilled
                          style={{
                            color: "#D3D6E1",
                            marginLeft: 5
                          }}
                        />
                      }
                      position="bottom center"
                      hoverable
                      flowing
                      style={{ backgroundColor: "#F1F1F1" }}
                    >
                      <Popup.Header
                        style={{
                          marginLeft: 10,
                          marginTop: 5,
                          fontSize: 14
                        }}
                      >
                        Amputation on Foot
                      </Popup.Header>
                      <Popup.Content>
                        <img
                          src={AMPUTATION_ON_FOOT}
                          style={{
                            width: 300,
                            height: (300 * 205) / 284
                          }}
                        />
                      </Popup.Content>
                    </Popup>
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      paddingTop: 10,
                      marginLeft: -3,
                      width: "15%"
                    }}
                  >
                    Left
                  </div>
                  <div
                    style={{
                      marginLeft: 15,
                      fontSize: 12,
                      paddingTop: 10
                    }}
                  >
                    Right
                  </div>
                </div>
                {FOOT_AMPUTATION.map((p, index) => {
                  return (
                    <div
                      style={{ display: "flex", marginTop: 10 }}
                      key={`${index}`}
                    >
                      <div style={{ width: "60%" }}>{p.name}</div>
                      <Checkbox
                        checked={this.state.footAmputation.includes(p.value_L)}
                        onChange={e =>
                          this.onChangeFootAmputationLeft(e, p, index)
                        }
                        style={{ width: "15%" }}
                        disabled={disableCheck}
                      />
                      <Checkbox
                        checked={this.state.footAmputation.includes(p.value_R)}
                        onChange={e =>
                          this.onChangeFootAmputationRight(e, p, index)
                        }
                        style={{ marginLeft: 20 }}
                        disabled={disableCheck}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  medicalConditionList: state.authGroupStore.medicalConditions.map(item => ({
    name: item.value,
    value: item.condition
  }))
});

export default connect(mapStateToProp, null)(PatientMedicalHistory);
