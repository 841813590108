import { Select, Tag, Popover, Divider } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { getPatientList } from "../../action/patientAction";
import "../../home/DeviceList.css";
import { formatNumber } from "../../tools/phoneFormat";
import getPatchTrProps, {
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../../tools/reactTablePatch";
import "./home.css";
import {
  COLOR,
  ProgramOptions,
  DeviceOptions
} from "../../constant/programDeviceStatus";
import ProgramStatusDesc from "../../tools/program-status-desc";
import DeviceStatusDesc from "../../tools/device-status-desc";
import CircleColor from "./circle-color";
import RtmDeviceStatus from "./RtmDeviceStatus";
import RpmDeviceStatus from "./RpmDeviceStatus";
import DateInput from "../../common/DateInput";
import {
  SortAscendingOutlined,
  SortDescendingOutlined
} from "@ant-design/icons";

export default class PatientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      totalSize: 0,
      rpmSort: null,
      rtmSort: null,
      pcmSort: null,
      ccmSort: null,
      socksSort: null,
      moveSort: null
    };

    this.tableInstance = null;
    this.debounceRequest = false;
  }

  gotoDoctorDetailPage(id, userId) {
    let date = moment().format("YYYY-MM-DD");

    const patientList = this.tableInstance.state.sortedData.map(p => ({
      id: p._original.id,
      userId: p._original.userId
    }));
    Control.go(
      `/StaffMedicalDetails?Patients/id=${id}&userId=${userId}&startDate=${date}&endDate=${date}`,
      { patientList }
    );
  }

  gotoStatisticsPage(id, userId, userTimezone) {
    Control.go(
      "/sockstatus?PatientsHome/id=" +
        id +
        "&userId=" +
        userId +
        "&timeZone=" +
        userTimezone
    );
  }

  reqPatientList = () => {
    this.debounceReqPatientList();
    if (!this.debounceRequest) {
      this.debounceReqPatientList.flush();
    }
  };

  debounceReqPatientList = _.debounce(() => {
    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance
    );

    this.rawReqPatientList(filter, sort, pagination);
  }, 3000);

  rawReqPatientList(filter, sort, pagination) {
    this.debounceRequest = false;
    this.setState({
      loading: true,
      patientError: null
    });

    getPatientList(filter, sort, pagination)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;

        this.setState({
          data: patiens,
          totalSize: totalSize
        });
      })
      .catch(error => {
        this.setState({
          patientError: error
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  programSort = program => {
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let rpmSort = null,
      rtmSort = null,
      pcmSort = null,
      ccmSort = null;
    switch (program) {
      case "RPM":
        rpmSort = !this.state.rpmSort ? "asc" : nextSort[this.state.rpmSort];
        break;
      case "RTM":
        rtmSort = !this.state.rtmSort ? "asc" : nextSort[this.state.rtmSort];
        break;
      case "PCM":
        pcmSort = !this.state.pcmSort ? "asc" : nextSort[this.state.pcmSort];
        break;
      case "CCM":
        ccmSort = !this.state.ccmSort ? "asc" : nextSort[this.state.ccmSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        rpmSort,
        rtmSort,
        pcmSort,
        ccmSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${program.toLowerCase()}ProgramStatus`
        });
      }
    );
  };

  deviceSort = device => {
    const deviceName2Code = {
      socks: "rpm",
      move: "rtm"
    };
    const nextSort = {
      asc: "desc",
      desc: null
    };
    let socksSort = null,
      moveSort = null;
    switch (device) {
      case "Socks":
        socksSort = !this.state.socksSort
          ? "asc"
          : nextSort[this.state.socksSort];
        break;
      case "Move":
        moveSort = !this.state.moveSort ? "asc" : nextSort[this.state.moveSort];
        break;
      default:
        break;
    }
    this.setState(
      {
        socksSort,
        moveSort
      },
      () => {
        this.tableInstance.sortColumn({
          id: `${deviceName2Code[device.toLowerCase()]}DeviceStatus`
        });
      }
    );
  };

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceRequest = false;
          return onChange(value);
        }}
        onChange={value => {
          this.debounceRequest = true;
          return onChange(value);
        }}
      />
    );
  };

  render() {
    const { Option } = Select;

    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(rowInfo.original.id, rowInfo.original.userId);
    });

    return (
      <div>
        <ReactTable
          loading={this.state.loading}
          getTrProps={trProps}
          ref={ref => (this.tableInstance = ref)}
          filterable
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.debounceRequest = true;
            },
            onPressEnter: () => {
              this.debounceRequest = false;
            }
          })}
          manual
          onSortedChange={newSorted => {
            if (!newSorted[0].id.endsWith("ProgramStatus")) {
              this.setState({
                rpmSort: null,
                rtmSort: null,
                pcmSort: null,
                ccmSort: null
              });
            }
            if (!newSorted[0].id.endsWith("DeviceStatus")) {
              this.setState({
                socksSort: null,
                moveSort: null
              });
            }
          }}
          pages={
            !this.tableInstance
              ? -1
              : Math.ceil(
                  this.state.totalSize / this.tableInstance.state.pageSize
                )
          }
          data={this.state.data}
          onFetchData={(_, instance) => {
            this.tableInstance = instance;

            this.reqPatientList();
          }}
          columns={[
            {
              Header: "SiteID",
              accessor: "siteId",
              maxWidth: 60
            },
            {
              Header: "SiteName",
              accessor: "siteName"
            },
            {
              Header: "Prescribing Doctor",
              accessor: "doctorName"
            },
            {
              Header: "SirenID",
              accessor: "sirenId",
              maxWidth: 60
            },
            {
              Header: "PatientID",
              accessor: "patientId"
            },
            {
              Header: "First Name",
              accessor: "firstName"
            },
            {
              Header: "Last Name",
              accessor: "lastName"
            },
            {
              Header: "DOB",
              accessor: "birthday",
              Filter: this.dateInputComponent,
              Cell: props => {
                return !_.isEmpty(props.value)
                  ? moment(props.value).format("YYYY-MM-DD")
                  : "";
              }
            },
            {
              Header: "Phone",
              accessor: "phone",
              Cell: props => {
                if (!_.isEmpty(props.value)) {
                  return formatNumber(props.value);
                }
                return "";
              }
            },
            {
              Header: "Medical Status",
              accessor: "overallStatus",
              Cell: props => {
                return props.value === "NORMAL" ? "GREEN" : props.value;
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="GREEN">GREEN</Option>
                  <Option value="RED">RED</Option>
                  <Option value="YELLOW">YELLOW</Option>
                </Select>
              )
            },
            {
              Header: "Time Worn(Avg last 7 days)",
              accessor: "timeWorn",
              Cell: props => {
                if (props.value) {
                  const h = Math.floor(props.value / 60);
                  const m = props.value % 60;
                  return `${h}h${m}m`;
                }
                return "";
              }
            },
            {
              Header: "Last Date Worn",
              accessor: "dataLastSeen",
              Filter: this.dateInputComponent,
              Cell: props => {
                if (props.value) {
                  return props.value.split("T")[0];
                }
                return "";
              }
            },
            {
              style: { cursor: "pointer" },
              Header: "Sock Status",
              accessor: "sockStatus",
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoStatisticsPage(
                          props.original.id,
                          props.original.userId,
                          props.original.userTimezone
                        )
                      }
                    >
                      {props.original.sockStatus}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="Low Battery">Low Battery</Option>
                  <Option value="Broken">Broken</Option>
                  <Option value="Low Battery, Broken">
                    Low Battery, Broken
                  </Option>
                  <Option value="OK">OK</Option>
                </Select>
              )
            },
            {
              style: { cursor: "pointer" },
              Header: "Hub Type",
              accessor: "hubType",
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoStatisticsPage(
                          props.original.id,
                          props.original.userId,
                          props.original.userTimezone
                        )
                      }
                    >
                      {props.original.hubType}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="None">None</Option>
                  <Option value="Wifi">Wifi</Option>
                  <Option value="2G">2G</Option>
                  <Option value="4G">4G</Option>
                  <Option value="Wifi, 2G">Wifi, 2G</Option>
                  <Option value="Wifi, 4G">Wifi, 4G</Option>
                  <Option value="2G, 4G">2G, 4G</Option>
                  <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
                </Select>
              )
            },
            {
              style: {
                cursor: "pointer",
                textAlign: "left",
                paddingLeft: 10
              },
              Header: (
                <>
                  <Popover placement="top" content={<ProgramStatusDesc />}>
                    Program Status
                  </Popover>
                  <div
                    style={{ cursor: "pointer", marginTop: 6 }}
                    title="sort by program status"
                  >
                    {["RPM", "RTM", "PCM", "CCM"].map((program, index) => {
                      const stateKey = `${program.toLowerCase()}Sort`;
                      return (
                        <React.Fragment key={program}>
                          {index ? <Divider type="vertical" /> : null}
                          <span onClick={() => this.programSort(program)}>
                            {program}
                            <SortAscendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "asc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                            <SortDescendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "desc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                          </span>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              ),
              accessor: "programStatus",
              minWidth: 220,
              sortable: false,
              Cell: props => {
                return (
                  <div>
                    {props.original.rpmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.rpmProgramStatus]}
                        key={"RPM"}
                      >
                        {"RPM"}
                      </Tag>
                    )}
                    {props.original.rtmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.rtmProgramStatus]}
                        key={"RTM"}
                      >
                        {"RTM"}
                      </Tag>
                    )}
                    {props.original.pcmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.pcmProgramStatus]}
                        key={"PCM"}
                      >
                        {"PCM"}
                      </Tag>
                    )}
                    {props.original.ccmProgramStatus && (
                      <Tag
                        color={COLOR[props.original.ccmProgramStatus]}
                        key={"CCM"}
                      >
                        {"CCM"}
                      </Tag>
                    )}
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  mode="multiple"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter && filter.value}
                  placeholder="All"
                  showSearch
                  optionLabelProp="label"
                >
                  {ProgramOptions.map(e => (
                    <Option
                      value={e.value}
                      key={e.value}
                      label={<Tag color={e.color}>{e.tagText}</Tag>}
                    >
                      <CircleColor color={e.color} />
                      {e.text}
                    </Option>
                  ))}
                </Select>
              )
            },
            {
              style: {
                textAlign: "left",
                paddingLeft: 15
              },
              Header: (
                <>
                  <Popover placement="topLeft" content={<DeviceStatusDesc />}>
                    Device Status
                  </Popover>
                  <div
                    style={{ cursor: "pointer", marginTop: 6 }}
                    title="sort by device status"
                  >
                    {["Socks", "Move"].map((device, index) => {
                      const stateKey = `${device.toLowerCase()}Sort`;
                      return (
                        <React.Fragment key={device}>
                          {index ? <Divider type="vertical" /> : null}
                          <span onClick={() => this.deviceSort(device)}>
                            {device}
                            <SortAscendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "asc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                            <SortDescendingOutlined
                              style={{
                                display:
                                  this.state[stateKey] === "desc"
                                    ? "inline-block"
                                    : "none"
                              }}
                            />
                          </span>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </>
              ),
              accessor: "deviceStatus",
              minWidth: 200,
              sortable: false,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    {props.original.rpmDeviceStatus && (
                      <RpmDeviceStatus
                        deviceStatus={props.original.rpmDeviceStatus}
                        userId={props.original.userId}
                        id={props.original.id}
                        userInfo={props.original}
                        onChange={status => {
                          let patientData = [...this.state.patientData];
                          patientData[props.index].rpmDeviceStatus = status;
                          this.setState({ patientData });
                        }}
                      />
                    )}
                    {props.original.rtmDeviceStatus && (
                      <RtmDeviceStatus
                        deviceStatus={props.original.rtmDeviceStatus}
                        userId={props.original.userId}
                        id={props.original.id}
                        userInfo={props.original}
                        onChange={status => {
                          let patientData = [...this.state.patientData];
                          patientData[props.index].rtmDeviceStatus = status;
                          this.setState({ patientData });
                        }}
                      />
                    )}
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  mode="multiple"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter && filter.value}
                  showSearch
                  placeholder="All"
                  optionLabelProp="label"
                >
                  {DeviceOptions.map(e => (
                    <Option
                      value={e.value}
                      key={e.value}
                      label={<Tag color={e.color}>{e.tagText}</Tag>}
                    >
                      <CircleColor color={e.color} />
                      {e.text}
                    </Option>
                  ))}
                </Select>
              )
            }
          ]}
          defaultSorted={[{ id: "dataLastSeen", desc: true }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
