import { Checkbox, Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  generatePCMBillingReport, searchBillingPCMPatients, updateBilledPCMStatus
} from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import ChangeBilledPCM from "./pcm/changeBilledPCM";
import ReportHeader99426_7 from "./ReportHeader99426_7";
import ReportNeedsReviewBase, { ReviewAllCheckbox } from "./ReportNeedsReviewBase";


const { Option } = Select;

class ReportNeedsReview99426_7 extends ReportNeedsReviewBase {

  cptCode = "99426-7"

  headerClass = ReportHeader99426_7

  renderTableProps(selectAll) {
    const data = this.state.tableData &&
      this.state.tableData.map(p => {
        const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
        const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
        const initializedDate = p.initializedDate
          ? p.initializedDate.split("T")[0]
          : "";
        const appInitialized = p.appInitialized ? p.appInitialized : "No";
        const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
        const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
        const diagnosisCodes = p.diagnosisCodes
          ? p.diagnosisCodes.replace(/_/g, ",")
          : "";
        const billingMonth = p.billingKey
          ? moment(p.billingKey).format("MM/YYYY")
          : "";
        return {
          ...p,
          createdAt,
          sirenId,
          initializedDate,
          dispensedDay,
          appInitialized,
          billingMonth,
          spentTime,
          diagnosisCodes
        };
      })

    const columns = [
      {
        Header: props => {
          return (
            <Checkbox
              onChange={this.handleCheckAll}
              checked={selectAll}
            />
          );
        },
        accessor: "",
        maxWidth: 60,
        sortable: false,
        filterable: false,
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <Checkbox
                onChange={e => {
                  this.handleCheck(e.target.checked, props.index);
                }}
                checked={this.state.selectedPatients.includes(
                  props.index
                )}
              />
            </div>
          );
        }
      },
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60
      },
      {
        Header: "PatientID",
        accessor: "patientId"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "Date of Birth",
        accessor: "birthday"
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes"
      },
      {
        Header: "Account Created",
        accessor: "createdAt"
      },
      {
        Header: "App Activated",
        accessor: "appInitialized",
        maxWidth: 72,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "Yes") {
            return row[filter.id] === "Yes";
          }
          return row[filter.id] === "No";
        },
        Filter: ({ filter, onChange }) => (
          <Select
            className="select_common_style"
            onChange={onChange}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
          >
            <Option value="all">All</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        )
      },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth"
      },
      {
        Header: "Date of Service",
        accessor: "periodStdEnd",
        Cell: props => props.value || "N/A"
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime"
      },
      {
        Header: "Reviewed",
        accessor: "billedPCM",
        sortable: false,
        Filter: () => (
          <ReviewAllCheckbox
            patients={() => {
              let reviewPatients = [];
              if (this.tableInstance) {
                const { page, pageSize, sortedData } = this.tableInstance.state;
                reviewPatients = sortedData.slice(page * pageSize, (page + 1) * pageSize);
              }
              return reviewPatients;
            }}
            reviewTask={async p => {
              const inputData = {
                input: {
                  billingKey: p._original.billingKey,
                  userId: p._original.userId,
                  id: p._original.id,
                  billedBy: this.props.username,
                  billedStatus: "BILLED",
                  siteId: p._original.siteId
                }
              };
              await updateBilledPCMStatus(inputData);
            }}
            onFinish={this.searchPatients}
          />
        ),
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <ChangeBilledPCM
                userInfo={props.original}
                spentTime={props.original.spentTime}
                searchUsers={this.searchPatients}
              />
            </div>
          );
        }
      }
    ]

    return {
      data,
      columns
    }
  }

}

const mapStateToProp = state => ({
  username: state.authGroupStore.profile.email,
  authSites: state.authGroupStore.profile.siteId,
  clinicSites: state.patientsListStore.clinicSites,
  showGenerateDelay: state.monthlyReportStore.showGeneratePCMDelay,
  generateDelay: state.monthlyReportStore.generatePCMDelay
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  searchBillingPatients: (input) =>
    dispatch(searchBillingPCMPatients(input)),
  generateBillingReport: (input) =>
    dispatch(generatePCMBillingReport(input))
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportNeedsReview99426_7);
