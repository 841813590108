import phoneutil from "google-libphonenumber";
import _ from "lodash";

export function formatNumber(p) {
  try {
    const PNF = phoneutil.PhoneNumberFormat;
    const phoneUtil = phoneutil.PhoneNumberUtil.getInstance();
    let region = "US";
    if (p.trim().length === 11 && !_.startsWith(p, "+")) {
      region = "CN";
    }
    // if (p.includes("(")) {
    //   return p;
    // }
    const number = phoneUtil.parse(p, region);
    if (phoneUtil.isValidNumber(number)) {
      let formatE164 = phoneUtil.format(number, PNF.E164);
      const parserNumber = phoneUtil.parseAndKeepRawInput(formatE164);
      return phoneUtil.format(parserNumber, PNF.NATIONAL);
      // return phoneUtil.format(parserNumber, PNF.NATIONAL).replace(/\s+/g, "");
    } else {
      return p;
    }
  } catch (e) {
    return p;
  }
}

export function phoneWithCountryCode(p) {
  try {
    const PNF = phoneutil.PhoneNumberFormat;
    const phoneUtil = phoneutil.PhoneNumberUtil.getInstance();
    let region = "US";
    if (p.trim().length === 11 && !_.startsWith(p, "+")) {
      region = "CN";
    }
    if (p.includes("(")) {
      return p;
    }
    const number = phoneUtil.parse(p, region);
    if (phoneUtil.isValidNumber(number)) {
      return phoneUtil.format(number, PNF.E164);
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}

export function phoneWithCountryFormat(p) {
  try {
    let noSym1 = p.replace("(", "");
    let noSym2 = noSym1.replace(")", "");
    let noSym3 = noSym2.replace("-", "");
    let noBlankPhone = noSym3.replace(/\s+/g, "");
    const PNF = phoneutil.PhoneNumberFormat;
    const phoneUtil = phoneutil.PhoneNumberUtil.getInstance();
    let region = "US";
    if (noBlankPhone.trim().length === 11 && !_.startsWith(noBlankPhone, "+")) {
      region = "CN";
    }
    if (noBlankPhone.includes("(")) {
      return noBlankPhone;
    }
    const number = phoneUtil.parse(noBlankPhone, region);
    if (phoneUtil.isValidNumber(number)) {
      return phoneUtil.format(number, PNF.E164);
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}

export function matchPhone(phone) {
  let phoneNumber = phone;
  if (phoneNumber.includes("(")) {
    phoneNumber = phoneNumber.replace(/[(]/g, "");
  }
  if (phoneNumber.includes(")")) {
    phoneNumber = phoneNumber.replace(/[)]/g, "");
  }
  if (phoneNumber.includes("-")) {
    phoneNumber = phoneNumber.replace(/[-]/g, "");
  }
  if (phoneNumber.includes(" ")) {
    phoneNumber = phoneNumber.replace(/[ ]/g, "");
  }
  return phoneNumber;
}
