import { Select } from "antd";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Icon, Input, Modal } from "semantic-ui-react";
import { updateStaff } from "../action/registerAction";
import { editDoctorNpi } from "../action/toolsAction";
import ButtonSuccess from "../MedicalDashboard/home/buttonSuccess";
import "../share/button.css";
import message from "../tools/message-info";
import "./EditStaff.css";
const { Option } = Select;

class EditStaff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      firstName: "",
      lastName: "",
      notificationEmail: "",
      email: "",
      confirmSuccess: false,
      isPrescribing: false,
      doctorNPI: "",
      doctorNPIs: [],
      newDoctorNPI: "",
      selectedSites: []
    };
  }

  handleCheckSites = (value) => {
    this.setState({
      selectedSites: value
    })
  }

  changeFirstName = event => {
    this.setState({ firstName: event.target.value });
  };

  changeLastName = event => {
    this.setState({ lastName: event.target.value });
  };
  changeNotificationEmail = event => {
    this.setState({ notificationEmail: event.target.value });
  };

  handleCheckDoctor = () => {
    this.setState({ isPrescribing: !this.state.isPrescribing });
  }
  show = () => {
    this.setState({
      open: true,
      confirmSuccess: false,
      selectedSites: this.props.newStaff.siteId.split("-"),
      lastName:
        this.props.newStaff && this.props.newStaff.lastName
          ? this.props.newStaff.lastName
          : "",
      notificationEmail:
        this.props.newStaff && this.props.newStaff.notificationEmail
          ? this.props.newStaff.notificationEmail
          : this.props.newStaff && this.props.newStaff.email
          ? this.props.newStaff.email
          : "",
      firstName:
        this.props.newStaff && this.props.newStaff.firstName
          ? this.props.newStaff.firstName
          : "",
      email:
        this.props.newStaff && this.props.newStaff.email
          ? this.props.newStaff.email
          : "",
      doctorNPI:
        this.props.newStaff && this.props.newStaff.doctorNPI
          ? this.props.newStaff.doctorNPI
          : "",
      isPrescribing: !!(
        this.props.newStaff && this.props.newStaff.isPrescribing === "Yes"
      ),
    });
    if (
      !isEmpty(this.props.newStaff.firstName) &&
      !isEmpty(this.props.newStaff.lastName)
    ) {
      const { getDoctorNpi } = this.props;
      getDoctorNpi(
        this.props.newStaff.firstName,
        this.props.newStaff.lastName,
        this
      );
    }
  };
  handleCancel = () =>
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      notificationEmail: "",
      email: "",
      isPrescribing: false,
      confirmSuccess: false
    });
  handleConfirm = () => {
    this.setState({ confirmSuccess: true });
    const { updateStaff } = this.props;
    if (this.state.firstName === "") {
      message.info("Please input First Name");
      return;
    }
    if (this.state.lastName === "") {
      message.info("Please input Last Name");
      return;
    }
    if (this.state.email === "") {
      message.info("Please input email");
      return;
    }
    if (this.state.selectedSites.length === 0) {
      message.info("Please select sites");
      return;
    }
    let newStaffInput = {
      input: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        notificationEmail: this.state.notificationEmail,
        doctorId: "1234",
        isPrescribing: this.state.isPrescribing,
        siteId: this.state.selectedSites.join("-")
      }
    };
    if (this.state.doctorNPI !== "") {
      Object.assign(newStaffInput.input, {
        doctorNPI: this.state.doctorNPI
      });
    }
    updateStaff(newStaffInput);
  };
  checkDoctorNpi() {
    if (isEmpty(this.state.firstName) || isEmpty(this.state.lastName)) {
      return;
    }
    const { getDoctorNpi } = this.props;
    getDoctorNpi(this.state.firstName, this.state.lastName, this);
  }
  onChangedDoctorNPI = event => {
    this.setState({ doctorNPI: event });
  };
  changeSearchDoctorNPI(value) {
    if (!!value) {
      this.setState({ newDoctorNPI: value });
    }
  }

  changeBlurDoctorNPI(value) {
    const { newDoctorNPI } = this.state;
    if (!!newDoctorNPI) {
      this.onChangedDoctorNPI(newDoctorNPI);
      this.setState({ newDoctorNPI: "" });
    }
  }

  render() {
    return (
      <Modal
        trigger={
          <Button onClick={this.show} size="mini" style={{ display: "none" }}>
            Edit
          </Button>
        }
        open={this.state.open}
      >
        <Modal.Header>Edit staff</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div className="newSite_box">
              <div className="inputTotal">
                <span className="checkbox_label">Prescribing Doctor</span>
                <Checkbox
                  className="checkbox_box"
                  checked={this.state.isPrescribing}
                  onChange={this.handleCheckDoctor}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">First Name</p>
                  <Input
                    placeholder="John"
                    className="inputContent"
                    onChange={this.changeFirstName}
                    value={this.state.firstName}
                    onBlur={event => this.checkDoctorNpi(event)}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Last Name</p>
                  <Input
                    placeholder="Smith"
                    className="inputContent"
                    onChange={this.changeLastName}
                    value={this.state.lastName}
                    onBlur={event => this.checkDoctorNpi(event)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">Notification Email</p>
                  <Input
                    placeholder="Johnsmith@email.com"
                    className="inputContent"
                    onChange={this.changeNotificationEmail}
                    value={this.state.notificationEmail}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Provider NPI</p>
                  <Select
                    className="selectStyle"
                    value={this.state.doctorNPI}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    onChange={this.onChangedDoctorNPI}
                    onSearch={value => this.changeSearchDoctorNPI(value)}
                    onBlur={value => this.changeBlurDoctorNPI(value)}
                  >
                    <Option value={" "}>None</Option>
                    {this.state.doctorNPIs.map((npi, i) => {
                      return (
                        <Option value={npi.number} key={"npi" + i}>
                          {npi.number +
                            " - " +
                            npi.addresses[0].city +
                            " " +
                            npi.addresses[0].state +
                            " " +
                            npi.addresses[0].postal_code}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="inputTotal" style={{ width: 620 }}>
                <p className="inputLable">
                  Sites <p1 style={{ color: "red" }}>*</p1>
                </p>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select sites"
                  onChange={this.handleCheckSites}
                  defaultValue={this.props.newStaff.siteId.split("-")}
                  optionLabelProp="value"
                >
                  {
                    this.props.clinicSites.map(site => (
                      <Option key={site.siteId} value={site.siteId}>{site.siteId} {site.name}</Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={e => this.handleConfirm()}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
            className="okButton"
          >
            <Icon name="checkmark" />
            Ok
          </Button>
          <Button
            basic
            color="red"
            onClick={e => this.handleCancel()}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  updateStaff: (newStaffInput) =>
    dispatch(updateStaff(newStaffInput)),
  getDoctorNpi: (firstName, lastName, self) =>
    dispatch(editDoctorNpi(firstName, lastName, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(EditStaff);
