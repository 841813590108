import { Select } from "antd";
import "antd/dist/antd.css";
import _ from "lodash";
import moment from "moment-timezone";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { getNewPatientsList } from "../../action/patientAction";
import "../../home/DeviceList.css";
import { getSockRegisterDay } from "../../tools/compare";
import { formatNumber } from "../../tools/phoneFormat";
import getPatchTrProps, {
  InputEnterKeyTriggerFilterComponent,
  transformPatientTableParams
} from "../../tools/reactTablePatch";
import { convertRelation } from "../../tools/relationConvert";
import AppLink from "./appLink";
import DeleteNewPatient from "./deleteNewPatient";
import EditNewPatientsList from "./EditNewPatientsList";
import ReNewPatientPwd from "./ReNewPatientPwd";
import DateInput from "../../common/DateInput";

const SecRela = [
  { rela: "", key: "1" },
  { rela: "Spouse/Partner", key: "2" },
  { rela: "Sibling", key: "3" },
  { rela: "Child", key: "4" },
  { rela: "Niece/Nephew", key: "5" },
  { rela: "Grandchild", key: "6" },
  { rela: "Caregiver", key: "7" },
  { rela: "Other", key: "8" }
];

export default class AllPatients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      totalSize: 0,
    };
  }

  gotoStatisticsPage(id, userId, userTimezone, hubType) {
    Control.go(
      "/sockstatus?AllPatients/id=" +
        id +
        "&userId=" +
        userId +
        "&timeZone=" +
        userTimezone +
        "&hubType=" +
        hubType
    );
  }

  reqPatientList = () => {
    this.debounceReqPatientList();
    if (!this.debounceRequest) {
      this.debounceReqPatientList.flush();
    }
  };

  debounceReqPatientList = _.debounce(() => {
    const { filter, sort, pagination } = transformPatientTableParams(
      this.tableInstance
    );

    this.rawReqPatientList(filter, sort, pagination);
  }, 3000);

  rawReqPatientList(filter, sort, pagination) {
    this.debounceRequest = false;
    this.setState({
      loading: true,
      patientError: null
    });

    getNewPatientsList(filter, sort, pagination)
      .then(data => {
        let patiens = data.data.lambdaSearchPatients.items;
        let totalSize = data.data.lambdaSearchPatients.total;

        this.setState({
          data: patiens,
          totalSize: totalSize,
        });
      })
      .catch(error => {
        this.setState({
          patientError: error
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  }

  dateInputComponent = column => {
    const { filter, onChange } = column;
    return (
      <DateInput
        defaultValue={filter ? filter.value : null}
        onPressEnter={value => {
          this.debounceRequest = false;
          return onChange(value);
        }}
        onChange={value => {
          this.debounceRequest = true;
          return onChange(value);
        }}
      />
    );
  };

  render() {
    const { Option } = Select;
    const trProps = getPatchTrProps(({ event }) => {
      //WARNING: black magic
      const buttons = event.currentTarget.getElementsByTagName("button");
      for (const button of buttons) {
        if (button.innerHTML === "Edit") {
          button.click();
          break;
        }
      }
    });

    return (
      <div className="device-list-container">
        <ReactTable
          loading={this.state.loading}
          getTrProps={trProps}
          filterable
          FilterComponent={InputEnterKeyTriggerFilterComponent({
            onChange: () => {
              this.debounceRequest = true;
            },
            onPressEnter: () => {
              this.debounceRequest = false;
            }
          })}
          manual
          pages={
            !this.tableInstance
              ? -1
              : Math.ceil(
                  this.state.totalSize /
                    this.tableInstance.state.pageSize
                )
          }
          data={this.state.data}
          onFetchData={(_, instance) => {
            // there are some other callbacks, like onPageChange onPageSizeChange etc,
            // we don't care them. this callback is enough
            this.tableInstance = instance;

            this.reqPatientList();
          }}
          resolveData={data => data.map(p => {
            const createdAt = p.createdAt
              ? moment.tz(p.createdAt, p.userTimezone).format("YYYY-MM-DD")
              : "";

            const email = p.email && p.email.includes("@") ? p.email : "";
            const primaryKey = p.id;
            // const dispensed = p.dispensed;
            const dispensed =
              p.sockStatus ||
              p.socks.length > 0 ||
              (p.dispenseType && p.dispenseType === "CLINIC_DISPENSED") ||
              (p.dispenseType &&
                p.dispenseType === "SIREN_SHIPPED" &&
                p.shipmentStatus &&
                (p.shipmentStatus === "In Transit" ||
                  p.shipmentStatus === "Delivered"))
                ? "Yes"
                : "No";

            return {
              ...p,
              createdAt,
              email,
              primaryKey,
              dispensed,
            };
          })}
          columns={[
            {
              Header: "SiteId",
              accessor: "siteId",
            },
            {
              Header: "SiteName",
              accessor: "siteName",
            },
            {
              Header: "SirenId",
              accessor: "sirenId",
            },
            {
              Header: "First Name",
              accessor: "firstName",
            },
            {
              Header: "Last Name",
              accessor: "lastName",
            },
            {
              Header: "Phone",
              accessor: "phone",
              Cell: props => {
                if (!_.isEmpty(props.value)) {
                  return formatNumber(props.value);
                }
                return ""
              }
            },
            {
              Header: "Email",
              accessor: "email",
              minWidth: 120,
            },
            {
              Header: "Account Created",
              accessor: "createdAt",
            },
            {
              Header: "Socks Dispensed",
              accessor: "dispensed",
              filterable: false,
              sortable: false,
            },
            // {
            //   Header: "Shipment Status",
            //   accessor: "shipmentStatus",
            //   filterMethod: (filter, row) =>
            //     row._original.shipmentStatus &&
            //     row[filter.id].includes(filter.value)
            // },
            {
              Header: "App Activated",
              accessor: "appInitialized",
              maxWidth: 72,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "App Activation Date",
              accessor: "initializedDate",
              Filter: this.dateInputComponent,
              Cell: props => {
                return !_.isEmpty(props.value)
                ? moment(props.value).format("YYYY-MM-DD")
                : "";
              }
            },
            {
              style: { cursor: "pointer" },
              Header: "Hub Type",
              accessor: "hubType",
              Cell: props => {
                return (
                  <div
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <span
                      className="spanonclick"
                      onClick={() =>
                        this.gotoStatisticsPage(
                          props.original.id,
                          props.original.userId,
                          props.original.userTimezone,
                          props.original.hubType
                        )
                      }
                    >
                      {props.original.hubType}
                    </span>
                  </div>
                );
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="Wifi">Wifi</Option>
                  <Option value="2G">2G</Option>
                  <Option value="4G">4G</Option>
                  <Option value="Wifi, 2G">Wifi, 2G</Option>
                  <Option value="Wifi, 4G">Wifi, 4G</Option>
                  <Option value="2G, 4G">2G, 4G</Option>
                  <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
                </Select>
              )
            },
            {
              Header: "Smartphone",
              accessor: "patientHasSmartPhone",
              maxWidth: 100,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : ""}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                  <Option value="Caregiver">Caregiver</Option>
                </Select>
              )
            },
            {
              Header: "Wifi",
              accessor: "patientHasWifi",
              maxWidth: 72,
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Secondary Contact Name",
              accessor: "contactName",
            },
            {
              Header: "Secondary Contact Phone",
              accessor: "contactPhone",
              Cell: props => {
                if (!_.isEmpty(props.value)) {
                  return formatNumber(props.value);
                }
                return ""
              }
            },
            {
              Header: "Secondary Contact Email",
              accessor: "secondaryEmail",
            },
            {
              Header: "Relationship To Patient",
              accessor: "relation",
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  {SecRela.map((si, i) => {
                    return (
                      <Option value={si.rela} key={"si" + i}>
                        {si.rela}
                      </Option>
                    );
                  })}
                </Select>
              )
            },
            {
              Header: "Actions",
              sortable: false,
              filterable: false,
              minWidth: 368,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <EditNewPatientsList userInfo={props.original} />
                    {/*<PatientIntakeDetails userInfo={props.original} />*/}
                    <DeleteNewPatient
                      id={props.original.primaryKey}
                      userInfo={props.original}
                    />
                    <AppLink
                      email={props.original.email}
                      phone={props.original.phone}
                    />
                    <ReNewPatientPwd id={props.original.primaryKey} />
                  </div>
                );
              }
            }
          ]}
          defaultSorted={[{ id: "createdAt", desc: true }]}
          defaultFiltered={[
            {
              id: "appInitialized",
              value: "No"
            }
          ]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
          showPaginationTop={true}
        />
      </div>
    );
  }
}
