import { API, graphqlOperation } from "aws-amplify";
import _, { clone, filter } from "lodash";
import moment from "moment";
import _api from "../graphql/_api";
import * as TYPES from "../types/types";
import { caseConversion, changeSymtoms } from "./patientAction";
import { Modal as AntdModal } from "antd";
import { listICD10Codes, listMedicalConditions } from "./userGroupAction";
/**
 *  Created by shengli.zhang on 2020-04-16 13:02
 */

export function listRpmTracker(userId, startDate, endDate) {
  return async dispatch => {
    try {
      dispatch(changeModalOpenState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.listRpmTracker(), { userId, startDate, endDate })
      );
      dispatch(changeModalOpenState(false));
      if (newEvent) {
        const sortedItems = newEvent.data.listRpmTracker.items.sort((a, b) => {
          return a.serviceDate.localeCompare(b.serviceDate);
        });
        const programItems = _(sortedItems)
          .groupBy(item => [item.programTime])
          .flatMap((value, key) => {
            if (!key) {
              return value.map(p => {
                return {
                  ...p,
                  programTime: p.rpmKey,
                  programNotes: [
                    {
                      program: "RPM",
                      rpmKey: p.rpmKey,
                      nurseTime: p.nurseTime,
                      doctorTime: p.doctorTime,
                      description: p.description,
                      addendums: p.addendums,
                      rpmService: p.rpmService
                    }
                  ]
                };
              });
            }
            const programNotes = value.map(p => {
              return {
                program: p.program || "RPM",
                rpmKey: p.rpmKey,
                nurseTime: p.nurseTime,
                doctorTime: p.doctorTime,
                spendTime: p.spendTime,
                description: p.description,
                addendums: p.addendums,
                rpmService: p.rpmService
              };
            });
            return {
              ...value[0],
              rpmService: _.uniq(value.map(t => t.rpmService)).join(","),
              programNotes
            };
          })
          .uniq()
          .value();

        // const newNpmService = programItems.sort((a, b) => {
        //   return a.programTime.localeCompare(b.programTime);
        // });
        const newNpmService = _.orderBy(programItems, ["programTime"], ["asc"]);

        dispatch(changeAdd99457ServiceState(newNpmService));
        document.dispatchEvent(new Event("refreshRpmList"));
      }
    } catch (e) {
      dispatch(changeModalOpenState(false));
      console.log("get listRpmTracker error: ", e);
    }
  };
}

export function updateNpmList(npmService, programList, currentMonth) {
  return dispatch => {
    let filterNpmService = [];
    for (const program of programList) {
      const npm = npmService.filter(item =>
        item.programNotes.find(p => p.program === program)
      );
      filterNpmService = _.uniqBy([...filterNpmService, ...npm], "programTime");
    }

    const allService = filterNpmService.map(item => {
      return {
        ...item,
        rpmService: _.uniq(
          _.sortBy(
            item.programNotes
              .map(p => p.rpmService)
              .join(",")
              .split(",")
          )
        ).join(", ")
      };
    });

    filterNpmService = _.orderBy(
      allService.filter(
        t => moment(t.serviceDate).format("YYYY-MM") === currentMonth
      ),
      ["programTime"],
      ["asc"]
    );

    dispatch(changeFilterNpmServiceState(filterNpmService));
  };
}

export function update99457Service(input, self) {
  return async dispatch => {
    try {
      self.setState({ submitting: true });
      dispatch(changeModalOpenState(true));
      let npmService = clone(self.props.npmService);
      await API.graphql(graphqlOperation(_api.updateRpmTracker(), { input }));
      dispatch(changeModalOpenState(false));
      let [serviceInfo] = filter(npmService, {
        programTime: input.programTime
      });
      if (serviceInfo) {
        serviceInfo.serviceDate = input.newServiceDate
          ? input.newServiceDate
          : input.serviceDate;
        serviceInfo.programNotes = input.programNotes.map(p => {
          return {
            ...p,
            rpmKey: `${serviceInfo.serviceDate}${p.rpmKey.substr(10)}`
          };
        });
        serviceInfo.programTime = `${
          serviceInfo.serviceDate
        }${input.programTime.substr(10)}`;
        serviceInfo.rpmService = input.rpmService;
        if (input.symptoms) {
          serviceInfo.symptoms = input.symptoms;
        }
        if (input.medicalCondition) {
          serviceInfo.medicalCondition = input.medicalCondition;
        }
        if (input.footSpecific) {
          serviceInfo.footSpecific = input.footSpecific;
        }
        if (input.footAmputation) {
          serviceInfo.footAmputation = input.footAmputation;
        }
        if (input.conditions) {
          serviceInfo.footConditions = input.conditions;
        }
        if (input.treatments) {
          serviceInfo.footTreatments = input.treatments;
        }
      }
      self.props.isEdit(false);
      self.setState({ submitting: false, open: false });
      if (input.symptoms) {
        updateSymptomList(self, input, dispatch);
      }
      dispatch(changeAdd99457ServiceState(npmService));
      dispatch(listICD10Codes);
      dispatch(listMedicalConditions);
      self.props.changePatientDetails({
        ...self.props.patientDetails,
        patientMedicalInfo: {
          medicalCondition: input.medicalCondition,
          footSpecific: input.footSpecific,
          footAmputation: input.footAmputation,
          footTreatments:
            input.treatments &&
            input.treatments.filter(item => item.status !== "Completed"),
          footConditions:
            input.conditions &&
            input.conditions.filter(item => item.status !== "Healed")
        }
      });
      document.dispatchEvent(new Event("refreshRpmList"));
    } catch (e) {
      self.setState({ submitting: false });
      dispatch(changeModalOpenState(false));
      console.log("update99457Service error: ", e);
    }
  };
}

function updateSymptomList(self, input, dispatch) {
  let patientSymptoms = [];
  let patientSymptomsApp = [];
  let docDetails = _.clone(self.props.patientDetails);
  input.symptoms = input.symptoms.map(p => {
    return {
      ...p,
      source: "DMT"
    };
  });
  docDetails.footSymptoms = [...docDetails.footSymptoms, ...input.symptoms];
  let footSymptomByDMT = _.filter(docDetails.footSymptoms, function(item) {
    return item.source === "DMT";
  });
  let footSymptomByApp = _.filter(docDetails.footSymptoms, function(item) {
    return item.source !== "DMT";
  });
  _(footSymptomByDMT)
    .groupBy(item => {
      return _.split(item.reportTime, "T")[0];
    })
    .flatMap((value, key) => {
      let symptomType = undefined;
      const symptomTypes = value.map(itemSub => {
        if (!itemSub.symptomType) {
          symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
          return symptomType;
        } else {
          symptomType = caseConversion(itemSub.symptomType);
          return symptomType;
        }
      });
      patientSymptoms.push({
        reportTime: key,
        symptomType: symptomTypes
          .map(p => {
            return p;
          })
          .join(", "),
        source: "DMT"
      });
    })
    .uniq()
    .value();

  _(footSymptomByApp)
    .groupBy(item => {
      return _.split(item.reportTime, "T")[0];
    })
    .flatMap((value, key) => {
      let symptomType = undefined;
      const symptomTypes = value.map(itemSub => {
        if (!itemSub.symptomType) {
          symptomType = itemSub.footSymptom ? itemSub.footSymptom : "";
          return symptomType;
        } else {
          symptomType = caseConversion(itemSub.symptomType);
          return symptomType;
        }
      });
      patientSymptomsApp.push({
        reportTime: key,
        symptomType: symptomTypes
          .map(p => {
            return p;
          })
          .join(", "),
        source: "APP"
      });
    })
    .uniq()
    .value();
  const symptoms = _.orderBy(
    [...patientSymptoms, ...patientSymptomsApp],
    ["reportTime"],
    ["desc"]
  );
  dispatch(
    changeSymtoms({
      footSymptoms: symptoms
    })
  );
}

export function add99457Service(input, self) {
  return async dispatch => {
    try {
      self.setState({ submitting: true });
      let npmService = clone(self.props.npmService);
      await API.graphql(graphqlOperation(_api.createRpmTracker(), { input }));
      // if (newEvent) {
      let startDate = moment(self.props.startDate).format("YYYY-MM");
      if (moment(input.serviceDate).format("YYYY-MM") === startDate) {
        let addInput = {
          ...input,
          footConditions: input.conditions ? input.conditions : [],
          footTreatments: input.treatments ? input.treatments : []
        };
        npmService.push(addInput);
      }
      if (input.symptoms) {
        updateSymptomList(self, input, dispatch);
      }
      dispatch(changeAdd99457ServiceState(npmService));
      dispatch(listICD10Codes);
      dispatch(listMedicalConditions);
      document.dispatchEvent(new Event("refreshRpmList"));
      self.close();
      // }
    } catch (e) {
      self.setState({ submitting: false });
      dispatch(changeModalOpenState(false));
      console.log("add99457Service error: ", e);
    }
  };
}

export function deleteMonitoringTracker(input, self) {
  return async dispatch => {
    try {
      self.setState({ submitting: true });
      let npmService = _.clone(self.props.npmService);
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteRpmTracker(), { input })
      );
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings,
          submitting: false
        };
      });
      if (newEvent && newEvent.data.deleteRpmTracker.status) {
        const index = _.findIndex(npmService, function(item) {
          return item.programTime === self.props.serviceInfo.programTime;
        });
        npmService.splice(index, 1);
        dispatch(changeAdd99457ServiceState(npmService));
        document.dispatchEvent(new Event("refreshRpmList"));
        self.setState({ open: false });
        self.props.isEdit(false);
      }
    } catch (e) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings,
          submitting: false
        };
      });
      console.log("deleteMonitoringTracker error: ", e);
    }
  };
}

function changeAdd99457ServiceState(service) {
  return {
    type: TYPES.NPM_SERVICE,
    text: service
  };
}

function changeFilterNpmServiceState(service) {
  return {
    type: TYPES.FILTER_NPM_SERVICE,
    text: service
  };
}

function changeModalOpenState(state) {
  return {
    type: TYPES.MODAL_STATE,
    text: state
  };
}

export function updateAddendum(input, self) {
  return async dispatch => {
    try {
      console.log("add addendum input: ", input);
      self.setState({ submitting: true });
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAddendum(), { input })
      );
      console.log("newEvent: ", newEvent);
      if (newEvent && newEvent.data.updateAddendum.status) {
        self.props.isEdit(false);
        const npmService = _.clone(self.props.npmService);
        let serviceInfo = _(npmService).find({
          programTime: input.programTime
        });
        if (serviceInfo) {
          serviceInfo.serviceDate = input.newServiceDate
            ? input.newServiceDate
            : input.serviceDate;
          serviceInfo.programNotes = input.programNotes.map(p => {
            return {
              ...p,
              rpmKey: `${serviceInfo.serviceDate}${p.rpmKey.substr(10)}`
            };
          });
          serviceInfo.programTime = `${
            serviceInfo.serviceDate
          }${input.programTime.substr(10)}`;
          if (input.rpmService) {
            serviceInfo.rpmService = input.rpmService;
          }
          if (input.symptoms) {
            serviceInfo.symptoms = input.symptoms;
          }
          dispatch(changeAdd99457ServiceState(npmService));
          document.dispatchEvent(new Event("refreshRpmList"));
        }
        self.setState({
          open: false,
          submitting: false,
          userName: "",
          programNotes: [],
          serviceProvided: [],
          symptomData: []
        });
      } else {
        self.setState({
          submitting: false
        });
        AntdModal.warning({
          title: "Add Addendum Failed",
          content: JSON.stringify(
            JSON.parse(newEvent.data.updateAddendum.message).message
          )
        });
      }
    } catch (e) {
      self.setState({
        open: false,
        submitting: false,
        addendums: [],
        addendum: "",
        userName: ""
      });
      console.log(`update addendum failed: ${JSON.stringify(e)}`);
      AntdModal.warning({
        title: "Add Addendum Failed",
        content: JSON.stringify(e)
      });
    }
  };
}
