import moment from "moment/moment";
import { celToFahrenheit, celToFahrenheitDelta } from "../action/chartAction";

//Delta
export function getChartDeltaOptions(delta, minStatus, tempUnit, delta_auto) {
  //usertime
  let dateList = delta.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //delta
  let dToe = delta.map(function(item) {
    return tempUnit === "Fahrenheit"
      ? item.dtoe > 0
        ? celToFahrenheitDelta(item.dtoe)
        : celToFahrenheitDelta(item.dtoe)
      : item.dtoe;
  });
  let dmts1 = delta.map(function(item) {
    return tempUnit === "Fahrenheit"
      ? item.dmts1 > 0
        ? celToFahrenheitDelta(item.dmts1)
        : celToFahrenheitDelta(item.dmts1)
      : item.dmts1;
  });
  let dmts3 = delta.map(function(item) {
    return tempUnit === "Fahrenheit"
      ? item.dmts3 > 0
        ? celToFahrenheitDelta(item.dmts3)
        : celToFahrenheitDelta(item.dmts3)
      : item.dmts3;
  });
  let dmts5 = delta.map(function(item) {
    return tempUnit === "Fahrenheit"
      ? item.dmts5 > 0
        ? celToFahrenheitDelta(item.dmts5)
        : celToFahrenheitDelta(item.dmts5)
      : item.dmts5;
  });
  let darch = delta.map(function(item) {
    return tempUnit === "Fahrenheit"
      ? item.darch > 0
        ? celToFahrenheitDelta(item.darch)
        : celToFahrenheitDelta(item.darch)
      : item.darch;
  });
  let dheel = delta.map(function(item) {
    return tempUnit === "Fahrenheit"
      ? item.dheel > 0
        ? celToFahrenheitDelta(item.dheel)
        : celToFahrenheitDelta(item.dheel)
      : item.dheel;
  });

  let deltaColor = [
    "#4E9FE4",
    "#9AC177",
    "#AC89C4",
    "#EE8C8E",
    "#F9D463",
    "#F2B456"
  ];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...deltaColor],
    legend: [
      {
        data: ["dtoe", "dmts1", "dmts3", "dmts5", "darch", "dheel"],
        top: "25%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        show: false,
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    title: {
      text: tempUnit === "Fahrenheit" ? "ºF" : "ºC",
      top: "26%",
      left: 30,
      textStyle: {
        fontStyle: "normal",
        fontWeight: "bold",
        align: "center",
        fontSize: 15,
        color: "#ff0000"
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -10,
        show: true,
        realtime: true,
        start: 0,
        end: 100,
        top: "4%"
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        top: "30%",
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        position: "bottom",
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Delta(left-right)",
        type: "value",
        axisLabel: {
          formatter: function(data) {
            return tempUnit === "Fahrenheit" ? Math.abs(data) : Math.abs(data);
          }
        },
        axisLine: {
          onZero: true,
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 0, 5, 60]
        },
        min: delta_auto ? null : tempUnit === "Fahrenheit" ? -10 : -6,
        max: delta_auto ? null : tempUnit === "Fahrenheit" ? 10 : 6
      }
    ],
    series: [
      {
        name: "dtoe",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dToe,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        },
        markLine: {
          symbol: "none",
          data: [
            {
              yAxis: tempUnit === "Fahrenheit" ? 4 : 2.2,
              lineStyle: {
                color: "#a88ac3",
                width: 2,
                type: "dashed"
              }
            },
            {
              yAxis: tempUnit === "Fahrenheit" ? -4 : -2.2,
              lineStyle: {
                color: "#a88ac3",
                width: 2,
                type: "dashed"
              },
              label: {
                show: true,
                position: "end",
                color: "#a88ac3",
                formatter: tempUnit === "Fahrenheit" ? "4" : "2.2"
              }
            },
            {
              yAxis: 0,
              lineStyle: {
                color: "#748390",
                width: 2,
                type: "dashed"
              }
            }
          ]
        }
      },
      {
        name: "dmts1",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dmts1,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "dmts3",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dmts3,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#AC89C4"
            }
          }
        }
      },
      {
        name: "dmts5",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: dmts5,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#EE8C8E"
            }
          }
        }
      },
      {
        name: "darch",
        type: "line",
        symbolSize: 8,
        xAxisIndex: 0,
        yAxisIndex: 0,
        hoverAnimation: false,
        data: darch,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#F9D463"
            }
          }
        }
      },
      {
        name: "dheel",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: dheel,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#F2B456"
            }
          }
        }
      }
    ]
  };
  return option;
}

//Steps
export function getChartStepsOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus
) {
  //usertime
  let dateList = steps.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //steps
  let stepData = steps.map(function(item) {
    return item.steps;
  });

  let stepColor = ["#706DAB"];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...stepColor],
    legend: [
      {
        data: ["steps"],
        left: 50,
        top: "15%",
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        show: false,
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        position: "bottom",
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Steps",
        type: "value",
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 10, 5, 0]
        },
        min: 0,
        max: "dataMax"
      }
    ],
    series: [
      {
        name: "steps",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: stepData,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#706DAB"
            }
          }
        }
      }
    ]
  };
  return option;
}

//Left Foot Temperature
export function getChartLeftTempOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit
) {
  //usertime
  let dateList = leftTemp.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //left temperature
  let lToe = leftTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.toe) : item.toe;
  });
  let lMts1 = leftTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.mts1) : item.mts1;
  });
  let lMts3 = leftTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.mts3) : item.mts3;
  });
  let lMts5 = leftTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.mts5) : item.mts5;
  });
  let lArch = leftTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.arch) : item.arch;
  });
  let lHeel = leftTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.heel) : item.heel;
  });

  let lTempColor = [
    "#4E9FE4",
    "#9AC177",
    "#AC89C4",
    "#EE8C8E",
    "#F9D463",
    "#F2B456"
  ];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...lTempColor],
    legend: [
      {
        data: ["ltoe", "lmts1", "lmts3", "lmts5", "larch", "lheel"],
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    title: {
      text: tempUnit === "Fahrenheit" ? "ºF" : "ºC",
      top: "14%",
      left: 30,
      textStyle: {
        fontStyle: "normal",
        fontWeight: "bold",
        align: "center",
        fontSize: 15,
        color: "#ff0000"
      }
    },
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Left Foot Temperature",
        type: "value",
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        min: tempUnit === "Fahrenheit" ? celToFahrenheit(20) : 20,
        max: tempUnit === "Fahrenheit" ? celToFahrenheit(45) : 45,
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 0, 10, 105]
        }
      }
    ],
    series: [
      {
        name: "ltoe",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lToe,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },
      {
        name: "lmts1",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lMts1,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "lmts3",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lMts3,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      },
      {
        name: "lmts5",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lMts5,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ee8c8e"
            }
          }
        }
      },
      {
        name: "larch",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lArch,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f9d463"
            }
          }
        }
      },
      {
        name: "lheel",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lHeel,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f2b456"
            }
          }
        }
      }
    ]
  };
  return option;
}

//Right Foot Temperature
export function getChartRightTempOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus,
  tempUnit
) {
  //usertime
  let dateList = rightTemp.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //right temperature
  let rToe = rightTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.toe) : item.toe;
  });
  let rMts1 = rightTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.mts1) : item.mts1;
  });
  let rMts3 = rightTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.mts3) : item.mts3;
  });
  let rMts5 = rightTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.mts5) : item.mts5;
  });
  let rArch = rightTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.arch) : item.arch;
  });
  let rHeel = rightTemp.map(function(item) {
    return tempUnit === "Fahrenheit" ? celToFahrenheit(item.heel) : item.heel;
  });

  let rTempColor = [
    "#4E9FE4",
    "#9AC177",
    "#AC89C4",
    "#EE8C8E",
    "#F9D463",
    "#F2B456"
  ];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
    },
    color: [...rTempColor],
    legend: [
      {
        data: ["rtoe", "rmts1", "rmts3", "rmts5", "rarch", "rheel"],
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    title: {
      text: tempUnit === "Fahrenheit" ? "ºF" : "ºC",
      top: "14%",
      left: 30,
      textStyle: {
        fontStyle: "normal",
        fontWeight: "bold",
        align: "center",
        fontSize: 15,
        color: "#ff0000"
      }
    },
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Right Foot Temperature",
        type: "value",
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        min: tempUnit === "Fahrenheit" ? celToFahrenheit(20) : 20,
        max: tempUnit === "Fahrenheit" ? celToFahrenheit(45) : 45,
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 0, 10, 110]
        }
      }
    ],
    series: [
      {
        name: "rtoe",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rToe,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },
      {
        name: "rmts1",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rMts1,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "rmts3",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rMts3,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      },
      {
        name: "rmts5",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rMts5,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ee8c8e"
            }
          }
        }
      },
      {
        name: "rarch",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rArch,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f9d463"
            }
          }
        }
      },
      {
        name: "rheel",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rHeel,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#f2b456"
            }
          }
        }
      }
    ]
  };
  return option;
}

//Left Foot Position
export function getChartLeftPositionOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus
) {
  //usertime
  let dateList = leftPosition.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //left gsensor
  let lGsensorX = leftPosition.map(function(item) {
    return item.gsensorx;
  });
  let lGsensorY = leftPosition.map(function(item) {
    return item.gsensory;
  });
  let lGsensorZ = leftPosition.map(function(item) {
    return item.gsensorz;
  });

  let lGsensorColor = ["#529DE1", "#9AC078", "#7472AF"];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
      // formatter: function(params) {
      //   let x = params[0].value;
      //   let y = params[1].value;
      //   let z = params[2].value;
      //   let photo = null;
      //   if (x !== "-" && y !== "-" && z !== "-") {
      //     if (x >= 800 || x <= -800) {
      //       photo = LEFT_X;
      //     } else if (y >= 800 || y <= -800) {
      //       photo = LEFT_Y;
      //     } else if (z >= 800 || z <= -800) {
      //       photo = LEFT_Z;
      //     } else if (x > 0 && y > 0 && z > 0) {
      //       photo = LEFT_1;
      //     } else if (x < 0 && y > 0 && z > 0) {
      //       photo = LEFT_2;
      //     } else if (x < 0 && y < 0 && z > 0) {
      //       photo = LEFT_3;
      //     } else if (x > 0 && y < 0 && z > 0) {
      //       photo = LEFT_4;
      //     } else if (x > 0 && y > 0 && z < 0) {
      //       photo = LEFT_5;
      //     } else if (x < 0 && y > 0 && z < 0) {
      //       photo = LEFT_6;
      //     } else if (x < 0 && y < 0 && z < 0) {
      //       photo = LEFT_7;
      //     } else if (x > 0 && y < 0 && z < 0) {
      //       photo = LEFT_8;
      //     }
      //   }
      //   if (photo !== null) {
      //     return (
      //       "<img style='width:120px;height:150px;' src='" +
      //       photo +
      //       "'/>" +
      //       `<br /> ${params[0].name}<br/>${params[0].marker} ${params[0].seriesName}: ${params[0].value}<br/>${params[1].marker} ${params[1].seriesName}: ${params[1].value}<br/>${params[2].marker} ${params[2].seriesName}: ${params[2].value}`
      //     );
      //   }
      //   return `${params[0].name}<br/>${params[0].marker} ${params[0].seriesName}: ${params[0].value}<br/>${params[1].marker} ${params[1].seriesName}: ${params[1].value}<br/>${params[2].marker} ${params[2].seriesName}: ${params[2].value}`;
      // }
    },
    color: [...lGsensorColor],
    legend: [
      {
        data: ["lgsensorX", "lgsensorY", "lgsensorZ"],
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Left Foot Position",
        type: "value",
        axisLabel: {
          formatter: function(data) {
            return Math.abs(data);
          }
        },
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 0, 10, 70]
        }
      }
    ],
    series: [
      {
        name: "lgsensorX",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lGsensorX,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },
      {
        name: "lgsensorY",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lGsensorY,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "lgsensorZ",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: lGsensorZ,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      }
    ]
  };
  return option;
}

//Left Foot Position
export function getChartRightPositionOptions(
  delta,
  steps,
  leftTemp,
  rightTemp,
  leftPosition,
  rightPosition,
  minStatus
) {
  //usertime
  let dateList = rightPosition.map(function(item) {
    // return moment(item.usertime).format("YYYY-MM-DD,HH:00");
    return item.usertime
      ? moment(item.usertime.replace("Z", "")).format("MM/DD HH:mm")
      : "-";
  });

  //right gsensor
  let rGsensorX = rightPosition.map(function(item) {
    return item.gsensorx;
  });
  let rGsensorY = rightPosition.map(function(item) {
    return item.gsensory;
  });
  let rGsensorZ = rightPosition.map(function(item) {
    return item.gsensorz;
  });

  let rGsensorColor = ["#529DE1", "#9AC078", "#7472AF"];

  const option = {
    tooltip: {
      trigger: "axis",
      showDelay: 0,
      textStyle: {
        align: "left"
      },
      position: function(point, params, dom, rect, size) {
        // The reference coordinate system of the mouse coordinates and the position of the prompt box is: the point in the upper left corner of the outer layer div is the origin, the x axis is right, and the y axis is down.
        let x = 0; // X position
        let y = 0; // Y position

        // Current position
        let pointX = point[0];

        // Outer view size
        let viewWidth = size.viewSize[0];

        // Tooltip size
        let boxWidth = size.contentSize[0];
        let boxHeight = size.contentSize[1];

        // boxWidth > viewWidth - pointX:  right side of the mouse can not put a prompt box
        if (boxWidth >= viewWidth - pointX) {
          x = pointX - boxWidth - 20;
        } else {
          x = pointX + 20;
        }

        y = (2000 / 6 - boxHeight) / 2;

        return [x, y];
      }
      // formatter: function(params) {
      //   let x = params[0].value;
      //   let y = params[1].value;
      //   let z = params[2].value;
      //   let photo = null;
      //   if (x !== "-" && y !== "-" && z !== "-") {
      //     if (x >= 800 || x <= -800) {
      //       photo = RIGHT_X;
      //     } else if (y >= 800 || y <= -800) {
      //       photo = RIGHT_Y;
      //     } else if (z >= 800 || z <= -800) {
      //       photo = RIGHT_Z;
      //     } else if (x > 0 && y > 0 && z > 0) {
      //       photo = RIGHT_1;
      //     } else if (x < 0 && y > 0 && z > 0) {
      //       photo = RIGHT_2;
      //     } else if (x < 0 && y < 0 && z > 0) {
      //       photo = RIGHT_3;
      //     } else if (x > 0 && y < 0 && z > 0) {
      //       photo = RIGHT_4;
      //     } else if (x > 0 && y > 0 && z < 0) {
      //       photo = RIGHT_5;
      //     } else if (x < 0 && y > 0 && z < 0) {
      //       photo = RIGHT_6;
      //     } else if (x < 0 && y < 0 && z < 0) {
      //       photo = RIGHT_7;
      //     } else if (x > 0 && y < 0 && z < 0) {
      //       photo = RIGHT_8;
      //     }
      //   }
      //   if (photo !== null) {
      //     return (
      //       "<img style='width:120px;height:150px;' src='" +
      //       photo +
      //       "'/>" +
      //       `<br /> ${params[0].name}<br/>${params[0].marker} ${params[0].seriesName}: ${params[0].value}<br/>${params[1].marker} ${params[1].seriesName}: ${params[1].value}<br/>${params[2].marker} ${params[2].seriesName}: ${params[2].value}`
      //     );
      //   }
      //   return `${params[0].name}<br/>${params[0].marker} ${params[0].seriesName}: ${params[0].value}<br/>${params[1].marker} ${params[1].seriesName}: ${params[1].value}<br/>${params[2].marker} ${params[2].seriesName}: ${params[2].value}`;
      // }
    },
    color: [...rGsensorColor],
    legend: [
      {
        data: ["rgsensorX", "rgsensorY", "rgsensorZ"],
        top: "15%",
        left: 50,
        x: "left"
      }
    ],
    toolbox: {
      show: false,
      feature: {
        dataZoom: {
          yAxisIndex: "none"
        },
        restore: {},
        saveAsImage: {}
      }
    },
    dataZoom: [
      {
        orient: "horizontal",
        x: 0,
        y: -100,
        show: true,
        realtime: true,
        start: 0,
        end: 100
      }
    ],
    grid: [
      {
        left: 40,
        right: 40,
        tooltip: {
          trigger: "axis"
        }
      }
    ],
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#C9CeD1",
            width: 1.5
          }
        },
        data: dateList,
        position: "bottom",
        axisLabel: {
          interval: minStatus === "min1" ? 200 : 100,
          textStyle: {
            color: "#000"
          }
        }
      }
    ],
    yAxis: [
      {
        name: "Right Foot Position",
        type: "value",
        axisLabel: {
          formatter: function(data) {
            return Math.abs(data);
          }
        },
        axisLine: { onZero: true, show: false },
        inverse: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#F1F1F1",
            width: 1,
            type: "dashed"
          }
        },
        axisTick: {
          show: false
        },
        nameTextStyle: {
          fontWeight: "bold",
          fontSize: "14",
          padding: [0, 0, 10, 75]
        }
      }
    ],
    series: [
      {
        name: "rgsensorX",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rGsensorX,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4E9FE4"
            }
          }
        }
      },
      {
        name: "rgsensorY",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rGsensorY,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#9AC177"
            }
          }
        }
      },
      {
        name: "rgsensorZ",
        type: "line",
        xAxisIndex: 0,
        yAxisIndex: 0,
        symbolSize: 8,
        hoverAnimation: false,
        data: rGsensorZ,
        symbol: "none",
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#ac89c4"
            }
          }
        }
      }
    ]
  };
  return option;
}
