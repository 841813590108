import { Checkbox } from "antd";
import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import {
  updateBilled99453Status,
  updateBilled99454Status
} from "../action/bliMonthyReportAction";
import ButtonSuccess from "../MedicalDashboard/home/buttonSuccess";
import "../share/button.css";
import "./report.css";

export default class ChangeBilled99454 extends Component {

  state = { open: false, loading: false, confirmSuccess: false };

  show = () => this.setState({ open: true, loading: false, confirmSuccess: false });

  handleConfirm = async () => {
    let user = await Auth.currentAuthenticatedUser();

    this.setState({ loading: true })
    const input = {
      id: this.props.userInfo.id,
      userId: this.props.userInfo.userId,
      siteId: this.props.userInfo.siteId,
    }
    if (this.props.userInfo.billedType === "99453") {
      Object.assign(input, {
        billDay: this.props.userInfo.billDay,
        billed99453By: user.username,
      })
      await updateBilled99453Status({ input }, this);
    } else {
      Object.assign(input, {
        billingDate: this.props.userInfo.billingDate,
        billedBy: user.username,
        billedStatus: "BILLED",
      })
      await updateBilled99454Status({ input }, this);
    }

    this.setState({
      loading: false,
      confirmSuccess: true
    });
    setTimeout(() => {
      this.setState({ open: false });
      this.props.searchUsers();
    }, 300);
  };

  handleCancel = () => this.setState({ open: false });

  render() {
    return (
      <Modal
        trigger={
          <div>
            <Checkbox
              onClick={this.show}
              checked={
                this.props.userInfo.billed99454 === "Yes" ||
                this.props.userInfo.billed99454 === "true"
              }
            />
          </div>
        }
        open={this.state.open}
      >
        <Modal.Header>Confirm Patient Information</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <div>
              <p>
                Carefully review details before clicking submit. The patient's
                record will be moved to the Reviewed section after confirmation.
              </p>
              <p className="change99457BilledTip">
                Patient:
                <strong className="cofirmBold">
                  {this.props.userInfo.firstName} {this.props.userInfo.lastName}
                </strong>
              </p>
              <p className="change99457BilledTip">
                Recording Period:
                <strong className="cofirmBold">{this.props.period}</strong>
              </p>
              <p className="change99457BilledTip">
                Days Recorded:
                <strong className="cofirmBold">
                  {this.props.userInfo.daysWorn}
                </strong>
              </p>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            loading={this.state.loading}
            onClick={this.handleConfirm}
            style={{
              display: this.state.confirmSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            Submit
          </Button>
          <Button
            basic
            color="red"
            onClick={this.handleCancel}
            className="removeButton"
          >
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
