import React from "react";
import EventEmitter from "eventemitter3";
/**
 *  Created by shengli.zhang on 12/18/20 8:48 PM
 */

const eventEmitter = new EventEmitter();
const Emitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  emit: (event, payload) => eventEmitter.emit(event, payload)
};

Object.freeze(Emitter);

export default Emitter;
