import { changeDoctorDetails, changeLoadingChartsState } from "./patientAction";
import { API, graphqlOperation } from "aws-amplify";
import _ from "lodash";
import _api from "../graphql/_api";
export function createNote(input, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.createSirenNote(), { input })
      );
      dispatch(changeLoadingChartsState(false));
      if (newEvent) {
        let noteList = self.props.doctorDetails;
        let newNoteList = _.clone(noteList);
        newNoteList.notes.push(newEvent.data.createSirenNote);
        dispatch(changeDoctorDetails(newNoteList));
        self.setState({ inputTerm: {} });
      }
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("createNote error: ", e);
    }
  };
}
export function editNote(input, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateSirenNote(), { input })
      );
      dispatch(changeLoadingChartsState(false));
      if (newEvent) {
        let newNoteList = _.clone(self.props.doctorDetails);
        _.remove(newNoteList.notes, {
          timestamp: input.timestamp,
          id: input.id
        });
        newNoteList.notes.push(newEvent.data.updateSirenNote);
        dispatch(changeDoctorDetails(newNoteList));
      }
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("editNote error: ", e);
    }
  };
}

export function deleteSirenNote(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.deleteSirenNote(), { input })
      );
      if (newEvent) {
        self.setState(({ loadings }) => {
          const newLoadings = [...loadings];
          newLoadings[0] = false;
          return {
            loadings: newLoadings
          };
        });
        let newNoteList = _.clone(self.props.doctorDetails);
        _.remove(newNoteList.notes, {
          timestamp: input.timestamp,
          id: input.id
        });
        dispatch(changeDoctorDetails(newNoteList));
        self.close();
      }
    } catch (err) {
      self.setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[0] = false;
        return {
          loadings: newLoadings
        };
      });
      console.log(`delete siren note error ${JSON.stringify(err)}`);
    }
  };
}
