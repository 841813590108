import Amplify from "aws-amplify";
import aws_exports from "../aws-exports-siren-medical";
import {
  createRpmTracker,
  createSirenNote,
  deleteCarePlan,
  deleteDoctor,
  deleteRegisteredPatient,
  deleteRpmTracker,
  deleteSirenNote,
  generateBillingReport,
  registerAccount,
  registerDoctorAccount,
  resetPatientPassword,
  resetStaffPassword,
  sendAppLink,
  setAppointmented,
  setComplianceReviewed,
  setSchduled,
  updateAccountStatus,
  updateBilled99453Status,
  updateBilled99454Status,
  updateBilled99457Status,
  updateBilledPatient,
  updateBillingStatus,
  updateDeviceStatus,
  updateDoctor,
  updateDoctorProfile,
  updateNewPatient,
  UpdateNewPatient,
  updateNewRegisterPatient,
  updatePhotoStatus,
  updateRpmTracker,
  updateServiceStatus,
  updateSirenNote,
  createCarePlan,
  createFootSymptom,
  updateSpecialCaseStatus,
  updateAddendum,
  updateBilledPCMStatus
} from "./mutations";
import {
  checkPatientSocksAndHub,
  docDetail,
  getCalendarData,
  getDoctorNpi,
  getDoctorProfile,
  getFoot,
  getPhotoReview,
  getSock,
  getSockBySirenId,
  getSyms,
  lambdaSearchAllPatients,
  lambdaSearchPatients,
  listAdminStaff,
  listBilling99453Patients,
  listBilling99454Patients,
  listBilling99457Patients,
  listBillingPatients,
  listBillingSummary,
  listClinicSites,
  listManagementSummary,
  listPatientMin1,
  listPatientMin30,
  listRpmTracker,
  listStaff,
  listUserGroups,
  PatientMinuteWorn,
  patientStaff,
  verifyBoxInfo,
  verifyHubInfo,
  getPatientActivity,
  listICD10Codes,
  listCarePlans,
  listMedicalConditions,
  listBillingPCMPatients
} from "./queries";
import { onReportGenerated } from "./subscriptions";

//If only use one aws_config, set this to false
const API_SPLIT = true;

const awsAppsync = aws_exports.aws_appsync_graphqlEndpoint;

//shared graphql
const sharedConfig = () => {
  aws_exports.aws_appsync_graphqlEndpoint =
    aws_exports.aws_appsync_shared_graphqlEndpoint;
  return Amplify.configure(aws_exports);
};

const awsConfig = () => {
  aws_exports.aws_appsync_graphqlEndpoint = awsAppsync;
  return Amplify.configure(aws_exports);
};

//call graphql api
const _api = {
  //search patient
  searchPatientsConnection: () => {
    if (API_SPLIT) sharedConfig();
    // return searchPatientsConnection;
    return lambdaSearchPatients;
  },
  // //get patient data with 1 min interval
  listPatientMin1: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin1;
  },
  // //get patient data with 30 min interval
  listPatientMin30: () => {
    if (API_SPLIT) sharedConfig();
    return listPatientMin30;
  },
  // //get all sites
  listClinicSites: () => {
    if (API_SPLIT) sharedConfig();
    return listClinicSites;
  },
  // //get all Staff
  listStaff: () => {
    if (API_SPLIT) sharedConfig();
    return listStaff;
  },
  // //get Prescribing Staff when create patient
  patientStaff: () => {
    if (API_SPLIT) sharedConfig();
    return patientStaff;
  },
  // //get patient worn info
  PatientMinuteWorn: () => {
    if (API_SPLIT) sharedConfig();
    return PatientMinuteWorn;
  },
  // //get one day status and month worn info
  getCalendarData: () => {
    if (API_SPLIT) sharedConfig();
    return getCalendarData;
  },
  // //get foot status
  getFoot: () => {
    if (API_SPLIT) sharedConfig();
    return getFoot;
  },
  // //get patient info
  docDetail: () => {
    if (API_SPLIT) sharedConfig();
    return docDetail;
  },
  // //get uploaded photo
  getPhotoReview: () => {
    if (API_SPLIT) sharedConfig();
    return getPhotoReview;
  },
  //get all registered patients
  listRegisteredPatients: () => {
    if (API_SPLIT) sharedConfig();
    return lambdaSearchAllPatients;
  },
  //send listBilling Patients data
  listBillingPatients: () => {
    if (API_SPLIT) sharedConfig();
    return listBillingPatients;
  },
  //get all patient groups
  listUserGroups: () => {
    if (API_SPLIT) awsConfig();
    return listUserGroups;
  },
  //get patient info of UserReportTable
  listBillingSummary: () => {
    if (API_SPLIT) sharedConfig();
    return listBillingSummary;
  },
  //send listBilling99454Patients Patients data
  listBilling99454Patients: () => {
    if (API_SPLIT) sharedConfig();
    return listBilling99454Patients;
  },
  //send listBilling99454Patients Patients data
  listBilling99453Patients: () => {
    if (API_SPLIT) sharedConfig();
    return listBilling99453Patients;
  },
  //send listBilling99454Patients Patients data
  listBilling99457Patients: () => {
    if (API_SPLIT) sharedConfig();
    return listBilling99457Patients;
  },
  // /**
  //  * mutation
  //  */
  // //edit patient info
  updateNewPatient: () => {
    if (API_SPLIT) sharedConfig();
    return updateNewPatient;
  },
  // //create patient
  registerAccount: () => {
    if (API_SPLIT) sharedConfig();
    return registerAccount;
  },
  // //update foot status(Retake、Resolved)
  updatePhotoStatus: () => {
    if (API_SPLIT) awsConfig();
    return updatePhotoStatus;
  },
  setSchduled: () => {
    if (API_SPLIT) awsConfig();
    return setSchduled;
  },
  setComplianceReviewed: () => {
    if (API_SPLIT) sharedConfig();
    return setComplianceReviewed;
  },
  setAppointmented: () => {
    if (API_SPLIT) sharedConfig();
    return setAppointmented;
  },
  // //edit Siren note
  updateSirenNote: () => {
    if (API_SPLIT) sharedConfig();
    return updateSirenNote;
  },
  //delete Siren note
  deleteSirenNote: () => {
    if (API_SPLIT) sharedConfig();
    return deleteSirenNote;
  },
  createSirenNote: () => {
    if (API_SPLIT) sharedConfig();
    return createSirenNote;
  },
  getSyms: () => {
    if (API_SPLIT) awsConfig();
    return getSyms;
  },
  //delete registered patient
  deleteRegisteredPatient: () => {
    if (API_SPLIT) sharedConfig();
    return deleteRegisteredPatient;
  },
  //send app link to patient
  sendAppLink: () => {
    if (API_SPLIT) sharedConfig();
    return sendAppLink;
  },
  //update new registered patient Dispensed or not
  updateNewRegisterPatient: () => {
    if (API_SPLIT) sharedConfig();
    return updateNewRegisterPatient;
  },
  //reset patient password, reset to a temporary password
  resetPatientPassword: () => {
    if (API_SPLIT) sharedConfig();
    return resetPatientPassword;
  },
  //change dispensedDay or setupBilled
  updateBilledPatient: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilledPatient;
  },
  //update patient account status
  updateAccountStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateAccountStatus;
  },
  //update patient service status
  updateServiceStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateServiceStatus;
  },
  updateBillingStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateBillingStatus;
  },
  updateBilled99453Status: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilled99453Status;
  },
  updateBilled99454Status: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilled99454Status;
  },
  updateBilled99457Status: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilled99457Status;
  },
  //change new patient info
  UpdateNewPatient: () => {
    if (API_SPLIT) sharedConfig();
    return UpdateNewPatient;
  },
  createRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return createRpmTracker;
  },
  listRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return listRpmTracker;
  },
  updateRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return updateRpmTracker;
  },
  resetStaffPassword: () => {
    if (API_SPLIT) sharedConfig();
    return resetStaffPassword;
  },
  //create staff
  registerDoctorAccount: () => {
    if (API_SPLIT) sharedConfig();
    return registerDoctorAccount;
  },
  //delete doctor
  deleteDoctor: () => {
    if (API_SPLIT) sharedConfig();
    return deleteDoctor;
  },
  //edit doctor info
  updateDoctor: () => {
    if (API_SPLIT) sharedConfig();
    return updateDoctor;
  },
  getDoctorNpi: () => {
    if (API_SPLIT) sharedConfig();
    return getDoctorNpi;
  },
  //get sock/hub/app info
  getSock: () => {
    if (API_SPLIT) sharedConfig();
    return getSock;
  },
  //get all registered patients
  listManagementSummary: () => {
    if (API_SPLIT) sharedConfig();
    return listManagementSummary;
  },
  subscribeBillingReport: () => {
    if (API_SPLIT) sharedConfig();
    return onReportGenerated;
  },
  generateBillingReport: () => {
    if (API_SPLIT) sharedConfig();
    return generateBillingReport;
  },
  deleteRpmTracker: () => {
    if (API_SPLIT) sharedConfig();
    return deleteRpmTracker;
  },
  verifyHubInfo: () => {
    if (API_SPLIT) sharedConfig();
    return verifyHubInfo;
  },
  verifyBoxInfo: () => {
    if (API_SPLIT) sharedConfig();
    return verifyBoxInfo;
  },
  checkPatientSocksAndHub: () => {
    if (API_SPLIT) sharedConfig();
    return checkPatientSocksAndHub;
  },
  updateDoctorProfile: () => {
    if (API_SPLIT) sharedConfig();
    return updateDoctorProfile;
  },
  listAdminUsers: () => {
    if (API_SPLIT) sharedConfig();
    return listAdminStaff;
  },
  getSockBySirenId: () => {
    if (API_SPLIT) sharedConfig();
    return getSockBySirenId;
  },
  getDoctorProfile: () => {
    if (API_SPLIT) awsConfig();
    return getDoctorProfile;
  },
  getPatientActivity: () => {
    if (API_SPLIT) sharedConfig();
    return getPatientActivity;
  },
  //update device status
  updateDeviceStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateDeviceStatus;
  },
  listICD10Codes: () => {
    if (API_SPLIT) sharedConfig();
    return listICD10Codes;
  },
  listCarePlan: () => {
    if (API_SPLIT) sharedConfig();
    return listCarePlans;
  },
  createCarePlan: () => {
    if (API_SPLIT) sharedConfig();
    return createCarePlan;
  },
  deleteCarePlan: () => {
    if (API_SPLIT) sharedConfig();
    return deleteCarePlan;
  },
  listMedicalConditions: () => {
    if (API_SPLIT) sharedConfig();
    return listMedicalConditions;
  },
  createFootSymptom: () => {
    if (API_SPLIT) sharedConfig();
    return createFootSymptom;
  },
  //updateSpecialCaseStatus
  updateSpecialCaseStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateSpecialCaseStatus;
  },
  updateAddendum: () => {
    if (API_SPLIT) sharedConfig();
    return updateAddendum;
  },
  updateBilledPCMStatus: () => {
    if (API_SPLIT) sharedConfig();
    return updateBilledPCMStatus;
  },
  //send listBillingPCMPatients Patients data
  listBillingPCMPatients: () => {
    if (API_SPLIT) sharedConfig();
    return listBillingPCMPatients;
  }
};

export default _api;
