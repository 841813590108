import moment from "moment";
import * as TYPES from "../types/types";
const initialState = {
  sockStatus: {},
  sockHomeStatus: {},
  medicalStatus: {},
  supportStatus: {},
  patientStatusDetail: [],
  calendardata: {},
  timezone: {},
  footStatus: {},
  footOverview: {},
  symtomDatas: [],
  delta: [],
  leftFootTemp: [],
  rightFootTemp: [],
  steps: [],
  leftPosition: [],
  rightPosition: [],
  resolvedList: [],
  complianceList: [],
  appointmentList: [],
  listCarePlan: [],
  doctorDetails: {},
  patientFirstName: "",
  patientLastName: "",
  patientBirMonth: "",
  patientBirDay: "",
  patientBirYear: "",
  patientEmail: "",
  patientMBI: "",
  patientPhone: "",
  patientCellPhone: "",
  patientHomePhone: "",
  patientSecPhone: "",
  patientSecEmail: "",
  patientSecContact: "",
  patientPatientID: "",
  patientBirthday: "",
  patientSiteId: "",
  patientSiteName: "",
  patientGender: "",
  patientSecRela: "",
  patientDoctorId: "",
  patientLPN: "",
  listAdminStaff: [],
  clinicSites: [],
  allClinicSites: [],
  listStaff: [],
  trialStaff: [],
  prescribingStaff: [],
  min1_or_min30: "min1",
  isZero: false,
  changeFootDate: new Date(),
  changeRangeDate: new Date(),
  patientWorn: [],
  delta_auto: false,
  loading_charts: false,
  tempUnit: "Fahrenheit",
  calendarDate: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
  emptyFirstName: false,
  emptyLastName: false,
  emptyBir: false,
  birMonthErrorFormat: false,
  birDayErrorFormat: false,
  birErrorFormat: false,
  emptyPhone: false,
  modalState: false,
  phoneErrorFormat: false,
  secPhoneErrorFormat: false,
  emptySiteId: false,
  emptyDoctorIds: false,
  emptyDispensedMethod: false,
  filterGroup: [],
  summaryList: [],
  summaryListFilter: [],
  delPatients: [],
  diagnosisCodes: "",
  medicalConditions: "",
  patientNotes: "",
  dispenseComment: "",
  dispenseStyle: "SIREN_SHIPPED",
  dispenseStyleCheck: null,
  sockSizeCheck: null,
  sockColorCheck: null,
  auth_secondary: false,
  remoteMonitor: false,
  remoteMonitorCheck: null,
  dispenseCriteria: false,
  dispenseCriteriaCheck: null,
  selRightToe: false,
  selRightMts: false,
  selRightArch: false,
  selRightHeel: false,
  selLeftToe: false,
  selLeftMts: false,
  selLeftArch: false,
  selLeftHeel: false,
  initializedNote: [],
  intakePatient: [],
  npmService: [],
  filterNpmService: [],
  ifHavePhone: "",
  ifHaveWifi: "",
  patientBirDate: "",
  sockHubType: null,
  contactByPhone: false,
  contactByEmail: false,
  contactByMessage: false,
  serialNumber: "",
  hubId: "",
  registerBoxType: "",
  allergicMaterials: false,
  agreeUploadPhoto: false,
  doctorId: "",
  clinicIRB: "",
  userName: ""
};

export default function patientsList(state = initialState, action) {
  switch (action.type) {
    case TYPES.USER_LOGIN_NAME:
      return {
        ...state,
        userName: action.text
      };
    case TYPES.DELTA_AUTO:
      return {
        ...state,
        delta_auto: action.text
      };
    case TYPES.TEMP_UNIT:
      return {
        ...state,
        tempUnit: action.text
      };
    case TYPES.IS_ZERO:
      return {
        ...state,
        isZero: action.text
      };
    case TYPES.MIN1_OR_MIN30:
      return {
        ...state,
        min1_or_min30: action.text
      };
    case TYPES.RESET_ALL_PATIENT_INFO:
      return {
        ...state,
        patientFirstName: "",
        patientLastName: "",
        patientEmail: "",
        patientPatientID: "",
        patientPhone: "",
        patientCellPhone: "",
        patientHomePhone: "",
        patientSecPhone: "",
        patientSecContact: "",
        patientSecEmail: "",
        patientBirMonth: "",
        patientBirDay: "",
        patientGender: "",
        patientSecRela: "",
        patientBirYear: "",
        medicalConditions: "",
        diagnosisCodes: "",
        patientNotes: "",
        dispenseComment: "",
        sockSize: "",
        sockColor: "Patient Preference",
        shipAddress: "",
        shipZip: "",
        shipState: "",
        shipCity: "",
        auth_secondary: false,
        selRightToe: false,
        selRightMts: false,
        selRightArch: false,
        selRightHeel: false,
        selLeftToe: false,
        selLeftMts: false,
        selLeftArch: false,
        selLeftHeel: false,
        remoteMonitor: false,
        remoteMonitorCheck: null,
        dispenseCriteria: false,
        dispenseCriteriaCheck: null,
        patientMBI: "",
        patientBirDate: "",
        ifHavePhone: "",
        ifHaveWifi: "",
        hubId: "",
        serialNumber: "",
        registerBoxType: "",
        sockHubType: ""
      };
    case TYPES.PATIENT_PHONE:
      return {
        ...state,
        patientPhone: action.text
      };
    case TYPES.PATIENT_CELL_PHONE:
      return {
        ...state,
        patientCellPhone: action.text
      };
    case TYPES.PATIENT_HOME_PHONE:
      return {
        ...state,
        patientHomePhone: action.text
      };
    case TYPES.PATIENT_SEC_PHONE:
      return {
        ...state,
        patientSecPhone: action.text
      };
    case TYPES.PATIENT_SEC_EMAIL:
      return {
        ...state,
        patientSecEmail: action.text
      };
    case TYPES.PATIENT_SEC_CONTACT:
      return {
        ...state,
        patientSecContact: action.text
      };
    case TYPES.PATIENT_FIRST_NAME:
      return {
        ...state,
        patientFirstName: action.text
      };
    case TYPES.PATIENT_LAST_NAME:
      return {
        ...state,
        patientLastName: action.text
      };
    case TYPES.BIR_MONTH:
      return {
        ...state,
        patientBirMonth: action.text
      };
    case TYPES.BIR_DAY:
      return {
        ...state,
        patientBirDay: action.text
      };
    case TYPES.BIR_YEAR:
      return {
        ...state,
        patientBirYear: action.text
      };
    case TYPES.CLINIC_IRB:
      return {
        ...state,
        clinicIRB: action.text
      };
    case TYPES.PATIENT_EMAIL:
      return {
        ...state,
        patientEmail: action.text
      };
    case TYPES.PATIENT_MBI:
      return {
        ...state,
        patientMBI: action.text
      };
    case TYPES.PATIENT_PATIENT_ID:
      return {
        ...state,
        patientPatientID: action.text
      };
    case TYPES.PATIENT_BIRTHDAY:
      return {
        ...state,
        patientBirthday: action.text
      };
    case TYPES.PATIENT_SITE_ID:
      return {
        ...state,
        patientSiteId: action.text
      };
    case TYPES.PATIENT_SITE_NAME:
      return {
        ...state,
        patientSiteName: action.text
      };
    case TYPES.PATIENT_GENDER:
      return {
        ...state,
        patientGender: action.text
      };
    case TYPES.PATIENT_SEC_RELA:
      return {
        ...state,
        patientSecRela: action.text
      };
    case TYPES.PATIENT_DOCTOR_ID:
      return {
        ...state,
        patientDoctorId: action.text
      };
    case TYPES.PATIENT_LPN:
      return {
        ...state,
        patientLPN: action.text
      };
    case TYPES.LIST_ADMIN_STAFF:
      return {
        ...state,
        listAdminStaff: action.text
      };
    case TYPES.DELTA:
      return {
        ...state,
        delta: action.text
      };
    case TYPES.LEFT_FOOT_TEMP:
      return {
        ...state,
        leftFootTemp: action.text
      };
    case TYPES.RIGHT_FOOT_TEMP:
      return {
        ...state,
        rightFootTemp: action.text
      };
    case TYPES.STEPS:
      return {
        ...state,
        steps: action.text
      };
    case TYPES.LEFT_POSITION:
      return {
        ...state,
        leftPosition: action.text
      };
    case TYPES.RIGHT_POSITION:
      return {
        ...state,
        rightPosition: action.text
      };
    case TYPES.PATIENTS_STATUS_DETAIL:
      return {
        ...state,
        patientStatusDetail: action.text
      };
    case TYPES.SOCK_STATUS:
      return {
        ...state,
        sockStatus: action.text
      };
    case TYPES.SOCK_HOME_STATUS:
      return {
        ...state,
        sockHomeStatus: action.text
      };
    case TYPES.MEDICAL_STATUS:
      return {
        ...state,
        medicalStatus: action.text
      };
    case TYPES.SUPPORT_STATUS:
      return {
        ...state,
        supportStatus: action.text
      };
    case TYPES.CALENDAR_DATA:
      return {
        ...state,
        calendardata: action.text
      };
    case TYPES.TIME_ZONE:
      return {
        ...state,
        timezone: action.text
      };
    case TYPES.FOOT_STATUS:
      return {
        ...state,
        footStatus: action.text,
        footOverview: action.footOverview,
      };
    case TYPES.FOOT_SYMTOMS:
      return {
        ...state,
        symtomDatas: action.text
      };
    case TYPES.DOCTOR_DETAILS:
      return {
        ...state,
        doctorDetails: action.text
      };
    case TYPES.RESOLVED_LIST:
      return {
        ...state,
        resolvedList: action.text
      };
    case TYPES.APPOINTMENT_LIST:
      return {
        ...state,
        appointmentList: action.text
      };
    case TYPES.COMPLIANCE_LIST:
      return {
        ...state,
        complianceList: action.text
      };
    case TYPES.CLINIC_SITES:
      return {
        ...state,
        clinicSites: action.text
      };
    case TYPES.ALL_CLINIC_SITES:
      return {
        ...state,
        allClinicSites: action.text
      };
    case TYPES.LIST_STAFF:
      return {
        ...state,
        listStaff: action.text
      };
    case TYPES.TRIAL_STAFF:
      return {
        ...state,
        trialStaff: action.text
      };
    case TYPES.PRESCRIBING_STAFF:
      return {
        ...state,
        prescribingStaff: action.text
      };
    case TYPES.FOOT_DATA:
      return {
        ...state,
        changeFootDate: action.text
      };
    case TYPES.RANGE_DATA:
      return {
        ...state,
        changeRangeDate: action.text
      };
    case TYPES.PATIENT_WORN:
      return {
        ...state,
        patientWorn: action.text
      };
    case TYPES.LOADING_CHARTS:
      return {
        ...state,
        loading_charts: action.text
      };
    case TYPES.CALEN_DATE:
      return {
        ...state,
        calendarDate: action.text
      };
    case TYPES.EMPTY_FIRST_NAME:
      return {
        ...state,
        emptyFirstName: action.text
      };
    case TYPES.EMPTY_LAST_NAME:
      return {
        ...state,
        emptyLastName: action.text
      };
    case TYPES.EMPTY_BIR:
      return {
        ...state,
        emptyBir: action.text
      };
    case TYPES.ERROR_MONTH_BIR:
      return {
        ...state,
        birMonthErrorFormat: action.text
      };
    case TYPES.ERROR_DAY_BIR:
      return {
        ...state,
        birDayErrorFormat: action.text
      };
    case TYPES.ERROR_BIR:
      return {
        ...state,
        birErrorFormat: action.text
      };
    case TYPES.EMPTY_PHONE:
      return {
        ...state,
        emptyPhone: action.text
      };
    case TYPES.ERROR_PHONE:
      return {
        ...state,
        phoneErrorFormat: action.text
      };
    case TYPES.ERROR_SEC_PHONE:
      return {
        ...state,
        secPhoneErrorFormat: action.text
      };
    case TYPES.EMPTY_SITE_ID:
      return {
        ...state,
        emptySiteId: action.text
      };
    case TYPES.EMPTY_DOCTOR_ID:
      return {
        ...state,
        emptyDoctorId: action.text
      };
    case TYPES.SUMMARY_LIST:
      return {
        ...state,
        summaryList: action.text
      };
    case TYPES.SUMMARY_LIST_FILTER:
      return {
        ...state,
        summaryListFilter: action.text
      };
    case TYPES.DELETE_PATIENTS:
      return {
        ...state,
        delPatients: action.text
      };
    case TYPES.FILTER_GROUP:
      return {
        ...state,
        filterGroup: action.text
      };
    case TYPES.DIAGNOSIS_CODES:
      return {
        ...state,
        diagnosisCodes: action.text
      };
    case TYPES.MEDICAL_CODITIONS:
      return {
        ...state,
        medicalConditions: action.text
      };
    case TYPES.PATIENT_NOTES:
      return {
        ...state,
        patientNotes: action.text
      };
    case TYPES.DISPENSE_COMMENT:
      return {
        ...state,
        dispenseComment: action.text
      };
    case TYPES.DISPENSE_STYLE:
      return {
        ...state,
        dispenseStyle: action.text
      };
    case TYPES.SOCK_SIZE:
      return {
        ...state,
        sockSize: action.text
      };
    case TYPES.SOCK_COLOR:
      return {
        ...state,
        sockColor: action.text
      };
    case TYPES.SHIP_ADDRESS:
      return {
        ...state,
        shipAddress: action.text
      };
    case TYPES.SHIP_CITY:
      return {
        ...state,
        shipCity: action.text
      };
    case TYPES.SHIP_STATE:
      return {
        ...state,
        shipState: action.text
      };
    case TYPES.SHIP_ZIP:
      return {
        ...state,
        shipZip: action.text
      };
    case TYPES.AUTH_SECONDARY:
      return {
        ...state,
        auth_secondary: action.text
      };
    case TYPES.CONTACT_PHONE:
      return {
        ...state,
        contactByPhone: action.text
      };
    case TYPES.CONTACT_EMAIL:
      return {
        ...state,
        contactByEmail: action.text
      };
    case TYPES.CONTACT_MESSAGE:
      return {
        ...state,
        contactByMessage: action.text
      };
    case TYPES.REMOTE_MONITOR:
      return {
        ...state,
        remoteMonitor: action.text
      };
    case TYPES.ALLERGIC_MATERIALS:
      return {
        ...state,
        allergicMaterials: action.text
      };
    case TYPES.AGREE_UPLOAD_PHOTO:
      return {
        ...state,
        agreeUploadPhoto: action.text
      };
    case TYPES.REMOTE_MONITOR_CHECK:
      return {
        ...state,
        remoteMonitorCheck: action.text
      };
    case TYPES.DISPENSE_CRITERIA:
      return {
        ...state,
        dispenseCriteria: action.text
      };
    case TYPES.DISPENSE_CRITERIA_CHECK:
      return {
        ...state,
        dispenseCriteriaCheck: action.text
      };
    case TYPES.DISPENSE_STYLE_CHECK:
      return {
        ...state,
        dispenseStyleCheck: action.text
      };
    case TYPES.SOCK_COLOR_CHECK:
      return {
        ...state,
        sockColorCheck: action.text
      };
    case TYPES.SOCK_SIZE_CHECK:
      return {
        ...state,
        sockSizeCheck: action.text
      };
    case TYPES.SEL_RIGHT_TOE:
      return {
        ...state,
        selRightToe: !state.selRightToe
      };
    case TYPES.SEL_RIGHT_MTS:
      return {
        ...state,
        selRightMts: !state.selRightMts
      };
    case TYPES.SEL_RIGHT_ARCH:
      return {
        ...state,
        selRightArch: !state.selRightArch
      };
    case TYPES.SEL_RIGHT_HEEL:
      return {
        ...state,
        selRightHeel: !state.selRightHeel
      };
    case TYPES.SEL_LEFT_TOE:
      return {
        ...state,
        selLeftToe: !state.selLeftToe
      };
    case TYPES.SEL_LEFT_MTS:
      return {
        ...state,
        selLeftMts: !state.selLeftMts
      };
    case TYPES.SEL_LEFT_ARCH:
      return {
        ...state,
        selLeftArch: !state.selLeftArch
      };
    case TYPES.SEL_LEFT_HEEL:
      return {
        ...state,
        selLeftHeel: !state.selLeftHeel
      };
    case TYPES.INITIALIZED_NOTE:
      return {
        ...state,
        initializedNote: action.text
      };
    case TYPES.INTAKE_PATIENT:
      return {
        ...state,
        intakePatient: action.text
      };
    case TYPES.NPM_SERVICE:
      return {
        ...state,
        npmService: action.text
      };
    case TYPES.FILTER_NPM_SERVICE:
      return {
        ...state,
        filterNpmService: action.text
      };
    case TYPES.MODAL_STATE:
      return {
        ...state,
        modalState: action.text
      };
    case TYPES.EMPTY_DISPENSED_METHOD:
      return {
        ...state,
        emptyDispensedMethod: action.text
      };
    case TYPES.HAVE_PHONE:
      return {
        ...state,
        ifHavePhone: action.text
      };
    case TYPES.HAVE_WIFI:
      return {
        ...state,
        ifHaveWifi: action.text
      };
    case TYPES.BIR_DATE:
      return {
        ...state,
        patientBirDate: action.text
      };
    case TYPES.SOCK_HUB_TYPE:
      return {
        ...state,
        sockHubType: action.text
      };

    case TYPES.UPDATE_SERIAL_NUMBER:
      return {
        ...state,
        serialNumber: action.text
      };
    case TYPES.UPDATE_HUB_ID:
      return {
        ...state,
        hubId: action.text
      };
    case TYPES.REGISTER_BOX_TYPE:
      return {
        ...state,
        registerBoxType: action.text
      };
    case TYPES.UPDATE_DOCTORID:
      return {
        ...state,
        doctorId: action.text
      };
    case TYPES.LIST_CARE_PLAN:
      return {
        ...state,
        listCarePlan: action.text
      };
    default:
      return state;
  }
}
