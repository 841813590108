import { Select } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Popup } from 'semantic-ui-react';
import { getlistStaff } from "../action/registerAction";
import getPatchTrProps from "../tools/reactTablePatch";
import AddStaff from "./AddStaff";
import DeleteStaff from "./DeleteStaff";
import EditStaff from "./EditStaff";
import ResetStaffPwd from "./ResetStaffPwd";

const { Option } = Select;
const ellipsisStyle = {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}

class StaffList extends Component {
  componentDidMount() {
    const { getlistStaff } = this.props;
    getlistStaff();
  }
  render() {
    const trProps = getPatchTrProps(({event}) => {
      //WARNING: black magic
      const buttons = event.currentTarget.getElementsByTagName('button')
      for (const button of buttons) {
        if (button.innerHTML === 'Edit') {
          button.click()
          break;
        }
      }
    })
    return (
      <div>
        <div style={{ textAlign: "left", marginTop: 8, marginBottom: 8 }}>
          <AddStaff />
        </div>
        <ReactTable
          getTrProps={trProps}
          data={
            this.props.listStaff &&
            this.props.listStaff.map(p => {
              const fullName =
                p.isPrescribing && !p.firstName.includes("Dr.")
                  ? "Dr. " + p.firstName + " " + p.lastName
                  : p.firstName + " " + p.lastName;
              const isPrescribing =
                p.isPrescribing === true
                  ? "Yes"
                  : p.isPrescribing === false
                  ? "No"
                  : "";
              const notificationEmail = p.notificationEmail
                ? p.notificationEmail
                : p.email;
              const lastLogin = p.lastLogin
                ? moment(p.lastLogin).format("MM/DD/YYYY HH:mm:ss")
                : "";
              return {
                ...p,
                fullName,
                notificationEmail,
                isPrescribing,
                lastLogin
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id].toString().toLowerCase().includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              Header: "Site Id",
              accessor: "siteId",
              Cell: props => (
                <Popup trigger={<p style={ellipsisStyle}>{props.value}</p>} >
                  {props.value.split('-').map((name, index) => <p key={index}>{name}</p>)}
                </Popup>
              )
            },
            {
              Header: "User Name",
              accessor: "email"
            },
            {
              Header: "Notification Email",
              accessor: "notificationEmail"
            },
            {
              Header: "Full Name",
              accessor: "fullName",
              filterMethod: (filter, row) => {
                let rowfilterfullname = row._original.fullName
                  ? row[filter.id].toLowerCase()
                  : "";
                let filterfullname = filter.value.toLowerCase();
                return rowfilterfullname.includes(filterfullname);
              }
            },
            {
              Header: "Prescribing Doctor",
              accessor: "isPrescribing",
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id].includes("Yes");
                }
                if (filter.value === "No") {
                  return row[filter.id] === "No";
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "NPI",
              accessor: "doctorNPI",
              sortable: false
            },
            {
              Header: "Role",
              accessor: "doctorRole",
              sortable: false,
              filterable: false
            },
            {
              Header: "Last Login",
              accessor: "lastLogin"
            },
            {
              Header: "Actions",
              accessor: "",
              sortable: false,
              minWidth: 230,
              filterable: false,
              Cell: props => {
                return (
                  <div onClick={e => e.stopPropagation()}>
                    <DeleteStaff
                      email={props.original.email}
                      givenName={props.original.fullName}
                    />
                    <EditStaff newStaff={props.original} />
                    <ResetStaffPwd id={props.original.email} />
                  </div>
                );
              }
            }
          ]}
          defaultPageSize={20}
          defaultSorted={[
            {
              id: "siteId",
              desc: false
            }
          ]}
          className="-striped -highlight"
          minRows={0}
        />
      </div>
    );
  }
}
const mapStateToProp = state => ({
  listStaff: state.patientsListStore.listStaff,
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  getlistStaff: () => dispatch(getlistStaff())
});
export default connect(mapStateToProp, mapDispatchToProp)(StaffList);
