import React from "react";
import CircleColor from "../MedicalDashboard/home/circle-color";

export default function DeviceStatusDesc() {
  return (
    <div
      style={{
        maxWidth: 400,
        maxHeight: 600,
        overflowY: "auto",
        paddingLeft: 10,
        paddingRight: 30,
        paddingBottom: 10,
        paddingTop: 10
      }}
    >
      <div style={{ fontWeight: "bold", fontSize: 16 }}>Device Status</div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#73D1AE"} />
        </div>{" "}
        <div>
          <strong>Active</strong> - On Service with data in last 7 days
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#AEC3BB"} />
        </div>{" "}
        <div>
          <strong>Idle</strong> - On Service without data in last 7 days
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#F1BE0A"} />
        </div>{" "}
        <div>
          <strong>Paused</strong> - The device has just been registered or it is
          paused temporarily by Siren
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <div>
          <CircleColor color={"#6F7381"} />
        </div>{" "}
        <div>
          <strong>Canceled</strong> - Devices were canceled and returned
        </div>
      </div>
    </div>
  );
}
