import { Checkbox, Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  generate99457BillingReport,
  searchBilling99457Patients,
  updateBilled99457Status
} from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import ChangeBilled from "./changeBilled";
import ReportHeader99457_8 from "./ReportHeader99457_8";
import ReportNeedsReviewBase, {
  ReviewAllCheckbox
} from "./ReportNeedsReviewBase";
import _ from "lodash";

const { Option } = Select;

class ReportNeedsReview99457_8 extends ReportNeedsReviewBase {

  cptCode = "99457-8";

  headerClass = ReportHeader99457_8

  convertCPT = totalTime => {
    if (_.inRange(totalTime, 20, 40)) {
      return "(1) 99457";
    } else if (_.inRange(totalTime, 40, 60)) {
      return "(1) 99457, (1) 99458";
    } else if (_.inRange(totalTime, 60, 80)) {
      return "(1) 99457, (2) 99458";
    }
    return "(1) 99457, (3) 99458";
  };

  renderTableProps(selectAll) {
    const data =
      this.state.tableData &&
      this.state.tableData.map(p => {
        const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
        const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
        const initializedDate = p.initializedDate
          ? p.initializedDate.split("T")[0]
          : "";
        const appInitialized = p.appInitialized ? p.appInitialized : "No";
        const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
        const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
        const diagnosisCodes = p.diagnosisCodes
          ? p.diagnosisCodes.replace(/_/g, ",")
          : "";
        const billingMonth = p.billingDate
          ? moment(p.billingDate).format("MM/YYYY")
          : "";
        const cpt = this.convertCPT(spentTime);
        return {
          ...p,
          createdAt,
          sirenId,
          initializedDate,
          dispensedDay,
          appInitialized,
          billingMonth,
          spentTime,
          diagnosisCodes,
          cpt
        };
      });

    const columns = [
      {
        Header: props => {
          return (
            <Checkbox onChange={this.handleCheckAll} checked={selectAll} />
          );
        },
        accessor: "",
        maxWidth: 60,
        sortable: false,
        filterable: false,
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <Checkbox
                onChange={e => {
                  this.handleCheck(e.target.checked, props.index);
                }}
                checked={this.state.selectedPatients.includes(props.index)}
              />
            </div>
          );
        }
      },
      {
        Header: "CPT",
        accessor: "cpt",
        minWidth: 150,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "(1) 99457, (1) 99458") {
            return row[filter.id] === "(1) 99457, (1) 99458";
          } else if (filter.value === "(1) 99457, (2) 99458") {
            return row[filter.id] === "(1) 99457, (2) 99458";
          } else if (filter.value === "(1) 99457, (3) 99458") {
            return row[filter.id] === "(1) 99457, (3) 99458";
          }
          return row[filter.id] === "(1) 99457";
        },
        Filter: ({ filter, onChange }) => (
          <Select
            className="select_common_style"
            onChange={onChange}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
          >
            <Option value="all">All</Option>
            <Option value="(1) 99457">(1) 99457</Option>
            <Option value="(1) 99457, (1) 99458">(1) 99457, (1) 99458</Option>
            <Option value="(1) 99457, (2) 99458">(1) 99457, (2) 99458</Option>
            <Option value="(1) 99457, (3) 99458">(1) 99457, (3) 99458</Option>
          </Select>
        )
      },
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60
      },
      {
        Header: "PatientID",
        accessor: "patientId"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "Date of Birth",
        accessor: "birthday"
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes"
      },
      {
        Header: "Account Created",
        accessor: "createdAt"
      },
      // {
      //   Header: "App Activated",
      //   accessor: "appInitialized",
      //   maxWidth: 72,
      //   filterMethod: (filter, row) => {
      //     if (filter.value === "all") {
      //       return true;
      //     }
      //     if (filter.value === "Yes") {
      //       return row[filter.id] === "Yes";
      //     }
      //     return row[filter.id] === "No";
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <Select
      //       className="select_common_style"
      //       onChange={onChange}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       showSearch
      //       optionFilterProp="children"
      //     >
      //       <Option value="all">All</Option>
      //       <Option value="Yes">Yes</Option>
      //       <Option value="No">No</Option>
      //     </Select>
      //   )
      // },
      {
        Header: "Socks Dispensed Date",
        accessor: "dispensedDay",
        minWidth: 120,
        Cell: props => {
          return <div>{props.original.dispensedDay}</div>;
        }
      },
      // {
      //   Header: "Initial Data Transmission",
      //   accessor: "firstStatusDay",
      //   minWidth: 120,
      //   filterMethod: (filter, row) => {
      //     return String(row[filter.id]).includes(filter.value);
      //   }
      // },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth"
      },
      {
        Header: "Date of Service",
        accessor: "periodStdEnd",
        Cell: props => props.value || "N/A"
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime"
      },
      {
        Header: "Reviewed",
        accessor: "billed99457",
        sortable: false,
        Filter: () => (
          <ReviewAllCheckbox
            patients={() => {
              let reviewPatients = [];
              if (this.tableInstance) {
                const { page, pageSize, sortedData } = this.tableInstance.state;
                reviewPatients = sortedData.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                );
              }
              return reviewPatients;
            }}
            reviewTask={async p => {
              const inputData = {
                input: {
                  billingDate: p._original.billingDate,
                  userId: p._original.userId,
                  id: p._original.id,
                  billedBy: this.props.username,
                  billedStatus: "BILLED",
                  siteId: p._original.siteId
                }
              };
              await updateBilled99457Status(inputData);
            }}
            onFinish={this.searchPatients}
          />
        ),
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <ChangeBilled
                userInfo={props.original}
                spentTime={props.original.spentTime}
                searchUsers={this.searchPatients}
              />
            </div>
          );
        }
      }
    ];

    return {
      data,
      columns
    };
  }

}

const mapStateToProp = state => ({
  username: state.authGroupStore.profile.email,
  authSites: state.authGroupStore.profile.siteId,
  clinicSites: state.patientsListStore.clinicSites,
  showGenerateDelay: state.monthlyReportStore.showGenerate99457Delay,
  generateDelay: state.monthlyReportStore.generate99457Delay
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  searchBillingPatients: input => dispatch(searchBilling99457Patients(input)),
  generateBillingReport: input => dispatch(generate99457BillingReport(input))
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportNeedsReview99457_8);
