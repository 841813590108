import {
  Checkbox,
  Col,
  Input,
  Row,
  Modal as AntdModal,
  InputNumber,
  Button as AntdButton,
  Select,
  DatePicker
} from "antd";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Button, Modal, Icon } from "semantic-ui-react";
import DividLine from "./dividLine";
import FootCondition from "./footCondition";
import FootSymptom from "./footSymptom";
import FootTreatment from "./footTreatment";
import PatientMedicalHistory from "./patientMedicalHistory";
import SpecialCasePop from "./specialCasePop";
import {
  ServiceOptionList,
  ServiceOptionList_PCM
} from "../tools/patient-medical";
import {
  deleteMonitoringTracker,
  updateAddendum
} from "../action/ServiceAction";
import _, { isEmpty } from "lodash";
import PatientICDCode from "./patientICDCode";
import { removeAllSpace } from "../tools/stringProcess";
const { confirm } = AntdModal;
const { Option } = Select;
const dateFormat = "ddd, Do MMM YYYY";
const programOptions = ["CCM", "PCM", "RPM", "RTM"];
const cptCodes = [
  "99490 & 99439",
  "99426 & 99427",
  "99457 & 99458",
  "98980 & 98981"
];
const program2CPT = {
  RPM: "99457 & 99458",
  RTM: "98980 & 98981",
  PCM: "99426 & 99427",
  CCM: "99490 & 99439"
};
class Addendum99457Service extends Component {
  state = {
    open: false,
    submitting: false,
    userName: "",
    loadings: [],
    programNotes: [],
    symptomData: [],
    conditionData: [],
    treatmentData: [],
    serviceDate: "",
    medicalCondition: [],
    footSpecific: [],
    footAmputation: [],
    diagnosisCodes: []
  };

  deleteRpmConfirm = () => {
    confirm({
      title: "Delete DMT Confirmation",
      content: `Are you sure you want to permanently delete this DMT entry?`,
      cancelText: "No",
      okText: "Yes",
      onCancel: () => {},
      onOk: () => {
        this.handleDeleteDMT();
      }
    });
  };

  handleDeleteDMT = () => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      userId: this.props.serviceInfo.userId,
      programTime: this.props.serviceInfo.programTime,
      programNotes: this.props.serviceInfo.programNotes,
      currentMonth: moment().format("YYYY-MM"),
      updatePatientMedicalInfoVersion: false,
      siteId: this.props.patientDetails.siteId,
      serviceDate: moment(this.props.serviceInfo.serviceDate).format(
        "YYYY-MM-DD"
      )
    };
    this.props.deleteMonitoringTracker(input, this).then(() => {
      this.props.loadNewEditValue();
    });
  };

  handleOpen = () => {
    this.props.isEdit(true);
    const { profile } = this.props;
    let userName = "Siren Doctor";
    if (profile.firstName && profile.lastName) {
      userName = `${profile.firstName} ${profile.lastName}`;
    }
    let serviceDate = moment(this.props.serviceInfo.serviceDate).format(
      "YYYY-MM-DD"
    );

    this.setState({
      open: true,
      userName,
      serviceDate,
      programNotes: this.props.serviceInfo.programNotes.map(item => {
        return {
          ...item,
          cptCode: item.cptCode ? item.cptCode : program2CPT[item.program]
        };
      }),
      diagnosisCodes: this.props.serviceInfo.diagnosisCodes
        ? this.props.serviceInfo.diagnosisCodes.split("_")
        : [],
      symptomData: this.props.serviceInfo.symptoms
        ? this.props.serviceInfo.symptoms
        : [],
      medicalCondition: this.props.serviceInfo.medicalCondition
        ? this.props.serviceInfo.medicalCondition.split(",")
        : [],
      footSpecific: this.props.serviceInfo.footSpecific
        ? this.props.serviceInfo.footSpecific.split(",")
        : [],
      footAmputation: this.props.serviceInfo.footAmputation
        ? this.props.serviceInfo.footAmputation.split(",")
        : [],
      conditionData: this.props.serviceInfo.footConditions
        ? this.props.serviceInfo.footConditions
        : [],
      treatmentData: this.props.serviceInfo.footTreatments
        ? this.props.serviceInfo.footTreatments
        : []
    });
  };

  onAddendumChanged = (event, index) => {
    const newAddendum = {
      userName: this.state.userName,
      addendumTime: moment().format("MM/DD/YY"),
      addendum: event.target.value
    };
    this.updateNoteList(newAddendum, index);
  };

  close = () => {
    this.props.isEdit(false);
    this.setState({
      open: false,
      submitting: false,
      userName: "",
      programNotes: [],
      symptomData: []
    });
  };

  updateNoteList = (newValue, index) => {
    const programNotes = this.state.programNotes.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          ...newValue
        };
      }
      return item;
    });
    this.setState({ programNotes });
  };

  onDoctorTimeChanged = (value, index) => {
    this.updateNoteList({ doctorTime: value && parseInt(value) }, index);
  };

  handleProgramChange = (value, index) => {
    this.updateNoteList({ program: value }, index);
  };

  onNurseTimeChanged = (value, index) => {
    this.updateNoteList({ nurseTime: value && parseInt(value) }, index);
  };

  onDescriptionChanged = (event, index) => {
    this.updateNoteList({ description: event.target.value }, index);
  };

  errorModal = content => {
    AntdModal.info({
      title: "Incomplete Fields",
      content,
      centered: true
    });
  };

  checkService = (program, rpmService) => {
    const service = rpmService.split(",");
    const rpm = ["A", "B", "C", "D", "E"];
    const pcm = ["F", "G", "H", "I"];
    return (
      (program !== "PCM" && _.intersection(service, rpm).length > 0) ||
      (program === "PCM" && _.intersection(service, pcm).length > 0)
    );
  };

  submit = () => {
    const programNotes = this.state.programNotes.map(item => {
      const newAddendum = item.addendum
        ? [
            {
              userName: item.userName,
              addendumTime: item.addendumTime,
              addendum: item.addendum
            }
          ]
        : [];
      const addendums = item.addendums
        ? _.compact([...item.addendums, ...newAddendum])
        : _.compact([...newAddendum]);
      return {
        ...item,
        addendums
      };
    });
    const programNotesCheck = _.compact(
      programNotes.map(item => {
        console.log(this.checkService(item.program, item.rpmService));
        if (
          item.program &&
          !isEmpty(item.description) &&
          !isEmpty(item.rpmService) &&
          this.checkService(item.program, item.rpmService)
        ) {
          return item;
        }
        return null;
      })
    );
    if (programNotesCheck.length < programNotes.length) {
      this.errorModal("Program, Service and Details are required.");
      return;
    }
    const input = {
      userId: this.props.patientDetails.userId,
      siteId: this.props.patientDetails.siteId,
      programTime: this.props.serviceInfo.programTime,
      serviceDate: moment(this.props.serviceInfo.serviceDate).format(
        "YYYY-MM-DD"
      ),
      currentMonth: moment().format("YYYY-MM"),
      programNotes,
      doctorId: this.props.serviceInfo.doctorId,
      doctorName: this.props.serviceInfo.doctorName
    };
    if (
      this.state.serviceDate !==
      this.props.serviceInfo.programTime.substr(0, 10)
    ) {
      Object.assign(input, {
        newServiceDate: moment(this.state.serviceDate).format("YYYY-MM-DD")
      });
    }
    if (this.state.symptomData.length > 0) {
      input.symptoms = this.state.symptomData.map(item => ({
        ...item,
        userId: this.props.patientDetails.userId
      }));
    }
    if (this.state.conditionData.length > 0) {
      Object.assign(input, { conditions: this.state.conditionData });
    }
    if (this.state.footTreatmentStatus === "No") {
      Object.assign(input, { treatments: this.state.treatmentData });
    }
    if (this.state.diagnosisCodes.length > 0) {
      Object.assign(input, {
        diagnosisCodes: this.state.diagnosisCodes
          .map(p => {
            return p;
          })
          .join("_")
      });
    }
    if (this.state.medicalCondition) {
      Object.assign(input, {
        medicalCondition: this.state.medicalCondition.join(",")
      });
    }
    if (this.state.footSpecific) {
      Object.assign(input, {
        footSpecific: this.state.footSpecific.join(",")
      });
    }
    if (this.state.footAmputation) {
      Object.assign(input, {
        footAmputation: this.state.footAmputation.join(",")
      });
    }
    this.props.updateAddendum(input, this);
  };

  renderService = service => {
    return service.map((v, i) => (
      <div style={{ marginTop: 10 }} key={i}>
        <Checkbox value={v.option} style={{ width: "100%" }}>
          <strong
            style={{ paddingLeft: 4, paddingRight: 4, display: "inline-block" }}
          >
            {v.option}.
          </strong>
          <span
            style={{
              display: "inline-block",
              width: "calc(100% - 60px)",
              verticalAlign: "top"
            }}
          >
            {v.brief} <strong>{v.memo}</strong>
          </span>
        </Checkbox>
      </div>
    ));
  };

  renderDescSection(item, i) {
    return (
      <div style={{ marginTop: 15 }}>
        <div style={{ display: "flex" }}>
          <div>
            <div style={{ display: "flex" }}>
              <strong>Program</strong> <p style={{ color: "red" }}>*</p>
            </div>
            <Select
              value={item.program}
              onChange={value => this.handleProgramChange(value, i)}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
              placeholder={"Select a program"}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              disabled
            >
              {this.props.patientDetails &&
                this.props.patientDetails.program &&
                this.props.patientDetails.program.map((p, index) => {
                  return (
                    <Option value={p} key={index}>
                      {p}
                    </Option>
                  );
                })}
            </Select>
          </div>
          <div style={{ marginLeft: 20 }}>
            <div style={{ display: "flex" }}>
              <strong>CPT Codes</strong> <p style={{ color: "red" }}>*</p>
            </div>
            <Select
              value={item.cptCode}
              style={{ width: 200, marginTop: 10, marginBottom: 15 }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              disabled
            >
              {cptCodes.map((p, index) => {
                return (
                  <Option value={p} key={index}>
                    {p}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <div>
              <strong>Doctor Time</strong>
            </div>
            <InputNumber
              min={0}
              value={item.doctorTime}
              onChange={value => this.onDoctorTimeChanged(value, i)}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled={
                item.program &&
                this.props.patientDetails[
                  `${item.program.toLowerCase()}ProgramStatus`
                ] === "NOT_ON_SERVICE"
              }
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong>Nurse Time</strong>
            </div>
            <InputNumber
              min={0}
              value={item.nurseTime}
              onChange={value => this.onNurseTimeChanged(value, i)}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled={
                item.program &&
                this.props.patientDetails[
                  `${item.program.toLowerCase()}ProgramStatus`
                ] === "NOT_ON_SERVICE"
              }
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <div>
              <strong>Total Time (mins)</strong>
            </div>
            <InputNumber
              min={0}
              value={item.doctorTime + item.nurseTime}
              style={{ width: 150, marginTop: 5 }}
              type={"number"}
              disabled
            />
          </div>
        </div>
        <div style={{ height: 40 }} />
        <strong>
          {item.program === "PCM"
            ? "Select the Principal Care Management (PCM) "
            : "Select the remote monitoring treatment management "}
          services provided:
        </strong>
        <Checkbox.Group
          onChange={event => this.onServiceChanged(event, i)}
          value={
            !isEmpty(item.rpmService)
              ? removeAllSpace(item.rpmService).split(",")
              : null
          }
        >
          <Row>
            {item.program === "PCM"
              ? this.renderService(ServiceOptionList_PCM)
              : this.renderService(ServiceOptionList)}
          </Row>
        </Checkbox.Group>
        <div style={{ marginTop: 15, display: "flex" }}>
          <strong>Details</strong> <p style={{ color: "red" }}>*</p>
        </div>
        <Input.TextArea
          autoSize={{
            minRows: 4
          }}
          value={item.description}
          onChange={e => this.onDescriptionChanged(e, i)}
          style={{ marginTop: 5 }}
          disabled
        />
        {item.addendums &&
          item.addendums.map((p, i) => {
            return (
              <div style={{ marginTop: 10 }} key={i}>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 12
                    }}
                  >
                    Addendum
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      marginLeft: 15,
                      paddingTop: "4px",
                      fontStyle: "oblique"
                    }}
                  >
                    Added by {p.userName} on {p.addendumTime}
                  </div>
                </div>
                <Input.TextArea
                  autoSize={{
                    minRows: 4
                  }}
                  value={p.addendum}
                  disabled
                />
              </div>
            );
          })}
        <div style={{ marginTop: 10 }}>
          <div style={{ display: "flex" }}>
            <div
              style={{
                flex: 1,
                fontSize: 18,
                fontWeight: "bold",
                marginTop: 15
              }}
            >
              Addendum
            </div>
          </div>
          <Input.TextArea
            autoSize={{
              minRows: 4
            }}
            value={item.addendum}
            onChange={e => this.onAddendumChanged(e, i)}
            style={{ marginTop: 5 }}
          />
        </div>
      </div>
    );
  }

  onServiceChanged = (event, index) => {
    this.updateNoteList({ rpmService: event.join(",") }, index);
  };

  onDateChanged = (date, dateString) => {
    this.setState({ serviceDate: moment(date).format("YYYY-MM-DD") });
  };

  disableFutureDate = current =>
    moment(current).format("YYYY-MM") > moment().format("YYYY-MM") ||
    moment(current).format("YYYY-MM") < moment().format("YYYY-MM");

  render() {
    return (
      <Modal
        trigger={
          <Button size="mini" style={{ display: "none" }}>
            Addendum
          </Button>
        }
        onOpen={this.handleOpen}
        onClose={this.close}
        open={this.state.open}
      >
        <Modal.Header>Add Addendum</Modal.Header>
        <Modal.Content scrolling style={{ padding: "1.5rem 3rem" }}>
          <div style={{ fontSize: 14 }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <div>
                      <strong>Service Date</strong>
                    </div>
                    <DatePicker
                      onChange={this.onDateChanged}
                      value={moment(this.state.serviceDate)}
                      format={dateFormat}
                      style={{ width: 200, marginTop: 10 }}
                      allowClear={false}
                      disabledDate={
                        this.props.patientDetails.siteId &&
                        !this.props.patientDetails.siteId.startsWith("0203") &&
                        this.disableFutureDate
                      }
                      disabled={
                        this.props.patientDetails.siteId &&
                        !this.props.patientDetails.siteId.startsWith("0203")
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  <div style={{ width: 200 }}>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: 10,
                        display: "flex"
                      }}
                    >
                      Special Case
                      <SpecialCasePop />
                    </div>
                    <Input
                      disabled
                      value={this.props.patientDetails.specialCase}
                    />
                  </div>
                  <div style={{ width: 400, marginLeft: 20 }}>
                    <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                      Reason
                    </div>
                    <Input
                      disabled
                      value={this.props.patientDetails.specialCaseReason}
                    />
                  </div>
                </div>
                <DividLine />
                {
                  <FootSymptom
                    onSymptomChange={symptomData => {
                      symptomData = symptomData.map(p => {
                        return {
                          ...p,
                          reportTime:
                            this.state.serviceDate + p.reportTime.substring(10)
                        };
                      });
                      this.setState({ symptomData });
                    }}
                    data={this.props.serviceInfo.symptoms}
                  />
                }
                <PatientMedicalHistory
                  readOnly
                  medicalCondition={this.state.medicalCondition}
                  footSpecific={this.state.footSpecific}
                  footAmputation={this.state.footAmputation}
                  serviceInfo={this.props.serviceInfo}
                  preview={true}
                />
                <DividLine />
                <PatientICDCode
                  canEdit={false}
                  diagnosisCodes={this.state.diagnosisCodes}
                />
                <DividLine />
                {<FootCondition serviceInfo={this.props.serviceInfo} />}
                <FootTreatment serviceInfo={this.props.serviceInfo} />
                <div style={{ marginTop: 20 }}>
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: 12
                    }}
                  >
                    Notes
                  </div>
                  {this.state.programNotes.map((item, i) => {
                    return (
                      <div key={i}>
                        {i > 0 && <DividLine />}
                        {this.renderDescSection(item, i)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions style={{ display: "flex" }}>
          <AntdButton
            type="link"
            style={{
              color: "red",
              float: "left",
              position: "absolute",
              display:
                this.props.staffId === this.props.serviceInfo.doctorId
                  ? "block"
                  : "none"
            }}
            loading={this.state.loadings[0]}
            onClick={this.deleteRpmConfirm}
          >
            Delete
          </AntdButton>
          <div style={{ textAlign: "center", flex: 1 }}>
            <Button
              color="green"
              onClick={e => this.submit()}
              style={{
                display: this.state.confirmSuccess ? "none" : "inline-block"
              }}
              disabled={this.state.submitting}
            >
              <Icon name="checkmark" />
              Submit
            </Button>
            <Button
              basic
              color="red"
              onClick={e => this.close()}
              style={{ marginLeft: 20 }}
            >
              <Icon name="remove" />
              Cancel
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  patientDetails: state.patientsListStore.doctorDetails,
  profile: state.authGroupStore.profile,
  npmService: state.patientsListStore.npmService
});

const mapDispatchToProp = dispatch => ({
  updateAddendum: (input, self) => dispatch(updateAddendum(input, self)),
  deleteMonitoringTracker: (input, self) =>
    dispatch(deleteMonitoringTracker(input, self))
});

export default connect(mapStateToProp, mapDispatchToProp)(Addendum99457Service);
