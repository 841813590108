import { Checkbox, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Control } from "react-keeper";
import { connect } from "react-redux";
import { searchBilling99453Patients, searchBilling99454Patients } from "../action/bliMonthyReportAction";
import { getlistClinicSites, getlistStaff } from "../action/registerAction";
import ReportHeader99453_4 from "./ReportHeader99453_4";
import ReportReviewedBase from "./ReportReviewedBase";


const { Option } = Select;

class ReportReviewed99453_4 extends ReportReviewedBase {

  cptCode = "99453-4"

  headerClass = ReportHeader99453_4

  makeRequestSearchPatient() {
    this.setState({
      selectedPatients: []
    });
    const fmtSiteId = this.state.selectSites.join("_");
    const billingInputValue = {
      siteId: fmtSiteId,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    this.setState({
      modalIsOpen: true
    })
    Promise.all([this.props.searchBilling99454Patients(billingInputValue), this.props.searchBilling99453Patients(billingInputValue)]).then((values) => {
      this.setState({
        tableData: values.flat()
      })

      let currentPath = Control.path;
      if (currentPath.includes('?')) {
        currentPath = currentPath.split('?')[0]
      }
      Control.replace(
        `${currentPath}?siteId=${fmtSiteId}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`
      );
    }).catch(e => {
      console.log("searchBillingPatients error: ", e);
    }).finally(() => {
      this.setState({
        modalIsOpen: false
      })
    });
  }

  renderTableProps() {
    const data = this.state.tableData && this.state.tableData.map(p => {
      const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
      const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
      const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
      const initializedDate = p.initializedDate
        ? p.initializedDate.split("T")[0]
        : "";
      const appInitialized = p.appInitialized ? p.appInitialized : "No";
      let spentTime = 0;
      let doctor = "";
      if (p.rpmInfo && p.rpmInfo.length > 0) {
        for (var i in p.rpmInfo) {
          spentTime =
            spentTime + parseInt(p.rpmInfo[i].spentTime.split(" ")[0]);
        }
        doctor = p.rpmInfo[0].doctorName;
      }
      let period;
      if (p.period99454Start) {
        if (p.period99454End) {
          period = p.period99454Start + "~" + p.period99454End;
        } else {
          period = p.period99454Start;
        }
      } else {
        if (p.period99454End) {
          period = "~" + p.period99454End;
        } else {
          period = "N/A";
        }
      }
      const billedAt =
        p.billedType === "99453"
          ? p.billed99453At
            ? moment
              .tz(p.billed99453At, p.userTimezone)
              .format("YYYY-MM-DD")
            : "N/A"
          : p.billedAt
            ? moment.tz(p.billedAt, p.userTimezone).format("YYYY-MM-DD")
            : "N/A";
      let billedBy =
        p.billedType === "99453"
          ? p.billed99453By
            ? p.billed99453By
            : "N/A"
          : p.billedBy
            ? p.billedBy
            : "N/A";
      let staffInfo = _.filter(this.props.listStaff, {
        email: p.billedType === "99453" ? p.billed99453By : p.billedBy
      });
      if (staffInfo.length > 0) {
        billedBy = staffInfo[0].firstName + " " + staffInfo[0].lastName;
      }
      const diagnosisCodes = p.diagnosisCodes
        ? p.diagnosisCodes.replace(/_/g, ",")
        : "";
      return {
        ...p,
        createdAt,
        sirenId,
        initializedDate,
        dispensedDay,
        spentTime,
        doctor,
        period,
        billedAt,
        billedBy,
        diagnosisCodes,
        appInitialized
      };
    })

    const columns = [
      {
        Header: props => {
          return (
            <Checkbox
              onChange={this.handleCheckAll}
              indeterminate={
                this.state.selectedPatients.length > 0 &&
                this.state.selectedPatients.length <
                this.state.tableData.length
              }
              checked={
                this.state.selectedPatients.length > 0 &&
                this.state.selectedPatients.length ===
                this.state.tableData.length
              }
            />
          );
        },
        accessor: "",
        maxWidth: 60,
        sortable: false,
        filterable: false,
        Cell: props => {
          return (
            <div onClick={e => e.stopPropagation()}>
              <Checkbox
                onChange={e =>
                  this.handleCheck(e.target.checked, props.index)
                }
                checked={this.state.selectedPatients.includes(
                  props.index
                )}
                name="selectSite"
              />
            </div>
          );
        }
      },
      {
        Header: "CPT",
        accessor: "billedType"
      },
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60
      },
      {
        Header: "PatientID",
        accessor: "patientId"
      },
      {
        Header: "First Name",
        accessor: "firstName"
      },
      {
        Header: "Last Name",
        accessor: "lastName"
      },
      {
        Header: "Date of Birth",
        accessor: "birthday"
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes"
      },
      {
        Header: "Account Created",
        accessor: "createdAt"
      },
      // {
      //   Header: "App Activated",
      //   accessor: "appInitialized",
      //   maxWidth: 72,
      //   filterMethod: (filter, row) => {
      //     if (filter.value === "all") {
      //       return true;
      //     }
      //     if (filter.value === "Yes") {
      //       return row[filter.id] === "Yes";
      //     }
      //     return row[filter.id] === "No";
      //   },
      //   Filter: ({ filter, onChange }) => (
      //     <Select
      //       className="select_common_style"
      //       onChange={onChange}
      //       style={{ width: "100%" }}
      //       value={filter ? filter.value : "all"}
      //       filterOption={(input, option) =>
      //         option.props.children
      //           .toLowerCase()
      //           .indexOf(input.toLowerCase()) >= 0
      //       }
      //       showSearch
      //       optionFilterProp="children"
      //     >
      //       <Option value="all">All</Option>
      //       <Option value="Yes">Yes</Option>
      //       <Option value="No">No</Option>
      //     </Select>
      //   )
      // },
      {
        Header: "Socks Dispensed Date",
        accessor: "dispensedDay",
        minWidth: 120
      },
      {
        Header: "Recording Period",
        accessor: "period"
      },
      {
        Header: "Days Recorded",
        accessor: "daysWorn"
      },
      {
        Header: "Reviewed By",
        accessor: "billedBy",
        filterMethod: (filter, row) => {
          let rowfilterfullname = row._original.billedBy
            ? row[filter.id].toLowerCase()
            : "";
          let filterfullname = filter.value.toLowerCase();
          return rowfilterfullname.includes(filterfullname);
        }
      },
      {
        Header: "Reviewed Date",
        accessor: "billedAt"
      }
    ]

    return {
      data,
      columns
    }
  }

}

const mapStateToProp = state => ({
  authSites: state.authGroupStore.profile.siteId,
  clinicSites: state.patientsListStore.clinicSites,
  listStaff: state.patientsListStore.listStaff,
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  searchBillingPatients: () => dispatch(() => {}),
  getlistStaff: () => dispatch(getlistStaff()),
  searchBilling99453Patients: (billingInputValue) =>
    dispatch(searchBilling99453Patients(billingInputValue)),
  searchBilling99454Patients: (billingInputValue) =>
    dispatch(searchBilling99454Patients(billingInputValue)),
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportReviewed99453_4);
