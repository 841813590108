import { Select, Input } from "antd";
import { Auth } from "aws-amplify";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  changePatientSiteId,
  changePatientLPN,
  changePatientSiteName,
  getlistClinicSitesPatient,
  getPresNewStaff,
  listLPNs,
  saveIfHavePhone,
  updateDoctorId,
  getlistClinicSites,
} from "../../action/registerAction";
import "./editpatient.css";

class StepProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroup: []
    };
  }

  async componentDidMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({ userGroup: groups });
    const {
      userInfo,
      changePatientSiteId,
      saveIfHavePhone,
      updateDoctorId
    } = this.props;
    this.handleChangedLPN(userInfo.LPN ? userInfo.LPN : "");
    if (this.props.clinicSites.length === 0) {
      this.props.getlistClinicSites().then(() => {
        this.handleSiteIdChange(userInfo.siteId, userInfo.doctorId);
      });
    } else {
      this.handleSiteIdChange(userInfo.siteId, userInfo.doctorId);
    }
    if (this.props.listAdminStaff.length === 0) {
      const { listLPNs } = this.props;
      listLPNs(this);
    }

    // changePatientSiteId(userInfo.siteId);
    // updateDoctorId(userInfo.doctorId);
    saveIfHavePhone(userInfo.patientHasSmartPhone);
  }

  handleChangedLPN = event => {
    this.props.changePatientLPN(event);
  };

  handleSiteIdChange = (event, doctorId) => {
    const {
      getPresNewStaff,
      changePatientSiteId,
      changePatientSiteName
    } = this.props;
    changePatientSiteId(event);
    getPresNewStaff(event, this, doctorId);
    const result = this.props.clinicSites.filter(item => item.siteId === event);
    changePatientSiteName(result[0].name);
    if (this.props.listAdminStaff.length > 0) {
      const staffs = _.filter(this.props.listAdminStaff, function(t) {
        return (
          t.group === "staff" &&
          JSON.parse(t.primarySites).length > 0 &&
          JSON.parse(t.primarySites).includes(event)
        );
      });
      const filterStaff = _(staffs).find({ id: this.props.userInfo.LPN });
      if (filterStaff) {
        this.handleChangedLPN(
          this.props.userInfo.LPN ? this.props.userInfo.LPN : ""
        );
      }
    }
  };
  handleDoctorChange = event => {
    const { updateDoctorId } = this.props;
    updateDoctorId(event);
  };
  handleIfHavePhone = event => {
    const { saveIfHavePhone } = this.props;
    saveIfHavePhone(event);
  };
  render() {
    const { Option } = Select;
    const self = this;
    const site = this.props.clinicSites.find(item => item.siteId === this.props.patientSiteId)
    return (
      <div style={{ height: "auto" }}>
        <div className="inputTotal step3InputTotal600">
          <p className="step3_section_title" style={{ textAlign: "left" }}>
            Enrollment Criteria
          </p>
          <div className="inputLeftNewTotal">
            <p className="inputLable">
              Site Name<span style={{ color: "red" }}> *</span>
            </p>
            <Select
              className="selectFullWdith"
              onChange={event => this.handleSiteIdChange(event, undefined)}
              value={this.props.patientSiteId || ""}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
              disabled={this.state.userGroup.includes("staff")}
            >
              {this.props.clinicSites.map((si, i) => {
                return (
                  <Option value={si.siteId} key={"si" + i}>
                    {si.siteId + " - " + si.name}
                  </Option>
                );
              })}
            </Select>
            <div
              className="error_birth_tip"
              style={{
                display: this.props.emptySiteId ? "block" : "none"
              }}
            >
              Please select SiteId
            </div>
          </div>
          <div className="inputRightNewTotal">
            <p className="inputLable">
              Staffing<span style={{ color: "red" }}> *</span>
            </p>
            <Input
              className="selectFullWdith"
              disabled
              value={site ? (site.onStaffing === 'Yes' ? 'On Staffing' : 'Not On Staffing') : ''}
            />
          </div>
          <div className="clear_both" />
        </div>
        <div className="inputTotal step3InputTotal600">
          <div className="inputLeftNewTotal">
            <p className="inputLable">
              Doctor<span style={{ color: "red" }}> *</span>
            </p>
            <Select
              className="selectMoreStyle"
              onChange={this.handleDoctorChange}
              value={this.props.patientDoctorId}
              showSearch
            >
              {this.props.prescribingStaff
                .filter(item =>
                  item.siteId.split("-").includes(this.props.patientSiteId)
                )
                .map((Staff, i) => {
                  return (
                    <Option value={Staff.email} key={"Staff" + i}>
                      {Staff.isPrescribing && !Staff.firstName.includes("Dr.")
                        ? "Dr. " + Staff.firstName + " " + Staff.lastName
                        : Staff.firstName + " " + Staff.lastName}
                    </Option>
                  );
                })}
            </Select>
            <div
              className="error_birth_tip"
              style={{
                display: this.props.emptyDoctorId ? "block" : "none"
              }}
            >
              Please select Doctor
            </div>
          </div>
          <div className="inputRightNewTotal">
            <p className="inputLable">Nurse</p>
            <Select
              className="selectMoreStyle"
              onChange={this.handleChangedLPN}
              value={this.props.patientLPN}
              filterOption={(input, option) =>
                option.props.value
                  .toLocaleLowerCase()
                  .indexOf(input.toLocaleLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              <Option value={""}>None</Option>
              {_.filter(this.props.listAdminStaff, function(t) {
                return (
                  t.group === "staff" &&
                  JSON.parse(t.primarySites).length > 0 &&
                  JSON.parse(t.primarySites).includes(
                    self.props.patientSiteId
                  )
                );
              }).map((p, index) => {
                return (
                  <Option value={p.id} key={index}>
                    {p.firstName} {p.lastName}
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="clear_both" />
        </div>
        <div className="inputTotal step3InputTotal600">
          <p
            className="step3_section_title"
            style={{ textAlign: "left", marginTop: 30 }}
          >
            General Questions<span style={{ color: "red" }}> *</span>
          </p>
          <div className="inputLeftNewTotal">
            <p className="inputLable">
              Does the patient have a smartphone?
              {/*<span style={{ color: "red" }}> *</span>*/}
            </p>
            <Select
              className="selectMoreStyle"
              onChange={this.handleIfHavePhone}
              value={this.props.ifHavePhone}
              filterOption={(input, option) =>
                option.props.value
                  .toLocaleLowerCase()
                  .indexOf(input.toLocaleLowerCase()) >= 0
              }
              showSearch
              optionFilterProp="children"
            >
              <Option value={"Yes"}>Yes</Option>
              <Option value={"Caregiver"}>Caregiver has a smartphone</Option>
              <Option value={"No"}>No</Option>
            </Select>
          </div>
          <div className="clear_both" />
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  patientDoctorId: state.patientsListStore.patientDoctorId,
  patientSiteId: state.patientsListStore.patientSiteId,
  clinicSites: state.patientsListStore.clinicSites,
  prescribingStaff: state.patientsListStore.prescribingStaff,
  emptySiteId: state.patientsListStore.emptySiteId,
  emptyDoctorId: state.patientsListStore.emptyDoctorId,
  ifHavePhone: state.patientsListStore.ifHavePhone,
  listAdminStaff: state.patientsListStore.listAdminStaff,
  patientLPN: state.patientsListStore.patientLPN
});
const mapDispatchToProp = dispatch => ({
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getlistClinicSitesPatient: self => dispatch(getlistClinicSitesPatient(self)),
  changePatientSiteId: siteId => dispatch(changePatientSiteId(siteId)),
  changePatientSiteName: siteName => dispatch(changePatientSiteName(siteName)),
  updateDoctorId: doctorId => dispatch(updateDoctorId(doctorId)),
  getPresNewStaff: (staffSiteId, self, doctorId) =>
    dispatch(getPresNewStaff(staffSiteId, self, doctorId)),
  saveIfHavePhone: ifHavePhone => dispatch(saveIfHavePhone(ifHavePhone)),
  listLPNs: self => dispatch(listLPNs(self)),
  changePatientLPN: lpn => dispatch(changePatientLPN(lpn))
});
export default connect(mapStateToProp, mapDispatchToProp)(StepProfile);
