import { API, graphqlOperation } from "aws-amplify";
import _api from "./_api";

export async function getPatientActivity(patientId, limit, nextToken, yearMonth, category) {
  const result = await API.graphql(
    graphqlOperation(_api.getPatientActivity(), {
      patientId,
      limit,
      nextToken,
      yearMonth,
      category
    })
  )

	if (result.errors) {
		throw result.errors
	}

  return result.data.getPatientActivity;
}