import React, { Component } from "react";
import { connect } from "react-redux";
import "./FAQ.css";
class FAQ extends Component {
  componentDidMount() {
    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.height = "100%";
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    document.getElementById("root").style.height = "100%";
    document.getElementById("root-div").style.height = "100%";
    document.getElementsByClassName("App")[0].style.height = "100%";
  }
  componentWillUnmount() {
    document.documentElement.style.overflow = "auto";
    document.documentElement.style.height = "auto";
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    document.getElementById("root").style.height = "auto";
    document.getElementById("root-div").style.height = "auto";
    document.getElementsByClassName("App")[0].style.height = "auto";
  }
  render() {
    return (
      <div className="iframe-container">
        <iframe
          id="iframeFaq"
          title="study"
          src="https://docs.google.com/document/u/2/d/e/2PACX-1vQwz5BTyMJgij71Qqg8DYdJbPCwvqpbzBgZR3DselR69DqNsu4UKLr9s5K8JJoI1bg2kKXbn3y5RDZh/pub"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    );
  }
}
const mapStateToProp = state => ({});

const mapDispatchToProp = dispatch => ({});
export default connect(
  mapStateToProp,
  mapDispatchToProp
)(FAQ);
