import * as TYPES from "../types/types";
const initialState = {
  userGroup: "",
  siteID: "",
  profile: {},
  ICD10Codes: []
};

export default function authGroup(state = initialState, action) {
  switch (action.type) {
    case TYPES.AUTH_GROUP:
      return {
        ...state,
        userGroup: action.text
      };
    case TYPES.DOCTOR_PROFILE:
      return {
        ...state,
        profile: action.profile,
        siteID: action.profile.siteId
      };
    case TYPES.LIST_ICD_10_CODES:
      return {
        ...state,
        ICD10Codes: action.payload
      };
    case TYPES.LIST_MEDICAL_CONDITIONS:
      return {
        ...state,
        medicalConditions: action.payload
      };
    default:
      return state;
  }
}
