import { CloseOutlined, InfoCircleFilled } from "@ant-design/icons";
import {
  Checkbox,
  DatePicker,
  Modal as AntdModal,
  Select,
  Tabs,
  Tooltip
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import ReactPhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import { Button, Icon, Input, Modal } from "semantic-ui-react";
import { changeNewPatient } from "../../action/addAction";
import { getGroups } from "../../action/patientAction";
import {
  getPrescribingStaff,
  getPrescribingStaffChanged
} from "../../action/registerAction";
import { checkBirthdayValid } from "../../tools/dateFormat";
import { PATIENT_RESIDENCE } from "../../tools/patient-medical";
import { phoneWithCountryFormat } from "../../tools/phoneFormat";
import ButtonSuccess from "../home/buttonSuccess";
import "./editpatient.css";
import EnrollmentCriteria from "./EnrollmentCriteria";
import PatientMedicalHistory from "../../report/patientMedicalHistory";

const { TabPane } = Tabs;
const GenderList = [
  { gender: "", key: "1" },
  { gender: "Female", key: "2" },
  { gender: "Male", key: "3" },
  { gender: "Other", key: "4" },
  { gender: "Prefer not to say", key: "5" }
];
const SecRela = [
  { rela: "", key: "1" },
  { rela: "Spouse/Partner", key: "2" },
  { rela: "Sibling", key: "3" },
  { rela: "Child", key: "4" },
  { rela: "Niece/Nephew", key: "5" },
  { rela: "Grandchild", key: "6" },
  { rela: "Caregiver", key: "7" },
  { rela: "Other", key: "8" }
];

class EditProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthday: null,
      open: false,
      siteId: "",
      firstName: "",
      lastName: "",
      email: "",
      patientMBI: "",
      contactName: "",
      secondaryEmail: "",
      contactPhone: "",
      relation: "",
      confirmSuccess: false,
      patientNote: "",
      otherCondition: null,
      additionDiagnosisCode: [],
      neuropathyCondition: false,
      amputationCondition: false,
      ulcersCondition: false,
      charcotCondition: false,
      PADCondition: false,
      otherCheckCondition: false,
      medicalCondition: [],
      footSpecific: [],
      footAmputation: [],
      diagnosisCodesStr: [],
      birYear: "",
      birMonth: "",
      birDay: "",
      phone: "",
      homePhone: "",
      patientId: "",
      doctorId: "",
      authSecondary: false,
      dispenseType: "",
      dispenseAddress: "",
      dispenseCity: "",
      dispenseState: "",
      dispenseZip: "",
      dispenseSize: "",
      dispenseColor: "",
      dispenseComment: "",
      editProfile: false,
      gender: "",
      enableApp: false,
      enableSms: false,
      enableEmail: false,
      enableWarning: false,
      viewAll: true,
      errorEmail: false,
      errorSecEmail: false,
      patientResidence: ""
    };
    this.handleSiteIdChange = this.handleSiteIdChange.bind(this);
    this.changeMBI = this.changeMBI.bind(this);
    this.onChangedDoctor = this.onChangedDoctor.bind(this);
    this.handleRelChange = this.handleRelChange.bind(this);
    this.changeGender = this.changeGender.bind(this);
  }

  handleSiteIdChange(event) {
    this.setState({ siteId: event });
    const { getPrescribingStaffChanged } = this.props;
    this.setState({ siteId: event });
    getPrescribingStaffChanged(event, this);
  }
  onChangedDoctor(event) {
    this.setState({ doctorId: event });
  }
  changeGender = event => {
    this.setState({ gender: event });
  };
  handleFirstNameChange(value) {
    this.setState({ firstName: value });
  }
  handleLastNameChange(value) {
    this.setState({ lastName: value });
  }
  changePatientResidence = event => {
    this.setState({ patientResidence: event });
  };
  handleEmailChange(value) {
    this.setState({
      email: value,
      errorEmail: !(_.isEmpty(value) || this.validateForm(value))
    });
  }
  changeMBI(value) {
    this.setState({ patientMBI: value });
  }
  handleContactNameChange(value) {
    this.setState({ contactName: value });
  }
  handleSecondaryEmailChange(value) {
    this.setState({
      secondaryEmail: value,
      errorSecEmail: !(_.isEmpty(value) || this.validateForm(value))
    });
  }

  validateForm = email => {
    const reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.\+])+\.([A-Za-z]{2,4})$/;
    return reg.test(email);
  };

  handleRelChange(event) {
    this.setState({ relation: event });
  }
  handlePhoneChange = contactPhone => {
    let noSym1 = contactPhone.replace("(", "");
    let noSym2 = noSym1.replace(")", "");
    let noSym3 = noSym2.replace("-", "");
    let noBlank = noSym3.replace(/\s+/g, "");
    this.setState({ contactPhone: noBlank });
  };

  onChangeContactPhone = event => {
    this.setState({ enableApp: event.target.checked });
  };

  onChangeContactEmail = event => {
    this.setState({ enableEmail: event.target.checked });
  };

  onChangeContactMessage = event => {
    this.setState({ enableSms: event.target.checked });
  };

  handleOpen = () => {
    if (_.isEmpty(this.props.doctorDetails)) {
      AntdModal.error({
        title: "Error",
        content: `Please confirm you have got this patient's info.`,
        centered: true
      });
      return;
    }
    this.setState({
      enableApp: this.props.userInfo.enableApp || false,
      enableEmail: this.props.userInfo.enableEmail || false,
      enableSms: this.props.userInfo.enableSms || false,
      birthday: this.props.userInfo.birthday || null,
      open: true,
      confirmSuccess: false,
      siteId:
        this.props.userInfo && this.props.userInfo.siteId
          ? this.props.userInfo.siteId
          : "",
      lastName:
        this.props.userInfo && this.props.userInfo.lastName
          ? this.props.userInfo.lastName
          : "",
      firstName:
        this.props.userInfo && this.props.userInfo.firstName
          ? this.props.userInfo.firstName
          : "",
      email:
        this.props.userInfo && this.props.userInfo.email
          ? this.props.userInfo.email
          : "",
      errorEmail:
        this.props.userInfo && this.props.userInfo.email
          ? !this.validateForm(this.props.userInfo.email)
          : false,
      patientMBI:
        this.props.userInfo && this.props.userInfo.patientMBI
          ? this.props.userInfo.patientMBI
          : "",
      patientResidence:
        this.props.userInfo && this.props.userInfo.patientResidence
          ? this.props.userInfo.patientResidence
          : "",
      contactName:
        this.props.userInfo && this.props.userInfo.contactName
          ? this.props.userInfo.contactName
          : "",
      secondaryEmail:
        this.props.userInfo && this.props.userInfo.secondaryEmail
          ? this.props.userInfo.secondaryEmail
          : "",
      errorSecEmail:
        this.props.userInfo && this.props.userInfo.secondaryEmail
          ? !this.validateForm(this.props.userInfo.secondaryEmail)
          : false,
      contactPhone:
        this.props.userInfo && this.props.userInfo.contactPhone
          ? phoneWithCountryFormat(this.props.userInfo.contactPhone)
          : "",
      relation:
        this.props.userInfo && this.props.userInfo.relation
          ? this.props.userInfo.relation
          : "",
      patientNote:
        this.props.userInfo && this.props.userInfo.patientNotes
          ? this.props.userInfo.patientNotes
          : "",
      medicalCondition:
        this.props.userInfo && this.props.userInfo.medicalCondition
          ? this.props.userInfo.medicalCondition.split(",")
          : [],
      footSpecific:
        this.props.userInfo && this.props.userInfo.footSpecific
          ? this.props.userInfo.footSpecific.split(",")
          : [],
      footAmputation:
        this.props.userInfo && this.props.userInfo.footAmputation
          ? this.props.userInfo.footAmputation.split(",")
          : [],
      diagnosisCodesStr:
        this.props.userInfo && this.props.userInfo.diagnosisCodes
          ? this.props.userInfo.diagnosisCodes.split("_")
          : [],
      birYear:
        this.props.userInfo &&
        this.props.userInfo.birthday &&
        this.props.userInfo.birthday.split("-")[0],
      birMonth:
        this.props.userInfo &&
        this.props.userInfo.birthday &&
        this.props.userInfo.birthday.split("-")[1],
      gender: this.props.userInfo && this.props.userInfo.gender,
      birDay:
        this.props.userInfo &&
        this.props.userInfo.birthday &&
        this.props.userInfo.birthday.split("-")[2],
      doctorId:
        this.props.userInfo && this.props.userInfo.doctorId
          ? this.props.userInfo.doctorId
          : "",
      patientId:
        this.props.userInfo && this.props.userInfo.patientId
          ? this.props.userInfo.patientId
          : "",
      phone:
        this.props.userInfo && this.props.userInfo.phone
          ? phoneWithCountryFormat(this.props.userInfo.phone)
          : "",
      homePhone:
        this.props.userInfo && this.props.userInfo.homePhone
          ? phoneWithCountryFormat(this.props.userInfo.homePhone)
          : "",
      authSecondary: !!(
        this.props.userInfo &&
        this.props.userInfo.authSecondaryContact &&
        this.props.userInfo.authSecondaryContact === "Yes"
      ),
      dispenseType:
        this.props.userInfo && this.props.userInfo.dispenseType
          ? this.props.userInfo.dispenseType
          : "",
      dispenseAddress:
        this.props.userInfo && this.props.userInfo.dispenseAddress
          ? this.props.userInfo.dispenseAddress
          : "",
      dispenseCity:
        this.props.userInfo && this.props.userInfo.dispenseCity
          ? this.props.userInfo.dispenseCity
          : "",
      dispenseState:
        this.props.userInfo && this.props.userInfo.dispenseState
          ? this.props.userInfo.dispenseState
          : "",
      dispenseZip:
        this.props.userInfo && this.props.userInfo.dispenseZip
          ? this.props.userInfo.dispenseZip
          : "",
      dispenseSize:
        this.props.userInfo && this.props.userInfo.dispenseSize
          ? this.props.userInfo.dispenseSize
          : "",
      dispenseColor:
        this.props.userInfo && this.props.userInfo.dispenseColor
          ? this.props.userInfo.dispenseColor
          : "",
      dispenseComment:
        this.props.userInfo && this.props.userInfo.dispenseComments
          ? this.props.userInfo.dispenseComments
          : ""
    });
  };

  submit() {
    let editInput = {
      id: this.props.userInfo.id,
      dispensed: this.props.userInfo.dispensed === "Yes" ? "Yes" : "No",
      authSecondaryContact: this.state.authSecondary ? "Yes" : "No",
      enableApp: this.state.enableApp,
      enableEmail: this.state.enableEmail,
      enableSms: this.state.enableSms,
      LPN: this.props.patientLPN
    };
    let birthday = moment(
      this.state.birYear + "-" + this.state.birMonth + "-" + this.state.birDay
    ).format("YYYY-MM-DD");
    let userInfo = _.clone(this.props.userInfo);

    if (
      this.props.patientHasSmartPhone &&
      this.props.patientHasSmartPhone !== ""
    ) {
      Object.assign(editInput, {
        patientHasSmartPhone: this.props.patientHasSmartPhone
      });
    }

    if (this.props.patientHasWifi && this.props.patientHasWifi !== "") {
      Object.assign(editInput, { patientHasWifi: this.props.patientHasWifi });
    }

    if (this.props.patientSiteId && this.props.patientSiteId !== "") {
      Object.assign(editInput, { siteId: this.props.patientSiteId });
    }

    if (this.props.patientSiteName && this.props.patientSiteName !== "") {
      Object.assign(editInput, { siteName: this.props.patientSiteName });
    }

    if (this.props.patientDoctorId && this.props.patientDoctorId !== "") {
      Object.assign(editInput, {
        doctorId: this.props.patientDoctorId
      });
    }

    if (this.props.dispenseType && this.props.dispenseType !== "") {
      Object.assign(editInput, {
        dispenseType: this.props.dispenseType
      });
    }

    if (this.state.firstName && this.state.firstName !== "") {
      Object.assign(editInput, { firstName: this.state.firstName });
    }
    if (this.state.lastName && this.state.lastName !== "") {
      Object.assign(editInput, { lastName: this.state.lastName });
    }
    if (!_.isEmpty(this.state.email)) {
      if (!this.validateForm(this.state.email)) {
        AntdModal.warning({
          title: "Oops",
          content: "Invalid email format.",
          centered: true
        });
        return;
      }
      Object.assign(editInput, { email: this.state.email });
    } else {
      Object.assign(editInput, { email: "" });
    }
    if (this.state.patientMBI && this.state.patientMBI !== "") {
      Object.assign(editInput, { patientMBI: this.state.patientMBI });
    }
    if (this.state.patientResidence && this.state.patientResidence !== "") {
      Object.assign(editInput, {
        patientResidence: this.state.patientResidence
      });
    }
    if (!_.isEmpty(this.state.birthday)) {
      if (!checkBirthdayValid(this.state.birthday)) {
        return;
      }
      Object.assign(editInput, { birthday: this.state.birthday });
    }
    if (this.state.contactName) {
      Object.assign(editInput, {
        contactName: this.state.contactName
      });
    }
    if (!_.isEmpty(this.state.secondaryEmail)) {
      if (!this.validateForm(this.state.secondaryEmail)) {
        AntdModal.warning({
          title: "Oops",
          content: "Invalid Emergency Contact Email format.",
          centered: true
        });
        return;
      }
      Object.assign(editInput, {
        secondaryEmail: this.state.secondaryEmail
      });
    } else {
      Object.assign(editInput, {
        secondaryEmail: ""
      });
    }
    if (!_.isEmpty(this.state.contactPhone)) {
      Object.assign(editInput, {
        contactPhone: this.state.contactPhone
      });
    } else {
      Object.assign(editInput, {
        contactPhone: ""
      });
    }
    let hasPhone = false;
    if (!_.isEmpty(phoneWithCountryFormat(this.state.homePhone))) {
      hasPhone = true;
      Object.assign(editInput, {
        homePhone: this.state.homePhone
      });
    } else {
      Object.assign(editInput, {
        homePhone: ""
      });
    }
    if (!_.isEmpty(phoneWithCountryFormat(this.state.phone))) {
      hasPhone = true;
      Object.assign(editInput, {
        phone: this.state.phone
      });
    } else {
      Object.assign(editInput, {
        phone: ""
      });
    }
    if (!hasPhone) {
      AntdModal.warning({
        title: "Incomplete Field",
        content: "Please enter a valid phone number.",
        centered: true
      });
      return;
    }
    if (this.state.relation && this.state.relation !== "") {
      Object.assign(editInput, {
        relation: this.state.relation
      });
    }
    if (this.state.gender && this.state.gender !== "") {
      Object.assign(editInput, {
        gender: this.state.gender
      });
    }
    if (this.state.patientNote && this.state.patientNote !== "") {
      Object.assign(editInput, {
        patientNotes: this.state.patientNote
      });
    }
    if (this.state.diagnosisCodesStr) {
      Object.assign(editInput, {
        diagnosisCodes: this.state.diagnosisCodesStr
          .map(p => {
            return p;
          })
          .join("_")
      });
    }
    if (this.state.dispenseType && this.state.dispenseType !== "") {
      Object.assign(editInput, {
        dispenseType: this.state.dispenseType
      });
    }
    if (this.state.dispenseSize && this.state.dispenseSize !== "") {
      Object.assign(editInput, {
        dispenseSize: this.state.dispenseSize
      });
    }
    if (this.state.dispenseColor && this.state.dispenseColor !== "") {
      Object.assign(editInput, {
        dispenseColor: this.state.dispenseColor
      });
    }
    if (this.state.dispenseAddress && this.state.dispenseAddress !== "") {
      Object.assign(editInput, {
        dispenseAddress: this.state.dispenseAddress
      });
    }
    if (this.state.dispenseCity && this.state.dispenseCity !== "") {
      Object.assign(editInput, {
        dispenseCity: this.state.dispenseCity
      });
    }
    if (this.state.dispenseState && this.state.dispenseState !== "") {
      Object.assign(editInput, {
        dispenseState: this.state.dispenseState
      });
    }
    if (this.state.dispenseZip && this.state.dispenseZip !== "") {
      Object.assign(editInput, {
        dispenseZip: this.state.dispenseZip
      });
    }
    if (this.state.dispenseComment && this.state.dispenseComment !== "") {
      Object.assign(editInput, {
        dispenseComments: this.state.dispenseComment
      });
    }
    if (this.state.patientId && this.state.patientId !== "") {
      Object.assign(editInput, {
        patientId: this.state.patientId
      });
    }

    const { changeNewPatient } = this.props;
    changeNewPatient(editInput, userInfo, this);
  }
  close = () =>
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      confirmSuccess: false,
      editProfile: false,
      enableWarning: false
    });

  onChangeAuthSecondary = event => {
    this.setState({ authSecondary: event.target.checked });
  };

  handlePatientIdChange = event => {
    this.setState({ patientId: event });
  };

  handleHomePhoneChange = phone => {
    let noSym1 = phone.replace("(", "");
    let noSym2 = noSym1.replace(")", "");
    let noSym3 = noSym2.replace("-", "");
    let noBlank = noSym3.replace(/\s+/g, "");
    this.setState({ homePhone: noBlank });
  };

  handleCellPhoneChange = phone => {
    let noSym1 = phone.replace("(", "");
    let noSym2 = noSym1.replace(")", "");
    let noSym3 = noSym2.replace("-", "");
    let noBlank = noSym3.replace(/\s+/g, "");
    this.setState({ phone: noBlank });
  };

  warning = () => {
    this.setState({ enableWarning: true });
    AntdModal.warning({
      title: "Update Shipping Address",
      content: "Updated shipping address will apply to future orders.",
      centered: true
    });
  };

  handleCheckDiagnosisCode = value => {
    const filterValue = value.map(v => v.trim()).filter(v => v.length);
    this.setState({ diagnosisCodesStr: filterValue });
  };

  render() {
    const { Option } = Select;
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <p
            onClick={this.handleOpen}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              whiteSpace: "nowrap"
            }}
          >
            View Full Profile
          </p>
        }
        open={open}
      >
        <Modal.Header>
          Patient Profile
          <CloseOutlined
            style={{ float: "right" }}
            onClick={e => this.close()}
          />
        </Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Tabs style={{ height: 600, overflowY: "auto" }}>
              <TabPane tab="Enroll" key="1">
                <div className="newSite_box">
                  <div className="Patient_group">
                    <EnrollmentCriteria userInfo={this.props.userInfo} />
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Profile" key="2">
                <div className="newSite_box">
                  <div className="Patient_group">
                    <p
                      className="step3_section_title"
                      style={{ width: "790px", textAlign: "left" }}
                    >
                      Patient Information
                    </p>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputLeftTotal">
                        <p className="inputLable">First Name</p>
                        <Input
                          placeholder="John"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.handleFirstNameChange(e.target.value);
                          }}
                          value={this.state.firstName}
                        />
                      </div>
                      <div className="inputLeftTotal">
                        <p className="inputLable">Last Name</p>
                        <Input
                          placeholder="Smith"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.handleLastNameChange(e.target.value);
                          }}
                          value={this.state.lastName}
                        />
                      </div>
                      <div className="inputrightTotal">
                        <p className="inputLable">Date of Birth(DOB)</p>
                        <DatePicker
                          className="inputContent"
                          defaultValue={moment(this.state.birthday)}
                          onChange={date => {
                            this.setState({
                              birthday: moment(date).format("YYYY-MM-DD")
                            });
                          }}
                          allowClear={false}
                        />
                      </div>
                      <div className="clear_both" />
                    </div>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputLeftTotal">
                        <p className="inputLable">Username</p>
                        {this.props.userInfo.id &&
                        this.props.userInfo.id.includes("@") ? (
                          <Input
                            value={this.props.userInfo.id}
                            className="inputContent"
                            disabled
                          />
                        ) : (
                          <ReactPhoneInput
                            inputExtraProps={{
                              name: "phone",
                              required: true,
                              autoFocus: false
                            }}
                            value={this.props.userInfo.id}
                            defaultCountry={"us"}
                            disableAreaCodes
                            disabled
                          />
                        )}
                      </div>
                      <div className="inputLeftTotal">
                        <p className="inputLable">Cell Phone</p>
                        <ReactPhoneInput
                          inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: false
                          }}
                          value={this.state.phone}
                          defaultCountry={"us"}
                          onChange={cellPhone =>
                            this.handleCellPhoneChange(cellPhone)
                          }
                          disableAreaCodes
                        />
                      </div>
                      <div className="inputrightTotal">
                        <p className="inputLable">Home Phone</p>
                        <ReactPhoneInput
                          inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: false
                          }}
                          value={this.state.homePhone}
                          defaultCountry={"us"}
                          onChange={homePhone =>
                            this.handleHomePhoneChange(homePhone)
                          }
                          disableAreaCodes
                        />
                      </div>
                      <div className="clear_both" />
                    </div>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputLeftTotal">
                        <p className="inputLable">Email</p>
                        <Input
                          placeholder="Johnsmith@email.com"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.handleEmailChange(e.target.value);
                          }}
                          value={this.state.email}
                          error={this.state.errorEmail}
                        />
                      </div>
                      <div className="inputLeftTotal">
                        <p className="inputLable">Gender</p>
                        <Select
                          className="selectStyle"
                          onChange={this.changeGender}
                          value={this.state.gender}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          optionFilterProp="children"
                        >
                          {GenderList.map((si, i) => {
                            return (
                              <Option value={si.gender} key={"si" + i}>
                                {si.gender}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>

                      <div className="inputrightTotal">
                        <p className="inputLable">Patient ID</p>
                        <Input
                          placeholder="123456"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.handlePatientIdChange(e.target.value);
                          }}
                          value={this.state.patientId}
                        />
                      </div>
                      <div className="clear_both" />
                    </div>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputLeftTotal">
                        <p className="inputLable">MBI</p>
                        <Input
                          placeholder="Medicare Beneficiary Identifier"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.changeMBI(e.target.value);
                          }}
                          value={this.state.patientMBI}
                        />
                      </div>
                      <div className="inputLeftTotal">
                        <p className="inputLable">Patient Residence</p>
                        <Select
                          className="selectStyle"
                          onChange={this.changePatientResidence}
                          value={this.state.patientResidence}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          optionFilterProp="children"
                        >
                          {PATIENT_RESIDENCE.map((si, i) => {
                            return (
                              <Option value={si.value} key={"si" + i}>
                                {si.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="clear_both" />
                    </div>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputTotal PaInforBox">
                        <p className="inputLable">
                          Shipping Address
                          {/*<span style={{ color: "red" }}> *</span>*/}
                        </p>
                        <p className="redAddressTip">
                          Required for shipping and/or replacements. No P.O. Box
                          deliveries
                        </p>
                        <Input
                          style={{ width: "45%", height: 45 }}
                          placeholder="Address"
                          value={this.state.dispenseAddress}
                          onChange={e => {
                            const value = e.target.value;
                            if (
                              !_.isEqual(value, this.state.dispenseAddress) &&
                              !this.state.enableWarning
                            ) {
                              this.warning();
                            }
                            this.setState({ dispenseAddress: value });
                          }}
                        />
                        <Input
                          style={{ width: "27%", height: 45, marginLeft: "1%" }}
                          placeholder="City"
                          value={this.state.dispenseCity}
                          onChange={e => {
                            const value = e.target.value;
                            if (
                              !_.isEqual(value, this.state.dispenseCity) &&
                              !this.state.enableWarning
                            ) {
                              this.warning();
                            }
                            this.setState({ dispenseCity: value });
                          }}
                        />
                        <Input
                          style={{ width: "12%", marginLeft: "1%", height: 45 }}
                          placeholder="State"
                          value={this.state.dispenseState}
                          onChange={e => {
                            const value = e.target.value;
                            if (
                              !_.isEqual(value, this.state.dispenseState) &&
                              !this.state.enableWarning
                            ) {
                              this.warning();
                            }
                            this.setState({ dispenseState: value });
                          }}
                        />
                        <Input
                          style={{ width: "12%", marginLeft: "1%", height: 45 }}
                          placeholder="Zip"
                          value={this.state.dispenseZip}
                          onChange={e => {
                            const value = e.target.value;
                            if (
                              !_.isEqual(value, this.state.dispenseZip) &&
                              !this.state.enableWarning
                            ) {
                              this.warning();
                            }
                            this.setState({ dispenseZip: value });
                          }}
                        />
                      </div>
                      <div
                        className="inputTotal PaInforBox"
                        style={{ marginTop: 20 }}
                      >
                        <p className="inputLable">
                          Preferred Contact Method (Select all that apply)
                        </p>
                        <Checkbox
                          onChange={this.onChangeContactPhone}
                          checked={this.state.enableApp}
                          style={{ marginRight: 10, marginTop: 10 }}
                        >
                          App Notification
                        </Checkbox>
                        <Checkbox
                          onChange={this.onChangeContactEmail}
                          checked={this.state.enableEmail}
                          style={{ marginRight: 10, marginTop: 10 }}
                        >
                          Email
                        </Checkbox>
                        <Checkbox
                          onChange={this.onChangeContactMessage}
                          checked={this.state.enableSms}
                          style={{ marginRight: 10, marginTop: 10 }}
                        >
                          SMS/Text Message
                        </Checkbox>
                      </div>
                      <div className="clear_both" />
                    </div>
                  </div>

                  <div className="Secondary_group_edit">
                    <p
                      className="step3_section_title"
                      style={{ width: "790px" }}
                    >
                      Secondary Contact Information
                    </p>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputLeftTotal">
                        <p className="inputLable">Secondary Contact Name</p>
                        <Input
                          placeholder="John"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.handleContactNameChange(e.target.value);
                          }}
                          value={this.state.contactName}
                        />
                      </div>
                      <div className="inputLeftTotal">
                        <p className="inputLable">Secondary Contact Email</p>
                        <Input
                          placeholder="Johnsmith@email.com"
                          className="inputContent"
                          onChange={(e, data) => {
                            this.handleSecondaryEmailChange(e.target.value);
                          }}
                          value={this.state.secondaryEmail}
                          error={this.state.errorSecEmail}
                        />
                      </div>
                      <div className="inputrightTotal">
                        <p className="inputLable">Secondary Contact Phone</p>
                        <ReactPhoneInput
                          inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: false
                          }}
                          value={this.state.contactPhone}
                          defaultCountry={"us"}
                          onChange={contactPhone =>
                            this.handlePhoneChange(contactPhone)
                          }
                          disableAreaCodes
                        />
                      </div>
                      <div className="clear_both" />
                    </div>
                    <div className="inputTotal step3InputTotal">
                      <div className="inputLeftTotal">
                        <p className="inputLable">Relationship To Patient</p>
                        <Select
                          className="selectStyle"
                          value={this.state.relation}
                          onChange={this.handleRelChange}
                          style={{ width: "100%" }}
                          placeholder="Please Select"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          showSearch
                          optionFilterProp="children"
                        >
                          {SecRela.map((relation, i) => {
                            return (
                              <Option
                                value={relation.rela}
                                key={"relation" + i}
                              >
                                {relation.rela}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                      <div className="clear_both" />
                    </div>
                    <div className="inputTotal step3InputTotal">
                      <Checkbox
                        onChange={this.onChangeAuthSecondary}
                        checked={this.state.authSecondary}
                        style={{ marginTop: 10, marginBottom: 20 }}
                      >
                        HIPAA Authorization obtained for Secondary Contact
                      </Checkbox>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane tab="Medical" key="3">
                <div className="Patient_group">
                  <div style={{ margin: "0 40px" }}>
                    <PatientMedicalHistory
                      readOnly
                      medicalCondition={this.state.medicalCondition}
                      footSpecific={this.state.footSpecific}
                      footAmputation={this.state.footAmputation}
                    />
                  </div>
                  <div style={{ margin: "30px 40px" }}>
                    <span className="patient_note_label">ICD-10 Codes</span>
                    <Tooltip title="To add a new code to the list, press enter after typing the code into the search bar.">
                      <InfoCircleFilled
                        style={{
                          color: "#D3D6E1",
                          marginLeft: 5
                        }}
                      />
                    </Tooltip>
                    <Select
                      mode="tags"
                      style={{ marginTop: 10, width: '100%' }}
                      placeholder="Click to add ICD-10 codes"
                      tokenSeparators={['_', ',']}
                      onChange={this.handleCheckDiagnosisCode}
                      optionLabelProp="value"
                      value={
                        this.state.diagnosisCodesStr.length === 0
                          ? undefined
                          : this.state.diagnosisCodesStr
                      }
                    >
                      {this.props.ICD10Codes.map(code => (
                        <Option value={code} key={code}>
                          {code}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          )}
        </Modal.Content>
        <Modal.Actions style={{ textAlign: "center" }}>
          {/*{this.state.editProfile ? (*/}
          <div>
            <Button color="green" onClick={e => this.submit()}>
              <Icon name="checkmark" />
              OK
            </Button>
            {/*<Button*/}
            {/*  basic*/}
            {/*  color="red"*/}
            {/*  onClick={e => this.close()}*/}
            {/*  style={{ marginLeft: 20 }}*/}
            {/*>*/}
            {/*  <Icon name="remove" />*/}
            {/*  {this.state.confirmSuccess ? "Close" : "Cancel"}*/}
            {/*</Button>*/}
          </div>
          {/*) : (*/}
          {/*  <Button*/}
          {/*    color="blue"*/}
          {/*    onClick={() => {*/}
          {/*      this.setState({ editProfile: true });*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <Icon name="edit" />*/}
          {/*    Edit*/}
          {/*  </Button>*/}
          {/*)}*/}
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  prescribingStaff: state.patientsListStore.prescribingStaff,
  filterGroup: state.patientsListStore.filterGroup,
  clinicSites: state.patientsListStore.clinicSites,
  patientSiteId: state.patientsListStore.patientSiteId,
  patientSiteName: state.patientsListStore.patientSiteName,
  patientDoctorId: state.patientsListStore.patientDoctorId,
  dispenseType: state.patientsListStore.dispenseStyle,
  patientHasSmartPhone: state.patientsListStore.ifHavePhone,
  patientHasWifi: state.patientsListStore.ifHaveWifi,
  patientLPN: state.patientsListStore.patientLPN,
  doctorDetails: state.patientsListStore.doctorDetails,
  ICD10Codes: state.authGroupStore.ICD10Codes.map(item => item.code)
});
const mapDispatchToProp = dispatch => ({
  changeNewPatient: (editInput, userInfo, self) =>
    dispatch(changeNewPatient(editInput, userInfo, self)),
  getGroups: () => dispatch(getGroups()),
  getPrescribingStaff: staffSiteId =>
    dispatch(getPrescribingStaff(staffSiteId)),
  getPrescribingStaffChanged: (staffSiteId, self) =>
    dispatch(getPrescribingStaffChanged(staffSiteId, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(EditProfileInfo);
