import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { getIntakePatientDetail } from "../../action/patientAction";
import { listAdminUsers } from "../../action/registerAction";
import PatientIntakeDetails from "../account/PatientIntakeDetails";
import AddText from "./AddText";
import "./SirenNotes.css";
import UpdateNote from "./UpdateNote";

class Notes extends Component {
  componentDidMount() {
    const { getIntakePatientDetail } = this.props;
    getIntakePatientDetail(this, this.props.sirenId);
    if (this.props.listAdminStaff.length === 0) {
      this.props.listAdminUsers();
    }
  }

  getAuthorName(author) {
    if (!author) {
      return "";
    }
    if (author.startsWith("Siren-")) {
      author = author.substring(6);
    } else if (author.startsWith("Siren -")) {
      author = author.substring(7);
    }

    let [doctor] = this.props.listStaff.filter(item => item.email === author);
    if (doctor) {
      if (doctor.isPrescribing && !doctor.firstName.includes("Dr.")) {
        return `Dr. ${doctor.firstName || ""} ${doctor.lastName || ""}`;
      }
      return `${doctor.firstName} ${doctor.lastName}`;
    }
    let [adminStaff] = this.props.listAdminStaff.filter(item => item.id === author);
    if (adminStaff) {
      return `${adminStaff.firstName} ${adminStaff.lastName}`;
    }

    return author;
  }

  render() {
    const columns = [
      {
        Header: "TIMESTAMP",
        accessor: "fmtDate",
        width: 130
      },
      {
        Header: "AUTHOR",
        accessor: "fmtAuthor",
        width: 150
      },
      {
        Header: "NOTE",
        accessor: "title",
        filterable: true,
        filterMethod: (filter, row) => {
          let searchText = `${row.title || ""} ${row._original.note || ""}`;
          return searchText.toLowerCase().includes(filter.value.toLowerCase());
        },
        Cell: props => {
          if (props.row.title === "Patient Intake Details") {
            return (
              <PatientIntakeDetails
                userInfo={
                  this.props.intakePatient.length > 0
                    ? this.props.intakePatient[0]
                    : {}
                }
                title={props.original.title}
                note={props.original.note}
                timestamp={props.original.timestamp}
              />
            );
          } else {
            return (
              <UpdateNote
                title={props.original.title}
                note={props.original.note}
                author={props.original.author}
                timestamp={props.original.timestamp}
                authorId={props.original.authorId}
                id={props.original.id}
              />
            );
          }
        }
      }
    ];
    let data = this.props.initializedNote;
    if (this.props.doctorDetails && this.props.doctorDetails.notes) {
      data = data.concat(this.props.doctorDetails.notes);
    }
    data = data.sort(function(a, b) {
      return b.timestamp.localeCompare(a.timestamp);
    });
    // pin 'Special Case: Yes'
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (element.title === 'Special Case: No') {
        break;
      } else if (element.title === 'Special Case: Yes') {
        if (i !== 0) {
          data.splice(i, 1)
          data.splice(0, 0, element)
        }
        break;
      }
    }

    return (
      <div className="notes_box">
        <div className="note_title_box">
          <div className="note_title">PATIENT NOTES</div>
          <div className="note_add_button">
            <AddText />
          </div>
          <div className="clear_both" />
        </div>
        <div className="patientContent">
          <ReactTable
            style={{ width: "100%" }}
            filterable={true}
            defaultFilterMethod={(filter, row) => {
              return row[filter.id]
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            }}
            data={data}
            resolveData={data =>
              data.map(d => {
                let fmtData = { ...d };
                fmtData.fmtDate = moment(d.timestamp).format("YYYY-MM-DD");
                fmtData.fmtAuthor = this.getAuthorName(d.author);
                return fmtData;
              })
            }
            columns={columns}
            showPagination={false}
            minRows={0}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  intakePatient: state.patientsListStore.intakePatient,
  initializedNote: state.patientsListStore.initializedNote,
  listStaff: state.patientsListStore.listStaff,
  listAdminStaff: state.patientsListStore.listAdminStaff
});

const mapDispatchToProp = dispatch => ({
  listAdminUsers: () => dispatch(listAdminUsers()),
  getIntakePatientDetail: (self, sirenId) =>
    dispatch(getIntakePatientDetail(self, sirenId))
});
export default connect(mapStateToProp, mapDispatchToProp)(Notes);
