import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import ReportCalendar from "./reportCalendar";
import "./detailsReport.css";
import moment from "moment";
class detailsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      openModal: false
    };
  }
  render() {
    const today = moment().format("MMMM D, YYYY");
    return (
      <div id="detail_report_dowload_container">
        {this.props.billingTableList.map((billing, i) => {
          if (billing.cptCode === "99454") {
            return (
              <div
                id={"detail_report_dowload_container" + i}
                key={"billing" + i}
                className="details_report_container"
              >
                <div className="report_table_box">
                  <div className="report_title_box report_table_box_one">
                    <span className="report_title">Patient Report:</span>
                    <span className="detail_report_title_content">
                      {billing.sirenId} - {billing.givenName}
                    </span>
                  </div>
                  <div className="report_title_box report_title_box_two">
                    <span className="report_title">DOB:</span>
                    <span className="detail_report_title_content">
                      {billing.birthday
                        ? moment(billing.birthday).format("M/D/YYYY")
                        : ""}
                    </span>
                  </div>
                  <div className="report_title_box report_title_box_three">
                    <span className="report_title">Report generated:</span>
                    <span className="detail_report_title_content">{today}</span>
                  </div>
                  <div className="report_title_box report_title_box_four">
                    <span className="report_title">Patient ID:</span>
                    <span className="detail_report_title_content">
                      {billing.patientId}
                    </span>
                  </div>
                </div>
                <Table celled className="report_table details_report_table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="report_table_th_first">
                        First day of period
                      </Table.HeaderCell>
                      <Table.HeaderCell className="report_table_th">
                        Last day of period
                      </Table.HeaderCell>
                      <Table.HeaderCell className="report_table_th">
                        Prescribing physician
                      </Table.HeaderCell>
                      <Table.HeaderCell className="report_table_th report_table_th_last">
                        Managing location
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="report_table_td_first">
                        {billing.period99454Start
                          ? moment(billing.period99454Start).format("M/D/YYYY")
                          : ""}
                      </Table.Cell>
                      <Table.Cell className="report_table_td">
                        {billing.period99454End
                          ? moment(billing.period99454End).format("M/D/YYYY")
                          : ""}{" "}
                        {/*(*/}
                        {/*{billing.period99454End && billing.period99454Start*/}
                        {/*  ? moment(billing.period99454End).diff(*/}
                        {/*      moment(billing.period99454Start),*/}
                        {/*      "days"*/}
                        {/*    )*/}
                        {/*  : ""}*/}
                        {/*&nbsp;&nbsp;days)*/}
                      </Table.Cell>
                      <Table.Cell className="report_table_td">
                        {billing.doctorId}
                      </Table.Cell>
                      <Table.Cell className="report_table_td">
                        {billing.siteName}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="report_table_th_first">
                        Days worn
                      </Table.HeaderCell>
                      <Table.HeaderCell className="report_table_th">
                        Yellow alerts generated
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        colSpan="2"
                        className="report_table_th report_table_th_last"
                      >
                        Red alerts generated
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell className="report_table_td_first">
                        {billing.daysWorn}
                      </Table.Cell>
                      <Table.Cell className="report_table_td">
                        {billing.yellowDays}
                      </Table.Cell>
                      <Table.Cell colSpan="2" className="report_table_td">
                        {billing.redDays}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  {billing.min30 ? (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell colSpan="4">
                          <ReportCalendar
                            userId={billing.userId}
                            StartDate={billing.period99454Start}
                            EndDate={billing.period99454End}
                            i={i}
                            min30={billing.min30}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  ) : null}
                </Table>
              </div>
            );
          }
        })}
      </div>
    );
  }
}
const mapStateToProp = state => ({});

const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(detailsReport);
