import React, { Component } from "react";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import "./biMonthly.css";
import { Control } from "react-keeper";
import "./report.css";
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ""
    };
  }

  async componentDidMount() {
    window.location.href.includes("RPM99453Report")
      ? this.setState({ url: "RPM99453Report" })
      : this.setState({ url: "Billed99453" });
  }
  render() {
    return (
      <div className="tab_container">
        <span
          className={
            this.state.url === "RPM99453Report" ? "tab_nav-active" : "tab_nav"
          }
          onClick={() => Control.go("/RPM99453Report")}
        >
          UnBilled
        </span>
        <span
          className={
            this.state.url === "Billed99453" ? "tab_nav-active" : "tab_nav"
          }
          onClick={() => Control.go("/Billed99453")}
        >
          Billed
        </span>
      </div>
    );
  }
}

const mapStateToProp = state => ({});
const mapDispatchToProp = dispatch => ({});
export default connect(mapStateToProp, mapDispatchToProp)(Report);
