import { CloseOutlined, QuestionCircleFilled } from "@ant-design/icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Checkbox, Input, Modal as AntdModal, Select } from "antd";
import "antd/dist/antd.css";
import { Auth } from "aws-amplify";
import _ from "lodash";
import moment from "moment-timezone";
import qs from "qs";
import React, { Component } from "react";
import "react-dropdown/style.css";
import { Control } from "react-keeper";
import Modal from "react-modal";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "semantic-ui-css/semantic.min.css";
import { Button, Popup } from "semantic-ui-react";
import {
  getAllListClinicSites,
  getlistSummaryStaff
} from "../action/registerAction";
import {
  getManagementSummary,
  onSummaryFilterChange,
  resetSummaryList
} from "../action/summaryAction";
import aws_exports from "../aws-exports-siren-medical";
import Progress from "../home/progress";
import "../share/common.css";
import { getSockRegisterDay } from "../tools/compare";
import { getPatientHubType } from "../tools/hubType";
import message from "../tools/message-info";
import { formatNumber } from "../tools/phoneFormat";
import getPatchTrProps from "../tools/reactTablePatch";
import "./biMonthly.css";
import "./selectComponent.css";

Modal.setAppElement("div");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0"
  }
};
const allDisplaySummary = [
  "New Patients",
  "App Activated",
  "Socks Dispensed",
  "Registered"
];
const allDisplayColumn = [
  "SiteID",
  "SirenID",
  "Doctor",
  "SiteName",
  "App Activated",
  "Registered Status",
  "Socks Dispensed Date",
  "First Name",
  "Last Name",
  "DOB",
  "Email",
  "Cell Phone",
  "Medical Status",
  "App Last Login",
  "Hub Type",
  "Hub Last Seen",
  "Hub Data Last Seen"
];
const OVERALL_PATIENTS = require("../img/Patients.png");
const NEW_PATIENTS = require("../img/New_Patients.png");
const ACCOUNT_ACTIVATED = require("../img/Account_Activated.png");
const SOCK_DISPENSED = require("../img/Socks_Dispensed.png");
const REGISTERED = require("../img/Registered.png");

class ManagementSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroup: [],
      modalIsOpen: false,
      billingYear: new Date().getFullYear(),
      billingMonth: new Date().getMonth() + 1,
      reportPeriod: "allMonth",
      selectedSites: [],
      selectedDoctors: [],
      selectedPatients: [],
      selectSummaryValues: [
        "New Patients",
        "App Activations",
        "Socks Dispensed",
        "Registered"
      ],
      selectFiltersValues: [
        "SiteID",
        "SirenID",
        "PatientID",
        "Doctor",
        "SiteName",
        "App Activated",
        "Registered Status",
        "Socks Dispensed Date",
        "First Name",
        "Last Name",
        "DOB",
        "Email",
        "Cell Phone",
        "Medical Status",
        "App Last Login",
        "Hub Type",
        "Hub Last Seen",
        "Hub Data Last Seen"
      ],
      inputValue: "",
      inputDoctorValue: "",
      summaryValue: "",
      filtersValue: "",
      showCheckGroup: false,
      showCheckDoctorGroup: false,
      showSummaryCheckGroup: false,
      showFiltersCheckGroup: false,
      checkAllSummary: true,
      checkAllFilters: true,
      ifOpen: false,
      startDate: "",
      EndDate: "",
      overallCount: "",
      newPatientCount: "",
      initializedCount: "",
      dispensedCount: "",
      registeredCount: ""
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSelectSummaryChange = this.onSelectSummaryChange.bind(this);
    this.onSelectFiltersChange = this.onSelectFiltersChange.bind(this);
    this.searchUsers = this.searchUsers.bind(this);
    this.selectBillMonth = this.selectBillMonth.bind(this);
    this.onChangeDateRange = this.onChangeDateRange.bind(this);
  }

  async componentDidMount() {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    this.setState({
      userGroup: groups,
      selectedSites: this.props.siteId
    });
    const {
      getlistSummaryStaff,
      getManagementSummary,
      getlistClinicSites
    } = this.props;
    getlistSummaryStaff(this);
    const filter = {};
    if (this.props.siteId.length > 0) {
      filter.sites = this.props.siteId;
    }
    getManagementSummary(filter, this, this.state.selectSummaryValues);
    if (this.props.clinicSites.length === 0) {
      getlistClinicSites();
    }
  }

  onCheckAllPatients = e => {
    const checked = e.target.checked;
    if (checked) {
      const patientIndex = this.props.summaryList.map((_, index) => index);
      this.setState({
        selectedPatients: patientIndex
      });
    } else {
      this.setState({
        selectedPatients: []
      });
    }
  };

  onCheckPatient(checked, index) {
    let selectedPatients = [...this.state.selectedPatients];
    if (checked) {
      selectedPatients.push(index);
    } else {
      selectedPatients = selectedPatients.filter(p => p !== index);
    }

    this.setState({
      selectedPatients
    });
  }
  handleClear() {
    const { resetAllSirenIdReport } = this.props;
    resetAllSirenIdReport();
  }

  onCheckAllSiteChange = e => {
    const checked = e.target.checked;

    this.setState({
      inputValue: "",
      selectedSites: checked ? this.props.siteId : []
    });
  };

  onCheckSiteChange = value => {
    this.setState({
      selectedSites: value
    });
  };

  deleteSelectSiteId = key => {
    const newSelectedSites = [...this.state.selectedSites];
    newSelectedSites.splice(key, 1);
    this.setState({
      selectedSites: newSelectedSites,
      inputValue: ""
    });
  };

  onCheckAllDoctorChange = e => {
    const checked = e.target.checked;
    if (checked) {
      const allDoctors = this.props.listStaff.map(p => {
        return p.email;
      });
      this.setState({
        inputDoctorValue: "",
        selectedDoctors: allDoctors
      });
    } else {
      this.setState({
        inputDoctorValue: "",
        selectedDoctors: []
      });
    }
  };

  onCheckDoctorChange = value => {
    this.setState({
      selectedDoctors: value
    });
  };

  deleteSelectDoctor = key => {
    const newSelectValues = [...this.state.selectedDoctors];
    newSelectValues.splice(key, 1);
    this.setState({
      selectedDoctors: newSelectValues,
      inputDoctorValue: ""
    });
  };

  onSelectSummaryChange(value) {
    this.setState({
      selectSummaryValues: value,
      indeterminate: !!value.length && value.length < allDisplaySummary.length,
      checkAllSummary: value.length === allDisplaySummary.length
    });
    const { onSummaryFilterChange } = this.props;
    if (this.props.summaryList.length > 0) {
      onSummaryFilterChange(value, this);
    }
  }
  onSelectFiltersChange(value) {
    this.setState({
      selectFiltersValues: value,
      indeterminate: !!value.length && value.length < allDisplayColumn.length,
      checkAllFilters: value.length === allDisplayColumn.length
    });
  }

  searchUsers() {
    const { getManagementSummary } = this.props;
    let filter = {};
    if (!_.isEmpty(this.state.startDate)) {
      filter.startDate = new Date(this.state.startDate);
    }
    if (!_.isEmpty(this.state.EndDate)) {
      filter.endDate = new Date(this.state.EndDate);
    }
    if (this.state.selectedSites.length > 0) {
      filter.sites = this.state.selectedSites;
    } else {
      this.setState({
        overallCount: "",
        newPatientCount: "",
        initializedCount: "",
        dispensedCount: "",
        registeredCount: ""
      });
      this.props.resetSummaryList();
      return;
    }
    if (this.state.selectedDoctors.length > 0) {
      filter.doctorIds = this.state.selectedDoctors;
    }
    this.setState({
      selectedPatients: []
    })
    getManagementSummary(filter, this, this.state.selectSummaryValues);
  }
  selectBillMonth(value) {
    this.setState({ billingMonth: parseInt(value) });
  }

  sendReport(list) {
    let selectSirenID = "";
    selectSirenID = list.join("_");
    Control.go(
      "/UserReportTable?siteId=" +
        this.state.siteId +
        "&sirenIds=" +
        selectSirenID +
        "&year=" +
        this.state.billingYear +
        "&month=" +
        this.state.billingMonth +
        "&reportPeriod=" +
        this.state.reportPeriod
    );
  }
  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  async downloadReport() {
    try {
      AntdModal.warn({
        title: "Downloading Report",
        content:
          "To download the report, disable your pop-up blocker. It will take a few minutes for us to generate your report. You may continue using the Siren Dashboard and the download will start automatically when it is ready.",
        centered: true,
        onOk: async () => {
          await this.startDownloading();
        },
        onCancel: () => {}
      });
    } catch (e) {
      console.log("download pdf error: ", e);
    }
  }

  async startDownloading() {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (this.state.selectedPatients.length === 0) {
      message.info(
        "Please select at least one patient record to download the Management Summary."
      );
      return;
    }
    if(this.state.selectedSites.length === 0){
      message.info(
        "Please select at least one site to download."
      );
      return;
    }
    const endpointInfo = _.filter(aws_exports.aws_cloud_logic_custom, {
      name: "dev-pdf-exporter"
    });
    const pdfEndpoint = endpointInfo[0].endpoint;
    let downloadUrl = null;
    const checkedAll =
      this.state.selectedPatients.length === this.props.summaryList.length;
    if (checkedAll) {
      downloadUrl = `${pdfEndpoint}/medical/allManagementSummary?siteIds=${encodeURIComponent(
        qs.stringify(this.state.selectedSites)
      )}&token=${token}&startDate=${this.state.startDate}&endDate=${
        this.state.EndDate
      }&overallCount=${this.state.overallCount}&newPatientCount=${
        this.state.newPatientCount
      }&activatedCount=${this.state.initializedCount}&dispensedCount=${
        this.state.dispensedCount
      }&registeredCount=${
        this.state.registeredCount
      }&generatedDate=${moment().format("YYYY-MM-DD")}`;
    } else {
      const selectedPatientSirenIds = this.state.selectedPatients.map(
        index => this.props.summaryList[index].sirenId
      );
      downloadUrl = `${pdfEndpoint}/medical/managementSummary?ids=${encodeURIComponent(
        qs.stringify(selectedPatientSirenIds)
      )}&token=${token}&startDate=${this.state.startDate}&endDate=${
        this.state.EndDate
      }&overallCount=${this.state.overallCount}&newPatientCount=${
        this.state.newPatientCount
      }&activatedCount=${this.state.initializedCount}&dispensedCount=${
        this.state.dispensedCount
      }&registeredCount=${
        this.state.registeredCount
      }&generatedDate=${moment().format("YYYY-MM-DD")}`;
    }
    window.open(downloadUrl);
  }

  handleSearchSummary(data) {
    this.setState({ summaryValue: data });
  }
  handleSearchFilters(data) {
    this.setState({ filtersValue: data });
  }
  renderValueItem({ value, key }) {
    return (
      <div
        key={key}
        style={{
          display: "inline-block",
          marginLeft: "5px"
        }}
        className="selectBox"
      >
        <span className="selectValue">{value}</span>{" "}
        <CloseOutlined
          className="CloseOutlined"
          onClick={() => this.deleteSelectSiteId(key)}
        />
      </div>
    );
  }
  renderDoctorValueItem({ value, key }) {
    return (
      <div
        key={key}
        style={{
          display: "inline-block",
          marginLeft: "5px"
        }}
        className="selectBox"
      >
        <span className="selectValue">{this.convertDoctorName(value)}</span>{" "}
        <CloseOutlined
          className="CloseOutlined"
          onClick={() => this.deleteSelectDoctor(key)}
        />
      </div>
    );
  }

  onCheckAllSummary = e => {
    this.setState({
      summaryValue: "",
      indeterminate: false,
      checkAllSummary: e.target.checked
    });
    if (e.target.checked) {
      this.setState({
        selectSummaryValues: [
          "New Patients",
          "App Activations",
          "Socks Dispensed",
          "Registered"
        ]
      });
    } else {
      this.setState({ selectSummaryValues: [] });
    }
  };
  onCheckAllFilters = e => {
    this.setState({
      filtersValue: "",
      indeterminate: false,
      checkAllFilters: e.target.checked
    });
    if (e.target.checked) {
      this.setState({
        selectFiltersValues: [
          "SiteID",
          "SirenID",
          "PatientID",
          "Doctor",
          "SiteName",
          "App Activated",
          "Registered Status",
          "Socks Dispensed Date",
          "First Name",
          "Last Name",
          "DOB",
          "Email",
          "Cell Phone",
          "Medical Status",
          "App Last Login",
          "Hub Type",
          "Hub Last Seen",
          "Hub Data Last Seen"
        ]
      });
    } else {
      this.setState({ selectFiltersValues: [] });
    }
  };
  onChangeDateRange = DateRangeDate => {
    this.setState({
      ifOpen: true,
      startDate: moment(DateRangeDate[0]).format("YYYY-MM-DD"),
      EndDate: moment(DateRangeDate[1]).format("YYYY-MM-DD")
    });
  };
  getAppLastLogin = (appLogin, timeZone) => {
    appLogin.sort(function(a, b) {
      return moment(b.createdAt) - moment(a.createdAt);
    });
    return _.isEmpty(appLogin[0].createdAt)
      ? ""
      : moment
          .tz(appLogin[0].createdAt, timeZone)
          .format("MM/DD/YYYY HH:mm:ss");
  };

  getHubDataLastSeen = (hubs, timeZone) => {
    hubs.sort(function(a, b) {
      return (a.lastSeen === null) - (b.lastSeen === null);
    });
    return _.isEmpty(hubs[0].lastSeen)
      ? ""
      : moment(hubs[0].lastSeen).format("MM/DD/YYYY HH:mm:ss");
  };

  getHubLastSeen = (hubs, timeZone) => {
    hubs.sort(function(a, b) {
      return (a.hubLastSeen === null) - (b.hubLastSeen === null);
    });
    return _.isEmpty(hubs[0].hubLastSeen)
      ? ""
      : moment.tz(hubs[0].hubLastSeen, timeZone).format("MM/DD/YYYY HH:mm:ss");
  };
  gotoStatisticsPage(id, userId, userTimezone) {
    Control.go(
      "/sockstatus?ManagementSummary/id=" +
        id +
        "&userId=" +
        userId +
        "&timeZone=" +
        userTimezone
    );
  }

  gotoDoctorDetailPage(id, userId, date) {
    Control.go(
      "/StaffMedicalDetails?ManagementSummary/id=" +
        id +
        "&userId=" +
        userId +
        "&startDate=" +
        date +
        "&endDate=" +
        date
    );
  }

  convertDoctorName = email => {
    const staffInfo = _.filter(this.props.listStaff, { email });
    return staffInfo[0].firstName && staffInfo[0].firstName.includes("Dr.")
      ? `${staffInfo[0].firstName} ${staffInfo[0].lastName}`
      : `Dr.${staffInfo[0].firstName} ${staffInfo[0].lastName}`;
  };

  render() {
    const { Option } = Select;
    const trProps = getPatchTrProps(({ rowInfo }) => {
      this.gotoDoctorDetailPage(
        rowInfo.original.id,
        rowInfo.original.userId,
        rowInfo.original.lastSeen || moment().format("YYYY-MM-DD")
      );
    });
    return (
      <div className="device-list-container">
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          transitionEnterTimeout={10000}
          transitionLeaveTimeout={10000}
        >
          <Progress />
        </Modal>
        <div className="search_patient_container">
          <div
            className="tab_title_box tab_title report_des_container"
            style={{ height: 30 }}
          >
            <p className="tab_title_box">
              <strong className="tab_title" style={{ paddingLeft: 0 }}>
                Management Summary
              </strong>
            </p>
            <Popup
              trigger={<QuestionCircleFilled className="summary_icon" />}
              position="bottom center"
              hoverable
              flowing
            >
              <Popup.Header className="popHeader">
                Summary Terminology
              </Popup.Header>
              <Popup.Content>
                <div className="popBox">
                  <div className="popContainer">
                    <p className="popTitle">Overall Patients:</p>
                    <p className="popContent">
                      Total number of patients for all accessed sites. Total is
                      not affected by SiteID or date range filters.
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">New Patients:</p>
                    <p className="popContent">
                      Total number of new patients accounts created between
                      selected dates.
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">App Activations:</p>
                    <p className="popContent">
                      Total number of patients that initially logged into the
                      Siren App between selected dates.
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">Socks Dispensed:</p>
                    <p className="popContent">
                      Total number of patients that have been provided Siren
                      Socks between selected dates.
                    </p>
                  </div>
                  <div className="popContainer">
                    <p className="popTitle">Registered:</p>
                    <p className="popContent">
                      Total number of patients that initially paired Siren Socks
                      to their accounts between selected dates.
                    </p>
                  </div>
                </div>
              </Popup.Content>
            </Popup>
          </div>
          <span className="search_label">SiteID: </span>
          <div
            className="selectContainer"
            onClick={() => {
              this.setState({ showCheckGroup: true });
            }}
          >
            <div className="selectValuesBox">
              {this.state.selectedSites.length > 0 ? (
                this.state.selectedSites.map((value, key) => {
                  return this.renderValueItem({ value, key });
                })
              ) : (
                <div className="blanck_SelectBox"></div>
              )}
            </div>
            {this.state.showCheckGroup && (
              <div
                className="CheckboxGroupBox"
                style={{
                  position: "absolute",
                  border: "1px solid #d9d9d9",
                  top: "100%",
                  borderRadius: 4,
                  width: 350
                }}
                onMouseLeave={e => {
                  this.setState({ showCheckGroup: false, inputValue: "" });
                }}
              >
                <Input
                  className="selectInputSearch"
                  placeholder="Type to Search"
                  value={this.state.inputValue}
                  onChange={(e, data) => {
                    this.setState({ inputValue: e.target.value });
                  }}
                />
                <br />
                <Checkbox
                  indeterminate={
                    this.state.selectedSites.length &&
                    this.state.selectedSites.length < this.props.siteId.length
                  }
                  onChange={this.onCheckAllSiteChange}
                  checked={
                    this.state.selectedSites.length &&
                    this.state.selectedSites.length === this.props.siteId.length
                  }
                  style={{ marginLeft: 8 }}
                >
                  All Sites
                </Checkbox>
                <br />
                <Checkbox.Group
                  className="CheckboxGroup"
                  onChange={this.onCheckSiteChange}
                  value={this.state.selectedSites}
                >
                  {this.props.siteId &&
                    this.props.siteId.map((group, i) => {
                      const siteInfo = this.props.clinicSites.filter(
                        t => t.siteId === group
                      );
                      let siteName = "";
                      if (siteInfo.length > 0) {
                        siteName = ` - ${siteInfo[0].name}`;
                      }
                      const groupValue = `${group}${siteName}`;
                      return (
                        <Checkbox
                          className="Checkbox"
                          value={group}
                          key={"select" + i}
                          style={{
                            display: _.isEmpty(this.state.inputValue)
                              ? "block"
                              : groupValue
                                  .toLowerCase()
                                  .indexOf(
                                    this.state.inputValue.toLowerCase()
                                  ) === -1
                              ? "none"
                              : "block"
                          }}
                        >
                          {groupValue}
                        </Checkbox>
                      );
                    })}
                </Checkbox.Group>
              </div>
            )}
          </div>
          <span className="search_label search_time_label">Doctor: </span>
          <div
            className="selectContainer"
            onClick={() => {
              this.setState({ showCheckDoctorGroup: true });
            }}
          >
            <div className="selectValuesBox">
              {this.state.selectedDoctors.length > 0 ? (
                this.state.selectedDoctors.map((value, key) => {
                  return this.renderDoctorValueItem({ value, key });
                })
              ) : (
                <div className="blanck_SelectBox"></div>
              )}
            </div>
            {this.state.showCheckDoctorGroup && (
              <div
                className="CheckboxGroupBox"
                style={{
                  position: "absolute",
                  border: "1px solid #d9d9d9",
                  top: "100%",
                  borderRadius: 4,
                  width: 350
                }}
                onMouseLeave={e => {
                  this.setState({
                    showCheckDoctorGroup: false,
                    inputDoctorValue: ""
                  });
                }}
              >
                <Input
                  className="selectInputSearch"
                  placeholder="Type to Search"
                  value={this.state.inputDoctorValue}
                  onChange={e => {
                    this.setState({ inputDoctorValue: e.target.value });
                  }}
                />
                <br />
                <Checkbox
                  indeterminate={
                    this.state.selectedDoctors.length &&
                    this.state.selectedDoctors.length <
                      this.props.listStaff.length
                  }
                  onChange={this.onCheckAllDoctorChange}
                  checked={
                    this.state.selectedDoctors.length &&
                    this.state.selectedDoctors.length ===
                    this.props.listStaff.length
                  }
                  style={{ marginLeft: 8 }}
                >
                  All Doctors
                </Checkbox>
                <br />
                <Checkbox.Group
                  className="CheckboxGroup"
                  onChange={this.onCheckDoctorChange}
                  value={this.state.selectedDoctors}
                >
                  {this.props.listStaff &&
                    this.props.listStaff.map((staff, i) => {
                      return (
                        <Checkbox
                          className="Checkbox"
                          value={staff.email}
                          key={"select" + i}
                          style={{
                            display: _.isEmpty(this.state.inputDoctorValue)
                              ? "block"
                              : `${staff.firstName} ${staff.lastName}`
                                  .toLowerCase()
                                  .indexOf(
                                    this.state.inputDoctorValue.toLowerCase()
                                  ) === -1
                              ? "none"
                              : "block"
                          }}
                        >
                          {staff.firstName && staff.firstName.includes("Dr.")
                            ? `${staff.firstName} ${staff.lastName}`
                            : `Dr.${staff.firstName} ${staff.lastName}`}
                        </Checkbox>
                      );
                    })}
                </Checkbox.Group>
              </div>
            )}
          </div>
          <span className="search_label search_time_label">Date Range: </span>
          <DateRangePicker
            onChange={this.onChangeDateRange}
            value={
              this.state.startDate
                ? [
                    new Date(moment(this.state.startDate).format("MM/DD/YYYY")),
                    new Date(moment(this.state.EndDate).format("MM/DD/YYYY"))
                  ]
                : null
            }
            clearIcon=""
            minDetail="month"
            locale="en"
            isOpen={this.state.ifOpen}
            onActiveDateChange={this.turnChange}
          />
          <Button
            size="small"
            onClick={this.searchUsers}
            style={{ marginLeft: 20 }}
            className="fixedButtonHeight"
          >
            Search
          </Button>
          <Button
            onClick={() => this.downloadReport()}
            style={{ marginLeft: 20 }}
            className="fixedButtonHeight"
          >
            Download
          </Button>
        </div>
        <div className="summary_des_container" style={{ whiteSpace: "nowrap" }}>
          <div
            className="summary_des_box"
            style={{ display: "flex", marginLeft: 0 }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                backgroundColor: "#4F1974",
                marginTop: 15,
                marginLeft: 10,
                border: 2,
                borderRadius: 5,
                borderColor: "rgba(0,0,0,0.02)"
              }}
            >
              <img
                src={OVERALL_PATIENTS}
                alt=""
                style={{
                  width: 35,
                  height: 30,
                  marginTop: 10
                }}
              />
            </div>
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number">{this.state.overallCount}</p>
              <p className="summary_des_title">Overall Patients</p>
            </div>
          </div>
          <div className="summary_des_box" style={{ display: "flex" }}>
            <div
              style={{
                width: 50,
                height: 50,
                backgroundColor: "#B892D2",
                marginTop: 15,
                marginLeft: 10,
                border: 2,
                borderRadius: 5,
                borderColor: "rgba(0,0,0,0.02)"
              }}
            >
              <img
                src={NEW_PATIENTS}
                alt=""
                style={{
                  width: 30,
                  height: 30,
                  marginTop: 10
                }}
              />
            </div>
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number">{this.state.newPatientCount}</p>
              <p className="summary_des_title">New Patients</p>
            </div>
          </div>
          <div className="summary_des_box" style={{ display: "flex" }}>
            <div
              style={{
                width: 50,
                height: 50,
                backgroundColor: "#B892D2",
                marginTop: 15,
                marginLeft: 10,
                border: 2,
                borderRadius: 5,
                borderColor: "rgba(0,0,0,0.02)"
              }}
            >
              <img
                src={ACCOUNT_ACTIVATED}
                alt=""
                style={{
                  width: 19,
                  height: 30,
                  marginTop: 10
                }}
              />
            </div>
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number">
                {this.state.initializedCount}
              </p>
              <p className="summary_des_title">App Activations</p>
            </div>
          </div>
          <div className="summary_des_box" style={{ display: "flex" }}>
            <div
              style={{
                width: 50,
                height: 50,
                backgroundColor: "#B892D2",
                marginTop: 15,
                marginLeft: 10,
                border: 2,
                borderRadius: 5,
                borderColor: "rgba(0,0,0,0.02)"
              }}
            >
              <img
                src={SOCK_DISPENSED}
                alt=""
                style={{
                  width: 21,
                  height: 30,
                  marginTop: 10
                }}
              />
            </div>
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number">{this.state.dispensedCount}</p>
              <p className="summary_des_title">Socks Dispensed</p>
            </div>
          </div>
          <div className="summary_des_box" style={{ display: "flex" }}>
            <div
              style={{
                width: 50,
                height: 50,
                backgroundColor: "#B892D2",
                marginTop: 15,
                marginLeft: 10,
                border: 2,
                borderRadius: 5,
                borderColor: "rgba(0,0,0,0.02)"
              }}
            >
              <img
                src={REGISTERED}
                alt=""
                style={{
                  width: 30,
                  height: 30,
                  marginTop: 10
                }}
              />
            </div>
            <div style={{ marginTop: 15, marginLeft: 10 }}>
              <p className="summary_des_number">{this.state.registeredCount}</p>
              <p className="summary_des_title">Registered</p>
            </div>
          </div>
        </div>
        <ReactTable
          id="managementSummaryTable"
          getTrProps={trProps}
          data={
            this.props.summaryList &&
            this.props.summaryList.map(p => {
              const lastSeen = p.dataLastSeen
                ? p.dataLastSeen.split("T")[0]
                : "";
              const dispensedDay = p.dispensedDay
                ? p.dispensedDay
                : p.socks.length > 0
                ? getSockRegisterDay(p.socks)
                : "";
              const initializedStr = p.appInitialized ? p.appInitialized : "No";
              const phone = !_.isEmpty(p.phone)
                ? formatNumber(p.phone).trim()
                : "";
              const medicalStatus =
                p.overallStatus && p.overallStatus === "NORMAL"
                  ? "GREEN"
                  : p.overallStatus;
              const hubType = p.hubs ? getPatientHubType(p.hubs) : "";
              const siteInfo = _.find(this.props.clinicSites, {
                siteId: p.siteId ? p.siteId : p.group
              });
              const siteName = siteInfo === undefined ? "" : siteInfo.name;
              const appLastLogin =
                p.appInfo && p.appInfo.length > 0
                  ? this.getAppLastLogin(p.appInfo, p.userTimezone)
                  : "";
              const hubLastSeen =
                p.hubs && p.hubs.length > 0
                  ? this.getHubLastSeen(p.hubs, p.userTimezone)
                  : "";
              const hubDataLastSeen =
                p.hubs && p.hubs.length > 0
                  ? this.getHubDataLastSeen(p.hubs, p.userTimezone)
                  : "";
              const registeredStatus = p.socks.length > 0 ? "Yes" : "No";
              const doctorName =
                (p.doctor && p.doctor.firstName && p.doctor.firstName !== null
                  ? p.doctor.firstName
                  : "") +
                " " +
                (p.doctor && p.doctor.lastName && p.doctor.lastName !== null
                  ? p.doctor.lastName
                  : "");
              return {
                ...p,
                siteName,
                phone,
                initializedStr,
                dispensedDay,
                lastSeen,
                medicalStatus,
                hubType,
                appLastLogin,
                hubLastSeen,
                hubDataLastSeen,
                registeredStatus,
                doctorName
              };
            })
          }
          filterable
          defaultFilterMethod={(filter, row) => {
            if (!row[filter.id]) {
              return false;
            }
            return row[filter.id]
              .toString()
              .toLowerCase()
              .includes(filter.value.toLowerCase());
          }}
          columns={[
            {
              columns: [
                {
                  Header: props => {
                    return (
                      <Checkbox
                        onChange={this.onCheckAllPatients}
                        indeterminate={
                          this.state.selectedPatients.length &&
                          this.state.selectedPatients.length <
                            this.props.summaryList.length
                        }
                        checked={
                          this.state.selectedPatients.length &&
                          this.state.selectedPatients.length ===
                          this.props.summaryList.length
                        }
                        name="selectAll"
                        className="billCheck_all"
                      />
                    );
                  },
                  show: this.state.selectFiltersValues.length > 0,
                  accessor: "",
                  maxWidth: 60,
                  sortable: false,
                  filterable: false,
                  Cell: props => {
                    return (
                      <div onClick={e => e.stopPropagation()}>
                        <Checkbox
                          onChange={(e, data) =>
                            this.onCheckPatient(e.target.checked, props.index)
                          }
                          checked={this.state.selectedPatients.includes(
                            props.index
                          )}
                          name="selectSite"
                        />
                      </div>
                    );
                  }
                }
              ]
            },
            {
              Header: "SiteID",
              accessor: "siteId",
              show: this.state.selectFiltersValues.includes("SiteID"),
              maxWidth: 60
            },
            {
              Header: "SiteName",
              accessor: "siteName",
              show: this.state.selectFiltersValues.includes("SiteName"),
              minWidth: 100
            },
            {
              Header: "Doctor",
              accessor: "doctorName",
              show: this.state.selectFiltersValues.includes("Doctor")
            },
            {
              Header: "SirenID",
              accessor: "sirenId",
              show: this.state.selectFiltersValues.includes("SirenID"),
              maxWidth: 60
            },
            {
              Header: "PatientID",
              accessor: "patientId",
              show: this.state.selectFiltersValues.includes("SirenID")
            },
            {
              Header: "First Name",
              accessor: "firstName",
              show: this.state.selectFiltersValues.includes("First Name")
            },
            {
              Header: "Last Name",
              accessor: "lastName",
              show: this.state.selectFiltersValues.includes("Last Name")
            },
            {
              Header: "DOB",
              accessor: "birthday",
              show: this.state.selectFiltersValues.includes("DOB")
            },
            {
              Header: "Cell Phone",
              accessor: "phone",
              show: this.state.selectFiltersValues.includes("Cell Phone"),
              maxWidth: 200
            },
            {
              Header: "Email",
              accessor: "email",
              show: this.state.selectFiltersValues.includes("Email")
            },
            {
              Header: "App Activated",
              accessor: "initializedStr",
              show: this.state.selectFiltersValues.includes("App Activated"),
              maxWidth: 100,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id] === "Yes";
                }
                return row[filter.id] === "No";
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Registered Status",
              accessor: "registeredStatus",
              show: this.state.selectFiltersValues.includes(
                "Registered Status"
              ),
              maxWidth: 100,
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Yes") {
                  return row[filter.id] === "Yes";
                }
                return row[filter.id] === "No";
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              )
            },
            {
              Header: "Socks Dispensed Date",
              accessor: "dispensedDay",
              show: this.state.selectFiltersValues.includes(
                "Socks Dispensed Date"
              ),
              minWidth: 120
              // Cell: props => {
              //   return (
              //     <div>
              //       {props.original.dispensedDay}
              //       <ChangeDispensedDay
              //        userId={props.original.userId}
              //        id={props.original.id}
              //        dispensedDay={props.original.dispensedDay}
              //        from="BiMonthly"
              //        />
              //     </div>
              //   );
              // },
            },
            {
              Header: "Medical Status",
              accessor: "medicalStatus",
              show: this.state.selectFiltersValues.includes("Medical Status"),
              Cell: props => {
                return <div>{props.original.medicalStatus}</div>;
              },
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "GREEN") {
                  return row[filter.id] === "GREEN";
                }
                if (filter.value === "RED") {
                  return row[filter.id] === "RED";
                }
                return row[filter.id] === "YELLOW";
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="GREEN">GREEN</Option>
                  <Option value="RED">RED</Option>
                  <Option value="YELLOW">YELLOW</Option>
                </Select>
              )
            },
            {
              Header: "App Last Login",
              accessor: "appLastLogin",
              show: this.state.selectFiltersValues.includes("App Last Login")
            },
            {
              Header: "Hub Type",
              accessor: "hubType",
              show: this.state.selectFiltersValues.includes("Hub Type"),
              Cell: props => {
                return <div>{props.original.hubType}</div>;
              },
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "Wifi") {
                  return getPatientHubType(row._original.hubs).includes("Wifi");
                }
                if (filter.value === "2G") {
                  return getPatientHubType(row._original.hubs).includes("2G");
                }
                if (filter.value === "4G") {
                  return getPatientHubType(row._original.hubs).includes("4G");
                }
                if (filter.value === "Wifi, 2G") {
                  return getPatientHubType(row._original.hubs).includes(
                    "Wifi, 2G"
                  );
                }
                if (filter.value === "Wifi, 4G") {
                  return getPatientHubType(row._original.hubs).includes(
                    "Wifi, 4G"
                  );
                }
                if (filter.value === "2G, 4G") {
                  return getPatientHubType(row._original.hubs).includes(
                    "2G, 4G"
                  );
                }
                if (filter.value === "Wifi, 2G, 4G") {
                  return getPatientHubType(row._original.hubs).includes(
                    "Wifi, 2G, 4G"
                  );
                }
              },
              Filter: ({ filter, onChange }) => (
                <Select
                  className="select_common_style"
                  onChange={onChange}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  optionFilterProp="children"
                >
                  <Option value="all">All</Option>
                  <Option value="Wifi">Wifi</Option>
                  <Option value="2G">2G</Option>
                  <Option value="4G">4G</Option>
                  <Option value="Wifi, 2G">Wifi, 2G</Option>
                  <Option value="Wifi, 4G">Wifi, 4G</Option>
                  <Option value="2G, 4G">2G, 4G</Option>
                  <Option value="Wifi, 2G, 4G">Wifi, 2G, 4G</Option>
                </Select>
              )
            },
            {
              Header: "Hub Last Seen",
              accessor: "hubLastSeen",
              show: this.state.selectFiltersValues.includes("Hub Last Seen")
            },
            {
              Header: "Hub Data Last Seen",
              accessor: "hubDataLastSeen",
              show: this.state.selectFiltersValues.includes(
                "Hub Data Last Seen"
              )
            }
          ]}
          defaultSorted={[{ id: "sirenId", desc: false }]}
          defaultPageSize={20}
          className="-striped -highlight"
          minRows={0}
        />
        {/*<div className="add_import_box" style={{ textAlign: "center" }}>*/}
        {/*  <Button*/}
        {/*    size="small"*/}
        {/*    onClick={() => this.sendReport(this.props.bliSirenIdReport)}*/}
        {/*  >*/}
        {/*    Next*/}
        {/*  </Button>*/}
        {/*  <Button size="small" onClick={this.handleClear}>*/}
        {/*    Cancel*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    );
  }
}

const mapStateToProp = state => ({
  siteId: state.authGroupStore.profile.siteId,
  summaryList: state.patientsListStore.summaryList,
  summaryListFilter: state.patientsListStore.summaryListFilter,
  clinicSites: state.patientsListStore.allClinicSites,
  listStaff: state.patientsListStore.listStaff
});
const mapDispatchToProp = dispatch => ({
  getManagementSummary: (filter, self, selectSummaryValues) =>
    dispatch(getManagementSummary(filter, self, selectSummaryValues)),
  getlistClinicSites: () => dispatch(getAllListClinicSites()),
  onSummaryFilterChange: (summaryFilter, self) =>
    dispatch(onSummaryFilterChange(summaryFilter, self)),
  getlistSummaryStaff: () => dispatch(getlistSummaryStaff),
  resetSummaryList: () => dispatch(resetSummaryList())
});
export default connect(mapStateToProp, mapDispatchToProp)(ManagementSummary);
