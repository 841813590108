import * as TYPES from "../types/types";
import _api from "../graphql/_api";
import _ from "lodash";
import { graphqlOperation, API } from "aws-amplify";
import {
  changeAppointmentList,
  changeComplianceList,
  changeLoadingChartsState,
  changeDeletePatientslListState
} from "./patientAction";
import { Modal as AntdModal } from "antd";
import { listICD10Codes } from "./userGroupAction";

export function addSchduled(schduled, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.setSchduled(), { input: schduled })
      );
      if (newEvent) {
        self.setState({ open: false });
        const tmpSchduled = _.clone(self.props.appointmentList);
        _.remove(tmpSchduled, {
          userId: schduled.userId
        });
        const schduledAfterDel = tmpSchduled;
        dispatch(changeAppointmentList(schduledAfterDel));
      }
    } catch (err) {
      console.log("addSchduled exception: ", err);
    }
  };
}
export function addComplianceReviewed(reviewed, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.setComplianceReviewed(), { input: reviewed })
      );
      if (newEvent) {
        self.setState({ open: false });
        const tmpReviewed = _.clone(self.props.complianceList);
        _.remove(tmpReviewed, {
          userId: reviewed.userId
        });
        const reviewedAfterDel = tmpReviewed;
        dispatch(changeComplianceList(reviewedAfterDel));
      }
    } catch (err) {
      console.log("addComplianceReviewed exception: ", err);
    }
  };
}

export function editPatient(editInput, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateNewPatient(), { input: editInput })
      );
      dispatch(changeLoadingChartsState(false));
      if (newEvent) {
        self.setState({
          open: false,
          confirmSuccess: true,
          birMonthErrorFormat: false,
          birDayErrorFormat: false,
          birErrorFormat: false,
          firstName: "",
          lastName: "",
          phone: "",
          patientId: "",
          doctorId: ""
        });
        // dispatch(changeEditPatient(newEvent.data.updatePatient));
        let patientInfo = self.props.doctorDetails;
        const newPatientInfo = _.clone(patientInfo);
        if (editInput.hasOwnProperty("phone")) {
          newPatientInfo.phone = editInput.phone;
          delete editInput["phone"];
        }
        if (editInput.hasOwnProperty("patientId")) {
          newPatientInfo.patientId = editInput.patientId;
          delete editInput["patientId"];
        }
        if (editInput.hasOwnProperty("doctorId")) {
          newPatientInfo.doctorId = editInput.doctorId;
          delete editInput["doctorId"];
        }
        delete editInput["userId"];
        if (newPatientInfo.userInfo) {
          if (!_.isEmpty(editInput)) {
            Object.assign(newPatientInfo.userInfo, editInput);
          }
        } else {
          newPatientInfo.userInfo = editInput;
        }
        dispatch(changeDoctorDetails(newPatientInfo));
      }
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("editPatient error: ", e);
    }
  };
}
export function changeDoctorDetails(doctorDetails) {
  return {
    type: TYPES.DOCTOR_DETAILS,
    text: doctorDetails
  };
}

export function getAppointmented(inputData, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.setAppointmented(), { input: inputData })
      );
      if (newEvent) {
        self.setState({
          comment: "",
          value: "",
          showSuccessAlert: true,
          showFailedAlert: false
        });
        let doctorDetails = _.clone(self.props.doctorDetails);
        doctorDetails.reviewStatus = "RESOLVED";
        doctorDetails.reviewPhotoStatus = "No";
        dispatch(changeDoctorDetails(doctorDetails));
      }
    } catch (err) {
      self.setState({ showSuccessAlert: false, showFailedAlert: true });
      console.log("getAppointmented error: ", err);
    }
  };
}
export function getAppLink(information, self) {
  return async dispatch => {
    try {
      await API.graphql(
        graphqlOperation(_api.sendAppLink(), { input: information })
      );
      self.setState({ modalIsOpen: false, open: false });
    } catch (err) {
      console.log("getAppLink exception: ", err);
    }
  };
}
export function updateDispensed(input) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateNewRegisterPatient(), { input })
      );
      dispatch(changeLoadingChartsState(false));
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("updateDispensed error: ", e);
    }
  };
}
export function changeAccountStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAccountStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false, note: "" });
      }
    } catch (err) {
      console.log("changeAccountStatus exception: ", err);
    }
  };
}

export function changeDeviceStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDeviceStatus(), { input: newStatus })
      );
      console.log(`update device status success: ${newEvent}`);
      if (newEvent) {
        self.setState({ open: false });
      }
    } catch (err) {
      console.log("changeDeviceStatus exception: ", err);
    }
  };
}

export function changeRegisterAccountStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAccountStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false });
        userInfo.accountStatus = newStatus.status;
        const newRDelPatientInfo = _.clone(self.props.delPatients);
        _.remove(newRDelPatientInfo, {
          id: newStatus.id
        });
        newRDelPatientInfo.push(userInfo);
        dispatch(changeDeletePatientslListState(newRDelPatientInfo));
      }
    } catch (err) {
      console.log("changeAccountStatus exception: ", err);
    }
  };
}

export function changeDeletedAccountStatus(newStatus, userInfo, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateAccountStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false });
        userInfo.accountStatus = newStatus.status;
        const newRDelPatientInfo = _.clone(self.props.delPatients);
        _.remove(newRDelPatientInfo, {
          id: newStatus.id
        });
        dispatch(changeDeletePatientslListState(newRDelPatientInfo));
      }
    } catch (err) {
      console.log("changeAccountStatus exception: ", err);
    }
  };
}

export function changeNewPatient(editInput, userInfo, self) {
  return async dispatch => {
    try {
      dispatch(changeLoadingChartsState(true));
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateNewRegisterPatient(), { input: editInput })
      );
      dispatch(changeLoadingChartsState(false));
      if (newEvent) {
        self.setState({ open: false, enableWarning: false });
        dispatch(listICD10Codes);
        if (self.props.loadNewEditValue) {
          self.props.loadNewEditValue();
        }
      }
    } catch (e) {
      dispatch(changeLoadingChartsState(false));
      console.log("changeNewPatient error: ", e);
    }
  };
}
export function changeServiceStatus(newStatus, userInfo, self) {
  console.log("newStatus, userInfo", newStatus, userInfo);
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateServiceStatus(), { input: newStatus })
      );
      if (newEvent) {
        self.setState({ open: false });
      }
    } catch (err) {
      console.log("changeServiceStatus exception: ", err);
    }
  };
}

export function checkSocksAndHub(sirenId, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.checkPatientSocksAndHub(), { sirenId })
      );
      if (newEvent) {
        const patient = newEvent.data.getPatientBySirenId;
        if (patient.socks.length > 0 && patient.hubs.length > 0) {
          AntdModal.warn({
            title: "Delete Patient Failed",
            content:
              "The user still has unbound socks and hubs, please unbind them first.",
            centered: true
          });
        } else if (patient.socks.length > 0) {
          AntdModal.warn({
            title: "Delete Patient Failed",
            content:
              "The user still has unbound socks, please unbind them first.",
            centered: true
          });
        } else if (patient.hubs.length > 0) {
          AntdModal.warn({
            title: "Delete Patient Failed",
            content:
              "The user still has unbound hubs, please unbind them first.",
            centered: true
          });
        } else {
          self.handleConfirm();
        }
      } else {
        AntdModal.warn({
          title: "Delete Patient Failed",
          content: `SirenId: ${sirenId} not exist`,
          centered: true
        });
      }
    } catch (e) {
      console.log(`checkSocksAndHub error: ${e}`);
    }
  };
}

export function changeSpecialCaseStatus(newStatus) {
  return API.graphql(
    graphqlOperation(_api.updateSpecialCaseStatus(), { input: newStatus })
  );
}
