import { changeLoadingChartsState } from "./patientAction";
import zlib from "react-zlib-js";
import Buffer from "react-zlib-js/buffer";
import _ from "lodash";
import _api from "../graphql/_api";
import moment from "moment";
import { graphqlOperation, API } from "aws-amplify";
import { isToday } from "../constant/TimeConversion";
import * as TYPES from "../types/types";
//Modify chart
export function changeRenderDate(self, id, userId, dateRender) {
  return async dispatch => {
    dispatch(changeLoadingChartsState(true));
    let time1 = dateRender ? dateRender[0] : null;
    let time2 = dateRender ? dateRender[1] : null;
    let rangetime1 = moment(time1).format("YYYY-MM-DD");
    let rangetime2 = moment(time2)
      .add(1, "days")
      .format("YYYY-MM-DD");
    let dateTime = {
      id,
      userId: userId,
      startDate: rangetime1,
      endDate: rangetime2
    };
    let DELAY = moment(rangetime2) - moment(rangetime1);
    let reg = new RegExp('"', "g");
    converDelta([], dispatch, DELAY, self, rangetime1);
    converSteps([], dispatch, DELAY, self, rangetime1);
    converLeftTemp([], dispatch, DELAY, self, rangetime1);
    converRightTemp([], dispatch, DELAY, self, rangetime1);
    converLeftPosition([], dispatch, DELAY, self, rangetime1);
    converRightPosition([], dispatch, DELAY, self, rangetime1);
    try {
      const newEvent = await API.graphql(
        graphqlOperation(
          DELAY > self.DELAY ? _api.listPatientMin30() : _api.listPatientMin1(),
          dateTime
        )
      );
      if (newEvent) {
        dispatch(changeLoadingChartsState(false));
        let deflateDate = null;
        let stepsRaw;
        if (DELAY > self.DELAY) {
          deflateDate = newEvent.data.getPatient.min30.replace(reg, "");
          stepsRaw = getMinrawData(
            newEvent.data.getPatient.min30raw,
            reg,
            dispatch,
            DELAY,
            self,
            rangetime1
          );
        } else {
          deflateDate = newEvent.data.getPatient.min1.replace(reg, "");
          stepsRaw = getMinrawData(
            newEvent.data.getPatient.min1raw,
            reg,
            dispatch,
            DELAY,
            self,
            rangetime1
          );
        }
        const inflateData = zlib
          .inflateSync(new Buffer(deflateDate, "base64"))
          .toString();
        let delta = [];
        let steps = [];
        _.forEach(inflateData.split("|"), function(item) {
          let keyData = [
            "usertime",
            "ltoe",
            "lmts1",
            "lmts3",
            "lmts5",
            "larch",
            "lheel",
            "lgsensorx",
            "lgsensory",
            "lgsensorz",
            "rtoe",
            "rmts1",
            "rmts3",
            "rmts5",
            "rarch",
            "rheel",
            "rgsensorx",
            "rgsensory",
            "rgsensorz",
            "steps"
          ];
          let objData = {};
          item.split(",").forEach((subItem, index) => {
            objData[keyData[index]] = subItem;
          });
          let objDelta = {};
          let objSteps = {};
          Object.assign(objDelta, {
            usertime: objData.usertime,
            dtoe: (objData.ltoe - objData.rtoe).toFixed(2),
            dmts1: (objData.lmts1 - objData.rmts1).toFixed(2),
            dmts3: (objData.lmts3 - objData.rmts3).toFixed(2),
            dmts5: (objData.lmts5 - objData.rmts5).toFixed(2),
            darch: (objData.larch - objData.rarch).toFixed(2),
            dheel: (objData.lheel - objData.rheel).toFixed(2)
          });
          delta.push(objDelta);

          Object.assign(objSteps, {
            usertime: objData.usertime,
            steps: objData.steps
          });
          if (objData.steps) {
            steps.push(objSteps);
          }
        });
        dispatch(
          changeMin1ORMin30Status(DELAY > self.DELAY ? "min30" : "min1")
        );
        let allPointDatas = [];
        _(delta).forEach(function(item) {
          allPointDatas.push(Math.abs(item.dtoe));
          allPointDatas.push(Math.abs(item.dmts1));
          allPointDatas.push(Math.abs(item.dmts3));
          allPointDatas.push(Math.abs(item.dmts5));
          allPointDatas.push(Math.abs(item.darch));
          allPointDatas.push(Math.abs(item.dheel));
        });
        if (
          allPointDatas.length > 0 &&
          Math.max.apply(Math, allPointDatas) > 6
        ) {
          dispatch(changeDeltaAutoStatus(true));
        } else {
          dispatch(changeDeltaAutoStatus(false));
        }
        if (stepsRaw.rightStepsRaw.length >= stepsRaw.leftStepsRaw.length) {
          _.forEach(stepsRaw.rightStepsRaw, function(item) {
            if (_.filter(steps, { usertime: item.usertime }).length === 0) {
              steps.push(item);
            }
          });
        } else {
          _.forEach(stepsRaw.leftStepsRaw, function(item) {
            if (_.filter(steps, { usertime: item.usertime }).length === 0) {
              steps.push(item);
            }
          });
        }
        let newSteps = [];
        _.forEach(steps, function(item) {
          if (DELAY > self.DELAY) {
            if (item.steps > 1500) {
              newSteps.push({ usertime: item.usertime, steps: 0 });
            } else {
              newSteps.push(item);
            }
          } else {
            if (item.steps > 160) {
              newSteps.push({ usertime: item.usertime, steps: 0 });
            } else {
              newSteps.push(item);
            }
          }
        });
        converDelta(
          deduplication(
            delta.sort(function(a, b) {
              return new moment(a.usertime) - new moment(b.usertime);
            })
          ),
          dispatch,
          DELAY,
          self,
          rangetime1
        );
        converSteps(
          deduplication(
            newSteps.sort(function(a, b) {
              return new moment(a.usertime) - new moment(b.usertime);
            })
          ),
          dispatch,
          DELAY,
          self,
          rangetime1
        );
      }
    } catch (err) {
      dispatch(changeLoadingChartsState(false));
      console.log(
        DELAY > self.DELAY ? "listPatientMin30" : "listPatientMin1 error: ",
        err
      );
    }
  };
}

function deduplication(arr) {
  let newArr = [];
  let end;
  arr.sort();
  end = arr[0];
  newArr.push(arr[0]);
  for (let i = 1; i < arr.length; i++) {
    if (arr[i].usertime !== end.usertime) {
      newArr.push(arr[i]);
      end = arr[i];
    }
  }
  return newArr;
}

function getMinrawData(min1raw, reg, dispatch, DELAY, self, rangetime1) {
  let deflateDateRaw = min1raw.replace(reg, "");
  const inflateDataRaw = zlib
    .inflateSync(new Buffer(deflateDateRaw, "base64"))
    .toString();
  let leftFootTempRaw = [];
  let rightFootTempRaw = [];
  let leftPositionRaw = [];
  let rightPositionRaw = [];
  let leftStepsRaw = [];
  let rightStepsRaw = [];

  _.forEach(inflateDataRaw.split("|"), function(item) {
    let keyDataRaw = [
      "usertime",
      "foot",
      "toe",
      "mts1",
      "mts3",
      "mts5",
      "arch",
      "heel",
      "gsensorx",
      "gsensory",
      "gsensorz",
      "steps"
    ];
    let objDataRaw = {};

    item.split(",").forEach((subItem, index) => {
      objDataRaw[keyDataRaw[index]] = subItem;
    });
    let objLeftTempRaw = {};
    let objRightTempRaw = {};
    let objLeftPositionRaw = {};
    let objRightPositionRaw = {};
    let objLeftSteps = {};
    let objRightSteps = {};

    if (objDataRaw.foot === "left") {
      Object.assign(objLeftTempRaw, {
        usertime: objDataRaw.usertime,
        toe: objDataRaw.toe,
        mts1: objDataRaw.mts1,
        mts3: objDataRaw.mts3,
        mts5: objDataRaw.mts5,
        arch: objDataRaw.arch,
        heel: objDataRaw.heel
      });
      leftFootTempRaw.push(objLeftTempRaw);

      Object.assign(objLeftPositionRaw, {
        usertime: objDataRaw.usertime,
        gsensorx: objDataRaw.gsensorx,
        gsensory: objDataRaw.gsensory,
        gsensorz: objDataRaw.gsensorz
      });
      leftPositionRaw.push(objLeftPositionRaw);

      Object.assign(objLeftSteps, {
        usertime: objDataRaw.usertime,
        steps: objDataRaw.steps
      });
      leftStepsRaw.push(objLeftSteps);
    } else {
      Object.assign(objRightTempRaw, {
        usertime: objDataRaw.usertime,
        toe: objDataRaw.toe,
        mts1: objDataRaw.mts1,
        mts3: objDataRaw.mts3,
        mts5: objDataRaw.mts5,
        arch: objDataRaw.arch,
        heel: objDataRaw.heel
      });
      rightFootTempRaw.push(objRightTempRaw);

      Object.assign(objRightPositionRaw, {
        usertime: objDataRaw.usertime,
        gsensorx: objDataRaw.gsensorx,
        gsensory: objDataRaw.gsensory,
        gsensorz: objDataRaw.gsensorz
      });
      rightPositionRaw.push(objRightPositionRaw);

      Object.assign(objRightSteps, {
        usertime: objDataRaw.usertime,
        steps: objDataRaw.steps
      });
      rightStepsRaw.push(objRightSteps);
    }
  });

  converLeftTemp(leftFootTempRaw, dispatch, DELAY, self, rangetime1);
  converRightTemp(rightFootTempRaw, dispatch, DELAY, self, rangetime1);
  converLeftPosition(leftPositionRaw, dispatch, DELAY, self, rangetime1);
  converRightPosition(rightPositionRaw, dispatch, DELAY, self, rangetime1);
  // if (rightStepsRaw.length >= leftStepsRaw.length) {
  //   converSteps(rightStepsRaw, dispatch, DELAY, self, rangetime1);
  // } else {
  //   converSteps(leftStepsRaw, dispatch, DELAY, self, rangetime1);
  // }
  return { leftStepsRaw, rightStepsRaw };
}

function converDelta(delta, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < delta.length; i++) {
    if (i === 0) {
      if (
        moment(delta[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          dtoe: "-",
          dmts1: "-",
          dmts3: "-",
          dmts5: "-",
          darch: "-",
          dheel: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(delta[i].usertime.replace("Z", "")) - moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            dtoe: "-",
            dmts1: "-",
            dmts3: "-",
            dmts5: "-",
            darch: "-",
            dheel: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(delta[0]);
    } else {
      if (
        moment(delta[i].usertime) - moment(delta[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(delta[i]);
      } else {
        let intever = Math.floor(
          (moment(delta[i].usertime) - moment(delta[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(delta[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            dtoe: "-",
            dmts1: "-",
            dmts3: "-",
            dmts5: "-",
            darch: "-",
            dheel: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(delta[i]);
      }
    }
  }
  if (delta.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeDeltaStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(delta[delta.length - 1].usertime.replace("Z", ""))
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(delta[delta.length - 1].usertime.replace("Z", "")) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          dtoe: "-",
          dmts1: "-",
          dmts3: "-",
          dmts5: "-",
          darch: "-",
          dheel: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeDeltaStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        dtoe: "-",
        dmts1: "-",
        dmts3: "-",
        dmts5: "-",
        darch: "-",
        dheel: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeDeltaStatus(arrayB));
  }
}

function converLeftTemp(leftTempData, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < leftTempData.length; i++) {
    if (i === 0) {
      if (
        moment(leftTempData[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          toe: "-",
          mts1: "-",
          mts3: "-",
          mts5: "-",
          arch: "-",
          heel: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(leftTempData[i].usertime.replace("Z", "")) -
            moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            toe: "-",
            mts1: "-",
            mts3: "-",
            mts5: "-",
            arch: "-",
            heel: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(leftTempData[0]);
    } else {
      if (
        moment(leftTempData[i].usertime) -
          moment(leftTempData[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(leftTempData[i]);
      } else {
        let intever = Math.floor(
          (moment(leftTempData[i].usertime) -
            moment(leftTempData[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(leftTempData[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            toe: "-",
            mts1: "-",
            mts3: "-",
            mts5: "-",
            arch: "-",
            heel: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(leftTempData[i]);
      }
    }
  }
  if (leftTempData.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeLeftFootTempStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(
              leftTempData[leftTempData.length - 1].usertime.replace("Z", "")
            )
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              leftTempData[leftTempData.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          toe: "-",
          mts1: "-",
          mts3: "-",
          mts5: "-",
          arch: "-",
          heel: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeLeftFootTempStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        toe: "-",
        mts1: "-",
        mts3: "-",
        mts5: "-",
        arch: "-",
        heel: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeLeftFootTempStatus(arrayB));
  }
}

function converRightTemp(rightTempData, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < rightTempData.length; i++) {
    if (i === 0) {
      if (
        moment(rightTempData[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          toe: "-",
          mts1: "-",
          mts3: "-",
          mts5: "-",
          arch: "-",
          heel: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(rightTempData[i].usertime.replace("Z", "")) -
            moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            toe: "-",
            mts1: "-",
            mts3: "-",
            mts5: "-",
            arch: "-",
            heel: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(rightTempData[0]);
    } else {
      if (
        moment(rightTempData[i].usertime) -
          moment(rightTempData[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(rightTempData[i]);
      } else {
        let intever = Math.floor(
          (moment(rightTempData[i].usertime) -
            moment(rightTempData[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(rightTempData[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            toe: "-",
            mts1: "-",
            mts3: "-",
            mts5: "-",
            arch: "-",
            heel: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(rightTempData[i]);
      }
    }
  }
  if (rightTempData.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeRightFootTempStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(
              rightTempData[rightTempData.length - 1].usertime.replace("Z", "")
            )
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              rightTempData[rightTempData.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          toe: "-",
          mts1: "-",
          mts3: "-",
          mts5: "-",
          arch: "-",
          heel: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeRightFootTempStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        toe: "-",
        mts1: "-",
        mts3: "-",
        mts5: "-",
        arch: "-",
        heel: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeRightFootTempStatus(arrayB));
  }
}

function converSteps(steps, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < steps.length; i++) {
    if (i === 0) {
      if (
        moment(steps[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          steps: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(steps[i].usertime.replace("Z", "")) - moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            steps: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(steps[0]);
    } else {
      if (
        moment(steps[i].usertime) - moment(steps[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(steps[i]);
      } else {
        let intever = Math.floor(
          (moment(steps[i].usertime) - moment(steps[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(steps[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            steps: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(steps[i]);
      }
    }
  }
  if (steps.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeStepsStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(steps[steps.length - 1].usertime.replace("Z", ""))
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(steps[steps.length - 1].usertime.replace("Z", "")) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          steps: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeStepsStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        steps: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeStepsStatus(arrayB));
  }
}

function converLeftPosition(leftPosition, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < leftPosition.length; i++) {
    if (i === 0) {
      if (
        moment(leftPosition[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(leftPosition[i].usertime.replace("Z", "")) -
            moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(leftPosition[0]);
    } else {
      if (
        moment(leftPosition[i].usertime) -
          moment(leftPosition[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(leftPosition[i]);
      } else {
        let intever = Math.floor(
          (moment(leftPosition[i].usertime) -
            moment(leftPosition[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(leftPosition[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(leftPosition[i]);
      }
    }
  }
  if (leftPosition.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeLeftPositionStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(
              leftPosition[leftPosition.length - 1].usertime.replace("Z", "")
            )
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              leftPosition[leftPosition.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeLeftPositionStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        gsensorx: "-",
        gsensory: "-",
        gsensorz: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeLeftPositionStatus(arrayB));
  }
}

function converRightPosition(rightPosition, dispatch, delay, self, startTime) {
  let arrayB = [];
  let DEFATLT_INTEVER = delay > self.DELAY ? 30 * 60 * 1000 : 60 * 1000;
  for (let i = 0; i < rightPosition.length; i++) {
    if (i === 0) {
      if (
        moment(rightPosition[i].usertime.replace("Z", "")) - moment(startTime) <
        2 * DEFATLT_INTEVER
      ) {
        let obj = {
          usertime: moment(startTime).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      } else {
        let intever = Math.floor(
          (moment(rightPosition[i].usertime.replace("Z", "")) -
            moment(startTime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(moment(startTime) + DEFATLT_INTEVER * k).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
      }
      arrayB.push(rightPosition[0]);
    } else {
      if (
        moment(rightPosition[i].usertime) -
          moment(rightPosition[i - 1].usertime) <
        2 * DEFATLT_INTEVER
      ) {
        arrayB.push(rightPosition[i]);
      } else {
        let intever = Math.floor(
          (moment(rightPosition[i].usertime) -
            moment(rightPosition[i - 1].usertime)) /
            DEFATLT_INTEVER
        );
        for (let k = 0; k < intever - 1; k++) {
          let obj = {
            usertime: moment(
              moment(rightPosition[i - 1].usertime.replace("Z", "")) +
                DEFATLT_INTEVER * k
            ).format("YYYY-MM-DDTHH:mm:ss"),
            gsensorx: "-",
            gsensory: "-",
            gsensorz: "-"
          };
          arrayB.push(obj);
        }
        arrayB.push(rightPosition[i]);
      }
    }
  }

  if (rightPosition.length > 0) {
    if (delay > self.DELAY) {
      dispatch(changeRightPositionStatus(arrayB));
    } else {
      let inteverSub =
        Math.floor(
          (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
            moment(
              rightPosition[rightPosition.length - 1].usertime.replace("Z", "")
            )
        ) / DEFATLT_INTEVER;
      for (let j = 0; j < inteverSub - 1; j++) {
        let obj = {
          usertime: moment(
            moment(
              rightPosition[rightPosition.length - 1].usertime.replace("Z", "")
            ) +
              DEFATLT_INTEVER * j
          ).format("YYYY-MM-DDTHH:mm:ss"),
          gsensorx: "-",
          gsensory: "-",
          gsensorz: "-"
        };
        arrayB.push(obj);
      }
      dispatch(changeRightPositionStatus(arrayB));
    }
  } else {
    let inteverSub =
      Math.floor(
        (isToday(startTime) ? moment() : moment(startTime + "T23:59:59")) -
          moment(startTime + "00:00:00")
      ) / DEFATLT_INTEVER;
    for (let j = 0; j < inteverSub - 1; j++) {
      let obj = {
        usertime: moment(
          moment(startTime + "00:00:00") + DEFATLT_INTEVER * j
        ).format("YYYY-MM-DDTHH:mm:ss"),
        gsensorx: "-",
        gsensory: "-",
        gsensorz: "-"
      };
      arrayB.push(obj);
    }
    dispatch(changeRightPositionStatus(arrayB));
  }
}

function changeMin1ORMin30Status(minStatus) {
  return {
    type: TYPES.MIN1_OR_MIN30,
    text: minStatus
  };
}

function changeDeltaAutoStatus(deltaAuto) {
  return {
    type: TYPES.DELTA_AUTO,
    text: deltaAuto
  };
}

function changeDeltaStatus(deltaData) {
  return {
    type: TYPES.DELTA,
    text: deltaData
  };
}

function changeLeftFootTempStatus(leftFootTempData) {
  return {
    type: TYPES.LEFT_FOOT_TEMP,
    text: leftFootTempData
  };
}

function changeRightFootTempStatus(rightFootTempData) {
  return {
    type: TYPES.RIGHT_FOOT_TEMP,
    text: rightFootTempData
  };
}

function changeStepsStatus(stepsData) {
  return {
    type: TYPES.STEPS,
    text: stepsData
  };
}

function changeLeftPositionStatus(leftPositionData) {
  return {
    type: TYPES.LEFT_POSITION,
    text: leftPositionData
  };
}

function changeRightPositionStatus(rightPositionData) {
  return {
    type: TYPES.RIGHT_POSITION,
    text: rightPositionData
  };
}

export function changeTempUnit(tempUnit) {
  return dispatch => {
    dispatch(changeTempUnitState(tempUnit));
  };
}

function changeTempUnitState(tempUnit) {
  return {
    type: TYPES.TEMP_UNIT,
    text: tempUnit
  };
}

export function celToFahrenheit(temp) {
  return Number.parseFloat(toFloat(temp * 1.8 + 32));
}

export function celToFahrenheitDelta(temp) {
  return Number.parseFloat(toFloat(temp * 1.8));
}

export var toFloat = function(value) {
  value = Math.round(parseFloat(value) * 10) / 10;

  if (value.toString().indexOf(".") < 0) {
    value = value.toString() + ".0";
  } else value = value.toString();

  return value;
};

export function updateUserNameState(userName) {
  return {
    type: TYPES.USER_LOGIN_NAME,
    text: userName
  };
}
