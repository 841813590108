import { API, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";
import _ from "lodash";
import { changelistStaff } from "./registerAction";

/**
 *  Created by shengli.zhang on 12/28/20 11:26 AM
 */

export function updateStaffProfile(input, self) {
  return async dispatch => {
    try {
      const newEvent = await API.graphql(
        graphqlOperation(_api.updateDoctorProfile(), { input })
      );
      if (newEvent) {
        self.setState({
          open: false,
          fullName: "",
          email: "",
          notificationEmail: "",
          weeklyNotificationStatus: "OFF",
          weeklyReportSend: false,
          userSiteId: [],
          inputValue: "",
          showCheckGroup: false,
          allSiteIdSelect: [],
          indeterminate: false,
          checkAll: false,
          selectValues: [],
          selectValuesBK: []
        });
        let staffs = _.clone(self.props.listStaff);
        let staffInfo = _(staffs).find({ email: input.email });
        if (input.notificationEmail) {
          staffInfo.notificationEmail = input.notificationEmail;
        }
        if (input.autoReportSites) {
          staffInfo.autoReportSites = input.autoReportSites;
        }
        if(input.weeklyNotificationStatus){
          staffInfo.weeklyNotificationStatus = input.weeklyNotificationStatus;
        }
        if(input.weeklyReportSend){
          staffInfo.weeklyReportSend = input.weeklyReportSend;
        }
        dispatch(changelistStaff(staffs));
      }
    } catch (e) {
      console.log(`updateStaffProfile exception: ${JSON.stringify(e)}`);
    }
  };
}
