import { Auth } from "aws-amplify";
import _ from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { Button as AntdButton, Modal as AntdModal } from "antd";
import { connect } from "react-redux";
import { Button, Form, Icon, Input, Modal, TextArea } from "semantic-ui-react";
import { deleteSirenNote, editNote } from "../../action/DoctorNoteAction";
import ButtonSuccess from "../home/buttonSuccess";
import "./AddText.css";
const { confirm } = AntdModal;
class Add extends Component {
  constructor(props) {
    super(props);
    this.submit.bind(this);
    this.state = {
      open: false,
      confirmSuccess: false,
      doctorId: "",
      userId: "",
      note: "",
      title: "",
      timestamp: "",
      id: "",
      loadings: []
    };
  }

  async componentDidMount() {
    const allParams = window.location.href.split("?")[1].includes("/")
      ? window.location.href.split("?")[1].split("/")[1]
      : window.location.href.split("?")[1];
    let params = queryString.parse(allParams);
    const id = params.id.replace(" ", "+");
    const userId = params.userId;
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ doctorId: user.username, userId, id });
  }

  handleOpen = () => {
    console.log("userName: ", this.props.userName);
    this.setState({
      open: true,
      confirmSuccess: false,
      note: this.props.note ? this.props.note : "",
      title: this.props.title ? this.props.title : "",
      timestamp: this.props.timestamp
    });
  };
  close = () =>
    this.setState({ open: false, confirmSuccess: false, loadings: [] });
  handleTitleChange = event => {
    this.setState({ title: event.target.value });
  };
  handleNoteChange = event => {
    this.setState({ note: event.target.value });
  };
  submit() {
    const { editNote } = this.props;
    this.setState({ open: false });
    const input = {
      id: this.state.id,
      author: this.state.doctorId,
      timestamp: this.state.timestamp
    };
    if (this.state.note && this.state.note !== "") {
      Object.assign(input, { note: this.state.note });
    }
    if (this.state.title && this.state.title !== "") {
      Object.assign(input, { title: this.state.title });
    }
    editNote(input, this);
  }
  isAutoGenNote() {
    const autoGenTitles = [
      "Special Case: Yes",
      "Special Case: No",
      "Account Status Active",
      "Account Status Paused",
      "Account Service Status: On Service",
      "Account Service Status: Not On Service"
    ];
    return autoGenTitles.includes(this.state.title);
  }

  deleteNoteConfirm = () => {
    confirm({
      title: "Delete Note",
      content: `Are you sure you want to delete this note?`,
      cancelText: "No",
      okText: "Yes",
      onCancel: () => {},
      onOk: () => {
        this.handleDeleteNote();
      }
    });
  };

  handleDeleteNote = () => {
    this.setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[0] = true;
      return {
        loadings: newLoadings
      };
    });
    const input = {
      id: this.props.id,
      timestamp: this.props.timestamp
    };
    this.props.deleteSirenNote(input, this);
  };

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <div
            className="note_title_table two_line_ellipses"
            onClick={this.handleOpen}
          >
            {_.isEmpty(this.props.title)
              ? _.isEmpty(this.props.note)
                ? ""
                : this.props.note.length > 30
                ? this.props.note.substring(0, 30) +
                  this.props.note
                    .split(this.props.note.substring(0, 30))[1]
                    .split(" ")[0] +
                  " ..."
                : this.props.note
              : this.props.title}
          </div>
        }
        open={open}
      >
        <Modal.Header>EDIT NOTE</Modal.Header>
        <Modal.Content>
          {this.state.confirmSuccess ? (
            <ButtonSuccess />
          ) : (
            <Form className="attached">
              <Form.Group>
                <Form.Field width="16">
                  <Input
                    readOnly={this.isAutoGenNote()}
                    onChange={(e, data) => {
                      this.handleTitleChange(e);
                    }}
                    value={this.state.title}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width="16">
                  <TextArea
                    className="note_comments"
                    onChange={(e, data) => {
                      this.handleNoteChange(e);
                    }}
                    value={this.state.note}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions style={{ display: "flex" }}>
          {_.isEqual(this.props.userName, this.props.author) ||
          _.isEqual(this.props.userName, this.props.authorId) ? (
            <AntdButton
              type="link"
              style={{
                color: "red",
                float: "left"
              }}
              loading={this.state.loadings[0]}
              onClick={this.deleteNoteConfirm}
            >
              Delete
            </AntdButton>
          ) : null}
          <div style={{ flex: 1 }} />
          {_.isEqual(this.props.userName, this.props.author) ||
          _.isEqual(this.props.userName, this.props.authorId) ? (
            <Button
              color="green"
              onClick={e => this.submit()}
              style={{
                display: this.state.confirmSuccess ? "none" : "inline-block"
              }}
            >
              <Icon name="checkmark" />
              Submit
            </Button>
          ) : null}
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.confirmSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProp = state => ({
  doctorDetails: state.patientsListStore.doctorDetails,
  userName: state.patientsListStore.userName
});
const mapDispatchToProp = dispatch => ({
  editNote: (input, self) => dispatch(editNote(input, self)),
  deleteSirenNote: (input, self) => dispatch(deleteSirenNote(input, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(Add);
