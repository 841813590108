import { Select } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { curMonthPatientPCM } from "../action/bliMonthyReportAction";
import { getlistClinicSites } from "../action/registerAction";
import ReportHeader99426_7 from "./ReportHeader99426_7";
import ReportCurrentMonthBase from "./ReportCurrentMonthBase";

const { Option } = Select;

class ReportCurrentMonth99426_7 extends ReportCurrentMonthBase {

  headerClass = ReportHeader99426_7

  renderTableProps() {
    const data = this.state.tableData.map(p => {
      const sirenId = p.sirenId ? parseInt(p.sirenId) : "";
      const createdAt = p.createdAt ? p.createdAt.split("T")[0] : "";
      const dispensedDay = p.dispensedDay ? p.dispensedDay : createdAt;
      const billingYear = this.state.billingYear;
      const billingMonth = moment().format("MM/YYYY");
      const endDay = parseInt(
        moment()
          .endOf("month")
          .format("DD")
      );
      const curDay = parseInt(moment().format("DD"));
      const daysRemaining = endDay - curDay;
      const spentTime = p.totalMinutesSpent ? p.totalMinutesSpent : 0;
      const Eligible = spentTime >= 30 ? "Yes" : "No";
      const appInitialized = p.appInitialized ? p.appInitialized : "No";
      const diagnosisCodes = p.diagnosisCodes
        ? p.diagnosisCodes.replace(/_/g, ",")
        : "";
      return {
        ...p,
        createdAt,
        sirenId,
        dispensedDay,
        billingYear,
        billingMonth,
        spentTime,
        Eligible,
        daysRemaining,
        diagnosisCodes,
        appInitialized
      };
    })

    const columns = [
      {
        Header: "SiteID",
        accessor: "siteId",
        maxWidth: 60,
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      },
      {
        Header: "SiteName",
        accessor: "siteName",
        minWidth: 100,
        filterMethod: (filter, row) => {
          let rowfilterfullname = row._original.siteName
            ? row[filter.id].toLowerCase()
            : "";
          let filterfullname = filter.value.toLowerCase();
          return rowfilterfullname.includes(filterfullname);
        }
      },
      {
        Header: "Doctor",
        accessor: "doctorName",
        minWidth: 100,
        filterMethod: (filter, row) => {
          let rowfilterfullname = row._original.doctorName
            ? row[filter.id].toLowerCase()
            : "";
          let filterfullname = filter.value.toLowerCase();
          return rowfilterfullname.includes(filterfullname);
        }
      },
      {
        Header: "SirenID",
        accessor: "sirenId",
        maxWidth: 60,
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      },
      {
        Header: "PatientID",
        accessor: "patientId",
        filterMethod: (filter, row) => {
          return String(row[filter.id])
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }
      },
      {
        Header: "First Name",
        accessor: "firstName",
        filterMethod: (filter, row) => {
          let rowfilterfullname = row._original.firstName
            ? row[filter.id].toLowerCase()
            : "";
          let filterfullname = filter.value.toLowerCase();
          return rowfilterfullname.includes(filterfullname);
        }
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        filterMethod: (filter, row) => {
          let rowfilterfullname = row._original.lastName
            ? row[filter.id].toLowerCase()
            : "";
          let filterfullname = filter.value.toLowerCase();
          return rowfilterfullname.includes(filterfullname);
        }
      },
      {
        Header: "Date of Birth",
        accessor: "birthday",
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      },
      {
        Header: "ICD-10 Codes",
        accessor: "diagnosisCodes",
        filterMethod: (filter, row) => {
          return String(row[filter.id].toLowerCase()).includes(
            filter.value.toLowerCase()
          );
        }
      },
      {
        Header: "Account Created",
        accessor: "createdAt",
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      },
      {
        Header: "App Activated",
        accessor: "appInitialized",
        maxWidth: 72,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          if (filter.value === "Yes") {
            return row[filter.id] === "Yes";
          }
          return row[filter.id] === "No";
        },
        Filter: ({ filter, onChange }) => (
          <Select
            className="select_common_style"
            onChange={onChange}
            style={{ width: "100%" }}
            value={filter ? filter.value : "all"}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            optionFilterProp="children"
          >
            <Option value="all">All</Option>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        )
      },
      {
        Header: "Monitoring Month",
        accessor: "billingMonth",
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      },
      {
        Header: "Total Time (mins)",
        accessor: "spentTime",
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      },
      {
        Header: "Days Remaining",
        accessor: "daysRemaining",
        filterMethod: (filter, row) => {
          return String(row[filter.id]).includes(filter.value);
        }
      }
    ]

    return {data, columns}
  }

}

const mapStateToProp = state => ({
  authSites: state.authGroupStore.profile.siteId,
  clinicSites: state.patientsListStore.clinicSites,
});

const mapDispatchToProp = dispatch => ({
  searchCurrentMonthPatients: (billingInputValue) =>
    dispatch(curMonthPatientPCM(billingInputValue)),
  getlistClinicSites: () => dispatch(getlistClinicSites())
});

export default connect(
  mapStateToProp,
  mapDispatchToProp
)(ReportCurrentMonth99426_7);
