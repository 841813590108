import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from "react";

export default function Progress(props) {
  const spin = <LoadingOutlined spin />

  return (
    <div className="progress">
      <Spin size="large" indicator={spin} />
    </div>
  )
}
