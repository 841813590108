import { API, Auth, graphqlOperation } from "aws-amplify";
import _api from "../graphql/_api";
import {
  AUTH_GROUP,
  DOCTOR_PROFILE,
  LIST_ICD_10_CODES,
  LIST_MEDICAL_CONDITIONS,
} from "../types/types";

export function getUserGroup() {
  return async dispatch => {
    const session = await Auth.currentSession();
    const groups = session.getIdToken().payload[`cognito:groups`];
    if(groups) {
      dispatch(changeUserGroupState(groups[0]));
    }
  };
}

function changeUserGroupState(groups) {
  return {
    type: AUTH_GROUP,
    text: groups
  };
}

export function getDoctorProfile() {
  return async dispatch => {
    const newEvent = await API.graphql(
      graphqlOperation(_api.getDoctorProfile())
    );
    const profile = newEvent.data.doctorProfile;
    profile.siteId = profile.siteId.split('-').sort((a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });

    dispatch({
      type: DOCTOR_PROFILE,
      profile
    });
  }
}

export async function listICD10Codes(dispatch) {
  const result = await API.graphql(
    graphqlOperation(_api.listICD10Codes())
  );

  dispatch({
    type: LIST_ICD_10_CODES,
    payload: result.data.listICD10Codes.sort((a, b) => a.code.localeCompare(b.code))
  })
}

export async function listMedicalConditions(dispatch) {
  const result = await API.graphql(
    graphqlOperation(_api.listMedicalConditions())
  );

  dispatch({
    type: LIST_MEDICAL_CONDITIONS,
    payload: result.data.listMedicalConditions.sort((a, b) => a.condition.localeCompare(b.condition))
  })
}
