import { Popover } from "antd";
import _ from 'lodash';
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import FootSvg from "./footSvg";
import "./status.css";

class FootStatus extends Component {

  render() {
    return (
      <div
        className="status_userbox1"
        id="footSize"
        style={{
          width: this.props.Developer ? "44%" : this.props.staff ? "100%" : null
        }}
      >
        <div className="footTitle">
          <span className="box1header2">
            Updated:{moment(this.props.changeFootDate).format("YYYY-MM-DD")}
          </span>
        </div>
        <div id="imgboxtotal"  style={{height:406}}>
          <FootSvg footStatus={this.props.footStatus} />
            <div className="footTitle_box">
            <div className="right_footTitle">Bottom Right</div>
            <div className="left_footTitle">Bottom Left</div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProp = state => ({
  footStatus: state.patientsListStore.footStatus,
  changeFootDate: state.patientsListStore.changeFootDate
});

export default connect(
  mapStateToProp,
  null
)(FootStatus);
