import { Select } from "antd";
import { isEmpty } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Icon, Input, Modal } from "semantic-ui-react";
import {
  createDoctorAccount,
  getlistClinicSites
} from "../action/registerAction";
import { getDoctorNpi } from "../action/toolsAction";
import message from "../tools/message-info";
import { lowercaseRmSpace } from "../tools/stringProcess";
import "./NewSite.css";
import StaffSuccess from "./StaffSuccess";
const { Option } = Select;


class NewSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      addStaffSuccess: false,
      isPrescribing: false,
      doctorNPI: "",
      doctorNPIs: [],
      newDoctorNPI: "",
      selectedSites: []
    };
  }

  handleCheckSites = (value) => {
    this.setState({
      selectedSites: value
    })
  }

  onChangedDoctorNPI = event => {
    this.setState({ doctorNPI: event });
  };

  changeSearchDoctorNPI(value) {
    if (!!value) {
      this.setState({ newDoctorNPI: value });
    }
  }

  changeBlurDoctorNPI(value) {
    const { newDoctorNPI } = this.state;
    if (!!newDoctorNPI) {
      this.onChangedDoctorNPI(newDoctorNPI);
      this.setState({ newDoctorNPI: "" });
    }
  }

  changeFirstName = event => {
    this.setState({ firstName: event.target.value });
  };

  changeLastName = event => {
    this.setState({ lastName: event.target.value });
  };
  changeEmail = event => {
    this.setState({ email: lowercaseRmSpace(event.target.value) });
  };

  changeDoctorNPI = event => {
    this.setState({ doctorNPI: event });
  };

  componentDidMount() {
    const { getlistClinicSites } = this.props;
    getlistClinicSites();
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };
  close = () =>
    this.setState({
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      addStaffSuccess: false
    });
  handleCreateAccount = () => {
    const { createDoctorAccount } = this.props;
    if (this.state.firstName === "") {
      message.info("Please input First Name");
      return;
    }
    if (this.state.lastName === "") {
      message.info("Please input Last Name");
      return;
    }
    if (this.state.email === "") {
      message.info("Please input email");
      return;
    }
    if (this.state.selectedSites.length === 0) {
      message.info("Please select sites");
      return;
    }
    let CreateDoctorInput = {
      input: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        doctorId: "1234",
        isPrescribing: this.state.isPrescribing,
        siteId: this.state.selectedSites.join("-"),
        doctorRole: "STAFF_CLINICAL"
      }
    };
    if (this.state.doctorNPI !== "") {
      Object.assign(CreateDoctorInput.input, {
        doctorNPI: this.state.doctorNPI
      });
    }
    createDoctorAccount(CreateDoctorInput).then(() => {
      this.setState({
        addStaffSuccess: true
      });
    });
  };

  checkDoctorNpi(event) {
    if (isEmpty(this.state.firstName) || isEmpty(this.state.lastName)) {
      return;
    }
    const { getDoctorNpi } = this.props;
    getDoctorNpi(this.state.firstName, this.state.lastName, this);
  }

  render() {
    const { open } = this.state;
    return (
      <Modal
        trigger={
          <Button size="small" onClick={this.handleOpen}>
            New Staff
          </Button>
        }
        open={open}
        className="add-box-form-container"
      >
        <Modal.Header>
          {this.state.addStaffSuccess
            ? "New Staff Account Has Been Created"
            : "Create New Staff Account"}
        </Modal.Header>
        <Modal.Content>
          {this.state.addStaffSuccess ? (
            <StaffSuccess
              SuccessFirstName={this.state.firstName}
              SuccessLastName={this.state.lastName}
              SuccessSiteId={this.state.selectedSites.join('-')}
            />
          ) : (
            <div className="newSite_box">
              <p className="checktext">Enter staff’s name and email address.</p>
              <div className="inputTotal">
                <span className="checkbox_label">Prescribing Doctor</span>
                <Checkbox
                  className="checkbox_box"
                  checked={this.state.isPrescribing}
                  onChange={e => this.setState({ isPrescribing: e.target.checked })}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">
                    First Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="John"
                    className="inputContent"
                    onChange={this.changeFirstName}
                    value={this.state.firstName}
                    onBlur={event => this.checkDoctorNpi(event)}
                    onBonKeyPress={event => this.checkDoctorNpi(event)}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="Smith"
                    className="inputContent"
                    onChange={this.changeLastName}
                    value={this.state.lastName}
                    onBlur={event => this.checkDoctorNpi(event)}
                    onBonKeyPress={event => this.checkDoctorNpi(event)}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  marginLeft: "10%",
                  marginRight: "10%"
                }}
              >
                <div className="inputTotal">
                  <p className="inputLable">
                    Email <span style={{ color: "red" }}>*</span>
                  </p>
                  <Input
                    placeholder="Johnsmith@email.com"
                    className="inputContent"
                    onChange={this.changeEmail}
                    value={this.state.email}
                  />
                </div>
                <div className="inputTotal">
                  <p className="inputLable">Provider NPI</p>
                  <Select
                    className="selectStyle"
                    value={this.state.doctorNPI}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch
                    optionFilterProp="children"
                    onChange={this.onChangedDoctorNPI}
                    onSearch={value => this.changeSearchDoctorNPI(value)}
                    onBlur={value => this.changeBlurDoctorNPI(value)}
                  >
                    <Option value={""}>None</Option>
                    {this.state.doctorNPIs.map((npi, i) => {
                      return (
                        <Option value={npi.number} key={"npi" + i}>
                          {npi.number +
                            " - " +
                            npi.addresses[0].city +
                            " " +
                            npi.addresses[0].state +
                            " " +
                            npi.addresses[0].postal_code}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="inputTotal" style={{ width: 620 }}>
                <p className="inputLable">
                  Sites <p1 style={{ color: "red" }}>*</p1>
                </p>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Please select sites"
                  onChange={this.handleCheckSites}
                  optionLabelProp="value"
                >
                  {
                    this.props.clinicSites.map(site => (
                      <Option key={site.siteId} value={site.siteId}>{site.siteId} {site.name}</Option>
                    ))
                  }
                </Select>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={this.handleCreateAccount}
            style={{
              display: this.state.addStaffSuccess ? "none" : "inline-block"
            }}
          >
            <Icon name="checkmark" />
            CREATE STAFF
          </Button>
          <Button basic color="red" onClick={e => this.close()}>
            <Icon name="remove" />
            {this.state.addStaffSuccess ? "Close" : "Cancel"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProp = state => ({
  clinicSites: state.patientsListStore.clinicSites
});
const mapDispatchToProp = dispatch => ({
  createDoctorAccount: (CreateDoctorInput) =>
    dispatch(createDoctorAccount(CreateDoctorInput)),
  getlistClinicSites: () => dispatch(getlistClinicSites()),
  getDoctorNpi: (firstName, lastName, self) =>
    dispatch(getDoctorNpi(firstName, lastName, self))
});
export default connect(mapStateToProp, mapDispatchToProp)(NewSite);
